import React from 'react'
import './LoginCard.css'
const LoginCard = ({ className, children }) => {
    return (
        <div className="login-card-wrapper">
            <div className={(className || "") + "login-card"}>
                {children}
            </div>
        </div>);
}

export const Header = ({ title, children }) => {
    return (
        <div className="header">
            <h1 className="login-card-title">{title}</h1>
            <div className="login-card-title-childern">
                {children}
            </div>
        </div>);
}

export const Body = ({ children }) => {
    return (
        <div className="login-card-body">
            {children}
        </div>);
}


export default LoginCard;
