import React, { useEffect, useState } from "react";
import { Field, reduxForm, FieldArray, Fields } from "redux-form";

import { renderDropDown, renderCheckbox } from "../../common/ReduxFormHelper";
import { IconButton } from "../../common/Buttons";
import stepTwoValidation from "./stepTwoValidation";

import ExploreContainer from "../../../containers/user/explore/Explore";

import {
  JobEventTypes,
  JobEventCompletionTypes,
  FileSizeCriterias,
  NewJobEvent,
  FileExsistsCriterias,
  TimeMeasurement,
  SizeMeasurement,
  MaxThreads,
  MaxBundling,
} from "../../../selectors/Job";

import "./wizzard.css";
import Card, { Header, Body } from "../../common/Card";
import { useModal } from "../../modal/Modal";

export const renderField = ({
  input,
  readOnly,
  label,
  type,
  placeholder,
  meta: { error },
  disabled,
}) => {
  const className = error && !disabled ? "has-error form-group" : "form-group";

  if (label && !placeholder) placeholder = label;

  return (
    <div className={className}>
      <label>{label || <span>&nbsp;</span>}</label>

      {disabled ? (
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className="form-control form-control-lg border-input"
          disabled
        />
      ) : (
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className="form-control form-control-lg border-input"
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export const renderlabelWithError = ({
  label,
  meta: { touched, error },
  onClick,
}) => {
  const className = error ? "has-error" : "";

  return (
    <div className={className} onClick={onClick || (() => {})}>
      <label className="control-label">{label}</label>
    </div>
  );
};

const GeneralInfo = ({ event, index }) => {
  return (
    <React.Fragment>
      <h6>General</h6>
      <Field
        component={renderDropDown}
        name={`${event}.eventType`}
        label="Event type"
        options={JobEventTypes}
      />
      <Field
        name={`${event}.description`}
        type="text"
        component={renderField}
        label="Description"
      />
    </React.Fragment>
  );
};

const Source = ({ eventObj, event, updateJobEvent, index }) => {
  return (
    <React.Fragment>
      <h6>Source endpoint</h6>
      <div className="row mr-0 ml-0">
        <div className="col-md-12 p-0">
          <div className="wiz-event-folder-card">
            <div className="row mr-0 ml-0">
              <div className="col-sm-4">
                <strong>Account</strong>
              </div>
              <div className="col-sm-8 break-word">
                {eventObj.source.accountName}
              </div>
              <div className="col-sm-4">
                <strong>Organisation</strong>
              </div>
              <div className="col-sm-8 break-word">
                {eventObj.source.organisationName}
              </div>
              <div className="col-sm-4">
                <strong>Connector</strong>
              </div>
              <div className="col-sm-8 break-word">
                {eventObj.source.clientName}
              </div>
              <div className="col-sm-4">
                <strong>Path</strong>
              </div>
              <div className="col-sm-8 break-word">{eventObj.source.path}</div>
              {eventObj.source.isDeleted ? (
                <div className="alert alert-danger col-sm-12">
                  <span>
                    The path does not exist! Have you changed or deleted the path 
                     on connector {eventObj.source.clientName}?
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 p-0 text-right">
          {eventObj.source.path && !eventObj.source.filesFromPreviousEvent ? (
            <button
              className="btn btn-light btn-lg"
              onClick={() => {
                updateJobEvent(`${event}.source.sourceId`, null);
                updateJobEvent(`${event}.source.path`, null);
                updateJobEvent(`${event}.source.shareId`, null);
                updateJobEvent(`${event}.source.clientName`, null);
                updateJobEvent(`${event}.source.accountName`, null);
                updateJobEvent(`${event}.source.organisationName`, null);
                updateJobEvent(`${event}.source.isDeleted`, false);
              }}
            >
              Clear
            </button>
          ) : (
            ""
          )}

          {!eventObj.source.filesFromPreviousEvent && (
            <SelectFolder
              title="Select source"
              init={{
                id: eventObj.source.sourceId,
                type: "Folder",
                shareId: eventObj.source.shareId,
              }}
              onSelect={(source) => {
                if (index === 0) {
                  updateJobEvent(`trigger.pathId`, source.id);
                  updateJobEvent(`trigger.source`, source.path);
                  //updateJobEvent(`trigger.clientName`, source.connector)
                  updateJobEvent(`trigger.connector`, source.connector);
                  updateJobEvent(`trigger.account`, source.accountName);
                  updateJobEvent(
                    `trigger.organisationName`,
                    source.organisationName
                  );
                }
                updateJobEvent(`${event}.source.sourceId`, source.id);
                updateJobEvent(`${event}.source.path`, source.path);
                updateJobEvent(`${event}.source.shareId`, source.shareId);
                updateJobEvent(`${event}.source.clientName`, source.connector);
                updateJobEvent(
                  `${event}.source.accountName`,
                  source.accountName
                );
                updateJobEvent(
                  `${event}.source.organisationName`,
                  source.organisationName
                );
                updateJobEvent(`${event}.source.isDeleted`, false);
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const Target = ({ eventObj, event, updateJobEvent, index }) => {
  useEffect(()=>{
    if (!eventObj.destination) {
      updateJobEvent(`${event}.destination.sourceId`, null);
      updateJobEvent(`${event}.destination.path`, null);
      updateJobEvent(`${event}.destination.shareId`, null);
      updateJobEvent(`${event}.destination.clientName`, null);
      updateJobEvent(`${event}.destination.accountName`, null);
      updateJobEvent(`${event}.destination.organisationName`, null);
      updateJobEvent(`${event}.destination.fileExsistsCriteria`, 601);
    }
  },[event, eventObj.destination, updateJobEvent])

  if( !eventObj.destination ) {
    return ""
  }
  return (
    <React.Fragment>
      {parseInt(eventObj.eventType, 10) !== 3 ? (
        <React.Fragment>
          <h6>Target endpoint</h6>
          <div className="row mr-0 ml-0">
            <div className="col-md-12 p-0">
              <div className="wiz-event-folder-card">
                <div className="row mr-0 ml-0">
                  <div className="col-sm-4">
                    <strong>Account</strong>
                  </div>
                  <div className="col-sm-8 break-word">
                    {eventObj.destination.accountName}
                  </div>
                  <div className="col-sm-4">
                    <strong>Organisation</strong>
                  </div>
                  <div className="col-sm-8 break-word">
                    {eventObj.destination.organisationName}
                  </div>
                  <div className="col-sm-4">
                    <strong>Connector</strong>
                  </div>
                  <div className="col-sm-8 break-word">
                    {eventObj.destination.clientName}
                  </div>
                  <div className="col-sm-4">
                    <strong>Path</strong>
                  </div>
                  <div className="col-sm-8 break-word">{eventObj.destination.path}</div>
                  {eventObj.destination.isDeleted ? (
                    <div className="alert alert-danger col-sm-12">
                      <span>
                        The path does not exist! Have you changed or deleted the path 
                         on connector {eventObj.destination.clientName}?
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0 text-right">
              {eventObj.destination.path ? (
                <button
                  className="btn btn-light btn-lg"
                  onClick={() => {
                    updateJobEvent(`${event}.destination.sourceId`, null);
                    updateJobEvent(`${event}.destination.path`, null);
                    updateJobEvent(`${event}.destination.shareId`, null);
                    updateJobEvent(`${event}.destination.clientName`, null);
                    updateJobEvent(`${event}.destination.accountName`, null);
                    updateJobEvent(
                      `${event}.destination.organisationName`,
                      null
                    );
                    updateJobEvent(`${event}.destination.isDeleted`, false);
                  }}
                >
                  Clear
                </button>
              ) : (
                ""
              )}
              <SelectFolder
                title="Select target"
                init={{
                  id: eventObj.destination.sourceId,
                  type: "Folder",
                  shareId: eventObj.destination.shareId,
                }}
                onSelect={(target) => {
                  updateJobEvent(`${event}.destination.sourceId`, target.id);
                  updateJobEvent(`${event}.destination.path`, target.path);
                  updateJobEvent(
                    `${event}.destination.shareId`,
                    target.shareId
                  );
                  updateJobEvent(
                    `${event}.destination.clientName`,
                    target.connector
                  );
                  updateJobEvent(
                    `${event}.destination.accountName`,
                    target.accountName
                  );
                  updateJobEvent(
                    `${event}.destination.organisationName`,
                    target.organisationName
                  );
                  updateJobEvent(`${event}.destination.isDeleted`, false);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const SelectionCriterias = ({
  previousEvent,
  eventObj,
  event,
  updateJobEvent,
  showAdvanced,
  setShowAdvanced,
}) => {
  return (
    <React.Fragment>
      <h6>Selection criterias</h6>
      {previousEvent && parseInt(previousEvent.eventType, 10) === 1 && (
        <Field
          name={`${event}.source.filesFromPreviousEvent`}
          component={renderCheckbox}
          type="checkbox"
          label="Files from previous event"
          onChange={(ev) => {
            if (ev.target.checked) {
              updateJobEvent(
                `${event}.source.sourceId`,
                previousEvent.source.sourceId
              );
              updateJobEvent(`${event}.source.path`, previousEvent.source.path);
              updateJobEvent(
                `${event}.source.shareId`,
                previousEvent.source.shareId
              );
              updateJobEvent(
                `${event}.source.clientName`,
                previousEvent.source.clientName
              );
              updateJobEvent(
                `${event}.source.accountName`,
                previousEvent.source.accountName
              );
              updateJobEvent(
                `${event}.source.organisationName`,
                previousEvent.source.organisationName
              );
              updateJobEvent(`${event}.source.isDeleted`, false);
              return;
            }
            updateJobEvent(`${event}.source.sourceId`, null);
            updateJobEvent(`${event}.source.path`, null);
            updateJobEvent(`${event}.source.shareId`, null);
            updateJobEvent(`${event}.source.clientName`, null);
            updateJobEvent(`${event}.source.accountName`, null);
            updateJobEvent(`${event}.source.organisationName`, null);
            updateJobEvent(`${event}.source.isDeleted`, false);
          }}
        />
      )}

      {!eventObj.source.filesFromPreviousEvent && (
        <div className="row mr-0 ml-0">
          <div className="col-md-3 wiz-event-select-crit">
            <Field
              name={`${event}.source.fileNamePattern`}
              type="text"
              component={renderField}
              label="Include files with filname patterns"
              placeholder="eg: *.*"
            />
          </div>
          <div className=" col-md-3 wiz-event-select-crit">
            <Field
              name={`${event}.source.fileNamePatternExclude`}
              type="text"
              component={renderField}
              label="Exclude files with filname patterns"
              placeholder="eg: *.txt;*.xml"
            />
          </div>
          <div className="col-md-2 wiz-event-select-crit">
            {showAdvanced && (
              <Field
                name={`${event}.source.minMinutesModified`}
                type="number"
                component={renderField}
                label="Minimum file age (minutes)"
              />
            )}
          </div>
          <div className="col-md-2 wiz-event-select-crit">
            {showAdvanced && (
              <Field
                component={renderDropDown}
                emptyOption={true}
                emptyOptionLabel="Select size option"
                label="File size option"
                name={`${event}.source.fileSizeCriteria`}
                options={FileSizeCriterias}
              />
            )}
          </div>
          <div className="col-md-2 wiz-event-select-crit">
            {showAdvanced && (
              <Field
                name={`${event}.source.fileSizeCriteriaValue`}
                type="number"
                component={renderField}
                label="File size (in bytes)"
                placeholder="File size (bytes)"
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const SourceOptions = ({ event, eventObj }) => {
  return (
    <React.Fragment>
      <h6>Source options</h6>
      <Field
        name={`${event}.source.forceSync`}
        component={renderCheckbox}
        type="checkbox"
        label="Force source sync before execution"
      />
      {!eventObj.source.filesFromPreviousEvent && (
        <Field
          name={`${event}.source.includeSubFolders`}
          component={renderCheckbox}
          type="checkbox"
          label="Include subdirectories"
        />
      )}
    </React.Fragment>
  );
};

const TargetOptions = ({ event, eventObj, index }) => {
  return (
    <React.Fragment>
      <h6>Target options</h6>
      <Field
        name={`${event}.destination.forceSync`}
        component={renderCheckbox}
        type="checkbox"
        label="Force target sync before execution"
      />
      <Fields
        names={[`${event}.eventType`]}
        component={(fields) => {
          return (
            (fields["events"][index].eventType.input.value.toString() === "1" ||
              fields["events"][index].eventType.input.value.toString() ===
                "2") && (
              <Field
                name={`${event}.destination.destinationisArchive`}
                component={renderCheckbox}
                type="checkbox"
                label="Target is archive"
              />
            )
          );
        }}
      />
      {parseInt(eventObj.eventType, 10) !== 3 && (
        <div>
          <div className="wiztooltip float-left mr-2">
            <i className="info icon-info-circled"></i>
            <span> </span>
            <span>
              <strong>%fileext%</strong>
              <br />
              File extention from original file. <br />
              <strong>%filename%</strong>
              <br />
              Original filename without extension
              <br />
              <strong>%datefile%</strong>
              <br />
              Mod date from original file (yyyyMMdd)
              <br />
              <strong>%timefile%</strong>
              <br />
              Mod time from original file (HHmmss)
              <br />
              <strong>%date%</strong>
              <br />
              Date of transfer (yyyyMMdd)
              <br />
              <strong>%time%</strong>
              <br />
              Time of transfer (HHmmss)
              <br />
              <strong>%counter%</strong>
              <br />
              Generate a unique counter
            </span>
          </div>
          <Field
            name={`${event}.destination.targetFileName`}
            type="text"
            component={renderField}
            label="Target file name"
          />
        </div>
      )}

      {parseInt(eventObj.eventType, 10) !== 3 && (
        <Field
          component={renderDropDown}
          label="If file exist on target:"
          name={`${event}.destination.fileExsistsCriteria`}
          options={FileExsistsCriterias}
        />
      )}
    </React.Fragment>
  );
};

const OtherOptions = ({ event, eventObj }) => {
  return (
    <React.Fragment>
      <h6>Process options</h6>

      <Field
        name={`${event}.source.throwErrorIfEmpty`}
        component={renderCheckbox}
        type="checkbox"
        label="Throw error if event runs empty"
      />

      {parseInt(eventObj.eventType, 10) !== 3 && (
        <Field
          name={`${event}.options.unEncrypt`}
          component={renderCheckbox}
          type="checkbox"
          label="Do not encrypt"
        />
      )}

      <Field
        name={`${event}.options.maxBundling`}
        label="Max bundling"
        component={renderDropDown}
        options={MaxBundling}
      />

      <Field
        name={`${event}.options.maxThreads`}
        label="Max threads"
        component={renderDropDown}
        options={MaxThreads}
      />

      <Field
        component={renderDropDown}
        name={`${event}.onSuccess`}
        options={JobEventCompletionTypes}
        label="On Success"
      />
      <Field
        component={renderDropDown}
        name={`${event}.onFailure`}
        options={JobEventCompletionTypes}
        label="On Error"
      />

      <Field
        name={`${event}.maxNumberOfError`}
        component={renderField}
        type="number"
        label="Accept max no. of errors to report success"
      />
    </React.Fragment>
  );
};

const EventBody = ({
  isOpen,
  updateJobEvent,
  event,
  eventObj,
  index,
  previousEvent,
  clearEventAlarm,
}) => {
  
  const [showAdvanced, setShowAdvanced] = useState(false);

  if (!isOpen) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="row mr-0 ml-0">
        <div className="col-xl-4 col-lg-12 wiz-event-form-content">
          <GeneralInfo event={event} index={index} />
        </div>
        <div className={parseInt(eventObj.eventType, 10) !== 3 ? "col-xl-4 col-lg-6 wiz-event-form-content" : "col-xl-8 col-lg-6 wiz-event-form-content"}>
          <Source
            event={event}
            eventObj={eventObj}
            updateJobEvent={updateJobEvent} 
            index={index}
          />
        </div>
        {parseInt(eventObj.eventType, 10) !== 3 ?
          <div className="col-xl-4 col-lg-6 wiz-event-form-content">
            
              <Target
                event={event}
                eventObj={eventObj}
                updateJobEvent={updateJobEvent}
                index={index}
              />
          </div>
        : ""
        }
      </div>
      <div className="row mr-0 ml-0">
        <div className="col-md-12 wiz-event-form-content">
          <SelectionCriterias
            previousEvent={previousEvent}
            eventObj={eventObj}
            event={event}
            showAdvanced={showAdvanced}
            setShowAdvanced={setShowAdvanced}
            updateJobEvent={updateJobEvent}
          />
        </div>
      </div>
      {showAdvanced && (
        <div>
          <div className="row mr-0 ml-0">
            <div className="col-lg-6 wiz-event-form-content">
              <SourceOptions event={event} eventObj={eventObj} />
              {parseInt(eventObj.eventType, 10) !== 3 ?
                <TargetOptions event={event} eventObj={eventObj} index={index} />
              : ""}
            </div>
            <div className="col-lg-6 wiz-event-form-content">
              <OtherOptions event={event} eventObj={eventObj} />
            </div>
          </div>

          <div className="row mr-0 ml-0">
            <div className="col-md-12 wiz-event-form-content">
              <h6>Expected activity</h6>
            </div>
            <div className="col-md-12 wiz-event-form-content">
              <Field
                name={`${event}.options.limitCheckOnlyOnWeekdays`}
                component={renderCheckbox}
                type="checkbox"
                label="Only check weekdays"
              />
            </div>
          </div>

          <div className="row mr-0 ml-0">
            <div className="col-md-12 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsLessThan.isActive`}
                component={renderCheckbox}
                type="checkbox"
                label="Send alarm if the total amount of data is less than"
                onChange={(e) =>
                  clearEventAlarm(
                    `${event}.sendAlarmIfTotalAmountDataIsLessThan`,
                    e.target.value
                  )
                }
              />
            </div>
            <div className="col-md-2 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsLessThan.data`}
                label="No. of units"
                component={renderField}
                type="number"
              />
            </div>
            <div className="col-md-2 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsLessThan.size`}
                label="Select volume unit"
                component={renderDropDown}
                options={SizeMeasurement}
              />
            </div>
            <div className="col-md-4 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsLessThan.pastTime`}
                component={renderField}
                label="the past"
                type="number"
              />
            </div>
            <div className="col-md-3 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsLessThan.time`}
                label="Select time unit"
                component={renderDropDown}
                options={TimeMeasurement}
              />
            </div>
          </div>
          <div className="row mr-0 ml-0">
            <div className="col-md-12 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsGreaterThan.isActive`}
                component={renderCheckbox}
                type="checkbox"
                label="Send alarm if the total amount of data is greater than"
                onChange={(e) =>
                  clearEventAlarm(
                    `${event}.sendAlarmIfTotalAmountDataIsLessThan`,
                    e.target.value
                  )
                }
              />
            </div>
            <div className="col-md-2 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsGreaterThan.data`}
                label="No. of units"
                component={renderField}
                type="number"
              />
            </div>
            <div className="col-md-2 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsGreaterThan.size`}
                label="Select volume unit"
                component={renderDropDown}
                options={SizeMeasurement}
              />
            </div>
            <div className="col-md-4 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsGreaterThan.pastTime`}
                component={renderField}
                type="number"
                label="the past"
              />
            </div>
            <div className="col-md-3 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalAmountDataIsGreaterThan.time`}
                label="Select time unit"
                component={renderDropDown}
                options={TimeMeasurement}
              />
            </div>
          </div>
          <div className="row mr-0 ml-0">
            <div className="col-md-12 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsLessThan.isActive`}
                component={renderCheckbox}
                type="checkbox"
                label="Send alarm if the total number of files is less than"
                onChange={(e) =>
                  clearEventAlarm(
                    `${event}.sendAlarmIfTotalAmountDataIsLessThan`,
                    e.target.value
                  )
                }
              />
            </div>
            <div className="col-md-4 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsLessThan.data`}
                label="No. of files"
                component={renderField}
                type="number"
              />
            </div>
            <div className="col-md-4 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsLessThan.pastTime`}
                component={renderField}
                type="number"
                label="the past"
              />
            </div>
            <div className="col-md-3 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsLessThan.time`}
                label="Select time unit"
                component={renderDropDown}
                options={TimeMeasurement}
              />
            </div>
          </div>
          <div className="row mr-0 ml-0">
            <div className="col-md-12 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsGreaterThan.isActive`}
                component={renderCheckbox}
                type="checkbox"
                label="Send alarm if the total number of files is greater than"
                onChange={(e) =>
                  clearEventAlarm(
                    `${event}.sendAlarmIfTotalAmountDataIsLessThan`,
                    e.target.value
                  )
                }
              />
            </div>
            <div className="col-md-4 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsGreaterThan.data`}
                label="No. of files"
                component={renderField}
                type="number"
              />
            </div>
            <div className="col-md-4 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsGreaterThan.pastTime`}
                component={renderField}
                label="the past"
                type="number"
              />
            </div>
            <div className="col-md-3 wiz-event-form-content">
              <Field
                name={`${event}.options.sendAlarmIfTotalNumberFilesIsGreaterThan.time`}
                label="Select time unit"
                component={renderDropDown}
                options={TimeMeasurement}
              />
            </div>
          </div>
          <div className="col-md-12 wiz-event-form-content">
            <button
              className="btn float-right btn-link"
              href="#"
              onClick={() => setShowAdvanced(false)}
            >
              Hide advanced options
            </button>
          </div>
        </div>
      )}

      {!showAdvanced && (
        <div className="row mr-0 ml-0">
          <div className="col-md-12 wiz-event-form-content">
            {
              <button
                className="btn btn-link float-right"
                onClick={() => setShowAdvanced(true)}
              >
                Show advanced options
              </button>
            }
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const SelectFolder = ({ title, onSelect, init }) => {
  const [showPopup, setShowPopup] = useState(false);
  const explore = showPopup ? (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        style={{ maxWidth: "900px" }}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShowPopup(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ExploreContainer
              mode="select"
              filters={["Folder"]}
              initParams={init}
              onSelect={(source) => {
                setShowPopup(false);
                onSelect(source);
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-light btn-lg"
              onClick={() => {
                setShowPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
  return (
    <React.Fragment>
      <button
        className="btn btn-secondary btn-lg"
        onClick={() => {
          setShowPopup(true);
        }}
      >
        Browse
      </button>
      {explore}
    </React.Fragment>
  );
};

const JobEvent = (props) => {
  const {
    event,
    eventObj,
    source,
    index,
    moveUp,
    moveDown,
    error,
    updateJobEvent,
    clearEventAlarm,
  } = props;

  const previousEvent = source.getAll()[index - 1];

  const hasError = error && Object.keys(error).length > 0;

  const { showModal } = useModal();

  const [isOpen, toggle] = useState(eventObj.isNew);

  return (
    <React.Fragment>
      <div className="row table-item">
        <div className="col-md-12">
          <div className="row m-0 table-row-item">
            <div className="col-3 d-md-none event-summary-cell">Step</div>
            <div className="col-9 col-md-1 col-xl-1 event-summary-cell">
              {index + 1}
            </div>
            <div className="col-3 d-md-none event-summary-cell">Type</div>
            <div className="col-9 col-md-2 col-xl-2 event-summary-cell">
              {
                JobEventTypes.find(
                  (j) => j.id.toString() === eventObj.eventType.toString()
                ).name
              }
            </div>
            <div className="col-3 d-md-none event-summary-cell">Desc.</div>
            <div className="col-9 col-md-8 col-xl-4 event-summary-cell">
              {eventObj.description}
            </div>
            <div className="col-3 d-md-none event-summary-cell">Status</div>
            <div className="col-9 col-md-1 col-xl-1  event-summary-cell">
              {hasError ? (
                <span className="badge badge-danger">Not valid</span>
              ) : (
                <span className="badge badge-success">Valid</span>
              )}
            </div>

            <div className="col-md-12 col-xl-4 d-flex justify-content-center justify-content-center justify-content-md-end">
              <IconButton
                className="m-1"
                icon={isOpen ? "icon-up-open" : "icon-edit"}
                type="dark"
                tooltip={isOpen ? "Stop editing and collapse" : "Edit event"}
                tooltipplacement="top"
                onClick={() => toggle(!isOpen)}
              />
              <IconButton
                className="m-1"
                icon="icon-copy"
                type="dark"
                tooltip="Copy event"
                tooltipplacement="top"
                onClick={() => source.push({ ...source.get(index), id: null })}
              />
              <IconButton
                className="m-1"
                icon="icon-move_up"
                type="dark"
                tooltip="Move up"
                tooltipplacement="top"
                onClick={() => moveUp(index, event)}
              />
              <IconButton
                className="m-1"
                icon="icon-move_down"
                type="dark"
                tooltip="Move down"
                tooltipplacement="top"
                onClick={() => moveDown(index, event)}
              />
              <IconButton
                className="m-1"
                icon="icon-trash"
                type="danger"
                tooltip="Delete event"
                tooltipplacement="top"
                onClick={() =>
                  showModal("Confirm", {
                    title: "Delete event?",
                    confirmBtnText: "Delete",
                    confirmBtnDanger: true,
                    message: (
                      <span>
                        Are you sure you want to delete event{" "}
                        <strong>{index + 1}</strong>?
                      </span>
                    ),
                    init: { next: () => source.remove(index) },
                  })
                }
              />
            </div>
          </div>

          <EventBody
            isOpen={isOpen}
            updateJobEvent={updateJobEvent}
            event={event}
            eventObj={eventObj}
            index={index}
            previousEvent={previousEvent}
            clearEventAlarm={clearEventAlarm}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const renderEvents = ({
  fields,
  meta: { error },
  formErrors,
  updateJobEvent,
  clearEventAlarm,
}) => {
  const moveUp = (oldIndex, event) => {
    const newIndex = oldIndex - 1;

    if (newIndex >= 0) {
      updateJobEvent(event + ".executionOrder", newIndex);
      fields.move(oldIndex, newIndex);
    }
  };

  const moveDown = (oldIndex, event) => {
    const newIndex = oldIndex + 1;
    const arrayCount = fields.length - 1;

    if (oldIndex < arrayCount) {
      updateJobEvent(event + ".executionOrder", newIndex);
      fields.move(oldIndex, newIndex);
    }
  };

  return (
    <React.Fragment>
        {error && (
            <div className="row ml-0 mr-0">
              <div className="col-md-12 pb-4 pl-0">
                <span className="text-danger"><i className="icon icon-info-circled"></i> {error}</span>
              </div>
            </div> 
        )}

      <div className="row m-0">
        <div className="col-md-1 col-xl-1 d-none d-md-block">
          <strong>Step</strong>
        </div>
        <div className="col-md-2 col-xl-2 d-none d-md-block">
          <strong>Event type</strong>
        </div>
        <div className="col-md-8 col-xl-4 d-none d-md-block">
          <strong>Description</strong>
        </div>
        <div className="col-md-1 col-xl-1 d-none d-md-block">
          <strong>Status</strong>
        </div>
        <div className="col-md-12 col-xl-4 d-none d-md-block"></div>
        <div className="col-md-12 d-none d-md-block table-header pt-1"></div>
      </div>
      {fields.map((event, index) => (
        <JobEvent
          source={fields}
          key={index}
          index={index}
          event={event}
          eventObj={fields.get(index)}
          moveDown={moveDown}
          moveUp={moveUp}
          clearEventAlarm={clearEventAlarm}
          error={formErrors && formErrors.events && formErrors.events[index]}
          updateJobEvent={updateJobEvent}
        />
      ))}
      <div className="row ml-0 mr-0">
        <div className="col-md-12 pl-0 pr-0 pt-0 pb-0 pt-md-3 text-center">
          <button
            className="btn btn-light btn-lg btn-block"
            onClick={() => {
              fields.push(NewJobEvent());
            }}
          >
            <i className="icon-plus"></i> Add event
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const CreateJobWizzardStepTwo = ({
  formErrors,
  updateJobEvent,
  clearEventAlarm,
  initialValues,
}) => {
  return (
    <Card>
      <Header title="Events">
        <div className="alert highlight" role="alert">
          <span>
            Create or edit events. The job will run events in sequential order
            according to event step number.
          </span>
        </div>
      </Header>
      <Body>
        <FieldArray
          name="events"
          component={renderEvents}
          formErrors={formErrors}
          updateJobEvent={updateJobEvent}
          rerenderOnEveryChange={true}
          clearEventAlarm={clearEventAlarm}
        />
      </Body>
    </Card>
  );
};

export default reduxForm({
  form: "create-job-wizzard",
  validate: stepTwoValidation,
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CreateJobWizzardStepTwo);
