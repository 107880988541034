import React from 'react'
import LeftSideUser from './LeftSideUser';
import Menu from '../common/menu/Menu';
import MenuItems from '../../selectors/Menu'
/*d-none d-lg-block*/

const LeftSide = ({user, isOpen,  location, toggleLeftMenu}) => {
    const mobileClass = isOpen ? 'visible' : '';
    return ( 
        <React.Fragment>
            <div id="left-side-menu" className={`sidebar no-print h-100 p-0 ${mobileClass}`} data-background-color="white" data-active-color="danger">
                <div className="sidebar-wrapper d-flex flex-column h-100">
                    <div className="logo d-md-none d-none-flex">
                        <h3 className="m-0 text-white"><i className="icon icon-logo_mark"></i></h3>
                    </div>
                    <div className="logo d-none d-md-flex">
                        <img src="/images/logo_white.svg" alt="Cloudbuz Manager" />
                    </div>
                    <div className="flex-fill d-flex overflow-auto">
                        <div className="d-flex flex-column w-100">
                            <LeftSideUser user={user} /> 
                            {
                                <Menu path={location.pathname} items={MenuItems.dashboard} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="popup-menu-wrapper" onClick={() => toggleLeftMenu()} ></div> : ""}
        </React.Fragment>
    )
}

export default LeftSide;