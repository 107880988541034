export const getActiveAccount = (auth) => {

    if(!auth || !auth.info || !auth.info.accounts || !auth.info.accounts.length || !auth.user) {
        return {};
    }

    return auth.info.accounts.filter(a => (a.accountId + "") === (auth.user.accountId + ""))[0] || {}
}

export const getAdminAccounts = (auth) => {
    if(!auth || !auth.info || !auth.info.accounts || !auth.info.accounts.length) {
        return [];
    }

    return auth.info.accounts.filter(a => a.privilege === 201);
}