import React from 'react'
import SignUpContainer from '../containers/SignUp'
import './Login.css'

const SignUpPage = () => {
    return (
        <div className="login-wrapper">
            <div className="login align-self-center flex-fill">
                <SignUpContainer />
            </div>
        </div>
    )
}

export default SignUpPage