import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import { getAllStats } from "../actions/stats";
import moment from "moment";
import Loader from "../components/common/Loader";
import DashboardInfo from "../components/dashboard/DashboardInfo";
import DashboardLimited from "../components/dashboard/DashboardLimited";
import { toggleHeaderContextMenu } from "../actions/menu";
import { getPrivilege } from "../selectors/Privilege";
import downloadFile from "../common/downloadFile";
import {
  showNotificationError,
  showNotificationSuccess,
} from "../actions/notification";

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  stats: state.stats.stats,
  user: state.auth.user,
  isAccountAdmin: getPrivilege(state, "Account page", "read_write"),
});

const mapDispatchToProps = (dispatch) => ({
  initialState: (utcDate, dateOffset, statisticHours) => {
    dispatch(getAllStats(utcDate, dateOffset, statisticHours));
  },
  toggleUserMenu: () => dispatch(toggleHeaderContextMenu()),
  download: () => {
    downloadFile(
      process.env.REACT_APP_DOWNLOAD_CONNECTOR_URL,
      "FileServerConnector_Setup.exe"
    )
      .then((msg) => {
        dispatch(showNotificationSuccess(msg));
      })
      .catch((msg) => {
        dispatch(showNotificationError(msg));
      });
  },
});

const DashboardContainer = ({
  stats,
  toggleUserMenu,
  isAuthenticated,
  initialState,
  user,
  isAccountAdmin,
  download,
}) => {
 

  useEffect(() => {
    if (parseInt(user.accountId, 10) && isAccountAdmin) {
      initialState(moment().format(), 0, 720);
    }
  }, [initialState, isAccountAdmin, user.accountId]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  
  if (!stats && parseInt(user.accountId, 10) && isAccountAdmin) {
    return <Loader title="Loading dashboard..." startDelay={0} />;
  }

  if (!parseInt(user.accountId, 10)) {
    return <DashboardInfo toggleUserMenu={toggleUserMenu} />;
  }

  if (!isAccountAdmin) {
    return <DashboardLimited accountId={user.accountId} />;
  }

  /* Create dashboard component */
  return (
    <Dashboard key={user.accountId} dashboardData={stats} download={download} />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
