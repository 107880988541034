import React, { useEffect, useMemo, useRef } from "react";
import Card, { Body, Header } from "../common/Card";
import TablePaginator from "../common/table/Paginator";
import Table from "../common/table/Table";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

export const SchedulesFreeText = ({ text, onChange }) => {
  const debounceSearchChanged = useMemo(() => {
    return new Subject();
  }, []);

  useEffect(() => {
    const subscription = debounceSearchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((text) => {
        onChange(text);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [debounceSearchChanged, onChange]);

  const ref = useRef();

  return (
    <div className="input-group">
      <input
        ref={ref}
        className="form-control form-control-lg"
        type="text"
        placeholder="Filter this list, type something.."
        defaultValue={text}
        onChange={(e) => {
          debounceSearchChanged.next(e.target.value);
        }}
      />
      <div className="input-group-append">
        <span
          className="input-group-text pointer"
          onClick={(e) => {
            onChange("");
            ref.current.value = "";
          }}
        >
          clear
        </span>
      </div>
    </div>
  );
};

const SchedulesComponent = ({
  header,
  schema,
  view,
  page,
  onScheduleSelect,
  onPageChange,
}) => {
  return (
    <Card>
      <Header title="Schedules">
        <div className="alert highlight" role="alert">
          Schedules
        </div>
        {header}
      </Header>
      <Body>
        <div className="table-responsive table-full-width">
          <Table
            data={view?.schedules}
            schema={schema}
            onRowClick={onScheduleSelect}
            infoIfEmpty="No schedules found.."
          />
          <TablePaginator
            currentPage={page}
            totalPages={view?.totalPages}
            onPageChange={onPageChange}
            pageNeighbours={3}
          />
        </div>
      </Body>
    </Card>
  );
};

export default SchedulesComponent;
