import React from 'react'
import './Login.css'
import SignupSuccess from '../components/signup/SignupSuccess';

const SignUpSuccessPage = ({location}) => {
    return (
        <div className="login-wrapper">
            <div className="login align-self-center flex-fill">
                <SignupSuccess name={location.state.fullName} />
            </div>
        </div>
    )
}

export default SignUpSuccessPage