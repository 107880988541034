import React from 'react'
import LoginCard, { Header, Body } from '../common/LoginCard';
import CbLogo from '../../images/logo_white.png'

const SignUpCreatedComponent = ({ email }) => {

    return (
        <div>
            <div className="logo_login_placeholder">
                <img src={CbLogo} height="30px" alt="Cloudbuz Manager" className="logo_login" />
            </div> 
            <LoginCard>
                <Header title="Almost there..."/>
                <Body>
                Please check your email account <span className="text-info">{email}</span> to complete your signup.

                If <span className="text-info">{email}</span> is not your email address, please go back.
                If you don't receive the verification email within 2 minutes, please check your spam folder. 
                If you still can't find the verification email, please contact <a href="mailto:support@cloudbuz.com">support@cloudbuz.com</a>.
                </Body>
            </LoginCard>
            <p className="greetings">Made with <i className="icon icon-heart love"></i>  by <a className="text-white" href="https://www.zavanto.se">Zavanto</a></p>
        </div>
    )

}

export default SignUpCreatedComponent

