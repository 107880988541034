import React from "react";
import Card, { Body } from "../../common/Card";

const CurrentCreditCard = ({ paymentMethod }) => {
  return (
    <Card>
      <Body>
        <div className="d-flex">
          <span className="p-1">
            {paymentMethod?.card?.brand === "visa" ? (
              <img
                role="presentation"
                alt="visa"
                src="https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg"
              ></img>
            ) : (
              <img
                role="presentation"
                alt="visa"
                src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg"
              ></img>
            )}
          </span>
          <span className="flex-fill p-1">{`XXX-XXX-XXXX-${paymentMethod?.card?.last4}`}</span>
          <span className="p-1">
            {paymentMethod?.card?.exp_month} / {paymentMethod?.card?.exp_year}
          </span>
        </div>
      </Body>
    </Card>
  );
};

export default CurrentCreditCard;
