import React from "react";
import { FieldArray, reduxForm } from "redux-form";
import "./AcceptAccountRelation.css";
import Card, { Body, Header } from "../common/Card";
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';

const renderAccountsSelection = ({
  name,
  tooltip,
  fields,
  accounts,
  privileges,
  meta: { touched, warning, error, dirty, submitFailed },
}) => {
  const selectedFields = fields.getAll() || [];
  return (
    <div className="row mb-3 mt-3">
      <div className="col-lg-12">
        {accounts.map((a, i) => {
          if (selectedFields.includes(a.accountId)) {
            return (
              <span
                className="box"
                key={i}
                onClick={() => {
                  fields.remove(selectedFields.indexOf(a.accountId));
                }}
              >
                {a.companyName}
              </span>
            );
          }

          return (
            <span 
              key={i}
              onClick={() => {
                fields.push(a.accountId);
              }}
              className="box text-muted mr-1"
            >
              {a.companyName}
            </span>
          );
        })}
        {(touched || submitFailed) && error && (
          <div className="text-danger mt-3">
            <i className="icon icon-attention-circled"></i> <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const AcceptAccountRelationComponent = ({
  initialValues,
  accounts,
  handleSubmit,
  onDecline,
  errors,
  submitting,
}) => {

  return (
    <PageContent className="border-0">
      <ContentHeader title="Network / Connection requests"/>
      <ContentBody>
        <Card className="change-avatar">
          <Header title={`Connection request from ${initialValues.invitorEmail}`}/>
          <Body>
            <div className="row">
              <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
                <img
                  className="avatar border-white"
                  alt="Gravatar"
                  src={`https://s.gravatar.com/avatar/${initialValues.invitorGravatarHash}?d=https%3A%2F%2Fapp.cloudbuz.com%2Favatar_default.png&?s=120`}
                />
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <p>
                  Requestor name: <strong>{initialValues.invitorName}</strong>
                </p>
                <p>
                  Requestor e-mail:{" "}
                  <strong>{initialValues.invitorEmail}</strong> <span className="badge badge-success">Verified e-mail</span>
                </p>
                <p>
                  Requestor account: <strong>{initialValues.invitedBy}</strong>
                </p>
                <p>
                  Message: <strong>{initialValues.inviteInfo.message}</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pt-3">
                Select which (if any) of your accounts you want to connect with{" "}
                <strong>{initialValues.invitedBy}</strong>:
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pt-3">
                <form onSubmit={handleSubmit}>
                  <div className="content text-left">
                    <FieldArray
                      name="accounts"
                      accounts={accounts}
                      component={renderAccountsSelection}
                      tooltip="Please select an account"
                    />
                    <footer className="row text-center">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-success btn-block btn-lg mt-3"
                          disabled={submitting}
                        >
                          Accept
                        </button>
                        or
                        <button
                          type="button"
                          className="btn btn-light btn-block btn-lg mt-0"
                          disabled={submitting}
                          onClick={onDecline}
                        >
                          Decline
                        </button>
                      </div>
                    </footer>
                  </div>
                </form>
              </div>
            </div>
          </Body>
        </Card>
      </ContentBody>
    </PageContent>
  );
};

export default reduxForm({
  form: "accept-accountrelation",
  validate: (values) => {
    const errors = {};
    if (!values.accounts || !values.accounts.length) {
      errors.accounts = { _error: "At least one account must be selected" };
    }
    return errors;
  },
})(AcceptAccountRelationComponent);
