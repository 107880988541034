import { get$, post$ } from './http'

const convertSortBy = (sortBy) => {
    return {
        sortBy: (sortBy[0] || {}).accessor,
        sortOrder: (sortBy[0] || {}).sortOrder
    }
}

export const loadExploreView$ = (id, type, shareId, currentPage = 1, sortBy, filters) =>
    id && type && shareId > 0 ?
        get$(`/explorer/view/${id}/${type}/${shareId}`, { currentPage, ...convertSortBy(sortBy || []), filters }) :
        id && type ?
            get$(`/explorer/view/${id}/${type}`, { currentPage, ...convertSortBy(sortBy || []), filters }) :
            get$(`/explorer/view`, { currentPage, ...convertSortBy(sortBy || []), filters })


export const searchInExplore$ = (text, filters) => get$(`/explorer/search`, { text, filters })

export const getShareFolder$ = (id) => get$(`/explorer/${id}/share`);

export const shareFolder$ = (id, name, sharedWith) => post$(`/explorer/${id}/share`, { name, sharedWith });

export const executeExploreCommand$ = (targetId, command) => post$(`/explorer/${targetId}/action`, command);