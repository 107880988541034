import * as api from "../api";

import { switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";

import { push } from "connected-react-router";

import {
  LOAD_CONNECTORS_LIST,
  LOAD_CONNECTOR_DETAILS,
  RECYCLE_CONNECTOR,
  CHANGE_CONNECTOR_LOGLEVEL,
  REQUEST_CONNECTOR_LOGFILE,
  TRANSFER_CONNECTOR_TO_NEW_ORG,
  UNREGISTER_DELETE_CONNECTOR,
  CREATE_CONNECTOR_INSTALLMENT_REQUEST,
  DELETE_CONNECTOR_INSTALLMENT_REQUEST,
  LOAD_CONNECTOR_INSTALLMENT_DATA,
  loadConnectorsListSuccess,
  loadConnectorDetailsSuccess,
  recycleConnectorSuccess,
  changeConnectorLogLevelSuccess,
  requestConnectorLogFilesSuccess,
  transferConnectorToNewOrgSuccess,
  unregisterAndDeleteConnectorSuccess,
  deleteConnectorInstallmentRequestSuccess,
  loadConnectorInstallmentDataSuccess,
  CREATE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS,
  createConnectorInstallmentRequestSuccess,
  DELETE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS,
  RESTART_CONNECTOR,
  restartSuccess,
  KILL_CONNECTOR,
  killSuccess,
  CLEAR_EXCLUDE_FOLDERS_CONNECTOR,
  clearExcludeFoldersSuccess,
  addExcludeFoldersSuccess,
  ADD_EXCLUDE_FOLDERS_CONNECTOR,
  REMOVE_EXCLUDE_FOLDERS_CONNECTOR,
  removeExcludeFoldersSuccess,
  CHANGE_DESCRIPTION_CONNECTOR,
  changeConnectorDescriptionSuccess,
  loadConnectorDetails,
  DOWNLOAD_LOGFILE,
  UNREGISTER_DELETE_CONNECTOR_SUCCESS
} from "../actions/connectors";

import { handleError } from "./common";
import { of } from "rxjs";
import downloadFile from "../common/downloadFile";
import { showNotificationError, showNotificationSuccess } from "../actions/notification";

export const listConnectorsEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_CONNECTORS_LIST, DELETE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS),
    switchMap((action) => {
      return api.listAllConnectors$().pipe(
        map((response) => {
          return loadConnectorsListSuccess(
            response.connectors,
            response.pendingRequests,
            response.connectorTypes
          );
        }),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const loadConnectorDetailsEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_CONNECTOR_DETAILS),
    switchMap((action) => {
      return api.getConnectorDetails$(action.clientId).pipe(
        map((response) => {
          return loadConnectorDetailsSuccess(
            response.details,
            response.organisations,
            response.logFiles,
            response.connectorLogLevels
          );
        }),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const recycleConnectorEpic$ = (action$) => {
  return action$.pipe(
    ofType(RECYCLE_CONNECTOR),
    switchMap((action) =>
      api.recycl$(action.clientId).pipe(
        map((response) => {
          return recycleConnectorSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const changeConnectorLogLevelEpic$ = (action$) => {
  return action$.pipe(
    ofType(CHANGE_CONNECTOR_LOGLEVEL),
    switchMap((action) =>
      api.changeLogLevel$(action.clientId, action.logLevel).pipe(
        map((response) => {
          return changeConnectorLogLevelSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const requestConnectorLogFileEpic$ = (action$) => {
  return action$.pipe(
    ofType(REQUEST_CONNECTOR_LOGFILE),
    switchMap((action) =>
      api.logfile$(action.clientId, action.date).pipe(
        map((response) => {
          return requestConnectorLogFilesSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const downloadConnectorLogFileEpic$ = (action$) => {
  return action$.pipe(
    ofType(DOWNLOAD_LOGFILE),
    switchMap(async (action) => {
      try {
        const path = api.downloadConnectorLogFilePath(
          action.clientId,
          action.fileId
        );
        const result = await downloadFile(path, action.fileName);
        return showNotificationSuccess(result);
        
      } catch (e) {
        return showNotificationError(e);
      }
    })
  );
};

export const transferConnectorToNewOrgEpic$ = (action$) => {
  return action$.pipe(
    ofType(TRANSFER_CONNECTOR_TO_NEW_ORG),
    switchMap((action) =>
      api.transferConnectorToNewOrg$(action.clientId, action.orgId).pipe(
        map((response) => {
          return transferConnectorToNewOrgSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const unregisterAndDeleteConnectorEpic$ = (action$) => {
  return action$.pipe(
    ofType(UNREGISTER_DELETE_CONNECTOR),
    switchMap((action) =>
      api.unregisterAndDeleteConnector$(action.clientId).pipe(
        map((response) => {
          return unregisterAndDeleteConnectorSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const unregisterAndDeleteConnectorSuccessEpic$ = (action$) => {
  return action$.pipe(
    ofType(UNREGISTER_DELETE_CONNECTOR_SUCCESS),
    map((response) => push("/admin/connectors"))
  );
};

export const createConnectorRequestInstallmentEpic$ = (action$) => {
  return action$.pipe(
    ofType(CREATE_CONNECTOR_INSTALLMENT_REQUEST),
    switchMap((action) =>
      api
        .createConnectorInstallmentRequest$(
          action.email,
          action.message,
          action.orgId,
          action.connectorType
        )
        .pipe(
          map((response) => {
            return createConnectorInstallmentRequestSuccess();
          }),
          catchError(handleError(action$, action.type))
        )
    )
  );
};

export const createConnectorRequestInstallmentSuccessEpic$ = (action$) => {
  return action$.pipe(
    ofType(CREATE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS),
    map((response) => push("/admin/connectors"))
  );
};

export const deleteConnectorRequestInstallmentEpic$ = (action$) => {
  return action$.pipe(
    ofType(DELETE_CONNECTOR_INSTALLMENT_REQUEST),
    switchMap((action) =>
      api.deleteConnectorInstallmentRequest$(action.token).pipe(
        map((response) => {
          return deleteConnectorInstallmentRequestSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const getAllConnectorTypesEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_CONNECTOR_INSTALLMENT_DATA),
    switchMap((action) =>
      api.loadRequestInstallmentData$().pipe(
        map((response) => {
          return loadConnectorInstallmentDataSuccess(
            response.connectorTypes,
            response.organisations
          );
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const restartConnectorEpic$ = (action$) => {
  return action$.pipe(
    ofType(RESTART_CONNECTOR),
    switchMap((action) =>
      api.restart$(action.clientId).pipe(
        map((response) => {
          return restartSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const killConnectorEpic$ = (action$) => {
  return action$.pipe(
    ofType(KILL_CONNECTOR),
    switchMap((action) =>
      api.kill$(action.clientId).pipe(
        map((response) => {
          return killSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const clearExcludeFoldersConnectorEpic$ = (action$) => {
  return action$.pipe(
    ofType(CLEAR_EXCLUDE_FOLDERS_CONNECTOR),
    switchMap((action) =>
      api.clearExcludeFolders$(action.clientId).pipe(
        map((response) => {
          return clearExcludeFoldersSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const addExcludeFoldersConnectorEpic$ = (action$) => {
  return action$.pipe(
    ofType(ADD_EXCLUDE_FOLDERS_CONNECTOR),
    switchMap((action) =>
      api.addExcludeFolders$(action.clientId, action.path).pipe(
        map((response) => {
          return addExcludeFoldersSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const removeExcludeFoldersConnectorEpic$ = (action$) => {
  return action$.pipe(
    ofType(REMOVE_EXCLUDE_FOLDERS_CONNECTOR),
    switchMap((action) =>
      api.removeExcludeFolders$(action.clientId, action.path).pipe(
        map((response) => {
          return removeExcludeFoldersSuccess();
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const changeConnectorDescriptionEpic$ = (action$) => {
  return action$.pipe(
    ofType(CHANGE_DESCRIPTION_CONNECTOR),
    switchMap((action) =>
      api.changeConnectorDescription$(action.clientId, action.description).pipe(
        mergeMap(() =>
          of(
            changeConnectorDescriptionSuccess(),
            loadConnectorDetails(action.clientId)
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );
};
