import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { get$ } from "../../api/endpoints";
import EndpointComponent from "../../components/endpoints/Endpoint";

const EndpointContainer = () => {
  const [endpoint, setEndpoint] = useState();

  const match = useRouteMatch();
  useEffect(() => {
    get$(match.params.endpointId, match.params.shareId).subscribe(
      (endpoint) => {
        setEndpoint(endpoint);
      }
    );
  }, [match.params.endpointId, match.params.shareId]);
  return <EndpointComponent endpoint={endpoint}></EndpointComponent>;
};

export default EndpointContainer;
