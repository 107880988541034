import React from "react";
import PropTypes from "prop-types";
import Table from "../../common/table/Table";
import RunsFilter from "./RunsFilter";
import PageContent, {
  ContentHeader,
  ContentBody,
} from "../../common/PageContent";
import Card, { Body } from "../../common/Card";
import TablePaginator from "../../common/table/Paginator";
import "./Runs.css";
import Loader from "../../common/Loader";
import Refresh from "./Refresh";

const RunsView = ({ view, currentPage, schema, onRowClick, onPageChange }) => {
  return (
    <div className="runs-view">
      <Table
        data={view.runs}
        schema={schema}
        onRowClick={onRowClick}
        infoIfEmpty="No runs found.."
      />
      <TablePaginator
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={view.totalPages}
        pageNeighbours={1}
      />
    </div>
  );
};

const Runs = ({
  isFetching = false,
  view = {},
  schema,
  onRowClick,
  onFilter,
  onRefresh,
  currentPage,
  onPageChange,
  executeActionOnRun,
  useInDashboard,
  selectedFilters,
}) => {
  // let hasErrors = view.runs.some(r => r.statusId === "9999" || r.statusId === "99999") ?  <div className="alert alert-danger alert-dismissible fade show"><strong>Warning!</strong> You have some unacknowledged errors!  <button type="button" className="close p-2" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div> : "";
  let hasErrors =
    view.totalErrors > 0 ? (
      <div className="mb-3 alert highlight">
        <h6 className="alert-heading text-danger">
          <i className="icon icon-attention-circled"></i>Unacknowledged errors
          detected!
        </h6>
        Please check reason of error and acknowledge.
        <hr />
        <button
          className="btn btn-light"
          onClick={() => executeActionOnRun(-1, "acknowledgeAll")}
        >
          <i className="icon icon-ok"></i>
          <span> </span>Acknowledge all
        </button>
      </div>
    ) : (
      ""
    );

  return !useInDashboard ? (
    <PageContent>
      <ContentHeader title="Jobs / Runs">
        <ol className="breadcrumb border">
          <li
            className="breadcrumb-item active d-flex align-items-center"
            aria-current="page"
          >
            <span>Runs</span>
          </li>
        </ol>
        {hasErrors}
      </ContentHeader>
      <ContentBody>
        <Card className="p-0 m-0 border-0">
          <Body className="p-0">
            <div className="float-left">
              <RunsFilter onFilter={onFilter} initialState={selectedFilters} />
            </div>
            <div className="float-right">
              <Refresh onRefresh={onRefresh} />
            </div>
            {isFetching && !view ? (
              <Loader />
            ) : (
              <RunsView
                schema={schema}
                view={view}
                currentPage={currentPage}
                onRowClick={onRowClick}
                onPageChange={onPageChange}
              />
            )}
          </Body>
        </Card>
      </ContentBody>
    </PageContent>
  ) : (
    <React.Fragment>
      {hasErrors}
      <div className="float-left">
        <RunsFilter onFilter={onFilter} initialState={selectedFilters} />
      </div>
      <div className="float-right">
        <Refresh onRefresh={onRefresh} />
      </div>
      <RunsView
        schema={schema}
        view={view}
        currentPage={currentPage}
        onRowClick={onRowClick}
        onPageChange={onPageChange}
      />
    </React.Fragment>
  );
};

Runs.propTypes = {
  view: PropTypes.object,
  schema: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  executeActionOnRun: PropTypes.func,
};

export default Runs;
