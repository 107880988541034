import React from 'react';

const taxes = [
    { iso: 'AU', name: 'Australia', enum: 'au_abn', description: 'Australian Business Number (AU ABN)', example: '12345678912' },
    { iso: 'AT', name: 'Austria', enum: 'eu_vat', description: 'European VAT number', example: 'ATU12345678' },
    { iso: 'BE', name: 'Belgium', enum: 'eu_vat', description: 'European VAT number', example: 'BE0123456789' },
    { iso: 'BR', name: 'Brazil', enum: 'br_cnpj', description: 'Brazilian CNPJ numbe', example: '01.234.456/5432-10' },
    { iso: 'BR', name: 'Brazil', enum: 'br_cpf', description: 'Brazilian CPF number', example: '123.456.789-87' },
    { iso: 'BG', name: 'Bulgaria', enum: 'eu_vat', description: 'European VAT number', example: 'BG0123456789' },
    { iso: 'CA', name: 'Canada', enum: 'ca_bn', description: 'Canadian BN', example: '123456789' },
    { iso: 'CA', name: 'Canada', enum: 'ca_gst_hst', description: 'Canadian GST/HST number', example: '123456789RT0002' },
    { iso: 'CA', name: 'Canada', enum: 'ca_pst_bc', description: 'Canadian PST number (British Columbia)', example: 'PST-1234-5678' },
    { iso: 'CA', name: 'Canada', enum: 'ca_pst_mb', description: 'Canadian PST number (Manitoba)', example: '123456-7' },
    { iso: 'CA', name: 'Canada', enum: 'ca_pst_sk', description: 'Canadian PST number (Saskatchewan)', example: '1234567' },
    { iso: 'CA', name: 'Canada', enum: 'ca_qst', description: 'Canadian QST number (Québec)', example: '1234567890TQ1234' },
    { iso: 'CL', name: 'Chile', enum: 'cl_tin', description: 'Chilean TIN', example: '12.345.678-K' },
    { iso: 'HR', name: 'Croatia', enum: 'eu_vat', description: 'European VAT number', example: 'HR12345678912' },
    { iso: 'CY', name: 'Cyprus', enum: 'eu_vat', description: 'European VAT number', example: 'CY12345678Z' },
    { iso: 'CZ', name: 'Czech Republic', enum: 'eu_vat', description: 'European VAT number', example: 'CZ123456789' },
    { iso: 'DK', name: 'Denmark', enum: 'eu_vat', description: 'European VAT number', example: 'DK12345678' },
    { iso: 'EE', name: 'Estonia', enum: 'eu_vat', description: 'European VAT number', example: 'EE123456789' },
    { iso: 'FI', name: 'Finland', enum: 'eu_vat', description: 'European VAT number', example: 'FI12345678' },
    { iso: 'FR', name: 'France', enum: 'eu_vat', description: 'European VAT number', example: 'FRAB1234567' },
    { iso: 'DE', name: 'Germany', enum: 'eu_vat', description: 'European VAT number', example: 'DE123456789' },
    { iso: 'GR', name: 'Greece', enum: 'eu_vat', description: 'European VAT number', example: 'EL123456789' },
    { iso: 'HK', name: 'Hong Kong', enum: 'hk_br', description: 'Hong Kong BR number', example: '12345678' },
    { iso: 'HU', name: 'Hungary', enum: 'eu_vat', description: 'European VAT number', example: 'HU12345678912' },
    { iso: 'IO', name: 'India', enum: 'in_gst', description: 'Indian GST number', example: '12ABCDE3456FGZH' },
    { iso: 'ID', name: 'Indonesia', enum: 'id_npw', description: 'Indonesian NPWP number', example: '12.345.678.9-012.345' },
    { iso: 'IE', name: 'Ireland', enum: 'eu_vat', description: 'European VAT number', example: 'IE1234567AB' },
    { iso: 'IL', name: 'Israel', enum: 'il_vat', description: 'Israel VAT', example: '000012345' },
    { iso: 'IT', name: 'Italy', enum: 'eu_vat', description: 'European VAT number', example: 'IT12345678912' },
    { iso: 'JP', name: 'Japan', enum: 'jp_cn', description: 'Japanese Corporate Number (*Hōjin Bangō*)', example: '1234567891234' },
    { iso: 'JP', name: 'Japan', enum: 'jp_rn', description: 'Japanese Registered Foreign Businesses\' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)', example: '12345' },
    { iso: 'KR', name: 'Korea, Republic of', enum: 'kr_brn', description: 'Korean BRN', example: '23-45-67890' },
    { iso: 'LV', name: 'Latvia', enum: 'eu_vat', description: 'European VAT number', example: 'LV12345678912' },
    { iso: 'LI', name: 'Liechtenstein', enum: 'li_uid', description: 'Liechtensteinian UID number', example: 'CHE123456789' },
    { iso: 'LT', name: 'Lithuania', enum: 'eu_vat', description: 'European VAT number', example: 'LT123456789123' },
    { iso: 'LU', name: 'Luxembourg', enum: 'eu_vat', description: 'European VAT number', example: 'LU12345678' },
    { iso: 'MY', name: 'Malaysia', enum: 'my_frp', description: 'Malaysian FRP number', example: '12345678' },
    { iso: 'MY', name: 'Malaysia', enum: 'my_itn', description: 'Malaysian ITN', example: 'C 1234567890' },
    { iso: 'MY', name: 'Malaysia', enum: 'my_sst', description: 'Malaysian SST number', example: 'A12-3456-78912345' },
    { iso: 'MT', name: 'Malta', enum: 'eu_vat', description: 'European VAT number', example: 'MT12345678' },
    { iso: 'MX', name: 'Mexico', enum: 'mx_rfc', description: 'Mexican RFC number', example: 'ABC010203AB9' },
    { iso: 'NL', name: 'Netherlands', enum: 'eu_vat', description: 'European VAT number', example: 'NL123456789B12' },
    { iso: 'NZ', name: 'New Zealand', enum: 'nz_gst', description: 'New Zealand GST number', example: '123456789' },
    { iso: 'NO', name: 'Norway', enum: 'no_vat', description: 'Norwegian VAT number', example: '123456789MVA' },
    { iso: 'PL', name: 'Poland', enum: 'eu_vat', description: 'European VAT number', example: 'PL1234567890' },
    { iso: 'PT', name: 'Portugal', enum: 'eu_vat', description: 'European VAT number', example: 'PT123456789' },
    { iso: 'RO', name: 'Romania', enum: 'eu_vat', description: 'European VAT number', example: 'RO1234567891' },
    { iso: 'RU', name: 'Russian Federation', enum: 'ru_inn', description: 'Russian INN', example: '1234567891' },
    { iso: 'RU', name: 'Russian Federation', enum: 'ru_kpp', description: 'Russian KPP', example: '123456789' },
    { iso: 'SA', name: 'Saudi Arabia', enum: 'sa_vat', description: 'Saudi Arabia VAT', example: '123456789012345' },
    { iso: 'SG', name: 'Singapore', enum: 'sg_gst', description: 'Singaporean GST', example: 'M12345678X' },
    { iso: 'SG', name: 'Singapore', enum: 'sg_uen', description: 'Singaporean UEN', example: '123456789F' },
    { iso: 'SK', name: 'Slovakia', enum: 'eu_vat', description: 'European VAT number', example: 'SK1234567891' },
    { iso: 'SK', name: 'Slovenia', enum: 'eu_vat', description: 'European VAT number', example: 'SI12345678' },
    { iso: 'ZA', name: 'South Africa', enum: 'za_vat', description: 'South African VAT number', example: '4123456789' },
    { iso: 'ES', name: 'Spain', enum: 'es_cif', description: 'Spanish CIF number', example: 'A12345678' },
    { iso: 'ES', name: 'Spain', enum: 'eu_vat', description: 'European VAT number', example: 'ESA1234567Z' },
    { iso: 'SE', name: 'Sweden', enum: 'eu_vat', description: 'European VAT number', example: 'SE123456789123' },
    { iso: 'CH', name: 'Switzerland', enum: 'ch_vat', description: 'Switzerland VAT number', example: 'CHE-123.456.789 MWST' },
    { iso: 'TW', name: 'Taiwan', enum: 'tw_vat', description: 'Taiwanese VAT', example: '12345678' },
    { iso: 'TH', name: 'Thailand', enum: 'th_vat', description: 'Thai VAT', example: '1234567891234' },
    { iso: 'AE', name: 'United Arab Emirates', enum: 'ae_trn', description: 'United Arab Emirates TRN', example: '123456789012345' },
    { iso: 'GB', name: 'United Kingdom', enum: 'gb_vat', description: 'United Kingdom VAT number', example: 'GB123456789' },
    { iso: 'GB', name: 'United Kingdom', enum: 'eu_vat', description: 'Northern Ireland VAT number', example: 'XI123456789' },
    { iso: 'US', name: 'United States', enum: 'us_ein', description: 'United States EIN	', example: '12-3456789' }
]


const TaxSelection = ({ country, taxId, taxType, onChange }) => {
    const filteredTaxes = taxes.filter(t => t.iso === country);
    if (!filteredTaxes.length) {
        return "";
    }

    const selected = filteredTaxes.find(t => t.enum === taxType);

    return <>
        <div className="form-group">
            <label htmlFor="tax">Tax Type</label>
            <select
                className="form-control"
                id="tax"
                aria-describedby="countryHelp"
                data-testid="tax"
                value={taxType}
                onChange={e => {
                    onChange({ taxId, taxType: e.target.value});
                }}>
                {
                    taxes.filter(t => t.iso === country).map(c => <option key={c.enum} value={c.enum}>{c.description}</option>)
                }
            </select>
        </div>
        <div className="form-group">
            <label htmlFor="name">Tax ID</label>
            <input
                type="text"
                className="form-control"
                id="taxId"
                placeholder={selected?.example}
                data-testid="taxId"
                value={taxId ?? ''}
                onChange={e => {
                    onChange({ taxType, taxId: e.target.value});
                }}
            />
        </div>
    </>
}

export default TaxSelection;