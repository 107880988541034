export const LOAD_CONNECTIONS_LIST = 'REQ_LOAD_CONNECTIONS_LIST'
export const LOAD_CONNECTIONS_LIST_SUCCESS = 'LOAD_CONNECTIONS_LIST_SUCCESS'

export const LOAD_CONNECTIONS_DETAILS = 'REQ_LOAD_CONNECTIONS_DETAILS'
export const LOAD_CONNECTIONS_DETAILS_SUCCESS = 'LOAD_CONNECTIONS_DETAILS_SUCCESS'

export const DISCONNECT_CONNECTION = "REQ_DISCONNECT_CONNECTION"
export const DISCONNECT_CONNECTION_SUCCESS = "DISCONNECT_CONNECTION_SUCCESS"

export const loadConnectionsList = () => ({
    type: LOAD_CONNECTIONS_LIST,
})

export const loadConnectionsListSuccess = (connections) => ({
    type: LOAD_CONNECTIONS_LIST_SUCCESS,
    connections
})

export const loadConnectionsDetails = (accountRelationId) => ({
    type: LOAD_CONNECTIONS_DETAILS,
    accountRelationId
})

export const loadConnectionsDetailsSuccess = (details,
    jobsFrom,
    jobsTo,
    sharesFrom,
    sharesTo) => ({
        type: LOAD_CONNECTIONS_DETAILS_SUCCESS,
        details,
        jobsFrom,
        jobsTo,
        sharesFrom,
        sharesTo
    })

export const disconnectConnection = (connection) => ({
    type: DISCONNECT_CONNECTION,
    connection
})

export const disconnectConnectionSuccess = () => ({
    type : DISCONNECT_CONNECTION_SUCCESS
})
