import { ajax } from 'rxjs/ajax';
import * as storage from '../helpers/auth'
import { defer } from 'rxjs';
import queryString from 'query-string'

export const baseUrl = (path) => {
    return process.env.REACT_APP_BASEURL + path;
}

export const url = (path) => {
    return baseUrl(path);
}

export const post$ = (path, model, withCredentials = false) => {
    return defer(() => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        let accessToken = storage.get().accessToken

        if (accessToken) {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return ajax({
            url: baseUrl(path),
            body: model,
            headers: headers,
            method: "POST",
            withCredentials,
            crossDomain: true
        })
    });
}

export const put$ = (path, model) => {
    return defer(() => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        let accessToken = storage.get().accessToken

        if (accessToken) {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return ajax.put(baseUrl(path), model, headers)
    });
}

export const get$ = (path, qs = null) => {
    return defer(() => {
        let headers = {};
        let accessToken = storage.get().accessToken
        if (accessToken) {
            headers = { 'Authorization': 'Bearer ' + accessToken }
        }

        if (qs) {
            return ajax.getJSON(baseUrl(`${path}?${queryString.stringify(qs)}`), headers)
        }

        return ajax.getJSON(baseUrl(`${path}`), headers)
    });
}

export const delete$ = (path, body) => {
    return defer(() => {

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        let accessToken = storage.get().accessToken

        if (accessToken) {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        return ajax.delete(baseUrl(path), headers);
    });
}