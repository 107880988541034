
import React from 'react'
import { usePasswordVerification } from '../../hooks/hooks';
import { PasswordVerificationDialog } from '../common/dialogs/PasswordVerificationDialog';
import Card, { Header, Body } from '../common/Card';

const DeleteAccount = ({ onDelete, adminUsers, username }) => {

    const [verificationState, showVerification, hideVerification] = usePasswordVerification(onDelete);
    const accountOwner = adminUsers.find((e) => e.isOwner);
    const disabled = username === accountOwner.mailAdress ? "" : "disabled";

    return (
        <Card className="card-settings">
            <PasswordVerificationDialog
                onHide={hideVerification}
                init={verificationState}
                confirmBtnText="Delete"
                confirmBtnDanger={true} />
            <Header title="Delete account">
                <div className="alert highlight" role="alert">
                    <p className="pt-3">
                        Only the current Cloudbuz account owner <strong>{accountOwner.fullName} / {accountOwner.mailAdress}</strong> can delete this Cloudbuz account.
                    </p>
                    <p>
                        We'll ask you to confirm, then permanently delete the account. This will delete all settings, organisations, jobs, connections, and billing information.
                    </p> 
                    <p>
                        <strong>Note! This action can not be undone.</strong>
                    </p>
                </div>
            </Header>
            <Body>
                <button disabled={disabled} onClick={() => showVerification()} className="btn btn-danger btn-lg">Delete account</button>
            </Body>
        </Card> 
    )
}

export default DeleteAccount
