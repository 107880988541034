import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Loader from '../components/common/Loader'

import NotificationDistributionsComponent from '../components/notificationsettings/NotificationDistributions'
import NotificationCategoriesComponent from '../components/notificationsettings/NotificationCategories'


import {
    setFormDataNotificationSettings,
    saveNotificationSettings,
    loadNotificationSettings,
    setJobNotificationSettings,
    saveJobNotificationSettings
} from '../actions/notificationsettings'
import JobNotificationsComponent from '../components/notificationsettings/JobNotifications';
import { isFetching } from '../selectors/Fetching';
import JobNotificationSettings from '../components/notificationsettings/JobNotificationSettings';

const mapStateToProps = (state) => {
    return {
        isFetching: isFetching(state.fetching, loadNotificationSettings),
        settings: state.notificationsettings.settings,
        jobSettings: state.notificationsettings.jobSettings
    }
}

const mapDispatchToPros = (dispatch) => {
    return {
        checkboxChangeEvent: (value, propertyName) => { dispatch(setFormDataNotificationSettings(value, propertyName)) },
        saveButtonClickEvent: (wantsEmail,
            wantsSms,
            wantsWeb,
            wantsJob,
            wantsSystem,
            wantsPromotional,
            emailNotificationType,
            emailNotificationSpec) =>
            dispatch(saveNotificationSettings(wantsEmail,
                wantsSms,
                wantsWeb,
                wantsJob,
                wantsSystem,
                wantsPromotional,
                emailNotificationType,
                emailNotificationSpec)),
        loadInitialState: () => dispatch(loadNotificationSettings()),
        jobNotificationsChangeEvent: (value, prop) => {
            dispatch(setJobNotificationSettings(value, prop))
        },
        onSaveJobNotifications: (settings) => dispatch(saveJobNotificationSettings(settings))
    }
}

const SettingsNotificationsContainer = ({
    loadInitialState,
    isFetching,
    settings,
    jobSettings,
    checkboxChangeEvent,
    saveButtonClickEvent,
    jobNotificationsChangeEvent,
    onSaveJobNotifications
}) => {

    useEffect(() => {
        loadInitialState();
    }, [loadInitialState])

    return isFetching || !settings || !jobSettings ?
        <Loader /> :
        <React.Fragment>
            <NotificationDistributionsComponent
                saveButtonClickEvent={saveButtonClickEvent}
                checkboxChangeEvent={checkboxChangeEvent}
                wantsEmail={settings.wantsEmail}
                wantsSms={settings.wantsSms}
                wantsWeb={settings.wantsWeb}
                onSave={() => {
                    saveButtonClickEvent(settings.wantsEmail,
                        settings.wantsSms,
                        settings.wantsWeb,
                        settings.wantsJob,
                        settings.wantsSystem,
                        settings.wantsPromotional,
                        settings.emailNotificationType,
                        settings.emailNotificationSpec)
                }} />

            <NotificationCategoriesComponent
                saveButtonClickEvent={saveButtonClickEvent}
                checkboxChangeEvent={checkboxChangeEvent}
                wantsJob={settings.wantsJob}
                wantsSystem={settings.wantsSystem}
                wantsPromotional={settings.wantsPromotional}
                onSave={() => {
                    saveButtonClickEvent(settings.wantsEmail,
                        settings.wantsSms,
                        settings.wantsWeb,
                        settings.wantsJob,
                        settings.wantsSystem,
                        settings.wantsPromotional,
                        settings.emailNotificationType,
                        settings.emailNotificationSpec)
                }} />

            <JobNotificationsComponent

                saveButtonClickEvent={saveButtonClickEvent}
                changeEvent={jobNotificationsChangeEvent}
                jobNotifications={settings.emailNotificationTypes}
                emailNotificationType={settings.emailNotificationType}
                emailNotificationSpec={settings.emailNotificationSpec}
                onSave={() => {
                    saveButtonClickEvent(settings.wantsEmail,
                        settings.wantsSms,
                        settings.wantsWeb,
                        settings.wantsJob,
                        settings.wantsSystem,
                        settings.wantsPromotional,
                        settings.emailNotificationType,
                        settings.emailNotificationSpec)
                }} />

            <JobNotificationSettings
                jobSettings={jobSettings}
                onSave={onSaveJobNotifications}
            />
        </React.Fragment>
}

export default connect(mapStateToProps, mapDispatchToPros)(SettingsNotificationsContainer)