import React from 'react'
import { connect } from 'react-redux'
import { createOrganisation } from '../actions/organisation'
import OrganisationCreate from '../components/organisations/OrganisationCreate'
import { getActiveAccount } from '../selectors/Auth';
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state, ownProps) => {
    return {
        values: state.organisation.organisation,
        isFetching: isFetching(state.fetching, createOrganisation),
        activeAccount: getActiveAccount(state.auth)
    };
};

const mapDispatchToProps = (dispatch) => ({
    createOrganisation: (accountId) => (values) => dispatch(createOrganisation(accountId, values))
})

let OrganisationCreateContainer = ({ match, activeAccount, createOrganisation }) => {
    return <OrganisationCreate onSubmit={createOrganisation(activeAccount.accountId)} />
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationCreateContainer)
