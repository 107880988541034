import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { listSchedules$ } from "../../api";
import SchedulesComponent, {
  SchedulesFreeText,
} from "../../components/schedules/Schedules";
import SchedulesSchema from "./SchedulesSchema";

const SchedulesContainer = () => {
  const [schedulesView, setSchedulesView] = useState();
  const [schedules, setSchedules] = useState();

  const [page, setPage] = useState(1);

  const schema = useMemo(() => SchedulesSchema, []);
  const onPageChange = useCallback((page) => setPage(page), []);
  const history = useHistory();

  const onFilter = useCallback(
    (text) => {
      const filteredSchedules = schedules.filter(
        (s) =>
          (s.name ?? "").toLowerCase().includes(text) ||
          (s.description ?? "").toLowerCase().includes(text)
      );
      setSchedulesView({ ...schedulesView, schedules: filteredSchedules });
    },
    [schedules, schedulesView]
  );

  useEffect(() => {
    listSchedules$(page).subscribe((view) => {
      setSchedulesView(view);
      setSchedules(view.schedules);
    });
  }, [page]);
  return (
    <SchedulesComponent
      header={<SchedulesFreeText onChange={onFilter} />}
      view={schedulesView}
      onPageChange={onPageChange}
      page={page}
      schema={schema}
      onScheduleSelect={(s) => history.push(`/admin/schedules/${s.id}`)}
    ></SchedulesComponent>
  );
};

export default SchedulesContainer;
