import React from "react";
import PropTypes from "prop-types";
import "./TabFilter.css";
import Privilege from "../../../containers/Privilege";

const OptionalPrivilege = ({ filter, children }) =>
  filter.type && filter.privilege ? (
    <Privilege type={filter.type} privilege={filter.privilege}>
      {children}
    </Privilege>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

const TabFilter = ({ filters, onChange, selectedFilter, children }) => (
  <div className="tab-filter">
    <ul className="nav nav-tabs">
      {filters.map((f, i) => (
        <OptionalPrivilege filter={f} key={i}>
          <li
            className={f.id === selectedFilter ? "active nav-item" : "nav-item"}
            onClick={() => onChange(f.id)}
          >
            <span
              className={
                f.id === selectedFilter ? "nav-link active" : "nav-link pointer"
              }
            >
              {f.description}
            </span>
          </li>
        </OptionalPrivilege>
      ))}
    </ul>
    <div className="tab-content">{children}</div>
  </div>
);

TabFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TabFilter;
