const dashboard = [ 
    {
        to: "/dashboard",
        privilege: "read",
        privilegeType: "Start/dashboard page",
        title: "Dashboard"
    },
    {
        to: "/jobs/all",
        privilege: "read",
        privilegeType: "Jobs page",
        title: "Jobs",
        children: [
            [
                {
                    to: "/jobs/all",
                    title: "All",
                    icon: "icon icon-inbox"
                },
                {
                    to: "/jobs/runs",
                    title: "Runs",
                    icon: "icon icon-exchange"
                },
                {
                    to: "/history",
                    title: "History & reports",
                    icon: "icon icon-file-pdf"
                }

            ], 
            [
                {
                    to: "/admin/jobs",
                    privilege: "read",
                    privilegeType: "Jobs settings page",
                    title: "Configuration",
                    icon: "icon icon-cog",
                },
                {
                    to: "/admin/schedules",
                    privilege: "read",
                    privilegeType: "Jobs settings page",
                    title: "Schedules",
                    icon: "icon icon-calendar",
                }
            ]
        ]
    },
    {
        to: "/admin/connectors",
        privilege: "read",
        privilegeType: "Network extend menu",
        title: "Network",
        children: [
            [
                {
                    to: "/admin/connectors",
                    title: "Connectors",
                    icon: "icon icon-fs_connector"
                },
                {
                    to: "/admin/endpoints",
                    title: "Endpoints",
                    icon: "icon icon-flow-tree"
                },                
                {
                    to: "/explore",
                    title: "Explore and share",
                    icon: "icon icon-explorer"
                },
            ],
            [
                {
                    to: "/admin/extendnetwork/invites",
                    title: "Connection requests",
                    icon: "icon icon-mail"
                },
                {
                    to: "/admin/extendnetwork/invites/createinvite",
                    title: "Connection requests",
                    hidden: true
                },
                {
                    to: "/admin/extendnetwork/tokens",
                    title: "Connection tokens",
                    icon: "icon icon-key"
                },
                {
                    to: "/admin/extendnetwork/tokens/connectbytoken",
                    title: "Connect by token",
                    hidden: true
                },
                {
                    to: "/admin/connections",
                    title: "Connections",
                    icon: "icon icon-link"
                }
            ]
        ]
    }
];

const exportDashboard = { dashboard }

export default exportDashboard