import React from 'react'
import { dateFormat } from '../../../common/dateFormatter';

const InvoicePeriods = ({periods, period, onChange}) => {
    return <select
    value={periods?.indexOf(period)}
    className="form-control form-control-lg border-input"
    onChange={(e) => {
      onChange(periods[e.target.value]);
    }}
  >
    {periods?.map((p, i) => (
      <option key={p.startPeriod} value={i}>
        {dateFormat(p.startPeriod)} -{" "}
        {dateFormat(p.endPeriod)}
      </option>
    ))}
  </select>
}

export default InvoicePeriods;