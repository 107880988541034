import React from 'react'
import Sso from '../components/sso/Sso'
import './Login.css'

const SsoPage = ({location}) => {
    return (
        <div className="login-wrapper">
            <div className="login align-self-center flex-fill">
                <Sso />
            </div>
        </div>
    )
}

export default SsoPage