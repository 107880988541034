export const volumeFormatter = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 bytes";
  const format = volume(bytes, decimals);
  return format.data + " " + format.volume;
};

export const volume = (bytes, decimals = 2) => {
  if (bytes === 0) return { data: 0, volume: "bytes" };
  let k = 1000,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return {
    data: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    volume: sizes[i],
  };
};
