import React from 'react'
import LoginContainer from '../containers/Login'
import './Login.css'

const LoginPage = ({match}) => {
    return (
        <div className="login-wrapper">
            <div className="login align-self-center flex-fill">
                <LoginContainer match={match} />
            </div>
        </div>
    )
}

export default LoginPage