import React from 'react'
import Card, { Header, Body } from '../common/Card';

const ChangePasswordComponent = ({
    inputChangeHandler,
    changePasswordClick,
    newPasswordRetype,
    newPassword,
    oldPassword,
    errors
}) => {
    return (
        <Card className="card-settings">
            <Header title="Change password">
                <span>Change your password</span>
            </Header>
            <Body>
                <div className="form-group">
                    <input className="form-control form-control-lg border-input" type="password" placeholder="Old password" onChange={(event) => inputChangeHandler(event.target.value, 'oldPassword')} />
                </div>
                <div className="form-group">
                    <input className="form-control form-control-lg border-input" type="password" placeholder="New password" onChange={(event) => inputChangeHandler(event.target.value, 'newPassword')} />
                </div>
                <div className="form-group">
                    <input className="form-control form-control-lg border-input" type="password" placeholder="Confirm new password" onChange={(event) => inputChangeHandler(event.target.value, 'newPasswordRetype')} />
                </div>
                <div className="form-group">
                    <button onClick={(event) => changePasswordClick(oldPassword, newPassword, newPasswordRetype)} className="btn btn-dark btn-lg">Save</button>
                </div>
                {
                    <div className="text-danger">
                        {
                            errors && errors.map((e, index) => (<li key={index}>{e}</li>))
                        }
                    </div>

                }
            </Body>
        </Card>
    )
}

export default ChangePasswordComponent;