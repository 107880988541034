import React from 'react'
import Table from '../common/table/Table'
import Privilege from '../../containers/Privilege'
import { NavLink } from 'react-router-dom'
import Card, { Body, Header } from '../common/Card';

const InvitesList = ({
    title,
    schema,
    invites,
    showInvite }) => {

    return (
        <div>
            {
                
                <Card className="border-0 mt-0">
                    <Header title={title}></Header>
                    <Body>
                        <div className="table-responsive table-full-width">
                            <Table data={invites}
                                schema={schema}
                                onRowClick={(d) => { showInvite && showInvite(d) }}
                                infoIfEmpty={'No ' + title} />
                        </div>

                        <Privilege type="Network extend page" privilege="read">
                            <NavLink className="btn btn-primary btn-lg" activeClassName="active" to="/admin/extendnetwork/invites/createinvite">
                                <span className="icon icon-plus"></span> New request
                            </NavLink>
                        </Privilege>
                    </Body>
                </Card>
            }
            
        </div >
    )
}

export default InvitesList;