import React from 'react'
import { Route, Switch } from "react-router-dom"
import GeneralSettingsPage from './GeneralSettings'
import SettingsNotificationsPage from './SettingsNotifications'
import AuthOptionsPage from './AuthOptions'
import TabLinks, { TabHeader, TabLink, TabContent } from '../components/common/tab/TabLinks';
import PageContent, { ContentBody, ContentHeader } from '../components/common/PageContent';

const MySettingsPage = () => {
    return (
        <PageContent>
            <ContentHeader title="My settings"/> 
            <ContentBody>
                <TabLinks header={
                    <TabHeader>
                        <TabLink to="/settings/my-settings/general" title={<span><i className="icon icon-cog"></i><span className="d-none d-md-inline"> General</span></span>}/>
                        <TabLink to="/settings/my-settings/notification-settings" title={<span><i className="icon icon-paper-plane"></i><span className="d-none d-md-inline"> Notifications</span></span>} />
                        <TabLink to="/settings/my-settings/authentication-options" title={<span><i className="icon icon-lock"></i><span className="d-none d-md-inline"> Password</span></span>} />
                    </TabHeader>
                }>
                    <TabContent>
                        <Switch>
                            <Route path="/settings/my-settings/general" component={GeneralSettingsPage} />
                            <Route path="/settings/my-settings/notification-settings" component={SettingsNotificationsPage} />
                            <Route path="/settings/my-settings/authentication-options" component={AuthOptionsPage} />
                        </Switch>
                    </TabContent>
                </TabLinks>
            </ContentBody>
        </PageContent>
    )
}

export default MySettingsPage;