

export const JobEventTypes = [
    { id: 1, name: 'Copyfiles' },
    { id: 2, name: 'Movefiles' },
    { id: 3, name: 'Deletefiles' }
]

export const JobEventCompletionTypes = [
    { id: 10, name: 'GoToNextEventInChain' },
    { id: 20, name: 'StopAndReportError' },
    { id: 30, name: 'StopAndReportSuccess' }
]

export const JobNotificationTypes = [
    { id: 10, name: 'Always notify' },
    { id: 20, name: 'Only errors' },
    { id: 30, name: 'Notifications and warnings' }
]

export const getJobNotificationType = (id) => JobNotificationTypes.find(j  => j.id === parseInt(id, 10));

export const ScheduleDateTypes = [
    { id: 1, name: 'One time' },
    { id: 101, name: 'Repeat every X days' },
    { id: 102, name: 'Repeat specific weekdays' },
    { id: 103, name: 'Repeat every X days in month' }
]

export const ScheduleTimeTypes = [
    { id: 1, name: 'Once' },
    { id: 100, name: 'Repeat every X hours' },
    { id: 101, name: 'Repeat every X minutes' }
]

export const ScheduleTypes = [
    { id: 1, name: 'Select an already existing schedule to execute the job' },
    { id: 2, name: 'Start job upon detection of a trigger file' },
    { id: 3, name: 'Create a new schedule to execute the job' }
]

export const FileExsistsCriterias = [
    { id: 601, name: 'Replace' },
    { id: 602, name: 'Copy' },
    { id: 603, name: 'Skip' }
]

export const FileSizeCriterias = [
    { id: 201, name: 'Exact match' },
    { id: 202, name: 'Larger than' },
    { id: 203, name: 'Smaller than' }
]

export const WeekDays = [
    { id: 1, name: 'Mon' },
    { id: 2, name: 'Tue' },
    { id: 4, name: 'Wed' },
    { id: 8, name: 'Thu' },
    { id: 16, name: 'Fri' },
    { id: 32, name: 'Sat' },
    { id: 64, name: 'Sun' }
]

export const TimeMeasurement = [
    { id: 'hour', name: 'hour' },
    { id: 'day', name: 'day' },
    { id: 'week', name: 'week' },
    { id: 'month', name: 'month' }
]

export const SizeMeasurement = [
    { id: 'byte', name: 'byte' },
    { id: 'kb', name: 'kB' },
    { id: 'MB', name: 'MB' },
    { id: 'GB', name: 'GB' } 
]

export const MaxBundling = [
    { id: '1', name: '1 - Off' },
    { id: '100', name: '100 - On' }
]

export const MaxThreads = [
    { id: '1', name: '1' },
    { id: '5', name: '5' },
    { id: '10', name: '10' }
]

export const JobPermissions = [
    { id: '0', name: 'None' },
    { id: '1', name: 'View' },
    { id: '4', name: 'Run' }
]

export const getJobPermission = (id) => {
    return JobPermissions.find(j => j.id === (id +''))
};

export const NewJob = () => {
    return {
        name: '',
        description: '',
        triggerType: '1',
        trigger: {},
        schedule: {
            time: {
                type: "1"
            },
            date: {
                type: "1"
            }
        }
    }
}

export const NewJobEvent = () => {
    return {
        isNew: true,
        eventType: "1",
        onSuccess: "10",
        onFailure: "20",
        maxNumberOfError: 0,
        
        source: {
            sourceId: null,
            shareId: null,
            filesFromPreviousEvent: false,
            sendAlarmIfJobRunsEmpty: false,
            onlyCheckOnWeekdays: false,
            includeSubFolders: false,
            throwErrorIfEmpty: false,
            forceSync: false,
            fileSizeCriteria: null,
            minMinutesModified: 1
        },
        destination: {
            sourceId: null,
            shareId: null,
            forceSync: false,
            destinationisArchive: false,
            fileExsistsCriteria: '601'
        },
        options: {
            unEncrypt: false,
            maxBundling: "100",
            maxThreads: "10",
            sendAlarmIfTotalAmountDataIsLessThan: { isActive: false, time: "hour", size: "byte" },
            sendAlarmIfTotalAmountDataIsGreaterThan: { isActive: false, time: "hour", size: "byte" },
            sendAlarmIfTotalNumberFilesIsLessThan: { isActive: false, time: "hour" },
            sendAlarmIfTotalNumberFilesIsGreaterThan: { isActive: false, time: "hour" }
        }
    }
}

