import React, { useState } from 'react'
import Card, { Body, Header } from '../common/Card'
import { TimeZones } from '../../selectors/Common';

const TimeZone = ({ initTimeZone, onSave }) => {

    const [timeZone, setTimeZone] = useState(initTimeZone);

    return (
        <Card className="card-settings">
            <Header title="My timezone">
                <div className="alert highlight" role="alert"><span>Edit your timezone.</span></div>
            </Header>
            <Body>
                <div className="table-responsive">
                    <div className="form-group">
                        <label>Time zone</label>
                        <select className="form-control form-control-lg border-input" value={timeZone}
                            onChange={(ev) => setTimeZone(ev.target.value)}>
                            <option>My timezone...</option>
                            {TimeZones.map((o, index) =>
                                <option key={index} value={o.id} >{o.name}</option>)}
                        </select>
                    </div>
                </div>
                <button className="btn btn-dark btn-lg" onClick={() => onSave(timeZone)}>Save</button>
            </Body>
        </Card>
    )
}

export default TimeZone
