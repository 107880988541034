import {
    LOAD_CONNECTIONS_LIST,
    LOAD_CONNECTIONS_LIST_SUCCESS,
    LOAD_CONNECTIONS_DETAILS,
    LOAD_CONNECTIONS_DETAILS_SUCCESS
} from '../actions/connections';

export const connections = (state = { connections: [], details: {}, sharesFrom: [], sharesTo: [], jobsTo: [], jobsFrom: [] }, action) => {
    switch (action.type) {
        case LOAD_CONNECTIONS_LIST:
            return {
                ...state
            }
        case LOAD_CONNECTIONS_LIST_SUCCESS:
            return {
                ...state,
                connections: action.connections || []
            }
        case LOAD_CONNECTIONS_DETAILS:
            return {
                ...state,
            }
        case LOAD_CONNECTIONS_DETAILS_SUCCESS:
            return {
                ...state,
                details: action.details,
                jobsFrom: action.jobsFrom,
                jobsTo: action.jobsTo,
                sharesFrom: action.sharesFrom,
                sharesTo: action.sharesTo
            }
        default:
            return state
    }
}