export const LOAD_INVITES_LIST = 'REQ_LOAD_INVITES_LIST'
export const LOAD_INVITES_LIST_SUCCESS = 'LOAD_INVITES_LIST_SUCCESS'
export const LOAD_INVITES_LIST_FAILED = 'LOAD_INVITES_LIST_FAILED'

export const LOAD_INVITATIONTOKEN_LIST = 'REQ_LOAD_INVITATIONTOKEN_LIST'
export const LOAD_INVITATIONTOKEN_LIST_SUCCESS = 'LOAD_INVITATIONTOKEN_LIST_SUCCESS'
export const LOAD_INVITATIONTOKEN_LIST_FAILED = 'LOAD_INVITATIONTOKEN_LIST_FAILED'

export const CREATE_INVITATION_TOKEN = 'REQ_CREATE_INVITATION_TOKEN'
export const CREATE_INVITATION_TOKEN_SUCCESS = 'CREATE_INVITATION_TOKEN_SUCCESS'
export const CREATE_INVITATION_TOKEN_FAILED = 'CREATE_INVITATION_TOKEN_FAILED'

export const DELETE_INVITATION_TOKEN = 'REQ_DELETE_INVITATION_TOKEN'
export const DELETE_INVITATION_TOKEN_SUCCESS = 'DELETE_INVITATION_TOKEN_SUCCESS'

export const CONNECT_WITH_INVITATION_TOKEN = 'CONNECT_WITH_INVITATION_TOKEN'

export const LOAD_INVITATION_TOKEN_ACCOUNT = 'REQ_LOAD_INVITATION_TOKEN_ACCOUNT'

export const LOAD_INVITATION_TOKEN_ACCOUNT_SUCCESS = 'LOAD_INVITATION_TOKEN_ACCOUNT_SUCCESS'
export const LOAD_INVITATION_TOKEN_ACCOUNT_FAILED = 'LOAD_INVITATION_TOKEN_ACCOUNT_FAILED'
export const SET_INVITATION_TOKEN_ACCOUNT = 'REQ_SET_INVITATION_TOKEN_ACCOUNT'
export const CONNECT_WITH_INVITATION_TOKEN_SUCCESS = 'CONNECT_WITH_INVITATION_TOKEN_SUCCESS'
export const CONNECT_WITH_INVITATION_TOKEN_FAILED = 'CONNECT_WITH_INVITATION_TOKEN_FAILED'

export const SET_FORM_DATA_INVITE = 'SET_FORM_DATA_INVITE'

export const REVOKE_INVITE = 'REQ_REVOKE_INVITE'
export const REVOKE_INVITE_SUCCESS = 'REVOKE_INVITE_SUCCESS'

export const DECLINE_INVITE = 'REQ_DECLINE_INVITE'
export const DECLINE_INVITE_SUCCESS = 'DECLINE_INVITE_SUCCESS'

export const ACCEPT_INVITE = 'REQ_ACCEPT_INVITE'
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS'

export const GET_INVITE = 'REQ_GET_INVITE'
export const GET_INVITE_SUCCESS = 'GET_INVITE_SUCCESS'
export const GET_INVITE_FAILED = 'GET_INVITE_FAILED'

export const CREATE_ACCOUNT_RELATION_INVITE = 'REQ_CREATE_ACCOUNT_RELATION_INVITE'
export const CREATE_USER_INVITE = 'REQ_CREATE_USER_INVITE'
export const CREATE_ACCOUNT_RELATION_INVITE_SUCCESS = 'CREATE_ACCOUNT_RELATION_INVITE_SUCCESS'
export const CREATE_USER_INVITE_SUCCESS = 'CREATE_USER_INVITE_SUCCESS'
export const REVOKE_USER_INVITE = "REVOKE_USER_INVITE"
export const RESEND_INVITE = "RESEND_INVITE"

export const loadInvitesList = () => ({
    type: LOAD_INVITES_LIST,
})

export const revokeInvite = (inviteId) => ({
    type: REVOKE_INVITE,
    inviteId
})

export const resendInvite = (token) => ({
    type: RESEND_INVITE,
    token
})

export const declineInvite = (token) => ({
    type: DECLINE_INVITE,
    token: token
})

export const acceptInvite = (acceptInfo, verificationToken) => ({
    type: ACCEPT_INVITE,
    acceptInfo, verificationToken
})

export const loadInvitesListSuccess = (sentInvites, recivedInvites) => ({
    type: LOAD_INVITES_LIST_SUCCESS,
    sentInvites,
    recivedInvites
})

export const loadInvitesListFailed = (error) => ({
    type: LOAD_INVITES_LIST_FAILED,
    error: error
})

export const loadInvitationTokens = () => ({
    type: LOAD_INVITATIONTOKEN_LIST
})

export const loadInvitationTokenListSuccess = (tokens) => ({
    type: LOAD_INVITATIONTOKEN_LIST_SUCCESS,
    invitationTokens: tokens
})

export const loadInvitationTokenListFailed = (error) => ({
    type: LOAD_INVITATIONTOKEN_LIST_FAILED,
    error: error
})

export const createInvitationToken = () => ({
    type: CREATE_INVITATION_TOKEN
})


export const deleteInvitationToken = (tokenId) => ({
    type: DELETE_INVITATION_TOKEN,
    tokenId: tokenId
})

export const deleteInvitationTokenSuccess = () => ({
    type: DELETE_INVITATION_TOKEN_SUCCESS
})

export const loadAccountFromToken = (token) => ({
    type: LOAD_INVITATION_TOKEN_ACCOUNT,
    token: token
})

export const loadAccountFromTokenSuccess = (account) => ({
    type: LOAD_INVITATION_TOKEN_ACCOUNT_SUCCESS,
    account: account
})

export const loadAccountFromTokenFailed = (error) => ({
    type: LOAD_INVITATION_TOKEN_ACCOUNT_FAILED,
    error: error
})

export const connectWithAccount = (token) => ({
    type: CONNECT_WITH_INVITATION_TOKEN,
    token: token
})

export const connectWithAccountSuccess = () => ({
    type: CONNECT_WITH_INVITATION_TOKEN_SUCCESS
})

export const connectWithAccountFailed = () => ({
    type: CONNECT_WITH_INVITATION_TOKEN_FAILED
})

export const setSearchAccountToken = (token) => ({
    type: SET_INVITATION_TOKEN_ACCOUNT,
    token: token
})

export const setFormDataInvite = (value, key) => ({
    type: SET_FORM_DATA_INVITE,
    value: value,
    key: key
})

export const createAccountRelationInvite = (email, message) => ({
    type: CREATE_ACCOUNT_RELATION_INVITE,
    email,
    message
})


export const createUserInvite = (email, inviteInfo, accountId) => ({
    type: CREATE_USER_INVITE,
    email,
    inviteInfo,
    accountId
})

export const createAccountRelationInviteSuccess = () => ({
    type: CREATE_ACCOUNT_RELATION_INVITE_SUCCESS
})


export const createUserInviteSuccess = (accountId) => ({
    type: CREATE_USER_INVITE_SUCCESS,
    accountId
})


export const revokeUserInvite = (inviteId) => ({
    type: REVOKE_USER_INVITE,
    inviteId
})


export const revokedInviteSuccessFully = () => ({
    type: LOAD_INVITES_LIST
})

export const acceptedInviteSuccessFully = (acceptInfo) => {
    return {
        type: ACCEPT_INVITE_SUCCESS,
        acceptInfo
    }
}

export const declinedInviteSuccessFully = () => ({
    type: DECLINE_INVITE_SUCCESS
})

export const getInvite = (verificationToken) => ({
    type: GET_INVITE,
    verificationToken
})

export const getInviteSuccess = (invite) => ({
    type: GET_INVITE_SUCCESS,
    invite
})

export const getInviteFailed = (errors) => ({
    type: GET_INVITE_FAILED,
    errors: errors
})

