import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { renderRadioButtons } from '../../common/ReduxFormHelper'
import ScheduleTypeForm from './ScheduleTypeForm'
import TriggerTypeForm from './TriggerTypeForm'
import { ScheduleTypes } from '../../../selectors/Job'
import stepThreeValidation from './stepThreeValidation'
import { connect } from 'react-redux'
import Card, { Body, Header } from '../../common/Card';


let CreateJobWizzardStepThree = ({
    schedules,
    changeSchedule,
    resetSchedule,
    weedDaysChangeEvent,
    resetScheduleDate,
    //resetScheduleDay,
    events,
    triggerType,
    schedule,
    updateJobEvent }) => {

    const event = events[0];

    //const dateValue = schedule ? schedule.datevalue : 0

    return (
        <Card>
            <Header title="Frequency">
                <div className="alert highlight" role="alert">
                    <span>When and with what frequency do you want to run this job?</span>
                </div>
            </Header>
            <Body>
                <div className="row">
                    <div className="col-md-12">
                        <Field component={renderRadioButtons} name="triggerType" options={ScheduleTypes} onChange={(e) => {
                        updateJobEvent(`trigger.pathId`, event.source.sourceId)
                        updateJobEvent(`trigger.source`, event.source.path)
                        updateJobEvent(`trigger.connector`, event.source.clientName)
                        updateJobEvent(`trigger.account`, event.source.accountName)
                        updateJobEvent(`trigger.organisationName`, event.source.organisationName)
                        resetSchedule();
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {triggerType === "1" && <ScheduleTypeForm schedule={schedule} schedules={schedules} changeSchedule={changeSchedule} readonlyMode={true} />}
                        {triggerType === "2" && <TriggerTypeForm sourceDir={event.source.path} />}
                        {triggerType === "3" && <ScheduleTypeForm schedule={schedule} readonlyMode={false} weedDaysChangeEvent={weedDaysChangeEvent} resetScheduleDate={resetScheduleDate} />}
                    </div>
                </div>
            </Body>
        </Card>
    )
}

CreateJobWizzardStepThree = reduxForm({
    form: 'create-job-wizzard',
    validate: stepThreeValidation,
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
})(CreateJobWizzardStepThree)

const selector = formValueSelector('create-job-wizzard') // <-- same as form name

export default connect(
    state => {
        const { events, triggerType, schedule } = selector(state, 'events', 'triggerType', 'schedule')
        return { events, triggerType, schedule }
    }
)(CreateJobWizzardStepThree)