const EndpointsSchema = [
  {
    accessor: "name",
    header: "Name",
    type: "custom",
    render: (data) => (
      <div className="content">
        <div><i className="table-icon icon icon-folder"></i></div>
        <div className="text">
          <span>{data.name}</span>
          <small>{data.path}</small>
        </div>
      </div>
      /*<span>
        <i className="icon icon-folder"></i> {data.name} <br />
        {data.path} 
      </span>*/
    ),
  },
  { accessor: "connector", header: "Connector" },
  { accessor: "account", header: "Account" },
  { accessor: "jobs", header: "Jobs" },
  {
    type: "custom",
    header: "Status",
    render: (data) =>
      data.status === "online" ? (
        <i
          className="icon icon-ok-circled text-success"
          title={data.status}
        ></i>
      ) : (
        <span className={"badge badge-danger"}>
          <i className="icon icon-attention-circled"></i> {data.status}
        </span>
      ),
  },
];

export default EndpointsSchema;
