import React from "react";
import PageContent, { ContentBody, ContentHeader } from "../common/PageContent";
import { NavLink } from "react-router-dom";
import "./Dashboard.css";

const ShortCuts = ({ toggleUserMenu }) => {
  return (
    <div>
      <h6 className="text-danger"><i className="icon icon-attention-circled"></i> Seems like you don't have a last active Cloudbuz account? </h6>
      <p>This could be because the account is deleted or an administrator removed you from the account.</p>
      <p>Try one of the following:</p>
      <ul>
        <li>
          <span>
            Select active Cloudbuz account{" "}
            <span
              className="btn-link pointer"
              onClick={(e) => toggleUserMenu()}
            >
              @Select account
            </span>
          </span>
        </li>
        <li>
          <span>
            Or create a new Cloudbuz account{" "}
            <NavLink activeClassName="active" to="/admin/accounts/new">
              @Create account
            </NavLink>
          </span>
        </li>        
        <li>
          <span>
            Or do you want to completely delete your user account and all Cloudbuz accounts you´re currently the owner of? Then delete your user account here{" "}
            <NavLink
              activeClassName="active"
              to="/settings/my-settings/general/"
            >
              @My settings
            </NavLink>
          </span>
        </li>
      </ul>
      <p>
        <i className="icon icon-logo_mark"></i> The Cloudbuz team
      </p>
    </div>
  );
};

const DashboardInfo = ({ toggleUserMenu }) => {
  return (
    <PageContent className="bg-light">
      <ContentHeader title="Dashboard" />
      <ContentBody>
        <div className="dashboardArea">
          <h5>General status</h5>
          <ShortCuts toggleUserMenu={toggleUserMenu} />
        </div>
      </ContentBody>
    </PageContent>
  );
};

export default DashboardInfo;
