import React from "react";
import NavbarPopupMenu from "../common/navbar/NavbarPopupMenu";
import Search from "../search/Search";
import "./Header.css";
import NotificationHeader from "./NotificationHeader";
import SettingsMenu from "./SettingsMenu";

const NavBarTitle = ({ userName }) => {
  return (
    <div className="round-item">
      <p className="p-0 m-0">{userName}</p>
    </div>
  );
};

const Header = ({
  signOut,
  changeAccount,
  user,
  notifications,
  toggleLeftMenu,
  toggleUserMenu,
  userMenuOpen,
  info,
  isChangingAccount,
}) => {
  return (
    <div className="top-menu no-print">
      <div className="row no-print">
        <div className="col-2">
          <div className="btn-group d-lg-none">
            <button
              type="button"
              className="btn btn-outline btn-light"
              onClick={() => toggleLeftMenu()}
            >
              <span className="icon icon-menu"></span>
            </button>
          </div>
        </div>
        <div className="col-10 right-menu text-right">
          <div className="btn-toolbar float-right">
            <div className="btn-group no-print">
              <button
                type="button"
                className="btn btn-outline btn-light text-secondary"
              >
                
                {
                    parseInt(user.accountId, 10) ? <Search /> : ""
                }
                
              </button>
              <button type="button" className="btn btn-outline btn-light">
                <NavbarPopupMenu
                  toggleMenu={toggleUserMenu}
                  isOpen={userMenuOpen}
                  title={<NavBarTitle userName={user.name[0]} />}
                >
                  <SettingsMenu
                    info={info}
                    signOut={signOut}
                    changeAccount={changeAccount}
                    isChangingAccount={isChangingAccount}
                    toggleUserMenu={toggleUserMenu}
                    user={user}
                  />
                </NavbarPopupMenu>
              </button>
              <button type="button" className="btn btn-outline btn-light">
                <NotificationHeader notifications={notifications} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
