import React, { useCallback, useEffect, useState } from "react";
import Explore, {
  ExploreButtonBreadcrumbs,
  ExploreSearch,
  ExploreTable,
} from "../../../components/explore-and-share/Explore";
import { volumeFormatter } from "../../../common/volumeFormatter";
import { dateFormat } from "../../../common/dateFormatter";
import Loader from "../../../components/common/Loader";

const renderType = (data, activeAccountId) => {
  switch (data.type) {
    case "Folder":
      return (
        <div className="content">
          <div>
            <i
              className={
                data.isShared
                  ? "table-icon icon icon-folder_shared"
                  : "table-icon icon icon-folder"
              }
            ></i>
          </div>
          <div className="text">
            <span> {data.name}</span>
            <small>{data.name === data.path ? "" : data.path}</small>
          </div>
        </div>
      );
    case "FTP root":
      return (
        <div className="content">
          <div>
            <i
              className={
                data.isShared
                  ? "table-icon icon icon-folder_shared"
                  : "table-icon icon icon-folder"
              }
            ></i>
          </div>
          <div className="text">
            <span> {data.name}</span>
            <small>{data.name === data.path ? "" : data.path}</small>
          </div>
        </div>
      );
    case "File":
      return (
        <div className="content">
          <i className="table-icon icon icon-file_1"></i>
          <div className="text">
            <span> {data.name}</span>
            <small>{data.name === data.path ? "" : data.path}</small>
          </div>
        </div>
      );
    case "File Server":
      return (
        <div className="content">
          <div className="icon-warning">
            <span className="table-icon icon-fs_connector"></span>
          </div>
          <div>
            <span> {data.name}</span>
            <small>{data.name === data.path ? "" : data.path}</small>
          </div>
        </div>
      );
    case "FTP Client":
      return (
        <div className="content">
          <div className="icon-warning">
            <span className="table-icon icon-ftp_connector"></span>
          </div>
          <div className="text">
            <span> {data.name}</span>
            <small>{data.name === data.path ? "" : data.path}</small>
          </div>
        </div>
      );
    case "Organization":
      return (
        <div className="content">
          <div className="icon-warning">
            <span className="table-icon icon-organization"></span>
          </div>
          <div className="text">
            <span> {data.name}</span>
            <small>{data.name === data.path ? "" : data.path}</small>
          </div>
        </div>
      );
    case "Account":
      return (
        <div className="content">
          <div className="icon-warning">
            <span className="table-icon icon-home"></span>
          </div>
          <div className="text">
            <span> {data.name}</span>
            <small>
              {data.id === activeAccountId ? (
                <span>
                  <i className="icon icon-circle text-selected"></i> Active
                  account
                </span>
              ) : (
                <span>
                  <i className="icon icon-link text-secondary"></i> Connected
                  account
                </span>
              )}
            </small>
          </div>
        </div>
      );
    default:
      return (
        <div className="content">
          <div className="icon-success">
            <i className="table-icon ti-panel"></i>
          </div>
          <div className="text">
            <span> {data.name}</span>
            <small>{data.name === data.path ? "" : data.path}</small>
          </div>
        </div>
      );
  }
};

const ExportSelect = ({
  view,
  onRowClick,
  currentPage,
  onPageChange,
  searchText,
  onSortChange,
  sortBy,
  onSelect,
  initParams,
  isSearching,
  onSearchSub$,
  onSearchChange,
  initialState,
  isFetching,
  reset,
  mode,
  filters,
  selectName,
  activeAccount,
  download,
}) => {
  let defaultSchema = [
    {
      header: "Name",
      type: "custom",
      accessor: "name",
      render: (data) => renderType(data, activeAccount.accountId),
    },
    {
      header: "Type",
      accessor: "type",
    },
    {
      header: "Account",
      accessor: "accountName",
      collapseIfEmpty: true,
    },
    {
      header: "Size",
      type: "custom",
      accessor: "size",
      render: (data) => (data.size > 0 ? volumeFormatter(data.size) : "--"),
      collapseIfEmpty: true,
    },
    {
      header: "Modified",
      type: "custom",
      accessor: "date",
      render: (data) => (data.date ? dateFormat(data.date) : "--"),
      collapseIfEmpty: true,
    },
    {
      header: "Last activity",
      type: "custom",
      accessor: "lastActivity",
      render: (data) =>
        data.lastActivity ? dateFormat(data.lastActivity) : "--",
      collapseIfEmpty: true,
    },
  ];

  const schema = [
    ...defaultSchema,
    {
      type: "custom",
      render: (data) => (
        <div>
          {(data.type === "Folder" || data.type === "FTP root") &&
          !data.isLockedByJob ? (
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.stopPropagation();
                onSelect(data);
              }}
            >
              {selectName || "select"}
            </button>
          ) : (
            ""
          )}
          {data.isLockedByJob && (
            <div className="table-icon icon-warning">
              <i className="icon icon-lock"></i>
            </div>
          )}
        </div>
      ),
    },
  ];

  const init = useCallback(() => {
    initialState(
      initParams.id,
      initParams.shareId,
      initParams.type,
      1,
      mode,
      filters
    );
  }, [
    filters,
    initParams.id,
    initParams.shareId,
    initParams.type,
    initialState,
    mode,
  ]);

  /* Should handle loading logic in here instead of parental container */
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }

    init();
    setLoaded(true);

    return () => {
      reset && reset();
    };
  }, [init, loaded, onSearchChange, onSearchSub$, reset, view]);

  useEffect(() => {
    const subscription = onSearchSub$.subscribe(onSearchChange);
    return () => {
      subscription?.unsubscribe();
    };
  }, [onSearchChange, onSearchSub$]);
  return (
    <Explore
      view={view}
      header={
        <div>
          <ExploreButtonBreadcrumbs
            breadcrumbs={view?.breadcrumbs}
            onClick={onRowClick}
          />
          <ExploreSearch
            onSearchChange={(searchText) => {
              if (!searchText) {
                onSearchSub$.next("");
                init();
                return;
              }
              onSearchSub$.next(searchText);
            }}
            searchText={searchText}
            onClear={() => {
              onSearchSub$.next("");
              init();
            }}
          />
        </div>
      }
    >
      {!view || isFetching ? (
        <Loader title={isSearching ? "Still searching" : "Still loading"} />
      ) : (
        <ExploreTable
          view={view}
          schema={schema}
          onRowClick={onRowClick}
          currentPage={currentPage}
          onPageChange={onPageChange(
            initParams.id,
            initParams.shareId,
            initParams.type,
            sortBy
          )}
          onSortChange={
            isSearching
              ? null
              : onSortChange(initParams.id, initParams.shareId, initParams.type)
          }
          sortBy={sortBy}
          download={download}
        />
      )}
    </Explore>
  );
};

export default ExportSelect;
