import React from 'react'
import Table from '../common/table/Table'
import Card, { Header, Body } from '../common/Card'
import { NavLink } from 'react-router-dom'

const OrganisationList = ({ organisations, schema, onOrganisationSelect }) => {
  return (
    <Card className="card-settings">
      <Header title="Organisations">
        <div className="highlight">
          <span>Organisations are the second level in the account hierarchy. All accounts need at least one organisation but you can create new organisations to better comply with your companys organisational domains.</span>
        </div>
      </Header>
      <Body>
        {
          organisations ? <Table data={organisations} schema={schema} onRowClick={onOrganisationSelect} /> :
            <h4>You dont belong to any organisations yet.</h4>
        }
        <div className="content pt-5">
          <footer>
            <NavLink to="/admin/settings/organisations/new" className="btn btn-dark btn-lg">
              New organisation
            </NavLink>
          </footer>
        </div>
      </Body>
    </Card>
  )
}

export default OrganisationList;