import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { required, email } from "../common/ReduxFormFieldValidation";
import LoginCard, { Header, Body } from "../common/LoginCard";
import CbLogo from "../../images/logo_white.png";
import SsoLogin from "../sso/SsoLogin";

const renderField = ({
  input,
  label,
  type,
  name,
  autoComplete,
  meta: { touched, error, warning },
  icon,
  tooltip,
}) => (
  <div className="form-group">
    <input
      className="form-control form-control-lg border-input"
      {...input}
      placeholder={label}
      type={type}
      autoComplete={autoComplete}
    />
    {touched &&
      ((error && (
        <em htmlFor={name} className="invalid">
          {tooltip}
        </em>
      )) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

const SignUpComponent = ({ handleSubmit, submitting, errors }) => {
  const showError =
    errors && errors.length
      ? errors.map((e, i) => (
          <div key={i} className="alert alert-danger">
            <span>
              {e}{" "}
              {e === "User already exists." ? (
                <span>
                  {" "}
                  <Link to="forgotpassword" className="text-white">
                    Forgot password?
                  </Link>
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
        ))
      : "";

  return (
    <div>
      <div className="logo_login_placeholder">
        <img
          src={CbLogo}
          height="30px"
          alt="Cloudbuz Manager"
          className="logo_login"
        />
      </div>
      <LoginCard>
        <Header title="Sign up">
          <div className="alert alert-primary" role="alert">
            <p>
              Setup, manage and monitor automated secure file transfers in
              minutes. Enter your work email and get started today.
            </p>
            <p>It´s easy and free to sign up your company or organisation!</p>
            <div className="row">
              <div className="col-md-12">
                <p>
                  <i className="icon icon-ok text-success"></i> &nbsp;No
                  creditcard needed
                </p>
              </div>
              <div className="col-md-12">
                <p>
                  <i className="icon icon-ok text-success"></i> &nbsp;File
                  transfer automation, secure and simple
                </p>
              </div>
            </div>
          </div>
        </Header>
        <Body>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <Field
                name="email"
                component={renderField}
                type="email"
                label="Your work email"
                icon="icon icon-mail"
                validate={[required, email]}
                tooltip="Please enter your work email address"
              />

              {showError}

              <button
                type="submit"
                className="btn btn-success btn-block btn-lg"
                disabled={submitting}
              >
                Sign up
              </button>
              <div className="text-center mb-3">or</div>
              <SsoLogin />
            </fieldset>
            <footer>
              <div className="text-center">
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      By signing up, you agree to the{" "}
                      <Link to="/terms" target="_blank">Terms of Service</Link> and our{" "}
                      <Link to="/privacy" target="_blank">Privary policy</Link>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p>
                      Already have an account? <Link to="login">Log in</Link>
                    </p>
                  </div>
                </div>
              </div>
            </footer>
          </form>
        </Body>
      </LoginCard>
      <p className="greetings">
        Made with <i className="icon icon-heart love"></i> by{" "}
        <a className="text-white" href="https://www.zavanto.se">
          Zavanto
        </a>
      </p>
    </div>
  );
};

export default reduxForm({
  form: "signup",
})(SignUpComponent);
