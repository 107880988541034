import { map, takeUntil, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { START_AUTO_REFRESH, CANCEL_AUTO_REFRESH } from '../actions/autorefresh';
import { interval } from 'rxjs';

export const startAutoRefreshEpic$ = (action$, state$) => action$.pipe(
    ofType(START_AUTO_REFRESH),
    switchMap(action => 
        interval(action.interval).pipe(
            map(() => action.actionToExecute(state$.value)),
            takeUntil(action$.pipe(ofType(CANCEL_AUTO_REFRESH)))
    )
));