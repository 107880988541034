import React from 'react'
import { Field, Fields } from 'redux-form'
import { renderDropDown, renderField, renderCheckbox, renderDateBox, renderCheckboxWithChecked } from '../../common/ReduxFormHelper'
import { ScheduleTimeTypes, ScheduleDateTypes, WeekDays } from '../../../selectors/Job'
import { TimeZones } from '../../../selectors/Common'
import { createBinaryString } from '../../../common/decimalToBinary';

const renderDailyFrequencyFields = (fields) => {
    return (
        <div>
            {
                fields.schedule.timeType.input.value !== "1" &&
                <Field label="Value of X" component={renderField} type="text" name="schedule.timeValue" disabled={fields.readonlyMode} />
            }

        </div>
    )
}

/*
const createBinaryString = (nMask) => {
    for (var nFlag = 0, nShifted = nMask, sMask = ""; nFlag < 32;
         nFlag++, sMask += String(nShifted >>> 31), nShifted <<= 1);
    return sMask;
  }
*/

const renderDurationFrequencyFields = (fields) => {
    let dayMask = createBinaryString(parseInt(fields.dateValue,10))
    return (
    <div>
        {
            (parseInt(fields.schedule.dateType.input.value,10) !== 1 && parseInt(fields.schedule.dateType.input.value,10) !== 102) &&
            <Field label="Value of X" component={renderField} type="text" name="schedule.dateValue" disabled={fields.readonlyMode} />
        }

        {parseInt(fields.schedule.dateType.input.value,10) === 102 && !fields.readonlyMode && <div>
            {WeekDays.map((day, index) => <Field key={day.id}
                name={`schedule.day.${day.id}`}
                component={renderCheckbox}
                onChange={(e) => {
                    var name = e.target.name;
                    var value = name.substr(name.lastIndexOf('.') + 1)
                    fields.weedDaysChangeEvent(parseInt(value, 0), e.currentTarget.checked, fields.dateValue)
                }}
                type="checkbox"
                label={day.name}
                disabled={fields.readonlyMode}
                 />)} </div>}

        {parseInt(fields.schedule.dateType.input.value,10) === 102 && fields.readonlyMode && <div>
            {WeekDays.map((day, index) => <Field key={day.id} 
                checked={parseInt(dayMask.charAt(dayMask.length - index-1),10) === 1 ? true : false}
                name={`schedule.day.${day.id}`}
                component={renderCheckboxWithChecked}
                type="checkbox"
                label={day.name}
                disabled={fields.readonlyMode}
                />
                 )} 
        </div>}

    </div>
)}



const ScheduleTypeForm = ({
    schedule,
    schedules,
    changeSchedule,
    readonlyMode,
    weedDaysChangeEvent,
    resetScheduleDate
    //resetScheduleDay
}) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row pb-2">
                    {schedules && <div className="col-md-4">
                        <div className="table-responsive">
                            <Field component={renderDropDown}
                                emptyOption={true}
                                options={schedules}
                                type="text"
                                name="schedule.id"
                                label="Select existing"
                                onChange={(e) => changeSchedule(e.target.value)} />
                        </div>
                    </div>}
                </div>
            </div>
            <div className="col-md-4">
                <h6 className="wiz-form-header">General</h6>
                <Field component={renderField} type="text" name="schedule.name" label="Name  *" disabled={readonlyMode} />
                <Field component={renderField} type="text" name="schedule.description" label="Description" disabled={readonlyMode} />
                <div className="table-responsive">
                    <Field emptyOption={true} component={renderDropDown} options={TimeZones} type="text" name="schedule.timeZoneId" label="Time zone" disabled={readonlyMode} />
                </div>
            </div>
            <div className="col-md-4">
                <h6 className="wiz-form-header">Duration and frequency</h6>
                <div className="row">
                    <div className={schedule.dateType === "1" ? "col-md-12" : "col-md-8"}>
                        <Field component={renderDropDown}
                            options={ScheduleDateTypes} 
                            type="text"
                            name="schedule.dateType"
                            label="Type"
                            disabled={readonlyMode}
                            onChange={e => resetScheduleDate()} />
                    </div>
                    <div className="col-md-4">
                        <Fields names={[
                            'schedule.dateType',
                            'schedule.dateStart',
                            'schedule.dateStop',
                            'schedule.dateValue']}
                            component={renderDurationFrequencyFields}
                            readonlyMode={readonlyMode}
                            weedDaysChangeEvent={weedDaysChangeEvent}
                            label="Value of X"
                            dateValue={schedule.dateValue} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Field component={renderDateBox} type="date" name="schedule.dateStart" label="Start *" disabled={readonlyMode} />
                    </div>
                    {
                        schedule.dateType !== "1" &&
                        <div className="col-md-12">
                            <Field component={renderDateBox} type="date" name="schedule.dateStop" label="Stop" disabled={readonlyMode} />
                        </div>
                    }
                </div>


            </div>
            <div className="col-md-4">
                <h6 className="wiz-form-header">Daily frequency</h6>
                <div className="row">
                    <div className={schedule.timeType === "1" ? "col-md-12" : "col-md-8"}>
                        <Field component={renderDropDown} options={ScheduleTimeTypes} type="text" name="schedule.timeType" label="Type" disabled={readonlyMode} />
                    </div>
                    <div className="col-md-4">
                        <Fields names={[
                            'schedule.timeType',
                            'schedule.timeStart',
                            'schedule.timeStop',
                            'schedule.timeValue']}
                            component={renderDailyFrequencyFields}
                            readonlyMode={readonlyMode} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Field component={renderField} type="time" name="schedule.timeStart" label="Start *" disabled={readonlyMode} />
                    </div>
                    <div className="col-md-12">
                        {
                            schedule.timeType !== "1" &&
                            <Field component={renderField} type="time" name="schedule.timeStop" label="Stop" disabled={readonlyMode} />
                        }
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ScheduleTypeForm


