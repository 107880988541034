import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal-root");

export const Context = React.createContext();

export const ModalProvider = ({ children }) => {
  const [activeModal, setModal] = useState();
  const [modals, _addModal] = useState({});

  const showModal = useCallback(
    (name, props = {}) => {
      setModal({ ...modals[name], props, isVisible: true });
    },
    [modals]
  );

  const closeModal = useCallback(() => {
    setModal({ ...activeModal, isVisible: false });
  }, [activeModal]);

  const addModal = useCallback((modal) => {
    _addModal((m) => ({ ...m, ...modal }));
  }, []);

  return (
    <React.Fragment>
      <Context.Provider
        value={{
          activeModal,
          closeModal,
          showModal,
          addModal,
        }}
      >
        {children}
      </Context.Provider>
    </React.Fragment>
  );
};

export const Modal = ({
  title,
  modal,
  close,
  icon,
  position = "modal",
  ...rest
}) => {
  if (position === "right") {
    return (
      <div
        id="right-context-menu"
        className={modal && modal.isVisible ? "visible no-print" : "no-print"}
      >
        <div className="row right-context-menu-header">
          <div className="col-6">
            <h5 className="m-0 p-0">
              <i className={`icon ${icon}`}></i>
              {title}
            </h5>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-light btn-sm m-0 p-0">
              <i
                className="icon icon-cross"
                data-testid="close"
                onClick={() => close()}
              ></i>
            </button>
          </div>
        </div>
        {modal && modal.isVisible && <modal.modal title={title} {...rest} />}
      </div>
    );
  }

  // TODO: Set title/body here.

  return modal && modal.isVisible ? (
    <div>{modal && <modal.modal title={title} {...rest} />}</div>
  ) : (
    ""
  );
};

export const ModalContainer = ({ type }) => {
  const { closeModal, activeModal } = useModalContext();
  if ((!activeModal?.type && !type) || activeModal?.type === type) {
    return createPortal(
      <Modal
        modal={activeModal}
        close={closeModal}
        {...(activeModal ? activeModal.props : {})}
      />,
      modalRoot
    );
  }

  return <div></div>
};

export const useModalContext = () => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
};

export const useModal = () => {
  return useModalContext();
};
