import React, { useMemo } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import TabLinks, {
  TabHeader,
  TabLink,
  TabContent,
} from "../components/common/tab/TabLinks";
import PageContent, {
  ContentBody,
  ContentHeader,
} from "../components/common/PageContent";

import Billing from "../containers/subscription/Billing";
import Invoices from "../containers/subscription/Invoices";
import Usage from "../containers/subscription/Usage";
import YourPlan from "../containers/subscription/YourPlan";
import ChangePlan from "../containers/subscription/ChangePlan";
import CompareChangePlanContainer from "../containers/subscription/CompareChangePlan";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { ModalContainer } from "../components/modal/Modal";
import { stripeConfig } from "../config/stripeConfig";

const SubscriptionPage = ({ match }) => {
  const stripePromise = useMemo(() => {
    return loadStripe(stripeConfig.stripePk);
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <PageContent>
        <ContentHeader
          title={
            <React.Fragment>
              <NavLink to="/admin/settings/general">Account settings</NavLink>{" "}
              <span>/ Subscriptions</span>
            </React.Fragment>
          }
        />
        <ContentBody>
          <TabLinks
            header={
              <TabHeader>
                <TabLink
                  to="/admin/subscription/your-plan"
                  title={
                    <span>
                      <span className="d-md-inline">Your plan</span>
                    </span>
                  }
                />
                <TabLink
                  to="/admin/subscription/billing"
                  title={
                    <span>
                      <span className="d-md-inline">Billing info</span>
                    </span>
                  }
                />
                <TabLink
                  to="/admin/subscription/usage"
                  title={
                    <span>
                      <span className="d-md-inline">Usage</span>
                    </span>
                  }
                />
                <TabLink
                  to="/admin/subscription/invoices"
                  title={
                    <span>
                      <span className="d-md-inline">Invoices</span>
                    </span>
                  }
                />
              </TabHeader>
            }
          >
            <TabContent>
              <Switch>
                <Route
                  exact={true}
                  path="/admin/subscription/your-plan"
                  component={YourPlan}
                />

                <Route
                  path="/admin/subscription/your-plan/change-plan/:id"
                  exact={true}
                  component={ChangePlan}
                />

                <Route
                  path="/admin/subscription/your-plan/compare-change-plan/:newId"
                  exact={true}
                  component={CompareChangePlanContainer}
                />
                <Route path="/admin/subscription/billing" component={Billing} />
                <Route path="/admin/subscription/usage" component={Usage} />

                <Route
                  path="/admin/subscription/invoices"
                  component={Invoices}
                />
              </Switch>
            </TabContent>
          </TabLinks>
        </ContentBody>
      </PageContent>
      <ModalContainer type="payments" />
    </Elements>
  );
};

export default SubscriptionPage;
