import React from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { IconButton } from '../../common/Buttons';
import { renderDropDown, renderCheckbox } from '../../common/ReduxFormHelper'

import {
    JobPermissions, JobNotificationTypes
} from '../../../selectors/Job'

const CreateAccountNotificationForm = ({ handleSubmit, accounts }) => {

    return (
        <div className="row table-item">
            <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                    <div className="row table-row-item share-not-table">
                        <div className="col-sm-12 d-md-none">
                            <h6>Add new account</h6>
                        </div>
                        <div className="col-sm-3 d-md-none pb-0 pt-0">
                            Account
                        </div>
                        <div className="col-sm-9 col-md-3">
                            <Field component={renderDropDown} 
                                name="account" 
                                options={accounts} 
                                 emptyOption={true} noSpace={true}  />
                        </div>
                        <div className="col-sm-3 d-md-none pb-0 pt-0">
                            Permission
                        </div>
                        <div className="col-sm-9 col-md-3">
                            <Field component={renderDropDown} 
                                name="jobPermission" 
                                options={JobPermissions} 
                                emptyOption={true} noSpace={true}  />
                        </div>
                        <div className="col-sm-3 d-md-none pb-0 pt-0">
                            Notifications
                        </div>
                        <div className="col-sm-9 col-md-3">
                            <Field  component={renderDropDown} 
                                    name="type" 
                                    options={JobNotificationTypes}
                                    emptyOption={true} noSpace={true} />
                        </div>
                        <div className="col-sm-3 d-md-none pb-0 pt-0">
                            If job runs empty
                        </div>                        
                        <div className="col-sm-9 col-md-2">
                            <Field component={renderCheckbox}
                                name="withContentOnly"
                                label="Skip notification"
                                className="notification-checkbox"
                                noSpace={true} />
                        </div>
                        <div className="col-12 col-md-1 text-right">
                            <IconButton icon="icon-plus" type="success" submit={true} tooltip="Add" tooltipplacement="top"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default reduxForm({
    form: 'create-account-notification-job-wizzard',
    validate: (values) => {
        const errors = {}

        if (!values.account) {
            errors.account = 'Required'
        }

        if (!values.type) {
            errors.type = 'Required'
        }

        return errors
    },
    onSubmitSuccess: (result, dispatch) => {
        dispatch(reset('create-account-notification-job-wizzard'))
    }
})(CreateAccountNotificationForm)