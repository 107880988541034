import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { forkJoin } from "rxjs";
import { showNotificationSuccess } from "../../actions/notification";
import {
  get$,
  changePlan$,
  getBillingInfo$,
  getPreviewInvoice$,
} from "../../api/subscriptions";
import LoaderComponent from "../../components/common/Loader";
import { useModal } from "../../components/modal/Modal";
import CompareChangePlan from "../../components/subscriptions/your-plan/CompareChangePlan";

const CompareChangePlanContainer = () => {
  const [isLoading, setLoading] = useState(false);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPlan, setCurrentPlan] = useState();
  const [newPlan, setNewPlan] = useState();
  const [info, setInfo] = useState();
  const [invoice, setInvoice] = useState();

  const { showModal } = useModal();

  useEffect(() => {
    setLoading(true);

    forkJoin(
      get$(match.params.newId),
      get$(),
      getBillingInfo$(),
      getPreviewInvoice$(match.params.newId)
    ).subscribe(
      ([newPlan, currentPlan, info, invoice]) => {
        setLoading(false);
        setNewPlan(newPlan);
        setCurrentPlan(currentPlan?.upcomming ? { ...currentPlan?.upcomming, currentPeriodEnd: currentPlan.currentPeriodEnd } : currentPlan);
        setInfo(info);
        setInvoice(invoice);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, [match.params.newId]);

  const getPaymentMethod = useCallback(() => {
    return new Promise((resolve, reject) => {
      showModal("NewCreditCard", {
        title: "Payment details",
        onSubmit: (paymentMethod) => {
          resolve(paymentMethod);
        },
        onClose: () => reject(),
      });
    });
  }, [showModal]);

  const onConfirm = useCallback(
    async (newPlan) => {
      setLoading(true);
      let paymentId = info?.stripePaymentId;

      if (!paymentId) {
        try {
          const paymentMethod = await getPaymentMethod();
          paymentId = paymentMethod.id;
        } catch (e) {
          setLoading(false);
          return;
        }
      }

      changePlan$(newPlan.id, paymentId).subscribe(
        (result) => {
          dispatch(
            showNotificationSuccess("You've successfully changed your plan")
          );
          history.push("/admin/subscription/your-plan");

          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      );
    },
    [dispatch, getPaymentMethod, history, info?.stripePaymentId]
  );

  if (isLoading) {
    return <LoaderComponent title="Loading invoice details" />;
  }

  return (
    <div>
      <CompareChangePlan
        currentPlan={currentPlan}
        newPlan={newPlan}
        info={info}
        invoice={invoice}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default CompareChangePlanContainer;
