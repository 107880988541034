import * as api from '../api'

import { switchMap, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { handleError } from './common';
import { GET_ALL_STATS, getAllStatsSuccess } from '../actions/stats';

export const getAllStatsEpic$ = (action$, state) =>
    action$.pipe(
        ofType(GET_ALL_STATS),
        switchMap(action => api.getAllStats$(
                action.utcDate, 
                action.dateOffset, 
                action.statisticHours).pipe(
            map(response => getAllStatsSuccess(response)),
            catchError(handleError(action$, action.type))
        ))
    )