import React from "react";
import { connect } from "react-redux";
import { lifecycle } from "recompose";

import UserList from "../components/users/UserList";

import { listAccountUsers, listOrganisationUsers } from "../actions/user";
import { editUser } from "../actions/user";

import Loader from "../components/common/Loader";

import { removeUserFromOrganisation } from "../actions/organisation";
import { removeUserFromAccount } from "../actions/account";
import { isFetching } from "../selectors/Fetching";
import { useModal } from "../components/modal/Modal";

const mapStateToProps = (state, ownProps) => ({
  isFetching:
    isFetching(state.fetching, listOrganisationUsers) ||
    isFetching(state.fetching, listAccountUsers),
  users: state.user.users,
  accountId: state.auth.user.accountId,
});

const noop = () => {};

const mapDispatchToProps = (dispatch) => ({
  onUserSelect: (user) =>
    user.isOwner ? noop : dispatch(editUser(user.accountId, user.userId)),
  onRemove: (accountId, organisationId) => (user) =>
    organisationId
      ? dispatch(removeUserFromOrganisation({ ...user, organisationId }))
      : dispatch(removeUserFromAccount({ ...user, accountId })),
  initialState: (accountId, organisationId) =>
    dispatch(
      organisationId
        ? listOrganisationUsers(organisationId)
        : listAccountUsers(accountId)
    ),
});

let UserListContainer = ({
  match,
  isFetching,
  title,
  accountId,
  users,
  onUserSelect,
  onRemove,
}) => {
  const { showModal } = useModal();

  if (isFetching || !users) {
    return <Loader />;
  }
  
  let schema = [
    { accessor: "fullName", header: "Name" },
    { accessor: "mailAdress", header: "Email" },
    {
      accessor: "lastActivity",
      header: "Last activity",
      type: "date",
      format: "YYYY-MM-DD HH:mm",
    },
    {
      type: "button",
      css: "btn btn-danger float-right",
      action: (user) => {
        showModal("Confirm", {
          title:
            "Disconnect user from " +
            (match.params.organisationId ? "organisation?" : "account?"),
          message: (
            <span>
              Are you sure you want to disconnect{" "}
              <strong>{user.fullName}</strong> from this{" "}
              {match.params.organisationId ? "organisation?" : "account?"}
            </span>
          ),
          confirmBtnText: "Disconnect",
          confirmBtnDanger: true,
          init: {
            next: onRemove(accountId, match.params.organisationId),
            args: [user],
          },
        });
      },
      actionText: match.params.organisationId
        ? " Disconnect from organisation"
        : " Disconnect from account",
      disabled: (user) => {
        return user.isOwner;
      },
    },
  ];

  return (
    <UserList
      users={users}
      schema={schema}
      title={title}
      onUserSelect={onUserSelect}
    />
  );
};

UserListContainer = lifecycle({
  componentDidMount() {
    const { initialState, match, accountId } = this.props;
    initialState(accountId, match.params.organisationId);
  },
})(UserListContainer);

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
