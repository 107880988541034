
import { reducer as formReducer } from 'redux-form'

import { auth } from './auth'
import search from './search'
import { account } from './account'
import { notification } from './notification'
import { organisation } from './organisation'
import { user } from './user'
import { invite } from './invite'
import { connections } from './connections'
import { connectors } from './connectors'
import { notificationsettings } from './notificationsettings'
import { general } from './general'
import { generalsettings } from './generalsettings'
import { menu } from './menu'
import { jobs } from './jobs'
import { autocomplete } from './autocomplete'
import { fetching } from './fetching'
import { explore } from './explore'
import { signup } from './signup'
import { stats } from './stats' 

export const cloudbuz = {
    auth,
    search,
    form: formReducer,
    account,
    notification,
    organisation,
    user,
    invite,
    connections,
    connectors,
    notificationsettings,
    general,
    generalsettings,
    menu,
    jobs,
    autocomplete,
    fetching,
    explore,
    signup,
    stats
}









