import React from 'react'

let selectedSource = {};

const Path = ({ path }) => {

    if (!path || !path.length) {
        return "";
    }

    const bcs = path.map((bc, i) => (
        <li key={bc.id} className="d-flex align-items-center">
            {bc.name}
            {
                i !== (path.length - 1) &&
                <span>/</span>
            }
        </li>
    ))

    return <ol className="breadcrumb p-0 bg-transparent">
        {bcs}
    </ol>
}



const ExploreCommands = ({ selectedItems, command, breadcrumbs, onAction, onExecuteCommand }) => {
    let thisPlace = breadcrumbs ? breadcrumbs[breadcrumbs.length - 1] : null
    let sourcePlace = selectedSource[selectedSource.length - 1];

    const pasteDisabled = command ? command.items?.some(item => {
        const existsInBreadcrumb = breadcrumbs.find(bc => {
            return item.id === bc.id && item.type === "Folder"
        })
        return existsInBreadcrumb ? existsInBreadcrumb : (thisPlace.type === "Folder" || thisPlace.type === "FTP root") && thisPlace.id !== sourcePlace.id ? false : true
    }) : ""


    const renderStateItems = command ? (
        <React.Fragment>
            <div className="action">
                {/*<h5 className="mb-2"><i className={`icon  ${icons[command.action]}`}></i>*/}
                {command.action !== "delete" ? <h5 className="mb-2">Clipboard for {command.action}</h5> : ""}
                {command.action === "copy" ? "Copy " : command.action === "cut" ? "Move " : "Delete "} objects:
            </div>
            <ul className="list-unstyled team-members">
                {command.items?.map(s =>
                    <li key={s.id}>
                        <span className="badge badge-lightBlue">
                            <i className={s.type === "Folder" ? "icon icon-folder" : "icon icon-file_1"} />
                            <span> {s.name}</span>
                        </span>
                    </li>
                )}
            </ul>
            <div>
                From: <Path path={selectedSource} />
            </div>
            <div>
                {command.action !== "delete" ? <React.Fragment><span>To:</span> <Path path={breadcrumbs} /></React.Fragment> : ""}
            </div>
        </React.Fragment>

    ) : ""

    const current = breadcrumbs[breadcrumbs.length - 1]


    if ((!selectedItems || !selectedItems.length) && !command) {
        const isCorrectType = current && ["Folder", "FTP root"].includes(current.type);
        return (
            <div className="explore-actions">
                {
                    isCorrectType && current.status === 0 &&
                    <button className="btn btn-light mr-1"><i className="icon icon-sync"></i> Syncing <i class="icon icon-circle-notch animate-spin text-primary"></i></button>
                }
                {
                    isCorrectType && current.status !== 0 &&
                    <button className="btn btn-light mr-1" onClick={() => { onExecuteCommand({ action: "sync" }) }}>
                        <i className="icon icon-sync"></i> Sync
                    </button>
                }
            </div>
        )
    }


    if (selectedItems && selectedItems.length) {
        return (
            <div className="explore-actions">
                <button className="btn btn-light mr-1" onClick={() => { selectedSource = breadcrumbs; onAction({ action: "copy", items: selectedItems }) }}>
                    <span><i className="icon icon-docs"></i> Copy</span>
                </button>
                <button className="btn btn-light mr-1" disabled={thisPlace.type === "File Server" ? true : false} onClick={() => { selectedSource = breadcrumbs; onAction({ action: "cut", items: selectedItems }) }}>
                    <span><i className="icon icon-scissors"></i> Cut</span>
                </button>
                <button className="btn btn-danger mr-1" disabled={thisPlace.type === "File Server" ? true : false} onClick={() => { selectedSource = breadcrumbs; onAction({ action: "delete", items: selectedItems }) }}>
                    <span><i className="icon icon-trash-empty"></i> Delete</span>
                </button>
            </div>

        )
    }

    if (command && command.action === "delete") {
        return (
            <React.Fragment>
                <div className="explore-actions">
                    <div className="alert highlight">
                        <h5 className="text-danger"><i className="icon icon-attention-circled"></i> Are you sure you want to delete these items?</h5>
                        {renderStateItems}
                        <hr></hr>
                        <button className="btn btn-light mr-1" onClick={() => onExecuteCommand(command)}>Ok</button>
                        <button className="btn btn-light mr-1" onClick={() => { selectedSource = {}; onAction(null) }}>Cancel</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="explore-actions">
                <div className="alert highlight">
                    {renderStateItems}
                    <hr></hr>
                    <button className="btn btn-light mr-1" disabled={pasteDisabled} onClick={() => onExecuteCommand(command)}><i className="icon icon-paste"></i>{pasteDisabled ? "Can't paste here" : "Paste here"}</button>
                    <button className="btn btn-light mr-1" onClick={() => { selectedSource = {}; onAction(null) }}><i className="icon icon-clipboard" ></i>Empty clipboard</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExploreCommands