import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form';
import { required, email } from '../common/ReduxFormFieldValidation';
import LoginCard, { Header, Body } from '../common/LoginCard';
import CbLogo from '../../images/logo_white.png'

const renderField = ({ input, label, type, name, autoComplete, meta: { touched, error, warning }, icon, tooltip }) => (
    <div className="form-group">
        <input className="form-control form-control-lg border-input" {...input} placeholder={label} type={type} autoComplete={autoComplete} />
        {touched && ((error && <em htmlFor={name} className="invalid">{tooltip}</em>) || (warning && <span>{warning}</span>))}
    </div>
)

const CreateForgotPassword = ({ handleSubmit, pristine, reset, submitting }) => {

    return (
        <div>
            <div className="logo_login_placeholder">
                <img src={CbLogo} height="30px" alt="Cloudbuz Manager" className="logo_login" />
            </div> 
            <LoginCard>
                <Header title="Recover password">
                <div className="alert alert-primary" role="alert">
                        <span>Enter your email or username and follow the instructions sent to you.</span>
                    </div>
                </Header>
                <Body>                   
                    <form onSubmit={handleSubmit}>
                        <fieldset>
                            <Field
                                name="email"
                                component={renderField}
                                type="email"
                                label="Email or username"
                                icon="fa-envelope"
                                validate={[required, email]}
                                tooltip="Please enter your work email address"
                            />

                            <button type="submit" className="btn btn-dark btn-block btn-lg" id="btnLogin" disabled={submitting}>
                                Reset password
                            </button>
                        </fieldset>
                        <footer>
                            <div className="text-center">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            <Link to="/login">I remembered my password!</Link>
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p>Dont have an account? <Link to="signup">Sign up</Link></p>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </form>
                </Body>
            </LoginCard>
            <p className="greetings">Made with <i className="icon icon-heart love"></i> by <a className="text-white" href="https://www.zavanto.se">Zavanto</a></p>
        </div>)

}

export default reduxForm({
    form: 'createforgotpassword', // a unique identifier for this form
})(CreateForgotPassword);

