import React, { useRef } from "react";
import { usePasswordVerification } from "../../hooks/hooks";
import Card, { Header, Body } from '../common/Card';
import { PasswordVerificationDialog } from "../common/dialogs/PasswordVerificationDialog";

const EditTwoFactorComponent = ({
    showTwofactor,
    saveTwofactor,
    twofactor,
    errors
}) => {
    const [
        verificationState,
        showVerification,
        hideVerification,
    ] = usePasswordVerification(showTwofactor);


    const twofactorCodeRef = useRef();
    const enableTwoFactorRef = useRef();

    return <Card className="card-settings">
        <Header title="Two-factor authentication">
        </Header>
        <Body>

            {
                twofactor === null &&
                <div>
                    <PasswordVerificationDialog
                        onHide={hideVerification}
                        init={verificationState}
                        confirmBtnText="Show two-factor"
                        confirmBtnDanger={true} />

                    <div className="form-group">
                        <button onClick={(event) => showVerification({})} className="btn btn-dark btn-lg">Show two-factor</button>
                    </div>
                </div>
            }
            {
                twofactor != null &&
                <div className="text-center">
                    <img src={twofactor.qrCode} alt="Authenticator QR Code" />

                    <div className="mb-2">
                        <div className="form-group">
                            <input className="form-control form-control-lg border-input" ref={twofactorCodeRef} type="text" placeholder="Two-factor code (6 digits)" />
                        </div>
                    </div>

                    <div className="custom-control custom-checkbox mb-2">
                        <input
                            id="enable2f"
                            type="checkbox"
                            className="custom-control-input"
                            defaultChecked={twofactor.enabled}
                            ref={enableTwoFactorRef}
                        />
                        <label className="custom-control-label" htmlFor="enable2f">Two-factor enabled</label>
                    </div>


                    <div className="form-group">
                        <button
                            onClick={(event) => saveTwofactor({
                                enabled: enableTwoFactorRef.current.checked,
                                twofactorCode: twofactorCodeRef.current.value
                            })}
                            className="btn btn-dark btn-lg">Save two-factor</button>
                    </div>
                </div>

            }
            {
                <div className="text-danger">
                    {
                        errors && errors.map((e, index) => (<li key={index}>{e}</li>))
                    }
                </div>
            }
        </Body>
    </Card>
}

export default EditTwoFactorComponent;