import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import AcceptInvite from "../components/invites/AcceptInvite";
import AcceptAccountRelation from "../components/invites/AcceptAccountRelation";
import AcceptAccountRelationNewUser from "../components/invites/AcceptAccountRelationNewUser";
import { getInvite, acceptInvite, declineInvite } from "../actions/invite";
import { getActiveAccount } from "../selectors/Auth";
import AcceptInviteNewUser from "../components/invites/AcceptInviteNewUser";
import AcceptSignupUser from "../components/invites/AcceptSignupUser";
import AcceptSignupUserSso from "../components/invites/AcceptSignupUserSso";
import { showNotificationWarning } from "../actions/notification";

const mapStateToProps = (state, ownProps) => ({
  invite: state.invite.invite,
  activeAccount: getActiveAccount(state.auth),
  isAuthenticated: state.auth.isAuthenticated,
  accounts: state?.auth?.info?.accounts?.filter((a) => a.privilege === 201),
});

const mapDispatchToProps = (dispatch) => ({
  initialState: (verificationToken) => dispatch(getInvite(verificationToken)),
  acceptInvite: (verificationToken, accountId) => (values) =>
    dispatch(acceptInvite({ ...values, accountId }, verificationToken)),
  declineInvitation: (verificationToken) => () =>
    dispatch(declineInvite(verificationToken)),
  showNotificationWarning: (message) =>
    dispatch(showNotificationWarning(message)),
});

const InviteContainer = ({
  initialState,
  match,
  invite,
  activeAccount,
  acceptInvite,
  declineInvitation,
  accounts,
  showNotificationWarning,
}) => {
  useEffect(() => {
    initialState(match.params.verificationToken);
  }, [match.params.verificationToken, initialState]);

  useEffect(() => {
    if ((!accounts || !accounts.length) && invite && invite.inviteType === 1) {
      showNotificationWarning(
        "You must create an account before you accept this invitation"
      );
    }
  }, [showNotificationWarning, accounts, invite]);

  if (!invite) {
    return "";
  }

  const onAcceptInvite = acceptInvite(
    match.params.verificationToken,
    (activeAccount || {}).accountId
  );
  const onDeclineInvitation = declineInvitation(match.params.verificationToken);

  switch (invite.inviteType) {
    case 1:
      if (!accounts || !accounts.length) {
        return (
          <Redirect
            to={{
              pathname: "/admin/accounts/new",
              state: { referrer: match.url },
            }}
          />
        );
      }
      return (
        <AcceptAccountRelation
          initialValues={{ ...invite, activeAccount }}
          accounts={accounts}
          onSubmit={onAcceptInvite}
          onDecline={onDeclineInvitation}
        />
      );
    case 2:
      return (
        <div className="login-wrapper">
          <div className="login align-self-center flex-fill">
            <AcceptAccountRelationNewUser
              initialValues={invite}
              onSubmit={onAcceptInvite}
              onDecline={onDeclineInvitation}
            />
          </div>
        </div>
      );
    case 3:
      return (
        <AcceptInvite
          initialValues={invite}
          onSubmit={onAcceptInvite}
          onDecline={onDeclineInvitation}
        />
      );
    case 4:
      return (
        <div className="login-wrapper">
          <div className="login align-self-center flex-fill">
            <AcceptInviteNewUser
              initialValues={invite}
              onSubmit={onAcceptInvite}
              onDecline={onDeclineInvitation}
            />
          </div>
        </div>
      );
    case 5:
      return (
        <div className="login-wrapper">
          <div className="login align-self-center flex-fill">
            <AcceptSignupUser
              initialValues={invite}
              onSubmit={onAcceptInvite}
            />
          </div>
        </div>
      );
    case 6:
      return (
        <div className="login-wrapper">
          <div className="login align-self-center flex-fill">
            <AcceptSignupUserSso
              initialValues={invite}
              onSubmit={onAcceptInvite}
            />
          </div>
        </div>
      );
    default:
      return <Redirect to={{ pathname: "/dashboard" }} />;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteContainer);
