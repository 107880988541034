import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoginComponent from '../components/login/Login'
import Loader from '../components/common/Loader'
import { ensureLoggedOut, loginFailed, loginUser } from '../actions/auth'
import { Redirect } from "react-router-dom";
import { isFetching } from '../selectors/Fetching';
import Twofactor from '../components/Twofactor/Twofactor'

const mapStateToProps = (state) => ({
    isFetching: isFetching(state.fetching, loginUser),
    isAuthenticated: state.auth.isAuthenticated,
    twofactorEnabled: state.auth.data?.twofactorEnabled,
    qrCodeUrl: state.auth.data?.qrCodeUrl,
    password: state.auth.data?.password,
    email: state.auth.data?.email,
    message: state.auth.message,
    errors: state.auth.errors
});

const mapDispatchToProps = dispatch => ({
    login: (values) => {
        dispatch(loginUser({
            email: values.email,
            password: values.password
        }));
    },
    twoFactorLogin: (values) => {
        dispatch(loginUser({
            email: values.email,
            password: values.password,
            twofactorCode: values.twofactorCode
        }));
    },
    cancelLogin: () => {
        dispatch(loginFailed())
    },
    ensureLoggedOut: () => dispatch(ensureLoggedOut())
})

const LoginContainer = ({ login, twoFactorLogin, twofactorEnabled, qrCodeUrl, cancelLogin, email, password, isFetching, isAuthenticated, message, errors, location, ensureLoggedOut, match }) => {
    if (isAuthenticated) {
        if (location.state && location.state.referrer) {
            return <Redirect to={location.state.referrer} />
        }

        return <Redirect to="/" />
    }

    if (isFetching && twofactorEnabled) {
        return <div className="twofactor">
            <Twofactor onSubmit={twoFactorLogin} qrCodeUrl={qrCodeUrl} cancel={cancelLogin} initialValues={{ email: email, password: password }} />
        </div>
    }

    if (isFetching) {
        return <Loader title={"Still logging in"} />
    }

    return <LoginComponent onSubmit={login} errors={errors} initialValues={{ email: match.params.email }} />
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer))