import React from 'react'
import './Login.css'
import CbLogo from "../images/logo_white.png";

const TermsPage = () => {
    return (
        <div className="login-wrapper">
            <div className="terms align-self-center flex-fill d-flex justify-content-center">
                <div className="text-white">
                    <img
                        src={CbLogo}
                        height="30px"
                        alt="Cloudbuz Manager"
                        className="logo_login"
                        />
                    <hr className="border border-white"></hr>
                    <h4>Terms of Service</h4>
                <p className="mt-3">
    <i><em>Effective: July 19, 2020</em></i>
    <br/>
    <br/>
    This Subscription agreement is made between Zavanto AB ("Licensor") and You
    ("Subscriber"). If you use our Services on behalf of a company (such as
    your employer) or other legal entity, you represent that you have the
    authority to bind that company or other legal entity to these Terms.
    <br/>
    <br/>
    You should review the following terms and conditions carefully before
    installing or using the Software. By installing and/or making use of the
    Software and/or by paying a subscription fee, you agree to be bound by
    these Terms.
    <br/>
    <br/>
    If you do not wish to agree to terms and conditions in this agreement, then
    you are not allowed to download, copy, install or otherwise make use of the
    Software.
    <br/>
    <br/>
    All reasonable efforts should be made to ensure that Authorised users are
    aware of and abide by the terms and conditions in this agreement.
</p>
<h1>
    1. 
    Definitions
</h1>
<h3>
    1.1 Licensor/We/Our/Us
</h3>
<p>
    means Zavanto AB
</p>
<h3>
    1.2 Subscriber/You/Your
</h3>
<p>
    means you, or the company or entity you represent.
</p>
<h3>
    1.3 Terms
</h3>
<p>
    means Terms of Service.
</p>
<h3>
    1.4 Authorised user
</h3>
<p>
    means employees or consultants of the Subscriber who have a right to use
    the Services with a personal login and for whom the Subscriber has paid
    applicable license fees as specified herein.
</p>
<h3>
    1.5 Confidential information
</h3>
<p>
    means all of the information about the Software, Services and
    specifications, other related proprietary marketing, technical or business
    information, including without limitation product plans, personnel,
    research and know-how, products and software and any other information
    which is disclosed to the receiving party orally, visually or in a document
    or other tangible form, which is designated by the party as confidential
    and/or proprietary, and the terms and conditions of this agreement.
    <br/>
    "Confidential Information"
    does not include information received from a party (the "Disclosing Party")
    which the other party (the "Receiving Party") can clearly establish by
    written evidence (a) is or becomes generally known by the Receiving Party
    without an obligation to maintain its confidentiality; (b) is or becomes
    generally known to the public through no act or omission of the Receiving
    Party; (c) is independently developed by the Receiving Party without use of
    the Confidential Information; or (d) is required to be disclosed by court
    order or other legal process (provided that the Receiving Party uses
    reasonable efforts to provide reasonable notice to the Disclosing Party
    prior to such disclosure).
</p>
<h3>
    1.6 End user
</h3>
<p>
    means end users of the Subscriber services who are customers of the
    Subscriber.
</p>
<h3>
    1.7 Intellectual Property Rights
</h3>
<p>
    means any and all intellectual property rights existing world-wide,
    including any and all patents of inventions, registered industrial designs,
    trade secrets, know-how and other protected information, including
    applications for patent or registration, and copy rights, but specifically
    excluding trademark rights.
</p>
<h3>
    1.8 Services
</h3>
<p>
    means the Services offered by Licensor in the Software at any given time.
    In the Software the Subscriber can access a current list of Services that
    are available.
</p>
<h3>
    1.9 Software
</h3>
<p>
    means the, at any given time current version of the software platform that
    is offered and may be delivered by Licensor during the terms of this
    agreement.
</p>
<h3>
    1.10 Subscriber's services
</h3>
<p>
    means those of Services that the Subscriber at any give time has the right
    to use under the agreement. The Subscriber may during the term of this
    agreement extend the Subscriber's Services by ordering and paying relevant
    fees for such additional Services.
</p>
<h3>
    1.11 Account
</h3>
<p>
    means the account created by the Subscriber as described in section below
    titled "Registration". An account is needed to access the subscriber's
    services.
    <br/>
    <br/>
    <u>Account types</u>
</p>
<p>
    • Free Account
    <br/>
    This type of account is free but only a number of limited subscriber's
    services will be available for the Subscriber and Authorised user.
</p>
<p>
    •
    
        Paid Account
        <br/>
    
    This type of account has a fixed recurring subscription fee. The fee is
    depending of subscriber's services.
</p>
<p>
    • Enterprise Account
    <br/>
    Charges and fees for this type of account is regulated under terms and
    conditions to be mutually agreed upon between Licensor and Subscriber.
</p>
<h1>
    2. 
    Modification
</h1>
<p>
    Licensor may modify these Terms at any time, at our sole discretion. If we
    do, we will post the modified Terms on our website or notify you through
    other communications. It is important that you check our Terms from time to
    time because if you continue to use the Services after we have posted
    modified Terms on our website, you are indicating to us that you agree to
    be bound by the modified Terms. If you do not agree to the modified Terms,
    then you should not use our Services anymore.
</p>
<h1>
    3. 
    Registration
</h1>
<p>
    In order to access and use the Services, Subscriber must register to create
    a Cloudbuz account
    <br/>
    ("Account").
    <br/>
    <br/>
    When Subscriber register, Subscriber will be required to provide certain
    information, and Subscriber will establish a username and password.
    Subscriber agree to provide accurate, current, and complete information
    during the registration process and to update such information to keep it
    accurate, current, and complete. Licensor will have no liability for
    failure to deliver notices that result from inaccurate Account information
    or otherwise. Subscriber authorize Licensor, directly or through third
    parties, to make any inquiries Licensor consider necessary or appropriate
    to verify Subscriber Account information, which may include providing
    Subscriber information to such third parties. Licensor reserves the right
    to suspend or terminate Subscriber Account if any information provided
    during the registration process or thereafter proves to be inaccurate, not
    current, or incomplete. Subscriber are responsible for safeguarding
    Subscriber password. Subscriber agree not to disclose your password to any
    third party and to take sole responsibility for any activities or actions
    under Subscriber password, whether or not Subscriber has authorized such
    activities or actions. Subscriber will immediately notify Licensor of any
    unauthorized use of Subscriber password.
</p>
<h1>
    4. 
    License grant
</h1>
<h3>
    4.1 License grant
</h3>
<p>
    Licensor hereby grants to Subscriber under its Intellectual Property Rights
    a personal, non-transferable, non-exclusive, world-wide license to download
    and use the Software and Subscriber services solely for internal use and
    not for distribution to third parties.
    <br/>
    <br/>
    For purposes of this section, the term "use" shall mean a right for the
    Authorised users to use the Software in machine readable form via web
    interfaces or integrations.
</p>
<h3>
    4.2 Limitations
</h3>
<p>
    The license granted herein is limited to the number of Authorised users or
    End users or sessions or the Software modules for which Subscriber has paid
    the applicable subscription fees. The license is further limited to
    commercial internal use or for use in providing services to End users if
    such use is included in the license grant herein.
</p>
<h3>
    4.3 Not permitted
</h3>
<p>
    Software may not be decompiled or reverse engineered except as permitted by
    compulsory law.
</p>
<h3>
    4.4 Reservation of rights
</h3>
<p>
    Licensor shall retain on behalf of itself or the original owner all right
    title and interest to Software and related materials and information,
    including the title to copies of any or all media bearing information of
    the Software.
</p>
<h1>
    5. 
    Delivery and commencement of the Service
</h1>
<h3>
    5.1 Provision of the Service
</h3>
<p>
    Licensor shall provide Authorised users access to the Software and
    Subscriber services for the duration of the term of this agreement and in
    accordance with the terms and conditions hrein. Such access may be provided
    by passwords or by other means of electronic communication.
    <br/>
    <br/>
    Subscriber services will include one or more of several elements, as
    specified by Licensor from time to time and communicated to Subscriber
    through the Software.
</p>
<h3>
    5.2 Quality of the Service
</h3>
<p>
    Licensor shall perform its provision of Subscriber services in a
    workmanlike manner, with appropriate and skilled personnel and in
    accordance with generally accepted industry standards. Licensor agrees to
    uphold the service levels as specified herein or otherwise in writing.
</p>
<h1>
    6. 
    Covenants of the Customer
</h1>
<h3>
    6.1 Protection of intellectual property
</h3>
<p>
    Subscriber undertakes to use reasonable efforts to protect Licensor's
    Intellectual Property and Confidential Information while executing its
    rights under this agreement. Specifically, the Subscriber agrees to:
    <br/>
    <br/>
    a) advice Licensor immediately upon knowledge of any infringements of
    Licensor's Intellectual Property, and to assist as reasonable requested by
    Licensor in the safeguarding of such rights, provided that Licensee is
    compensated for its reasonable out of pocket expenses for such assistance,
    and
    <br/>
    <br/>
    b) advice Licensor of any change in the control or ownership.
</p>
<h3>
    6.2 Obligations
</h3>
<p>
    Subscriber shall comply with the following in its use of the Software and
    Subscriber services:
    <br/>
    <br/>
    a) Subscriber shall not make any copies of the Software or transfer any
    information of the Software or login data to any users or persons outside
    the Subscriber's business operations, and
    <br/>
    <br/>
    b) Subscriber shall ensure that the content and information that is
    provided by Subscriber or its End users in connection with use of the
    Software or Subscriber services complies with any applicable laws,
    treaties, regulations and industry practices, including those realated to
    data privacy, intenational communications and transmission of technical end
    personal data.
</p>
<h1>
    7. 
    Technical support and error corrections
</h1>
<h3>
    7.1 Technical support
</h3>
<p>
    <u>Free subscription includes no technical support.</u>
    <br/>
    <br/>
    For paid subscriptions, the following applies:
    <br/>
    <br/>
    Upon Licensee's request and for no additional charge, Licensor shall
    provide Subscriber with technical support to enable Subscriber to use the
    Subscriber service as specified in this agreement.
    <br/>
    <br/>
    Technical support is provided by e-mail during regular business hours.
    <br/>
    <br/>
    Licensor has no guarantees on response times. Technical support is provided
    to Subscriber according to best effort.
    <br/>
    <br/>
    However, upon Subscriber's request and under terms and conditions to be
    mutually agreed upon, Licensor shall provide Subscriber with technical
    support and appropriate Service Level Agreement.
</p>
<h3>
    7.2 Error corrections
</h3>
<p>
    Licensor may correct errors and shortcomings and otherwise improve and
    further develop the Subscriber services. Licensor provides however no
    warranties or representations that all errors will be corrected or that the
    Subscriber services will be error free.
</p>
<h3>
    7.3 Additional support
</h3>
<p>
    Upon Subscriber's request during the term of this agreement, in addition to
    the support set forth herein, Licensor agrees to provide training and
    technical support to requested extent and under terms and conditions that
    Licensor makes use of at the time of the request or as otherwise agreed
    between the parties.
</p>
<h3>
    7.4 Contact persons
</h3>
<p>
    Subscriber shall designate and furnish to Licensor the name of the point of
    contact for fulfilment of the support services. Unless otherwise agreed,
    the communications regarding support shall be made by and with such point
    of contact.
</p>
<h1>
    8. 
    Limited warranty
</h1>
<h3>
    8.1 No warranty
</h3>
<p>
    Licensor gives no warranties for the Software or its functionality,
    availability or properties. The Software and Subscriber services are
    provided "AS IS".
</p>
<h1>
    9. 
    Indemnification
</h1>
<h3>
    9.1 Subscriber's liability
</h3>
<p>
    Subscriber shall indemnify and hold Licensor harmless from any third party
    claims arising out of Subscriber's breach of its obligations under this
    agreement or out of Subscriber's content or provision of services to its
    customers or End users.
</p>
<h3>
    9.2 Infringement
</h3>
<p>
    Licensor shall not be liable to Subscriberfor claims that the Software or
    its use infringes, any Intellectual Property Right of any person. Licensor
    hereby disclaims any and all responsibility for infringement of any
    intellectual property rights and any and all liability for damages that may
    be caused by such infringement.
</p>
<h1>
    10. 
    Payment Terms
</h1>
<h3>
    10.1 License fee
</h3>
<p>
    • <u>Paid Account</u>
    <br/>
    By purchasing a Paid Account, Subscriber agree to pay all charges in effect
    at that time for the Services you buy, along with all applicable taxes and
    other fees. For more information about the current fees, refer to the
    Account Features Page located at http://www.cloudbuz.com/compare-plans.
    Paid Accounts are setup on an invoice basis; Subscriber agree to pay
    Licensor all amounts stated in such invoices within thirty (30) days of
    receipt.
</p>
<p>
    • <u>Enterprise Account</u>
    <br/>
    By purchasing an Enterprise Account, Subscriber agree to pay all charges in
    effect at that time for the Services you buy, along with all applicable
    taxes and other fees. Charges and fees for this type of account is
    regulated under terms and conditions to be mutually agreed upon between
    Licensor and Subscriber. Enterprise Accounts are setup on an invoice basis;
    Subscriber agree to pay Licensor all amounts stated in such invoices within
    thirty (30) days of receipt unless otherwise is mutually agreed between
    Subscriber and Licensor.
</p>
<p>
    The fees may be adjusted from time to time.
</p>
<h3>
    10.2 Recurring billing
</h3>
<p>
    Subscription plans to the subscriber's services consist of an initial
    period, for which there is a one-time charge, followed by recurring
    periodic charges as agreed to by Subscriber (e.g., annual or monthly). By
    entering into these Terms of Service, Subscriber acknowledge that your
    subscription has an initial and recurring payment feature and Subscriber
    accept responsibility for all recurring charges prior to cancellation.
</p>
<h3>
    10.3 Auto-renewal
</h3>
<p>
    Subscriptions to the subscriber's services will be automatically extended
    for successive renewal periods for the same duration as the subscription
    term originally selected by you, at the then current, non-promotional
    subscription rate. You may cancel your subscription at any time. Please see
    the Section below titled "Termination" for further information.
</p>
<h3>
    10.4 Free Trials
</h3>
<p>
    Licensor may offer free trial subscriptions during which Subscriber may use
    the subscriber's services for the time period specified in the promotional
    offer ("Trial Period"). Free trial subscriptions may not be combined with
    any other offer and except as may otherwise be provided in the promotional
    offer, free trial subscriptions are only available to new customers that
    have not previously been a Subscriber. By the end of the Trial Period
    Subscriber will have three choices:
    <br/>
    <br/>
</p>
<p>
    1. Cancel Account and subscription
</p>
<p>
    2. Continue to use the subscriber's services on a Free Account basis. This
    type of account is free but only a number of limited subscriber's services
    will be available.
</p>
<p>
    3. Continue to use the service with a Paid Account. Subscriber will be
    charged the applicable recurring subscription fee until Subscriber cancel
    the subscription. Subscriber may cancel the subscription at any time.
    Please see the Section below titled "Termination" for further information.
</p>
<h3>
    10.5 Delay
</h3>
<p>
    In the event that Subscriber has not paid for the Services in accordance
    with this agreement, in addition to remedies available to Licensor under
    this agreement or applicable law, Subscriber shall pay monthly interest on
    late payments in accordance with applicable law. Licensor shall further
    have the right to cease providing Services until the payments have been
    made.
</p>
<h3>
    10.6 Taxes
</h3>
<p>
    All fees stated in this agreement are exclusive of taxes. VAT shall be
    added, if applicable.
</p>
<h1>
    11. 
    Intellectual Property Rights
</h1>
<h3>
    11.1 Existing rights
</h3>
<p>
    Both Parties will retain on behalf of itself or the original owner, all
    right title and interest in and to any existing technology and Intellectual
    Property as well as associated Intellectual Property Rights, including
    title to copies of any and all media bearing the technology. Except for the
    express licenses set forth in this agreement, no license is implied or
    granted herein to the other party for any other existing Intellectual
    Property or Intellectual Property Rights therein by virtue of this
    agreement.
</p>
<h3>
    11.2 Improvements
</h3>
<p>
    For clarification, Subscriber shall have no rights to modify, adjust or
    improve Software or to use the Software to further develop or create new
    software that is based on or makes use of the Software. If Licensor, upon
    Subscriber's request or otherwise, creates modifications, improvements,
    additions or further developments of the Software ("Improvements"), then
    such Improvements shall be deemed to be included in the Software upon
    Licensor's making such Improvements available to Subscriber.
</p>
<h1>
    12. 
    Protection and confidentiality
</h1>
<h3>
    12.1 Confidentiality
</h3>
<p>
    The parties acknowledge the proprietary nature of each other's Confidential
    Information. Accordingly, the Receiving Party shall have following
    obligations regarding treatment of Confidential Information:
    <br/>
    <br/>
    a) the Receiving Party shall take all reasonable steps to maintain the
    confidentiality of the other party's Confidential Information,
    <br/>
    <br/>
    b) The Receiving Party shall store the other party's Confidential
    Information in a secure area being commensurate with the storage of its own
    confidential information of similar nature,
    <br/>
    <br/>
    c) The Receiving Party shall not without the other party's prior written
    consent disclose, provide or make available any of other party's
    Confidential Information in any form to any person, except employees of the
    Receiving Party whose access is necessary to enable that party to
    accomplish its obligations and exercise its rights under this Agreement.
    <br/>
    <br/>
    This Section shall for five (5) years survive any termination or expiration
    of the agreement for whatever cause.
</p>
<h1>
    13. 
    Publicity
</h1>
<h3>
    13.1 Publicity
</h3>
<p>
    Neither party shall, without securing the consent of the other party
    announce or otherwise disclose any information of the dealings between the
    parties. Such consent may not be unreasonably withheld.
</p>
<h1>
    14. 
    Limitation of liability
</h1>
<h3>
    14.1 Limitation of liability
</h3>
<p>
    Notwithstanding any other provision of this agreement, in no event will
    either party be liable for incidental, indirect or consequential damages or
    for loss of use or data, lost profits, savings or revenues of any kind,
    even if such party has been advised of the possibility of such damages.
    <br/>
    <br/>
    In no event shall Licensor's total aggregate liability under this agreement
    exceed 1 000 SEK.
</p>
<h1>
    15. 
    Choice of law, jurisdiction and disputes
</h1>
<h3>
    15.1 Settlement discussions
</h3>
<p>
    The parties shall use their best efforts to settle by amicable negotiations
    any differences which may occur between them in connection with this
    agreement.
</p>
<h3>
    15.2 Law, jurisdiction
</h3>
<p>
    The construction and performance of this agreement shall be governed by the
    laws of Sweden. All disputes, differences or questions arising out of or in
    connection with this agreement shall be finally settled by Swedish courts.
</p>
<h1>
    16. 
    Miscellaneous
</h1>
<h3>
    16.1 Force Majeure
</h3>
<p>
    The performance of either party as required by this agreement, shall be
    extended by a reasonable period of time if such performance of the
    respective party is impeded by an unforeseeable event beyond such party's
    control, which shall include but not be limited to acts of God, industrial
    actions, riots, wars, accidents, embargo or requisition (acts of
    government), hereinafter referred to as "Force Majeure". In case of Force
    Majeure, each party shall promptly notify and furnish the other party in
    writing with all relevant information hereto. As soon as the performance is
    no longer impeded, the party shall perform the obligation in accordance
    with the agreement. In the event the impediment has not ceased after three
    (3) months, then the other party shall have the right to terminate the
    agreement.
</p>
<h3>
    16.2 No waiver
</h3>
<p>
    The failure by either party to enforce any provisions of this agreement or
    to exercise any right in respect thereto shall not be construed as
    constituting a waiver of its rights thereof.
</p>
<h3>
    16.3 Entire agreement
</h3>
<p>
    This agreement contains the entire agreement between the parties on the
    subject matter of this agreement, and supersedes all representations,
    undertakings and agreements previously made between the parties.
</p>
<h1>
    17. 
    Termination: By Subscriber
</h1>
<p>
    Subscriber has the right to terminate the agreement upon written notice of
    termination. The termination shall become effective 30 days after receipt
    of such notice.
</p>
<h1>
    18. 
    Termination: By Licensor
</h1>
<p>
    We reserve the right to discontinue the Services we provide to you in their
    entirety upon written notice of termination. The termination shall become
    effective 30 days after receipt of such notice.
    <br/>
    <br/>
    We reserve the right to terminate or suspend free Accounts at any time,
    with or without notice, in our discretion. For example, we may terminate
    your free Account if you have not logged in for at least 90 days, but we
    may terminate it for any other reason in our discretion.
    <br/>
    <br/>
    Licensor may cancel or suspend your Paid Subscription if you have not paid
    for your Account when payment is due, with or without notice to you. We
    may, but will not be required to, convert any cancelled Paid Subscription
    to a free Account.
    <br/>
    <br/>
    If you violate any of these Terms, we will have the right to terminate or
    suspend your Account, immediately at our discretion and without prior
    notice to you, and we will not be required to refund any fees already paid
    by you. You will also remain liable for all amounts you were supposed to
    pay, but have not yet paid.
</p>
<h1>
    19. 
    Consequences of the termination
</h1>
<p>
    In the event of termination of this agreement, both parties shall return
    all materials, information and other property of the other party, except
    for materials that are necessary for exercising the rights that survive the
    termination or expiration of this agreement.
</p>
<h1>
    20. 
    Continuation
</h1>
<h3>
    20.1 Continuation
</h3>
<p>
    The provisions of sections 1,2,5,6,8,9,11,12,13,15,16 shall survive any
    termination or expiration of this agreement.
</p>


                </div>
            </div>
        </div>
    )
}

export default TermsPage