import React from "react";
import { dateFormat } from "../../../common/dateFormatter";

const Invoice = ({ invoice }) => {
  return (
    <>
      <div className="detail-table">
        <div className="row">
          <div className="col-sm-4 border-right border-white">Account</div>
          <div className="col-sm-8">{invoice.accountName}</div>
        </div>
        <div className="row">
          <div className="col-sm-4 border-right border-white">
            Billing period
          </div>
          <div className="col-sm-8">
            {dateFormat(invoice?.startPeriod, "YYYY-MM-DD")} to{" "}
            {dateFormat(invoice?.endPeriod, "YYYY-MM-DD")}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 border-right border-white">Status</div>
          <div className="col-sm-8">
            {invoice?.chargingDone ? (
              "Paid"
            ) : invoice?.billingDone ? (
              <div>
                Sent
                <a alt="View invoice" href={invoice?.invoiceUrl}>
                  View invoice
                </a>
              </div>
            ) : (
              "Not sent"
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 border-right border-white">
            Amount charged
          </div>
          <div className="col-sm-8">{invoice.cost  / 100} EUR</div>
        </div>
        <div className="row">
          <div className="col-sm-4 border-right border-white">Total CVU'S</div>
          <div className="col-sm-8">{invoice.cvUused}</div>
        </div>

        {invoice?.invoicePdfUrl && (
          <div className="row">
            <div className="col-sm-4 border-right border-white">Invoice</div>
            <div className="col-sm-8">
              <a alt="Download pdf" href={invoice?.invoicePdfUrl}>
                Download pdf
              </a>
            </div>
          </div>
        )}
      </div>

      {invoice?.url && (
        <iframe
          title="invoice"
          src={invoice?.url}
          style={{ width: "100%", height: "1200px" }}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}
    </>
  );
};

export default Invoice;
