import React, { useEffect, useMemo, useRef } from 'react'
import Table from '../common/table/Table'
import Privilege from '../../containers/Privilege'
import TablePaginator from '../common/table/Paginator';
import Card, { Body } from '../common/Card';
import PageContent, { ContentBody, ContentHeader} from '../common/PageContent';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";


const JobsViewFreeText = ({ text, onChange }) => {

    const debounceSearchChanged = useMemo(
        () => {
            return new Subject();
        },
        [],
    )

    useEffect(
        () => {
            //ref.current.focus();
            debounceSearchChanged
                .pipe(debounceTime(500), distinctUntilChanged()).subscribe((text) => {
                    onChange(text);
                });
            return () => {
                debounceSearchChanged.unsubscribe();
            };
        },
        [debounceSearchChanged, onChange],
    );

    const ref = useRef()

    return (
        <div className="input-group">
            <input ref={ref} className="form-control form-control-lg"
                type="text"
                placeholder="Filter this list, type something"
                defaultValue={text}
                onChange={e => {
                    debounceSearchChanged.next(e.target.value)
                }} />
            <div className="input-group-append">
                <span className="input-group-text pointer" onClick={e => { onChange(""); ref.current.value = ""; }}>clear</span>
            </div>
        </div>
    )
}

const JobsList = ({
    jobs,
    onJobSelect,
    schema,
    currentPage,
    sortBy,
    totalPages,
    onPageChange,
    onSortChange,
    onCreateNew,
    onSearch,
    searchText }) => {

    return (
        <PageContent>
            <ContentHeader title="Jobs / Configuration">
                <div className="alert highlight" role="alert">
                    <span>Create and configure file tranfer automation between selected source and targets.</span>
                </div>
                <JobsViewFreeText onChange={onSearch} text={searchText} />
            </ContentHeader>
            <ContentBody>
                <Card className="p-0 m-0 border-0">
                    <Body className="p-0">
                        <Privilege type="Jobs settings page" privilege="read">
                            <button className="btn btn-primary btn-lg mt-0 mb-2" onClick={onCreateNew}>
                                <i className="icon-plus"></i> New job
                            </button>
                        </Privilege>
                        <Table data={jobs || []}
                            schema={schema}
                            onRowClick={onJobSelect}
                            sortBy={sortBy}
                            onSortChange={onSortChange}
                            infoIfEmpty="No job configurations found.."  />
                        <TablePaginator currentPage={currentPage} totalPages={totalPages} pageNeighbours={3} onPageChange={onPageChange} />
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>
    )
}

export default JobsList;