import React from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import JobBreadCrumbsComponent from '../../components/jobs/wizzard/JobBreadCrumbs'

import stepOneValidation from '../../components/jobs/wizzard/stepOneValidation'
import stepTwoValidation from '../../components/jobs/wizzard/stepTwoValidation'
import stepThreeValidation from '../../components/jobs/wizzard/stepThreeValidation'

const JobBreadCrumbsContainer = ({ location,
                                   match,
                                   stepOneIsValid,
                                   stepTwoIsValid,
                                   stepThreeIsValid }) => {



    const arr = location.pathname.split('/')
    const active = "/" + arr[arr.length - 1];

    return (<JobBreadCrumbsComponent active={active}
        match={match}
        stepOneIsValid={stepOneIsValid}
        stepTwoIsValid={stepTwoIsValid}
        stepThreeIsValid={stepThreeIsValid} />
    )
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const mapStateToProps = (state) => {
    const formValues = getFormValues('create-job-wizzard')(state) || {};
    return {
        stepOneIsValid: Object.keys(stepOneValidation(formValues)).length === 0,
        stepTwoIsValid: Object.keys(stepTwoValidation(formValues)).length === 0,
        stepThreeIsValid: Object.keys(stepThreeValidation(formValues)).length === 0
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobBreadCrumbsContainer)