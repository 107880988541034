import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {  listCreateUserPrivileges } from '../actions/user';
import Loader from '../components/common/Loader';
import UserInvite from '../components/users/UserInvite';
import { listOrganisations } from '../actions/organisation';
import { getActiveAccount } from '../selectors/Auth';
import { createUserInvite } from '../actions/invite';
import { isFetching } from '../selectors/Fetching';


const mapStateToProps = (state) => {
    return {
        isFetching: isFetching(state.fetching, listOrganisations, listCreateUserPrivileges),
        organisations: state.organisation.organisations,
        accountPrivileges: state.account.privileges,
        organisationPrivileges: state.organisation.privileges,
        activeAccount: getActiveAccount(state.auth)
    };
};

const mapDispatchToProps = (dispatch) => ({
    createUser: accountId => values =>{
        return  dispatch(createUserInvite(values.mailAdress, { accountPrivilege : parseInt(values.accountPrivilege), organisations: values.organisations }, accountId));
    },
    listOrganisations: (accountId) => dispatch(listOrganisations(accountId)),
    listPrivileges: () => dispatch(listCreateUserPrivileges())
})

const UserInviteContainer = ({ selectedAccountPrivilege, listOrganisations, listPrivileges, organisations, accountPrivileges, organisationPrivileges,  isFetching, activeAccount,  createUser}) => {
    
    useEffect(() => {
        listOrganisations(activeAccount.accountId);
        listPrivileges();
    }, [activeAccount.accountId, listOrganisations, listPrivileges])

    if (!organisations || !accountPrivileges || !organisationPrivileges || isFetching) return <Loader />;

    return <UserInvite selectedAccountPrivilege={selectedAccountPrivilege} organisations={organisations} accountPrivileges={accountPrivileges} organisationPrivileges={organisationPrivileges} onSubmit={createUser(activeAccount.accountId)}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInviteContainer)
