const filters = ['_SUCCESS', '_FAILED']

const transformType = (type) => {
    let transformedType = filters.filter(f => type.indexOf(f) !== -1).map(f => type.replace(f, ""));
    return `REQ_${transformedType}`
}

export const fetching = (state = { isFetching: false }, action) => {

    if(action.type.indexOf('REQ_') !== -1) {
        return {
            ...state,
            [action.type]: {
                isFetching: true
            }
        }
    }
    if(filters.some(f => action.type.indexOf(f) !== -1)) {
        return {
            ...state,
            [transformType(action.type)]: {
                isFetching: false
            }
        }
    }

    return state;
}