import React from "react";
import { dateFormat } from "../../../common/dateFormatter";
import Card, { Body, Header } from "../../common/Card";
import Invoice from "./Invoice";
import InvoicePeriods from "./InvoicePeriods";

const Invoices = ({ periods, period, invoice, onPeriodChange }) => {
  return (
    <Card>
      <Header
        title={`Invoice - ${dateFormat(period.startPeriod, "YYYY-MM-DD")}`}
      >
        <InvoicePeriods
          periods={periods}
          period={period}
          onChange={onPeriodChange}
        />
      </Header>
      <Body>
        {invoice && invoice.billingTypeId < 40 ? (
          invoice.invoicePdfUrl ? (
            <Invoice invoice={invoice} />
          ) : (
            "No invoice for this period"
          )
        ) : (
          <div className="alert alert-info mt-5" role="alert">
            <h4 className="alert-heading">
              Enterprise account customers need to contact support@cloudbuz.com
              for invoice copy
            </h4>
          </div>
        )}
      </Body>
    </Card>
  );
};

export default Invoices;
