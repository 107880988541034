import React, { useCallback, useEffect, useState } from "react";
import { dateFormat } from "../../common/dateFormatter";
import TabFilter from "../../components/common/tab/TabFilter";
import { NavLink } from "react-router-dom";
import {
  getNotifications$,
  getNotificationsCount$,
  dismissAllNotification$,
  dismissNotification$,
} from "../../api";
import { first } from "rxjs/operators";
import { useModal } from "../../components/modal/Modal";

export const NotificationBellContainer = ({ onClick }) => {
  const [count, setCount] = useState(0);

  const { showModal } = useModal();

  const loadCount = useCallback(
    () =>
      getNotificationsCount$().subscribe((res) => {
        setCount(res.count);
      }),
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      loadCount();
    }, 30000);

    loadCount();
    return () => {
      clearInterval(interval);
      setCount(0);
    };
  }, [loadCount]);

  return (
    <div
      onClick={() =>
        showModal("Notifications", {
          position: "right",
          title: "Notifications",
          icon: "icon-bell",
        })
      }
    >
      <i
        className="icon icon-bell pointer text-secondary notification-icon"
        title="Notifications"
      ></i>
      <span className="badge count badge-primary">{count}</span>
    </div>
  );
};

const NotificationBadge = ({ badge }) => {
  return (
    <span
      className={badge > 0 ? "badge badge-primary" : "badge badge-secondary"}
    >
      {badge}
    </span>
  );
};

const NotificationsTopContent = ({
  noOfnotifications,
  dismissAll,
  systemType,
}) => {
  return (
    <div>
      <div className="row mb-3 mt-2">
        <div className="col-md-12 text-right">
          <div>
            {systemType && (
              <span>
                <button
                  className="btn btn-link btn-sm text-dark"
                  onClick={() => dismissAll(systemType)}
                >
                  Dismiss all
                </button>
                |
              </span>
            )}
            <NavLink
              to="/settings/my-settings/notification-settings"
              className="btn btn-link btn-sm text-dark pr-0"
            >
              <i className="icon icon-cog pointer"></i>
            </NavLink>
          </div>
        </div>
      </div>
      {noOfnotifications < 1 && (
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">No unread notifications</div>
          </div>
        </div>
      )}
    </div>
  );
};

export const NotificationsContainer = () => {
  const [filter, setFilter] = useState(2);
  const [notifications, setNotifications] = useState([]);

  const getNotifications = useCallback(() => {
    getNotifications$()
      .pipe(first())
      .subscribe((result) => result && setNotifications(result.notifications));

  }, []);

  useEffect(() => {
    getNotifications();
    return () => {
      setNotifications([])
    }
  }, [getNotifications]);

  const dismiss = useCallback(
    (notificationId) => {
      dismissNotification$(notificationId)
        .pipe(first())
        .subscribe(getNotifications);
    },
    [getNotifications]
  );

  const dismissAll = useCallback(
    (systemType) => {
      dismissAllNotification$(systemType)
        .pipe(first())
        .subscribe(getNotifications);
    },
    [getNotifications]
  );

  const filters = [
    {
      id: 2,
      description: (
        <div>
          Network{" "}
          <NotificationBadge
            badge={notifications.filter((n) => n.systemType === 2).length}
          />
        </div>
      ),
    },
    {
      id: 3,
      description: (
        <div>
          Jobs{" "}
          <NotificationBadge
            badge={notifications.filter((n) => n.systemType === 3).length}
          />
        </div>
      ),
    },
    {
      id: 1,
      description: (
        <div>
          System{" "}
          <NotificationBadge
            badge={notifications.filter((n) => n.systemType === 1).length}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <TabFilter
          filters={filters}
          selectedFilter={filter}
          onChange={setFilter}
        >
          <div className="tab-content-wrapper">
            <NotificationsTopContent
              noOfnotifications={notifications.length}
              dismissAll={dismissAll}
              systemType={
                notifications && notifications.length
                  ? notifications[0].systemType
                  : null
              }
            />

            {notifications &&
              notifications
                .filter((n) => n.systemType === filter)
                .map((n) => (
                  <div key={n.id}>
                    <div className="row">
                      <div className="col-md-12 notDate">
                        {dateFormat(n.created)}
                        <button
                          className="btn btn-light btn-sm float-right"
                          title="Dismiss message"
                          onClick={() => {
                            dismiss(n.id);
                          }}
                        >
                          <i className="icon icon-trash"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: n.message,
                      }}
                    />
                  </div>
                ))}
          </div>
        </TabFilter>
      </div>
    </div>
  );
};
