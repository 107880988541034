import * as storage from "../helpers/auth";

/*const downloadFile = () => {
  let element;
  let removeElement;

  return (downloadUrl, fileName) => {
    if (!element) {
      element = document.createElement("a");
      document.body.appendChild(element);
      element.style.display = "none";
    }

    if (removeElement) {
      clearTimeout(removeElement);
    }

    element.setAttribute("href", downloadUrl);
    element.setAttribute("download", fileName);

    element.click();

    removeElement = setTimeout(() => {
      if (element) {
        document.body.removeChild(element);
        element = null;
      }
    }, 1000);
  };
};*/

const downloadFile = (downloadUrl, fileName) => {
  return new Promise((resolve, reject) => {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    const accessToken = storage.get().accessToken;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);

    fetch(downloadUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        if (response.status === 404) {
          reject(`Failed to download ${fileName}, file was not found`);
        }

        reject(`Failed to download ${fileName}`);

        return null;
      })
      .then((blobby) => {
        if (blobby == null) {
          document.body.removeChild(anchor);
          return;
        }
        const objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
        document.body.removeChild(anchor);
        resolve(`Downloading ${fileName}`);
      });
  });
};


export const  downloadFileUrl = (downloadUrl, fileName) => {
  return new Promise((resolve, reject) => {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    const accessToken = storage.get().accessToken;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);

    fetch(downloadUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        if (response.status === 404) {
          reject(`Failed to download ${fileName}, file was not found`);
        }

        reject(`Failed to download ${fileName}`);

        return null;
      })
      .then((blobby) => {
        if (blobby == null) {
          document.body.removeChild(anchor);
          return;
        }
        const objectUrl = window.URL.createObjectURL(blobby);
        resolve(objectUrl);
      });
  });
};

export default downloadFile;
