import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getFormValues, getFormSyncErrors, change } from 'redux-form'
import CreateJobWizzardStepTwo from '../../components/jobs/wizzard/CreateJobWizzardStepTwo'
import stepOneValidation from '../../components/jobs/wizzard/stepOneValidation'

let JobWizzardStepTwoContainer = ({
    isPreviousStepValid,
    formErrors,
    updateJobEvent,
    clearEventAlarm}) => {


    return (
        !isPreviousStepValid ? <Redirect to="./general" /> :
            <CreateJobWizzardStepTwo formErrors={formErrors} updateJobEvent={updateJobEvent} clearEventAlarm={clearEventAlarm}/>
    )
}

const mapStateToProps = (state) => {
    const formErrors = getFormSyncErrors('create-job-wizzard')(state)
    const formValues = getFormValues("create-job-wizzard")(state) || {};
    return {
        isPreviousStepValid: Object.keys(stepOneValidation(formValues)).length === 0,
        formErrors
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateJobEvent: (key, value) => dispatch(change('create-job-wizzard', key, value)),
        clearEventAlarm: (key, value) => {
            if (value === "true") {
                dispatch(change('create-job-wizzard', key, { data: null, pastTime: null, time: "hour", size: "byte" }))
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobWizzardStepTwoContainer)