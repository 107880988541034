import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { dateFormat } from "../../../common/dateFormatter";
import Card, { Body, Header } from "../../common/Card";
import PeriodSummary from "../common/PeriodSummary";

const UsageInfo = ({ periods, period, onChange, summary, summaries }) => {
  const summariesBarData = useMemo(() => {
    if (!summaries) {
      return [];
    }
    return summaries
      //.filter((s) => s.cvu > 0)
      .map((s) => ({
        name: dateFormat(s.date, "D"),
        cvu: s.cvu,
        data: Math.floor(s.bytes / (1024 * 1024)),
        files: s.files,
        runs: s.runs,
        events: s.events,
      }));
  }, [summaries]);
  return (
    <Card>
      <Header title={`Usage (${dateFormat(period?.startPeriod, "YYYY-MM-DD")} to ${dateFormat(period?.endPeriod, "YYYY-MM-DD")})`}></Header>
      <Body>
        <select
          value={periods?.indexOf(period)}
          className="form-control form-control-lg border-input"
          onChange={(e) => {
            onChange(periods[e.target.value]);
          }}
        >
          <option value="">Change period</option>
          {periods?.map((p, i) => (
            <option key={p.startPeriod} value={i}>
              {dateFormat(p.startPeriod, "YYYY-MM-DD")} -{" "}
              {dateFormat(p.endPeriod, "YYYY-MM-DD")}
            </option>
          ))}
        </select>

        <PeriodSummary summary={summary} />

        {summariesBarData && summariesBarData.length > 0 && (
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              data={summariesBarData}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" width={20}  />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar name="Files" dataKey="files" stackId="b" fill="#82ca9d" />
              <Bar name="Data" dataKey="data" stackId="c" fill="#007bff" />
              <Bar name="Events" dataKey="events" stackId="a" fill="#8884d8" />
              <Bar name="CVU" dataKey="cvu" stackId="d" fill="#3599e3" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Body>
    </Card>
  );
};

export default UsageInfo;
