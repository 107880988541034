import { get$ as get } from "./http";
import qs from "query-string";

export const list$ = (text, status, page) => {
  return get("/endpoints?" + qs.stringify({ text, status, page }));
};
export const get$ = (endpointId, shareId) => {
  if (shareId) {
    return get(`/endpoints/${endpointId}/${shareId}`);
  }
  return get("/endpoints/" + endpointId);
};
