import React from 'react'
import './PrintButton.css'

export const PrintButton = ({ title, targetId, children }) => {



    return (
        <button className="btn btn-light btn-lg print-btn" type="button" onClick={() => {
            window.print();
        }}>
            <span className="icon icon-print"></span> Print summary
        </button>
    )
}