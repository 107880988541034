import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import FileDetails from "../../../components/user/jobs/FileDetails";
import { loadJobRunFile } from "../../../actions/jobs";
import { isFetching } from "../../../selectors/Fetching";
import Loader from "../../../components/common/Loader";

const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state.fetching, loadJobRunFile),
  details: state.jobs.file,
});

const mapDispatchToProps = (dispatch) => ({
  onPrintDetails: () => {},
  onDownload: () => {},
  initialState: (runId, eventId, fileId, fileSubId) =>
    dispatch(loadJobRunFile(runId, eventId, fileId, fileSubId)),
});

const FileDetailsContainer = ({
  initialState,
  isFetching,
  match,
  details,
  onDownload,
  onPrintDetails,
}) => {
  useEffect(() => {
    initialState(
      match.params.runId,
      match.params.eventId,
      match.params.fileId,
      match.params.fileSubId
    );
  }, [
    match.params.runId,
    match.params.eventId,
    match.params.fileId,
    match.params.fileSubId,
    initialState,
  ]);

  useEffect(() => {
    const interval = setInterval(
      () =>
        initialState(
          match.params.runId,
          match.params.eventId,
          match.params.fileId,
          match.params.fileSubId
        ),
      10000
    );

    return () => {
      clearInterval(interval);
    };
  }, [
    initialState,
    match.params.eventId,
    match.params.fileId,
    match.params.fileSubId,
    match.params.runId,
  ]);

  const onRefresh = useCallback(() => {
    initialState(
      match.params.runId,
      match.params.eventId,
      match.params.fileId,
      match.params.fileSubId
    );
  }, [
    initialState,
    match.params.eventId,
    match.params.fileId,
    match.params.fileSubId,
    match.params.runId,
  ]);

  if (
    !details ||
    (isFetching && details.sysCommandId !== parseInt(match.params.fileId, 10))
  ) {
    return <Loader title="Loading file"  startDelay={0}/>;
  }

  return (
    <FileDetails
      runId={match.params.runId}
      eventId={match.params.eventId}
      title="Jobs / Runs"
      details={details}
      onRefresh={onRefresh}
      onPrintDetails={onPrintDetails}
      onDownload={onDownload}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileDetailsContainer);
