import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../components/common/Loader";
import ConnectorDetailsComponent from "../components/connectors/ConnectorDetails";
import { volumeFormatter } from "../common/volumeFormatter";

import {
  loadConnectorDetails,
  recycleConnector,
  changeConnectorLogLevel,
  requestConnectorLogFiles,
  transferConnectorToNewOrg,
  unregisterAndDeleteConnector,
  setConnectorTransferOrg,
  setConnectorNewLogLevel,
  restart,
  kill,
  clearExcludeFolders,
  addExcludeFolders,
  removeExcludeFolders,
  changeConnectorDescription,
  downloadLogFile,
} from "../actions/connectors";
import { isFetching } from "../selectors/Fetching";

const mapStateToProps = (state) => {
  return {
    isFetching: isFetching(state.fetching, loadConnectorDetails),
    details: state.connectors.details,
    commandLogs: state.connectors.commandLogs,
    organisations: state.connectors.organisations,
    connectorLogLevels: state.connectors.connectorLogLevels,
    newLoglevel: state.connectors.newLogLevel,
    newOrgId: state.connectors.transferNewOrg,
    logFiles: state.connectors.logFiles,
  };
};

const mapDispatchToPros = (dispatch) => {
  return {
    initalStateConnectorsDetails: (clientId) =>
      dispatch(loadConnectorDetails(clientId)),
    recycle: (clientId) => dispatch(recycleConnector(clientId)),
    changeLogLevel: (clientId, logLevel) =>
      dispatch(changeConnectorLogLevel(clientId, logLevel)),
    getLogFiles: (clientId, date) =>
      dispatch(requestConnectorLogFiles(clientId, date)),
    restart: (clientId) => dispatch(restart(clientId)),
    kill: (clientId) => dispatch(kill(clientId)),
    clearExcludeFolders: (clientId) => dispatch(clearExcludeFolders(clientId)),
    addExcludeFolders: (clientId, path) =>
      dispatch(addExcludeFolders(clientId, path)),
    removeExcludeFolders: (clientId, path) =>
      dispatch(removeExcludeFolders(clientId, path)),
    transferToNewOrgEvent: (clientId, orgId) =>
      dispatch(transferConnectorToNewOrg(clientId, orgId)),
    unregisterAndDeleteConnectorEvent: (clientId) =>
      dispatch(unregisterAndDeleteConnector(clientId)),
    setConnectorTransferOrgEvent: (orgId) =>
      dispatch(setConnectorTransferOrg(orgId)),
    setConnectorNewLogLevelEvent: (logLevel) =>
      dispatch(setConnectorNewLogLevel(logLevel)),
    changeConnectorDescription: (clientId, description) =>
      dispatch(changeConnectorDescription(clientId, description)),
    downloadLogFile: (clientId) => (file) =>
      dispatch(downloadLogFile(clientId, file.fileId, file.fileName)),
  };
};

const types = {
  1: "Restart connector",
  2: "Upload logs",
  3: "Set option",
  4: "KillFSConnector",
  10: "Clear exclude folder",
  11: "Add exclude folder",
  12: "Remove exclude folder",
  13: "Get exclude folders",
};

const status = {
  "-1": "CommandQueueItem",
  0: "Ready to dispatch",
  1: "Dispatched",
  2: "Executed ok",
  9000: "Failed",
};

const commandLogsSchema = [
  {
    header: "Type",
    accessor: "type",
    type: "custom",
    render: (d) => types[d.type],
  },
  {
    header: "Status",
    accessor: "status",
    type: "custom",
    render: (d) => status[d.status],
  },
  { header: "Update", accessor: "update", type: "date" },
  { header: "Object", accessor: "object" },
  { header: "Param", accessor: "param" },
  { header: "Message", accessor: "message" },
];

const ConnectorsDetailsContainer = ({
  initalStateConnectorsDetails,
  clientId,
  isFetching,
  details,
  transferToNewOrgEvent,
  unregisterAndDeleteConnectorEvent,
  setConnectorTransferOrgEvent,
  setConnectorNewLogLevelEvent,
  organisations,
  connectorLogLevels,
  newOrgId,
  changeConnectorDescription,
  logFiles,
  recycle,
  changeLogLevel,
  getLogFiles,
  restart,
  kill,
  clearExcludeFolders,
  addExcludeFolders,
  removeExcludeFolders,
  commandLogs,
  downloadLogFile,
}) => {
  useEffect(() => {

    initalStateConnectorsDetails(clientId);

    const interval = setInterval(() => {
      initalStateConnectorsDetails(clientId);
    }, 30000);

    return () => {
      clearInterval(interval);
    };

  }, [clientId, initalStateConnectorsDetails]);

  const logFilesSchema = [
    { accessor: "fileName", header: "File" },
    { accessor: "date", header: "Date", type: "date" },
    {
      accessor: "size",
      header: "Size",
      type: "custom",
      render: (data) => volumeFormatter(data.size),
    },
    {
      type: "button",
      css: "btn btn-dark btn-fill btn-wd float-right",
      action: downloadLogFile(clientId),
      actionText: "Download",
    },
  ];


  return isFetching && (!details || details.id !== parseInt(clientId)) ? (
    <Loader />
  ) : (
    <div>
      <ConnectorDetailsComponent
        details={details}
        logFiles={
          logFiles || [{ fileName: "test.txt", date: "2020-01-01", size: 25 }]
        }
        transferToNewOrgEvent={transferToNewOrgEvent}
        unregisterAndDeleteConnectorEvent={unregisterAndDeleteConnectorEvent}
        organisations={organisations}
        connectorLogLevels={connectorLogLevels}
        setConnectorTransferOrgEvent={setConnectorTransferOrgEvent}
        setConnectorNewLogLevelEvent={setConnectorNewLogLevelEvent}
        newOrgId={newOrgId}
        changeConnectorDescription={changeConnectorDescription}
        recycle={recycle}
        changeLogLevel={changeLogLevel}
        getLogFiles={getLogFiles}
        restart={restart}
        kill={kill}
        clearExcludeFolders={clearExcludeFolders}
        addExcludeFolders={addExcludeFolders}
        removeExcludeFolders={removeExcludeFolders}
        commandLogs={commandLogs}
        logFilesSchema={logFilesSchema}
        commandLogsSchema={commandLogsSchema}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToPros
)(ConnectorsDetailsContainer);
