import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form';
import './AccountEdit.css'
import Card, { Header, Body } from '../common/Card';

const required = value => value ? undefined : 'Required'

const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' : undefined

const renderInputField = ({ input, label, type, name, meta: { touched, error, warning }, icon, tooltip }) => {
    return <div className="form-group">
        <label>{label}</label>
        <input className="form-control form-control-lg border-input" {...input} placeholder={label} type={type} />
        {touched && ((error && <em htmlFor={name} className="invalid">{tooltip}</em>) || (warning && <span>{warning}</span>))}
    </div>
}

let AccountEditComponent = ({ handleSubmit, errors, pristine, reset, submitting, initialValues, accountTypes }) => {

    let showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""

const [showAdvanced, setShowAdvanced] = useState(false);


    return (
        <Card className="card-settings">
            <Header title={'General info - ' + initialValues.name}>
                <div className="alert highlight" role="alert"><span>Edit general account info.</span></div>
            </Header>
            <Body>
                <form onSubmit={handleSubmit}>
                    <fieldset>
                        <Field
                            name="name"
                            component={renderInputField}
                            type="text"
                            label="Account name"
                            icon="fa-user"
                            validate={[required]}
                            tooltip="Please enter account name"
                        />

                        <Field
                            name="companyName"
                            component={renderInputField}
                            type="text"
                            label="Company name"
                            icon="fa-user"
                        />

                        {
                            showAdvanced &&
                            <Field
                                name="description"
                                component={renderInputField}
                                type="text"
                                label="Description"
                                icon="fa-user"
                                validate={[required]}
                                tooltip="Please enter a description"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="countryCode"
                                component={renderInputField}
                                type="text"
                                label="Country code"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="stateCode"
                                component={renderInputField}
                                type="text"
                                label="State code"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="phoneNumber"
                                component={renderInputField}
                                type="text"
                                label="Phone number"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="faxNumber"
                                component={renderInputField}
                                type="text"
                                label="Fax number"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="address1"
                                component={renderInputField}
                                type="text"
                                label="Address"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="address2"
                                component={renderInputField}
                                type="text"
                                label="Additonal address"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="webPageUrl"
                                component={renderInputField}
                                type="text"
                                label="Webpage"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="emailContact"
                                component={renderInputField}
                                type="text"
                                label="Email contact"
                                icon="fa-user"
                                validate={[email]}
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="contactFirstName"
                                component={renderInputField}
                                type="text"
                                label="Contact first name"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="contactLastName"
                                component={renderInputField}
                                type="text"
                                label="Contact last name"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="organizationNumber"
                                component={renderInputField}
                                type="text"
                                label="Organization number"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="postalNumber"
                                component={renderInputField}
                                type="text"
                                label="Postal number"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="postalCode"
                                component={renderInputField}
                                type="text"
                                label="Postal code"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="isSameAsInvoiceAddress"
                                component={renderInputField}
                                type="text"
                                label="Same as invoice address?"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&

                            <Field
                                name="invoiceByPaper"
                                component={renderInputField}
                                type="text"
                                label="Invoice by paper"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="invoiceByMail"
                                component={renderInputField}
                                type="text"
                                label="Invoice by mail"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="division"
                                component={renderInputField}
                                type="text"
                                label="Division"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="deliveryDivision"
                                component={renderInputField}
                                type="text"
                                label="Delivery division"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="paymentTerms"
                                component={renderInputField}
                                type="text"
                                label="Payment terms"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="inactivation"
                                component={renderInputField}
                                type="text"
                                label="Inactivation"
                                icon="fa-user"
                            />
                        }
                        {
                            showAdvanced &&
                            <Field
                                name="activation"
                                component={renderInputField}
                                type="text"
                                label="Activation"
                                icon="fa-user"
                            />
                        }
                    </fieldset>
                    {
                        showAdvanced &&
                        <Header title="Delivery address"></Header>
                    }
                    {
                        showAdvanced &&
                        <fieldset>

                            <Field
                                name="deliveryName"
                                component={renderInputField}
                                type="text"
                                label="Delivery name"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryCountryCode"
                                component={renderInputField}
                                type="text"
                                label="Delivery country code"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryStateCode"
                                component={renderInputField}
                                type="text"
                                label="Delivery state code"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryAddress1"
                                component={renderInputField}
                                type="text"
                                label="Delivery address"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryAddress2"
                                component={renderInputField}
                                type="text"
                                label="Delivery second address"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryEmailContact"
                                component={renderInputField}
                                type="text"
                                label="Delivery email contact"
                                icon="fa-user"
                                validate={[email]}
                            />

                            <Field
                                name="deliveryContactFirstName"
                                component={renderInputField}
                                type="text"
                                label="Delivery contact first name"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryContactLastName"
                                component={renderInputField}
                                type="text"
                                label="Delivery contact first name"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryPostalNumber"
                                component={renderInputField}
                                type="text"
                                label="Delivery postal number"
                                icon="fa-user"
                            />

                            <Field
                                name="deliveryPostalCode"
                                component={renderInputField}
                                type="text"
                                label="Delivery posta code"
                                icon="fa-user"
                            />

                            <div className="note">
                                {showError}
                            </div>
                        </fieldset>
                    }
                    <footer>
                        {
                            showAdvanced ?
                                <button type="button" className="btn btn-link float-right" onClick={() => setShowAdvanced(false)}>Hide details</button> :
                                <button type="button" className="btn btn-link float-right" onClick={() => setShowAdvanced(true)}>Show more details</button>
                        }
                        <button type="submit" className="btn btn-dark btn-lg" id="btnLogin" disabled={submitting}>Save</button>
                    </footer>
                </form>
            </Body>
        </Card>
    )
}

export default reduxForm({
    form: 'edit-account', // a unique identifier for this form
})(AccountEditComponent);

