export const EDIT_ORGANISATION = "EDIT_ORGANISATION"
export const GET_ORGANISATION = "REQ_GET_ORGANISATION"
export const GET_ORGANISATION_SUCCESS = "GET_ORGANISATION_SUCCESS"
export const GET_ORGANISATION_FAILED = "GET_ORGANISATION_FAILED"
export const SAVE_ORGANISATION = "REQ_SAVE_ORGANISATION"
export const SAVE_ORGANISATION_SUCCESS = "SAVE_ORGANISATION_SUCCESS"
export const SAVE_ORGANISATION_FAILED = "SAVE_ORGANISATION_FAILED"
export const LIST_ORGANISATIONS = "REQ_LIST_ORGANISATIONS"
export const LIST_ORGANISATIONS_SUCCESS = "LIST_ORGANISATIONS_FAILED"
export const LIST_ORGANISATIONS_FAILED = "LIST_ORGANISATIONS_FAILED"
export const CREATE_ORGANISATION = "REQ_CREATE_ORGANISATION"
export const CREATE_ORGANISATION_SUCCESS = "CREATE_ORGANISATION_SUCCESS"
export const CREATE_ORGANISATION_FAILED = "CREATE_ORGANISATION_FAILED"
export const LOAD_ORGANISATION_PRIVILEGES_SUCCESS = "LOAD_ORGANISATION_PRIVILEGES_SUCCESS"
export const REMOVE_USER_FROM_ORGANISATION = "REQ_REMOVE_USER_FROM_ORGANISATION"
export const REMOVE_USER_FROM_ORGANISATION_SUCCESS = "REMOVE_USER_FROM_ORGANISATION_SUCCESS"

export const editOrganisation = (organisationId) => ({
    type: EDIT_ORGANISATION,
    organisationId: organisationId
})

export const getOrganisation = (organisationId) => ({
    type: GET_ORGANISATION,
    organisationId
});

export const getOrganisationSuccess = (organisation) => ({
    type: GET_ORGANISATION_SUCCESS,
    organisation: organisation
})

export const getOrganisationFailed = (organisationId) => ({
    type: GET_ORGANISATION_FAILED,
    organisationId: organisationId
})

export const saveOrganisation = (accountId, organisationId, model) => ({
    type: SAVE_ORGANISATION,
    model,
    accountId,
    organisationId
})

export const createOrganisation = (accountId, model) => ({
    type: CREATE_ORGANISATION,
    model,
    accountId
})

export const createOrganisationSuccess = (organisationId) => ({
    type : CREATE_ORGANISATION_SUCCESS,
    organisationId
})

export const createOrganisationFailed = (error) => ({
    type : CREATE_ORGANISATION_FAILED,
    error
})


export const saveOrganisationSuccess = () => ({
    type : SAVE_ORGANISATION_SUCCESS
})

export const saveOrganisationFailed = (errors) => ({
    type: SAVE_ORGANISATION_FAILED,
    errors: errors
})

export const listOrganisations = (accountId) => ({
    type: LIST_ORGANISATIONS,
    accountId: accountId
})

export const listOrganisationsSuccess = (organisations) => ({
    type: LIST_ORGANISATIONS_SUCCESS,
    organisations: organisations
})

export const listOrganisationsFailed = (errors) => ({
    type: LIST_ORGANISATIONS,
    errors: errors
})

export const loadOrganisationPrivilegesSuccess = (privileges) => ({
    type: LOAD_ORGANISATION_PRIVILEGES_SUCCESS,
    privileges
})


export const removeUserFromOrganisation = (user) => ({
    type: REMOVE_USER_FROM_ORGANISATION,
    user
})

export const removeUserFromOrganisatiSuccess = (user) => ({
    type: REMOVE_USER_FROM_ORGANISATION_SUCCESS,
    user
})
