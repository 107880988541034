import React from 'react'
import { connect } from 'react-redux'
import { getAccount, saveAccount } from '../actions/account'
import { editOrganisation } from '../actions/organisation'
import { getHeaders as getOrganisationHeaders } from '../selectors/Organisation'
import { getAccountTypes } from '../selectors/Account'
import { isFetching } from '../selectors/Fetching'
import Loader from '../components/common/Loader'
import AccountEdit from '../components/accounts/AccountEdit'

const mapStateToProps = (state) => ({
    values: state.account.account,
    isFetching: isFetching(state.fetching, getAccount),
    accountId: state.auth.user.accountId,
    organizationHeaders: getOrganisationHeaders(),
    accountTypes: getAccountTypes()
});

const mapDispatchToProps = (dispatch) => ({
    saveAccount: (accountId) => (values) => dispatch(saveAccount(accountId, values)),
    onOrganisationClick:  (accountId) => (organisationRow) => dispatch(editOrganisation({ accountId, organisationId : organisationRow.organisationId }))
})

const AccountEditContainer = ({ values, isFetching, accountTypes, saveAccount, accountId }) => {

    if (!values || isFetching) return <Loader />;

    return <AccountEdit initialValues={values.account} accountTypes={accountTypes} onSubmit={saveAccount(accountId)}/>
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountEditContainer)
