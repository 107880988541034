import React, { PureComponent } from "react";
import PageContent, { ContentBody, ContentHeader } from "../common/PageContent";
import RunsContainer from "../../containers/user/jobs/Runs";
import { volumeFormatter } from "../../common/volumeFormatter";
import {
  BarChart,
  AreaChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
//import Progress from '../common/progress/Progress';
import { dateFormat } from "../../common/dateFormatter";
import { NavLink } from "react-router-dom";
import Privilege from "../../containers/Privilege";
import "./Dashboard.css";
import { GeneralStatus } from "./GeneralStatus";

const Warninng = () => {
  return (
    <div className="mb-3">
      <h6 className="alert-heading text-danger">
        <i className="icon icon-attention-circled"></i> Some problems
        identified, please check it!
      </h6>
    </div>
  );
};

const NoConnectorsInfo = ({ download }) => {
  return (
    <div className="alert highlight" role="alert">
      <h6>No connectors registered on this account?</h6>
      <span>
        Seems like there are no connectors registered on this account? To start
        transferring files you need at least one connector installed and
        registered.
      </span>
      <Privilege type="Network extend page" privilege="read">
        <span> There are two ways to do this:</span>
        <ul>
          <li>
            <NavLink
              activeClassName="active"
              to="/admin/connectors/requestnewinstallment"
            >
              Send an install request to a server administrator
            </NavLink>
            - install and register with a token included in the request e-mail.
          </li>
          <li>
            Download installer from this page - install and register with
            username and password.
          </li>
        </ul>
        <hr></hr>
        <button className="btn btn-dark" onClick={(e) => download()}>
          <i className="icon icon-download"></i> Download 32-bit x86
        </button>
      </Privilege>
    </div>
  );
};

const ShortCuts = () => {
  return (
    <div>
      <h6>Other stuff you can do</h6>
      <ul>
        <li>
          <span>
            Check out your personal settings like notifications, password,
            timezone and organizations{" "}
          </span>
          <NavLink activeClassName="active" to="/settings/my-settings/general/">
            @My settings
          </NavLink>
        </li>
        <li>
          <span>Manage users, organizations, subscription and more </span>
          <NavLink activeClassName="active" to="/admin/settings/general">
            @Account settings
          </NavLink>
        </li>
        <li>
          <span>Monitor and manage connectors </span>
          <NavLink activeClassName="active" to="/admin/connectors">
            @Network/Connectors
          </NavLink>
        </li>
        <li>
          <span>
            Explore content and share folders on FTP servers and local file
            servers{" "}
          </span>
          <NavLink activeClassName="active" to="/explore">
            @Network/Explore and share
          </NavLink>
        </li>
        <li>
          <span>
            Connect to other organization accounts to enable file transfer
            between your organizations{" "}
          </span>
          <NavLink activeClassName="active" to="/admin/connections">
            @Network/Connections
          </NavLink>
        </li>
        <li>
          <span>
            Configure automized file process workflows (Jobs) between any
            endpoints in your network{" "}
          </span>
          <NavLink activeClassName="active" to="/admin/jobs">
            @Jobs/Configuration
          </NavLink>
        </li>
        <li>
          <span>Monitor and overview job runs (executions of jobs) </span>
          <NavLink
            activeClassName="active"
            to="/admin/connectors/requestnewinstallment"
          >
            @Jobs/Runs
          </NavLink>
        </li>
      </ul>
      <p>
        This info will be replaced with real status information as soon as you
        have some installed connectors. Happy file processing!{" "}
      </p>
      <p>
        <i className="icon icon-logo_mark"></i> The Cloudbuz team
      </p>
    </div>
  );
};

const TotalVolCards = ({ statisticsPeriodSums }) => {
  return (
    <React.Fragment>
      <div className="card-deck totalVolCards">
        <div className="card bg-light mb-3">
          <div className="card-header">
            <i className="icon icon-file_1"></i> Files
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-6 align-self-center">
                <h2>
                  <i
                    className={`icon ${statisticsPeriodSums?.changePercentFiles > 0
                        ? "icon-up-circled"
                        : "icon-down-circled"
                      }`}
                  ></i>{" "}
                  <span>{statisticsPeriodSums?.changePercentFiles}%</span>
                </h2>
              </div>
              <div className="col-6 text-right">
                <h1 className="card-title">
                  {statisticsPeriodSums?.currentFiles
                    .toLocaleString("en-US")
                    .replace(/,/gi, " ")}
                </h1>
                <p className="card-text">Files processed</p>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">
              {statisticsPeriodSums?.changePercentFiles > 0 ? "Up " : "Down "}
              {statisticsPeriodSums?.changePercentFiles}% since previous period
              ({statisticsPeriodSums?.previousFiles})
            </small>
          </div>
        </div>
        <div className="card bg-light mb-3">
          <div className="card-header">
            <i className="icon icon-database"></i> Data
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-6 align-self-center">
                <h2>
                  <i
                    className={`icon ${statisticsPeriodSums?.changePercentBytes > 0
                        ? "icon-up-circled"
                        : "icon-down-circled"
                      }`}
                  ></i>{" "}
                  <span>{statisticsPeriodSums?.changePercentBytes}%</span>
                </h2>
              </div>
              <div className="col-6 text-right">
                <h1 className="card-title">
                  {volumeFormatter(statisticsPeriodSums?.currentBytes)
                    .toLocaleString("en-US")
                    .replace(/,/gi, " ")}
                </h1>
                <p className="card-text">Data processed</p>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">
              {statisticsPeriodSums?.changePercentBytes > 0 ? "Up " : "Down "}
              {statisticsPeriodSums?.changePercentBytes}% since previous period
              ({volumeFormatter(statisticsPeriodSums?.previousBytes)})
            </small>
          </div>
        </div>
        <div className="card bg-light mb-3">
          <div className="card-header">
            <i className="icon icon-loop-alt"></i> Runs
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-6 align-self-center">
                <h2>
                  <i
                    className={`icon ${statisticsPeriodSums?.changePercentEvents > 0
                        ? "icon-up-circled"
                        : "icon-down-circled"
                      }`}
                  ></i>{" "}
                  <span>{statisticsPeriodSums?.changePercentEvents}%</span>
                </h2>
              </div>
              <div className="col-6 text-right">
                <h1 className="card-title">
                  {statisticsPeriodSums?.currentEvents
                    ?.toLocaleString("en-US")
                    .replace(/,/gi, " ")}
                </h1>
                <p className="card-text">Event runs completed</p>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">
              {statisticsPeriodSums?.changePercentEvents > 0 ? "Up " : "Down "}
              {statisticsPeriodSums?.changePercentEvents}% since previous period
              ({statisticsPeriodSums?.previousEvents})
            </small>
          </div>
        </div>

        <div className="card bg-light mb-3">
          <div className="card-header">
            <i className="icon icon-logo_mark"></i> CVU spend
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-6 align-self-center">
                <h2>
                  <i
                    className={`icon ${statisticsPeriodSums?.changePercentCVU > 0
                        ? "icon-up-circled"
                        : "icon-down-circled"
                      }`}
                  ></i>{" "}
                  <span>{statisticsPeriodSums?.changePercentCVU}%</span>
                </h2>
              </div>
              <div className="col-6 text-right">
                <h1 className="card-title">
                  {statisticsPeriodSums?.currentCVU
                    ?.toLocaleString("en-US")
                    .replace(/,/gi, " ")}
                </h1>
                <p className="card-text">CVUs spent</p>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">
              {statisticsPeriodSums?.changePercentCVU > 0 ? "Up " : "Down "}
              {statisticsPeriodSums?.changePercentCVU}% since previous period (
              {statisticsPeriodSums?.previousCVU})
            </small>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const StatsDaily = (statisticsDailySums, category) => {
  let data = [];

  data = statisticsDailySums.map((ds, i) => {
    switch (category) {
      case "Files":
        return { day: dateFormat(ds.date, "YYYY-MM-DD"), Files: ds.files };
      case "Bytes":
        return {
          day: dateFormat(ds.date, "YYYY-MM-DD"),
          MB: parseFloat((ds.bytes / 1048576).toFixed(2)),
        };
      case "Events":
        return { day: dateFormat(ds.date, "YYYY-MM-DD"), Runs: ds.events };
      case "CVU":
        return { day: dateFormat(ds.date, "YYYY-MM-DD"), CVU: ds.cvu };
      default:
        return { day: dateFormat(ds.date, "YYYY-MM-DD"), Files: ds.files };
    }
  });

  return {
    data,
  };
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          fontSize="12px"
          textAnchor="end"
          fill="#666"
          transform="rotate(-10)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const Dashboard = ({ dashboardData, download }) => {
  const { stats, status, periodInfo } = dashboardData;

  /*data for topCVU chart*/
  let topCvuData = stats?.statisticsTopCVU?.map((j) => {
    return {
      name: j.jobName,
      "Files(CVU)": j.files_CVU,
      "Data(CVU)": j.bytes_CVU,
      "Runs(CVU)": j.events_CVU,
    };
  });

  let installedConnectors =
    status?.statusConnectors?.connectorError +
    status?.statusConnectors?.connectorOk +
    status?.statusConnectors?.connectorWarning;

  const periodPercentage = Math.round((periodInfo?.cvuUsed / periodInfo?.includedCVU) * 100);

  return (
    <PageContent>
      <ContentHeader title="Dashboard" />
      <ContentBody>
        {/*connectorCounts.connectorError > 0 ? <ConnectorCritical noOfErr={connectorCounts.connectorError}/> : ""*/}
        {/*jobAndVolumeCounts.jobsUnacknowledgedErrors > 0 ? <JobsCritical noOfErr={jobAndVolumeCounts.jobsUnacknowledgedErrors}/> : ""*/}

        <div className="dashboardArea">
          <div className="d-flex">
            <h5 className="flex-fill">General status</h5>

            {
              periodPercentage >= 0 &&
              <div>
                <NavLink className="mr-1" to="/admin/subscription/your-plan">
                  Account usage
                </NavLink>
                <span
                  className={`text-white badge ${periodPercentage > 90 ? "bg-danger" : "bg-success"
                    }`}
                >
                  {periodPercentage} %
                </span>
              </div>
            }
            {
              periodInfo?.includedCVU === -1 &&
              <div>
                <NavLink className="mr-1" to="/admin/subscription/your-plan">
                  Account usage
                </NavLink>
                <span
                  className={`text-white badge bg-success`}
                >
                  {periodInfo?.cvuUsed} CVU's
                </span>
              </div>
            }
          </div>
          {
            installedConnectors === 0 ? (
              <NoConnectorsInfo download={download} />
            ) : status?.statusJobRuns.jobRunsUnAck > 0 ||
              status?.statusConnectors?.connectorError > 0 ||
              status?.statusRootFolders?.rootError > 0 ||
              status?.statusJobsAutoDisabled?.jobsPermanentlyDisabled > 0 ||
              status?.statusJobsAutoDisabled?.jobsTemporarilyDisabled ? (
              <Warninng />
            ) : (
              ""
            ) /*<AllGood/>*/
          }
          {installedConnectors === 0 ? (
            <ShortCuts />
          ) : (
            <GeneralStatus
              statusConnectors={status.statusConnectors}
              statusJobRuns={status.statusJobRuns}
              statusJobsAutoDisabled={status.statusJobsAutoDisabled}
              statusRootFolders={status.statusRootFolders}
            />
          )}
        </div>
        <div className="row m-0">
          <div className="col-xl-8 pl-0 no-p-on-xl">
            <div className="col-12 pr-0 pl-0">
              <div className="dashboardArea pb-4">
                <h5>Job runs</h5>
                <RunsContainer useInDashboard="1" />
              </div>
            </div>
            <div className="col-12 pr-0 pl-0">
              <div className="dashboardArea">
                <h5>Summary (last 30 days)</h5>
                <TotalVolCards
                  statisticsPeriodSums={stats.statisticsPeriodSums}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4 pr-0 no-p-on-xl">
            <div className="dashboardArea">
              <h5>Stats (last 30 days)</h5>

              <div className="card bg-light mb-3">
                <div className="card-header">Top CVU jobs</div>
                <div className="card-body">
                  <ResponsiveContainer width="100%" height={200}>
                    <BarChart
                      data={topCvuData}
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        height={60}
                        tick={<CustomizedAxisTick />}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Files(CVU)" stackId="a" fill="#007bff" />
                      <Bar dataKey="Data(CVU)" stackId="a" fill="#6610f2" />
                      <Bar dataKey="Runs(CVU)" stackId="a" fill="#e83e8c" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="card bg-light mb-3">
                <div className="card-header">Processed files</div>
                <div className="card-body">
                  <ResponsiveContainer width="100%" height={200}>
                    <AreaChart
                      data={StatsDaily(stats.statisticsDailySums, "Files").data}
                      margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Area
                        type="monotone"
                        dataKey="Files"
                        stroke="#007bff"
                        fill="#007bff"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="card bg-light mb-3">
                <div className="card-header">Processed data (MB)</div>
                <div className="card-body">
                  <ResponsiveContainer width="100%" height={200}>
                    <AreaChart
                      data={StatsDaily(stats.statisticsDailySums, "Bytes").data}
                      margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Area
                        type="monotone"
                        dataKey="MB"
                        stroke="#6610f2"
                        fill="#6610f2"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="card bg-light mb-3">
                <div className="card-header">Event runs</div>
                <div className="card-body">
                  <ResponsiveContainer width="100%" height={200}>
                    <AreaChart
                      data={
                        StatsDaily(stats.statisticsDailySums, "Events").data
                      }
                      margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Area
                        type="monotone"
                        dataKey="Runs"
                        stroke="#e83e8c"
                        fill="#e83e8c"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="card bg-light">
                <div className="card-header">CVU spent</div>
                <div className="card-body">
                  <ResponsiveContainer width="100%" height={200}>
                    <AreaChart
                      data={StatsDaily(stats.statisticsDailySums, "CVU").data}
                      margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Area
                        type="monotone"
                        dataKey="CVU"
                        stroke="#20c997"
                        fill="#20c997"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentBody>
    </PageContent>
  );
};

export default Dashboard;
