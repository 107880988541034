import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom'
import './AcceptInvite.css'
import { required } from '../common/ReduxFormFieldValidation';
import { renderField } from '../common/ReduxFormHelper';
import LoginCard, { Header, Body } from '../common/LoginCard';
import CbLogo from '../../images/logo_white.png'

let AcceptAccountRelationNewUserComponent = ({ initialValues, handleSubmit, errors, submitting, onDecline }) => {
    const showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""

    return (
        <div>
            <div className="logo_login_placeholder">
                <img src={CbLogo} height="30px" alt="Cloudbuz Manager" className="logo_login" />
            </div>
            <LoginCard>
                <Header title="Join and connect accounts">
                    <div className="alert alert-primary" role="alert">
                        <p>
                            <strong>{initialValues.invitorName}</strong> with the e-mail <i><strong>{initialValues.invitorEmail}</strong></i> has invited you to create a free Cloudbuz organization account to enable automation of file transfers between your organizations.
                        </p>
                        <p>
                            Once accepted, your new account will be connected to <strong>{initialValues.invitedBy}</strong>.
                        </p>
                        <p>
                            Happy file transferring! <i className="icon icon-logo_mark"></i>
                        </p>
                    </div>
                </Header>
                <Body>
                    <form onSubmit={handleSubmit}>
                        <div className="content">
                            <fieldset>
                                <Field
                                    name="fullName"
                                    component={renderField}
                                    type="text"
                                    placeholder="Your full name"
                                    icon="fa-user"
                                    validate={[required]}
                                    tooltip="Please enter your full name"
                                />
                                <Field
                                    name="accountName"
                                    component={renderField}
                                    type="text"
                                    placeholder="Name of your organization"
                                    icon="fa-user"
                                    validate={[required]}
                                    tooltip="Please enter the name of your new account"
                                />
                                {showError}
                            </fieldset>
                            <footer className="text-center">
                                <button type="submit" className="btn btn-success btn-block btn-lg" disabled={submitting}>
                                    Accept
                                </button>
                                or
                                <button type="button" className="btn btn-light  btn-block btn-lg" disabled={submitting} onClick={onDecline}>
                                    Decline
                                </button>
                                <div className="text-center">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>
                                                Already have an account? <Link to="../../login">Log in</Link>
                                            </p>
                                        </div>
                                        <div className="col-md-12">
                                            <p>Or <Link to="../../signup">sign up</Link> from scratch.</p>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>

                    </form>
                </Body>
            </LoginCard>
        </div>
    )
}

export default reduxForm({
    form: 'accept-accountrelationnewuser', // a unique identifier for this form
})(AcceptAccountRelationNewUserComponent);

