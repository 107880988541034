import React from 'react'
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form'
import { connect } from 'react-redux'
import './UserEdit.css'
import { required, requiredArray, email } from '../common/ReduxFormFieldValidation';
import { renderDropDown, renderField } from '../common/ReduxFormHelper';

import Card, { Header, Body } from '../common/Card'


const valid = (fields, selectedOrganisation, selectedOrganisationPrivilege) => {
    return selectedOrganisation > 0 &&
        selectedOrganisationPrivilege > 0 &&
        !fields
            .map((f, i) => fields.get(i).organisationId)
            .includes(selectedOrganisation)
}

const renderOrganisationSection = ({
    selectedOrganisation,
    selectedOrganisationPrivilege,
    fields,
    organisations,
    privileges, meta: { touched, warning, error, dirty } }) => {

    const privilegesOptions = privileges.map(i => {
        return { id: i.id, name: i.name }
    })

    const organisationOptions = organisations.map(o => {
        return { id: o.organisationId, name: o.name }
    })

    return (
        <React.Fragment>
            <h6 className="d-none d-md-block"><span className="badge badge-primary"><i className="icon icon-info-circled"></i> Set user privilege</span></h6>
            <div className="row user-priv-tableheader">
                <div className="col-md-6 d-none d-md-block">
                    Organization
                </div>
                <div className="col-md-4 d-none d-md-block">
                    Privilege
                </div>
                <div className="col-md-2 d-none d-md-block"/>
            </div>

            {
                fields.map((field, i) => (
                    <div className="row user-priv-table" key={i}> 
                        <div className="col-5 d-md-none">
                            <strong>Organization</strong>
                        </div>
                        <div className="col-7 col-md-6 d-flex align-items-center">
                            {
                                organisations.find(o =>
                                    o.organisationId === fields.get(i).organisationId).name
                            }
                        </div>
                        <div className="col-5 d-md-none">
                            <strong>Privilege</strong>
                        </div>
                        <div className="col-7 col-md-4 d-flex align-items-center">
                            {
                                privileges.find(p =>
                                    p.id === fields.get(i).privilege).name
                            }
                        </div>
                        <div className="col-12 col-md-2 text-right align-items-center">
                            <button type="button" className="btn btn-outline-danger" onClick={() => fields.remove(i)}><i className="icon icon-trash"></i> Delete privilege</button>
                            {/*<IconButton icon="icon-trash" type="danger" tooltip="Delete" tooltipplacement="top"
                                onClick={() => fields.remove(i)} />*/}
                        </div>
                    </div>
                ))

            }
            <h6 className="d-md-none"><span className="badge badge-primary"><i className="icon icon-info-circled"></i> Set user privilege</span></h6>
            <div className="row user-priv-table">
                <div className="col-md-6 align-items-center">
                    <Field
                        name="organisationId"
                        component={renderDropDown}
                        type="text"
                        tooltip="Select organisation"
                        validate={[required]}
                        options={organisationOptions}
                        emptyOption={true}
                        emptyOptionLabel="Select organisation"
                        noSpace={true}
                    >
                    </Field>
                </div>
                <div className="col-md-4 align-items-center">
                    <Field
                        name="organisationPrivilege"
                        component={renderDropDown}
                        type="text"
                        tooltip="Select privilege"
                        validate={[required]}
                        options={privilegesOptions}
                        emptyOption={true}
                        emptyOptionLabel="Select privilege"
                        noSpace={true}
                    >
                    </Field>
                </div>

                <div className="col-md-2 col-12 align-items-center text-right">
                    <button type="button" className="btn btn-outline-success" onClick={() => {
                            valid(
                                fields, 
                                selectedOrganisation, 
                                selectedOrganisationPrivilege
                            ) && 
                            fields.push({ 
                                organisationId: selectedOrganisation, 
                                privilege: selectedOrganisationPrivilege 
                            })
                        }}><i className="icon icon-plus"></i> Add privilege</button>
                    {/*}
                    <IconButton
                        icon="icon-plus"
                        type="success"
                        tooltip="Add organisation"
                        tooltipplacement="top"
                        onClick={() => {
                            valid(
                                fields, 
                                selectedOrganisation, 
                                selectedOrganisationPrivilege
                            ) && 
                            fields.push({ 
                                organisationId: selectedOrganisation, 
                                privilege: selectedOrganisationPrivilege 
                            })
                        }}/>
                    */}
                </div>
            </div>
        </React.Fragment>
    )
}

let UserInviteComponent = ({
    selectedAccountPrivilege,
    selectedOrganisation,
    selectedOrganisationPrivilege,
    handleSubmit,
    errors, pristine, reset, submitting, organisations, accountPrivileges, organisationPrivileges }) => {

    const showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""

    const privileges = [{ id: 0, name: "User" }, ...accountPrivileges]


    return (
        <Card className="card-settings">
            <Header title="Invite user">
                <div className="highlight">
                    <span>Invite a person to join your account. Add an email address and select appropriate privileges.</span>
                </div>
            </Header>
            <Body>
                <form onSubmit={handleSubmit}>


                    <Field
                        name="mailAdress"
                        component={renderField}
                        type="text"
                        label="Email"
                        validate={[required, email]}
                        tooltip="Please enter email"
                    />

                    <Field
                        name="accountPrivilege"
                        component={renderDropDown}
                        type="text"
                        label="Role"
                        tooltip="Select account role"
                        className="form-group col-md-6 padding"
                        validate={[required]}
                        options={privileges}
                        emptyOption={true}
                        emptyOptionLabel="-- Select account role ---"
                    >
                    </Field>


                    <div className="note">
                        {showError}
                    </div>

                    {
                        selectedAccountPrivilege === 0 &&
                        <FieldArray
                            name="organisations"
                            organisations={organisations}
                            component={renderOrganisationSection}
                            privileges={organisationPrivileges}
                            validate={[requiredArray]}
                            tooltip="Please select an organisation"
                            selectedOrganisation={selectedOrganisation}
                            selectedOrganisationPrivilege={selectedOrganisationPrivilege}
                        />
                    }

                    <footer>
                        <button type="submit" className="btn btn-dark btn-lg" disabled={submitting}>
                            Send invitation
                        </button>
                    </footer>

                </form>
            </Body>
        </Card>
    )
}

const selector = formValueSelector("user-invite");

const afterSubmit = (result, dispatch) =>
  dispatch(reset('user-invite'));


UserInviteComponent = reduxForm({
    form: 'user-invite',
    onSubmitSuccess: afterSubmit
})(UserInviteComponent);

UserInviteComponent = connect(state => {
    const selectedAccountPrivilege = parseInt(selector(state, 'accountPrivilege'), 10)
    const selectedOrganisation = parseInt(selector(state, 'organisationId'), 10)
    const selectedOrganisationPrivilege = parseInt(selector(state, 'organisationPrivilege'), 10)

    return { selectedAccountPrivilege, selectedOrganisation, selectedOrganisationPrivilege }
})(UserInviteComponent)

export default UserInviteComponent