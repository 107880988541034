import moment from 'moment';

const  DateFormat = ({ date, format = "YYYY-MM-DD HH:mm:ss"}) => {
  if(!date) { return "-";}
  return (moment.utc(date)
              .local()
              .format(format))
}


export default DateFormat

