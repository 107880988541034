import * as api from '../api'

import { switchMap, catchError, map, mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'

import { handleError } from './common'

import {
    DELETE_ACCOUNT, deleteAccountSuccess, DELETE_ACCOUNT_SUCCESS, CHANGE_ACCOUNT_OWNER, CHANGE_ACCOUNT_OWNER_SUCCESS, changeAccountOwnerSuccess
} from '../actions/general'

import { refreshAuth } from '../actions/auth'
import { push } from 'connected-react-router';
import { showNotificationSuccess } from '../actions/notification';

export const deleteAccountEpic$ = action$ => {
    return action$.pipe(
        ofType(DELETE_ACCOUNT),
        switchMap((action) => {
            return api.deleteAccount$(action.model).pipe(
                map(ar => deleteAccountSuccess()),
                catchError(handleError(action$, action.type))
            )
        }
        )
    )
}

export const deleteAccountSuccessEpic$ = action$ => {
    return action$.pipe(
        ofType(DELETE_ACCOUNT_SUCCESS),
        mergeMap(action => of(refreshAuth(), push("/admin/accounts/new")))
    )
}

export const changeAccountOwnerEpic$ = action$ => {
    return action$.pipe(
        ofType(CHANGE_ACCOUNT_OWNER),
        switchMap((action) => {
            return api.changeAccountOwner$(action.model).pipe(
                map(ar => changeAccountOwnerSuccess()),
                catchError(handleError(action$, action.type))
            )
        }
        )
    )
}

export const changeAccountOwnerSuccessEpic$ = action$ => {
    return action$.pipe(
        ofType(CHANGE_ACCOUNT_OWNER_SUCCESS),
        mergeMap(action => of(refreshAuth(), push("/dashboard"), showNotificationSuccess("Account owner has been changed")))
    )
}