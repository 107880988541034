import React from 'react'
import { connect } from 'react-redux'
import SignUpComponent from '../components/signup/SignUp'
import { signUpUser } from '../actions/user'
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state) => ({
    isFetching: isFetching(state.fetching, signUpUser),
    errors: state.signup.errors
})

const mapDispatchToProps = dispatch => ({
    signup: (values) => dispatch(signUpUser(values))
})

const SignUpContainer = ({signup, isFetching, errors }) => {

    return <SignUpComponent onSubmit={signup} errors={errors} />
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer)