import React from "react";
import CbLogo from "../../images/favicon-196x196.png";
import { Link } from "react-router-dom";

const Download = ({ message, download }) => {
  let downloaded = message.substring(0, 6) === "Failed" ? false : true;
  return (
    <div>
      <div className="logo_login_placeholder">
        <img
          src={CbLogo}
          height="64px"
          alt="Cloudbuz Manager"
          className="logo_login"
        />
      </div>
      <div className="text-center p-3">
        <h1>Almost there!</h1>
        <h2>Now run the installer that you just downloaded.</h2>
        <p>Status: {!downloaded ? 
          <React.Fragment>
          <span className="text-danger">{message}.</span> 
          <span> Try again or contact customer support</span>
          <button className="btn btn-dark btn-lg btn-block mt-2 mb-2" onClick={(e) => download()}>
                    <i className="icon icon-download"></i> Download 32-bit x86
          </button>
          </React.Fragment>
          :
          <span className="text-success">Connector downloaded</span>
        }
        </p>
        <div className="card text-center">
          <div className="card-body">
            <h5 className="card-title"><i className="icon icon-windows"></i> Windows</h5>
            <p className="card-text">If you are prompted that the app you are trying to install is not a Microsoft-verified app, click "Install anyway" or change the app recommendation settings.</p>
          </div>
        </div>
      </div>

      <div className="text-center">
        <div className="row">
          <div className="col-md-12">
            <p>
              Don't have an account? <Link to="../signup">Sign up</Link>
            </p>
          </div>
        </div>
        </div>

    </div>
  );
};

export default Download;
