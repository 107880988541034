import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotificationError } from "../../actions/notification";
import {
  listPeriods$,
  getPeriodInfo$,
  getInvoiceUrl,
} from "../../api/subscriptions";
import { downloadFileUrl } from "../../common/downloadFile";
import LoaderComponent from "../../components/common/Loader";
import Invoices from "../../components/subscriptions/invoices/Invoices";

const InvoicesContainer = () => {
  const [periods, setPeriods] = useState();
  const [period, setPeriod] = useState();
  const [invoice, setInvoice] = useState();
  const dispatch = useDispatch();

  const onPeriodChange = useCallback((period) => {
    setPeriod(period);
    getPeriodInfo$(period.startPeriod, period.endPeriod).subscribe(
      (invoice) => {
        downloadFileUrl(
          getInvoiceUrl(period.startPeriod, period.endPeriod),
          "Invoice"
        ).then((result) => {
          setInvoice({ ...invoice, url: result });
        }).catch(err => {
          setInvoice(invoice);
          dispatch(showNotificationError("Failed to display invoice, please download your invoice"))
        });
      }
    );
  }, [dispatch]);

  useEffect(() => {
    listPeriods$().subscribe((periods) => {
      setPeriod(periods[0]);
      setPeriods(periods);
      onPeriodChange(periods[0]);
    });
  }, [onPeriodChange]);

  if (!periods || !periods.length) {
    return <LoaderComponent />;
  }

  return (
    <Invoices
      periods={periods}
      period={period}
      invoice={invoice}
      onPeriodChange={onPeriodChange}
    />
  );
};

export default InvoicesContainer;
