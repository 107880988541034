import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import qs from "query-string";
import RunsComponent from "../../../components/user/jobs/Runs";
import {
  showRunDetails,
  loadJobRuns,
  runsCurrentPageChange,
  executeActionOnRun,
  runsFilterChange,
} from "../../../actions/jobs";
import { isFetching } from "../../../selectors/Fetching";
import Progress from "../../../components/common/progress/Progress";
import RunStatusInfo from "../../../components/user/jobs/RunStatusInfo";
import { dateFormat } from "../../../common/dateFormatter";
import { withRouter } from "react-router";

const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state.fetching, loadJobRuns),
  runsView: state.jobs.runsView,
  currentPage: state.jobs.currentRunsPage || 1,
  cachedFilter: state.jobs.selectedRunsFilter,
});

const replaceStateQs = (qs) => {
  if (window.history.replaceState) {
    var newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }${qs ? `?${qs}` : ""}`;
    window.history.replaceState({ path: newurl }, "", newurl);
  }
};

const mapDispatchToProps = (dispatch) => ({
  onRowClick: (run, action) =>
    action === "select"
      ? dispatch(showRunDetails(run.sysJobId))
      : dispatch(executeActionOnRun(run.sysJobId, action)),
  initialState: (filters, currentPage) =>
    dispatch(loadJobRuns(filters, currentPage)),
  onFilterChange: (filter) => {
    replaceStateQs(qs.stringify(filter, { arrayFormat: "bracket" }));
    dispatch(runsFilterChange(filter));
  },
  onPageChange: (page) => dispatch(runsCurrentPageChange(page)),
  executeActionOnRun: (target = "list") => (jobRunId, action) =>
    dispatch(executeActionOnRun(jobRunId, action, target)),
});

const getProgressColor = (status) =>
  status === "1"
    ? "success"
    : status === "9999" || status === "99999"
    ? "danger"
    : status === "400"
    ? "warning"
    : "primary";
const getStatusType = (status) =>
  status === "1"
    ? "success"
    : status === "9999" || status === "99999"
    ? "error"
    : status === "400"
    ? "errorAcknowledged"
    : "inProgress";

const schema = [
  { accessor: "sysJobId", header: "Run ID" },
  { accessor: "name", header: "Name" },
  {
    type: "custom",
    header: "Progress",
    render: (data) => (
      <Progress
        value={data.progress}
        className={"bg-" + getProgressColor(data.statusId)}
        height="16px"
        fontSize="14px"
      />
    ),
  },
  {
    header: "Events",
    type: "custom",
    render: (data) => (
      <span>
        {data.totalHandeledEvents} of {data.totalEvents}
      </span>
    ),
  },
  {
    type: "custom",
    header: "Status",
    render: (data) => <RunStatusInfo type={getStatusType(data.statusId)} />,
  },
  { accessor: "start", header: "Start", type: "date" },
  { accessor: "stop", header: "Stop", type: "date" },
  {
    type: "custom",
    render: (row, schema, rowIndex, onRowClick) => {
      return row.statusId === "0" ? (
        ""
      ) : row.statusId === "9999" || row.statusId === "99999" ? (
        <button
          title="Acknowledge"
          className="btn btn-light btn-sm float-right"
          onClick={(e) => {
            e.stopPropagation();
            onRowClick(row, "acknowledge");
          }}
        >
          <span>
            <i className="icon icon-ok"></i> Acknowledge
          </span>
        </button>
      ) : (
        ""
      );
    },
  },
];

const StartStop = ({ data }) => {
  return (
    <React.Fragment>
      <div className="text-nowrap">
        <small>Start: {dateFormat(data.start)}</small>
      </div>
      <div className="text-nowrap">
        <small>Stop: {dateFormat(data.stop)}</small>
      </div>
    </React.Fragment>
  );
};

const schemaForDashboard = [
  { accessor: "name", header: "Name" },
  {
    type: "custom",
    header: "Progress",
    render: (data) => (
      <Progress
        value={data.progress}
        className={"bg-" + getProgressColor(data.statusId)}
        height="16px"
        fontSize="14px"
      />
    ),
  },
  {
    type: "custom",
    header: "Status",
    render: (data) => <RunStatusInfo type={getStatusType(data.statusId)} />,
  },
  {
    type: "custom",
    header: "Start / Stop",
    render: (data) => <StartStop data={data} />,
  },
  {
    type: "custom",
    render: (row, schema, rowIndex, onRowClick) => {
      return row.statusId === "0" ? (
        ""
      ) : row.statusId === "9999" || row.statusId === "99999" ? (
        <button
          title="Acknowledge"
          className="btn btn-light btn-sm float-right"
          onClick={(e) => {
            e.stopPropagation();
            onRowClick(row, "acknowledge");
          }}
        >
          <span>
            <i className="icon icon-ok"></i> Acknowledge
          </span>
        </button>
      ) : (
        ""
      );
    },
  },
];

const RunsContainer = ({
  initialState,
  isFetching,
  runsView,
  onFilterChange,
  onRowClick,
  startAutoRefresh,
  currentPage,
  onPageChange,
  executeActionOnRun,
  cancelAutoRefresh,
  useInDashboard,
  location,
  cachedFilter,
}) => {

  useEffect(() => {
    replaceStateQs(qs.stringify(cachedFilter, { arrayFormat: "bracket" }));
  }, [cachedFilter]);

  useEffect(() => {
    
    initialState(cachedFilter, currentPage);
    const interval = setInterval(() => {
      initialState(cachedFilter, currentPage);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [initialState, cancelAutoRefresh, cachedFilter, currentPage, startAutoRefresh]);

  const onFilter = useCallback((f) => onFilterChange(f), [onFilterChange]);

  const onRefresh = useCallback(() => {
    initialState(cachedFilter, currentPage);
  }, [currentPage, cachedFilter, initialState]);

  return (
    <RunsComponent
      isFetching={isFetching}
      selectedFilters={cachedFilter}
      schema={!useInDashboard ? schema : schemaForDashboard}
      view={runsView}
      onRowClick={onRowClick}
      onFilter={onFilter}
      onRefresh={onRefresh}
      currentPage={currentPage}
      onPageChange={onPageChange}
      executeActionOnRun={executeActionOnRun()}
      useInDashboard={useInDashboard}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RunsContainer)
);
