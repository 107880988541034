import * as api from "../api";

import {
  switchMap,
  catchError,
  map,
  takeUntil,
  take,
  mergeMapTo,
  merge,
  mergeMap,
  mapTo,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { of } from "rxjs";

import {
  LOGOUT,
  USER_LOGGEDOUT,
  LOGIN_USER,
  USER_FOUND,
  USER_CHANGE_PASSWORD,
  changePasswordSuccess,
  userFound,
  loginSuccess,
  loginFailed,
  logoutUser,
  REFRESH_TOKEN,
  refreshAuth,
  refreshUserInfoSuccess,
  logout,
  changePasswordFailed,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD,
  forgotPasswordSuccess,
  USER_FORGOT_PASSWORD_SUCCESS,
  REFRESH_USER_INFO,
  USER_CREATE_FORGOT_PASSWORD,
  createForgotPasswordSuccess,
  USER_CREATE_FORGOT_PASSWORD_SUCCESS,
  userTwoFactorValidation,
  forgotPasswordFailed,
} from "../actions/auth";

import { showNotificationSuccess } from "../actions/notification";

import * as authHelper from "../helpers/auth";
import {
  CHANGE_ACCOUNT,
  changeAccountSuccess,
  CHANGE_ACCOUNT_SUCCESS,
} from "../actions/user";
import { handleError } from "./common";
import { CREATE_ACCOUNT_SUCCESS } from "../actions/account";

export const userLogoutEpic = (action$) => {
  return action$.pipe(
    ofType(LOGOUT),
    map((action) => {
      authHelper.logout();
      return logoutUser(action.ref);
    })
  );
};

export const userLoggedOutEpic = (action$) => {
  return action$.pipe(
    ofType(USER_LOGGEDOUT),
    map((action) => push("/login", { referrer: action.ref }))
  );
};

export const userLoginEpic = (action$) => {
  return action$.pipe(
    ofType(LOGIN_USER),
    switchMap((action) => {
      return api.login$(action.data).pipe(
        map((apiResponse) => {
          if (apiResponse.response.twofactorEnabled) {
            console.log("apiResponse.response", apiResponse.response)
            return userTwoFactorValidation({
              ...action.data,
              twofactorEnabled: apiResponse.response.twofactorEnabled,
              qrCodeUrl: apiResponse.response.qrCodeUrl
            });
          }
          authHelper.save(apiResponse.response);
          return userFound();
        }),
        catchError((err) => {
          return of(loginFailed(err.response ? err.response.errors : null));
        })
      );
    })
  );
};

export const userInfoEpic = (action$) => {
  return action$.pipe(
    ofType(USER_FOUND),
    switchMap((user) => {
      return api.getUserInfo$().pipe(
        map((apiResponse) => {
          authHelper.saveUserInfo(apiResponse);
          return loginSuccess(
            {
              name: apiResponse.name,
              email: apiResponse.email,
              accountId: apiResponse.accountId,
              loginType: apiResponse.loginType,
            },
            apiResponse
          );
        }),
        catchError((err) => of(loginFailed(err.xhr.response.errors)))
      );
    })
  );
};

export const refreshUserInfoEpic = (action$) => {
  return action$.pipe(
    ofType(CREATE_ACCOUNT_SUCCESS, REFRESH_USER_INFO),
    switchMap((action) => {
      return api.getUserInfo$().pipe(
        map((apiResponse) => {
          authHelper.saveUserInfo(apiResponse);
          return refreshUserInfoSuccess(apiResponse);
        }),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const reAuthenticateEpic = (action$, store) =>
  action$.pipe(
    ofType(REFRESH_TOKEN),
    switchMap(() => {
      const { loginType } = store.value.auth.user;
      if (loginType === 2) {
        //AzureAd
        return api.acquireTokenSilent$().pipe(
          map((result) => {
            authHelper.save(result);
            return userFound();
          }),
          catchError((err) => of(logout(window.location.pathname)))
        );
      }

      return api.refreshAuth$().pipe(
        map((apiResponse) => {
          var user = authHelper.save(apiResponse.response);
          return userFound(user);
        }),
        catchError((err) => of(logout(window.location.pathname)))
      );
    })
  );

export const refreshToken = (action$, err, source) =>
  action$.pipe(
    ofType(USER_FOUND),
    takeUntil(action$.pipe(ofType(LOGOUT))),
    take(1),
    mergeMapTo(source),
    merge(of(refreshAuth()))
  );

export const changeAccountEpic = (action$, state) =>
  action$.pipe(
    ofType(CHANGE_ACCOUNT),
    switchMap((action) => {
      return api.changeAccount$(action.accountId).pipe(
        mergeMap((apiResponse) => {
          var user = authHelper.save(apiResponse.response);
          return of(userFound(user), changeAccountSuccess());
        }),
        takeUntil(action$.pipe(ofType(CHANGE_ACCOUNT_SUCCESS))),
        catchError(handleError(action$, action.type))
      );
    })
  );

export const changeAccountSuccessEpic = (action$) => {
  return action$.pipe(
    ofType(CHANGE_ACCOUNT_SUCCESS),
    mergeMap((action) => of(handleError(action$), push("/dashboard")))
  );
};

export const changePasswordEpic$ = (action$, state) =>
  action$.pipe(
    ofType(USER_CHANGE_PASSWORD),
    switchMap((action) => {
      return api
        .changePassword$(
          action.oldPassword,
          action.newPassword,
          action.newPasswordRetype
        )
        .pipe(
          map((p) => changePasswordSuccess()),
          catchError((a) => of(changePasswordFailed(a.response.errors)))
        );
    })
  );

export const changePasswordSuccessNotificationEpic$ = (action$) =>
  action$.pipe(
    ofType(USER_CHANGE_PASSWORD_SUCCESS),
    mapTo(showNotificationSuccess("Password was successful changed"))
  );

export const createForgotPasswordEpic$ = (action$) =>
  action$.pipe(
    ofType(USER_CREATE_FORGOT_PASSWORD),
    switchMap((action) => {
      return api.createForgotPassword$({ email: action.email }).pipe(
        map((r) => createForgotPasswordSuccess()),
        catchError(handleError(action$, action.type))
      );
    })
  );

export const createForgotPasswordSuccessEpic$ = (action$) =>
  action$.pipe(
    ofType(USER_CREATE_FORGOT_PASSWORD_SUCCESS),
    mergeMap((action) => of(handleError(action$), push("/login")))
  );

export const forgotPasswordEpic$ = (action$) =>
  action$.pipe(
    ofType(USER_FORGOT_PASSWORD),
    switchMap((action) => {
      return api.forgotPassword$(action.token, action.model).pipe(
        map((r) => {
          console.log("forgott password success");  
          return forgotPasswordSuccess();
        }),
        catchError((a) => of(forgotPasswordFailed(a.response.errors)))
      );
    })
  );

export const forgotPasswordSuccessEpic$ = (action$) =>
  action$.pipe(
    ofType(USER_FORGOT_PASSWORD_SUCCESS),
    mergeMap((action) =>
      of(
        showNotificationSuccess("Your password has been changed."),
        push("/login")
      )
    )
  );
