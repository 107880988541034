import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LeftSide from '../components/left-side/LeftSide';
import { getActiveAccount } from '../selectors/Auth'
import { toggleLeftMenu } from '../actions/menu' 

const mapStateToProps = (state) => {
    if (!state.auth.isAuthenticated) {
        return {
            isAuthenticated: false 
        }
    }

    return {
        isAuthenticated: state.auth.isAuthenticated,
        leftMenuOpen: state.menu.leftMenuOpen,
        user: {
            email: state.auth.user.email,
            name: state.auth.user.name,
            gravatarHash: state.auth.info.gravatarHash,
            activeAccount: getActiveAccount(state.auth),
            accounts: state.auth.info.accounts
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLeftMenu: (context) => dispatch(toggleLeftMenu(context))
    }
}

/*
const mapDispatchToProps = (dispatch) => {
    return {
        changeAccount: (accountId) => { dispatch(changeAccount(accountId)) }
    }
}*/

const LeftSideContainer = ({ isAuthenticated, user, leftMenuOpen, location, toggleLeftMenu }) => {
    return (
        !isAuthenticated ? "" : 
            <LeftSide user={user} location={location} isOpen={leftMenuOpen} toggleLeftMenu={toggleLeftMenu} />
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideContainer))
