import {
    GET_ORGANISATION_SUCCESS,
    EDIT_ORGANISATION,
    LIST_ORGANISATIONS_SUCCESS
}
from "../actions/organisation";
import { LIST_CREATE_USER_PRIVILEGES_SUCCESS } from "../actions/user";

export const organisation = (state = { }, action) => {
    switch (action.type) {
        case GET_ORGANISATION_SUCCESS:
            return {
                ...state,
                organisation: action.organisation,
            }
        case EDIT_ORGANISATION:
            return {
                ...state,
                organisation: null
            }
        case LIST_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                organisations:action.organisations
            }
        case LIST_CREATE_USER_PRIVILEGES_SUCCESS: 
            return {
                ...state,
                privileges: action.organisationPrivileges
            }
        default:
            return state
    }
}