import * as api from '../api'

import { switchMap, catchError, map, mapTo } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { push } from 'connected-react-router'

import { showNotificationSuccess } from '../actions/notification';

import { CREATE_CUSTOMER, createCustomerSuccess, CREATE_CUSTOMER_SUCCESS } from '../actions/customer';
import { handleError } from './common';

export const createCustomerEpic = action$ => {
    return action$.pipe(
        ofType(CREATE_CUSTOMER),
        switchMap((action) =>
            api.createCustomer$(action.model).pipe(
                map(ar => createCustomerSuccess(ar.response.accountId)),
                catchError(handleError(action$, action.type))
            )
        )
    )
}

export const createCustomerSuccessEpic = action$ => action$.pipe(
    ofType(CREATE_CUSTOMER_SUCCESS),
    map((action) => push('/account/' + action.accountId))
)

export const createCustomerSuccessNotificationEpic = action$ => action$.pipe(
    ofType(CREATE_CUSTOMER_SUCCESS),
    mapTo(showNotificationSuccess("Create customer was successful"))
)