import React from 'react'
import PageContent, { ContentBody, ContentHeader } from '../common/PageContent';
import RunsContainer from '../../containers/user/jobs/Runs'
import { NavLink } from 'react-router-dom'
import './Dashboard.css'


const ShortCuts = () => {
    return (
        <div>
            <h6><i className="icon icon-attention-circled"></i> Limited version of this application</h6>
            <div className="alert highlight">
                Since you are not an administrator of this Cloudbuz account you are limited to features and data decided by account administrators.
            </div>
            <ul>
                <li>
                    <span>Check out your personal settings like notifications, password, timezone and organizations </span>
                    <NavLink activeClassName="active" to="/settings/my-settings/general/">
                        @My settings
                    </NavLink>
                </li>
                <li>
                    <span>Monitor and overview job runs (executions of jobs) permitted by your account administrators</span>
                    <NavLink activeClassName="active" to="/admin/connectors/requestnewinstallment">
                        @Jobs/Runs
                    </NavLink>
                </li>
                <li>
                    <span>Create your own Cloudbuz account and enjoy a full featured version of this application</span>
                    <NavLink activeClassName="active" to="/admin/accounts/new">
                        @Create account
                    </NavLink>
                </li>
            </ul>
            <p>Happy file processing! </p>
            <p><i className="icon icon-logo_mark"></i> The Cloudbuz team</p>
        </div>
    ) 
}

const DashboardLimited = ({ accountId }) => {
    return (
        <PageContent>
            <ContentHeader title="Dashboard"/>
            <ContentBody>                
                <div className="row m-0">
                    <div className="col-xl-8 pl-0 no-p-on-xl">
                        <div className="col-12 pr-0 pl-0">
                            <div className="dashboardArea pb-4">
                                <h5>Job runs</h5>
                                <RunsContainer key={accountId} useInDashboard="1"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 pl-0 no-p-on-xl">
                        <div className="col-12 pr-0 pl-0">
                            <div className="dashboardArea pb-4">
                                <h5>Info</h5>
                                <ShortCuts/>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentBody>
        </PageContent>
    )
}

export default DashboardLimited;