import {
  LOGIN_USER,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  USER_LOGGEDOUT,
  REFRESH_USER_INFO_SUCCESS,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAILED,
  SET_CHANGE_PASSWORD_DATA,
  USER_FORGOT_PASSWORD,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAILED,
  USER_TWO_FACTOR_VALIDATION,
} from "../actions/auth";

import * as authHelper from "../helpers/auth";

let model = authHelper.get();

const { name, email, accountId, loginType, ...rest } = authHelper.getUserInfo();

export const auth = (
  state = {
    isAuthenticated: authHelper.isAuthenticated(model),
    user: { name, email, accountId, loginType },
    info: rest || { accounts: [], privileges: [], changePasswordErrors: [] },
  },
  action
) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        errors: null,
        message: null,
        data: action.data,
        authFailed: null
      };
    case USER_TWO_FACTOR_VALIDATION:
      return {
        ...state,
        data: action.data,
        fetching: null
      }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        message: null,
        errors: null,
        data: null,
        user: action.user,
        info: action.info,
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        authFailed: true,
        message: action.message,
        errors: action.errors,
        data: null,
        user: null,
      };
    }
    case REFRESH_USER_INFO_SUCCESS: {
      return {
        ...state,
        info: action.info,
      };
    }
    case USER_LOGGEDOUT:
      return {
        ...state,
        isAuthenticated: false,
        data: null,
        user: {},
      };
    case USER_FORGOT_PASSWORD:
      return {
        ...state,
      };
    case USER_CHANGE_PASSWORD:
      return {
        ...state,
      };
    case USER_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgottPasswordErrors: action.forgottPasswordErrors
      }
    case USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgottPasswordErrors: null
      };
    case USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordErrors: [],
      };
    case SET_CHANGE_PASSWORD_DATA:
      return {
        ...state,
        [action.key]: action.value,
      };
    case USER_CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordErrors: action.changePasswordErrors,
      };
    default:
      return state;
  }
};
