export const CREATE_CUSTOMER = "REQ_CREATE_CUSTOMER"
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS"

export const createCustomer = (model) => ({
    type: CREATE_CUSTOMER,
    model: model
})

export const createCustomerSuccess = (accountId) => ({
    type: CREATE_CUSTOMER_SUCCESS,
    accountId: accountId
})