export const LOAD_JOBS_LIST = 'REQ_LOAD_JOBS_LIST'
export const LOAD_JOBS_LIST_SUCCESS = 'LOAD_JOBS_LIST_SUCCESS'
export const LOAD_CREATE_JOBS = 'REQ_LOAD_CREATE_JOBS'
export const LOAD_CREATE_JOBS_SUCCESS = 'LOAD_CREATE_JOBS_SUCCESS'
export const SHOW_RUN_DETAILS = 'SHOW_RUN_DETAILS'
export const SHOW_EVENT_DETAILS = 'SHOW_EVENT_DETAILS'
export const SHOW_FILE_DETAILS = 'SHOW_FILE_DETAILS'
export const LOAD_SCHEDULE_SUCCESS = 'LOAD_SCHEDULE_SUCCESS'
export const LOAD_SCHEDULE = 'REQ_LOAD_SCHEDULE'
export const LOAD_JOB_NOTIFICATION_USERS = 'REQ_LOAD_JOB_NOTIFICATION_USERS'
export const LOAD_JOB_NOTIFICATION_USERS_SUCCESS = 'LOAD_JOB_NOTIFICATION_USERS_SUCCESS'
export const LOAD_JOB_RUNS = 'REQ_LOAD_JOB_RUNS'
export const LOAD_JOB_RUNS_SUCCESS = 'LOAD_JOB_RUNS_SUCCESS'
export const LOAD_JOB_RUN = 'REQ_LOAD_JOB_RUN'
export const LOAD_JOB_RUN_SUCCESS = 'LOAD_JOB_RUN_SUCCESS'
export const LOAD_JOB_RUN_EVENT = "REQ_LOAD_JOB_RUN_EVENT"
export const LOAD_JOB_RUN_EVENT_SUCCESS = "LOAD_JOB_RUN_EVENT_SUCCESS"
export const LOAD_JOB_RUN_FILE = "REQ_LOAD_JOB_RUN_FILE"
export const LOAD_JOB_RUN_FILE_SUCCESS = "LOAD_JOB_RUN_FILE_SUCCESS"
export const FILE_FILTER_CHANGED = "FILE_FILTER_CHANGED"
export const RUNS_FILTER_CHANGE = "RUNS_FILTER_CHANGE"
export const RUNS_CURRENTPAGE_CHANGE = "RUNS_CURRENTPAGE_CHANGE"
export const EXECUTE_ACTION_ON_RUN = "EXECUTE_ACTION_ON_RUN"
export const LOAD_JOB_VIEW = "REQ_LOAD_JOB_VIEW"
export const LOAD_JOB_VIEW_SUCCESS = "LOAD_JOB_VIEW_SUCCESS"
export const JOBS_CURRENTPAGE_CHANGE = "JOBS_CURRENTPAGE_CHANGE"
export const EXECUTE_ACTION_ON_JOB = "EXECUTE_ACTION_ON_JOB"

export const SAVE_JOB = 'REQ_SAVE_JOB'
export const SAVED_JOB_SUCCESS = 'SAVE_JOB_SUCCESS'

export const LOAD_JOB = 'REQ_LOAD_JOB'
export const LOAD_JOB_SUCCESS = 'LOAD_JOB_SUCCESS'

export const NEW_JOB = 'NEW_JOB'

export const TOGGLE_ACTIVE_JOB = 'REQ_TOGGLE_ACTIVE_JOB'
export const TOGGLE_ACTIVE_JOB_SUCCESS = 'TOGGLE_ACTIVE_JOB_SUCCESS'

export const DELETE_JOB = 'REQ_DELETE_JOB'
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS'

export const COPY_JOB= 'REQ_COPY_JOB'
export const COPY_JOB_SUCCESS = 'COPY_JOB_SUCCESS'

export const INIT_JOB = 'INIT_JOB'

export const CREATE_NEW_JOB = 'CREATE_NEW_JOB'

export const copyJob = (id) => ({
    type: COPY_JOB,
    id
})

export const copyJobSuccess = () => ({
    type: COPY_JOB_SUCCESS
})

export const toggleActiveJob = (jobId) => ({
    type: TOGGLE_ACTIVE_JOB,
    jobId
})

export const toggleActiveJobSuccess = () => ({
    type: TOGGLE_ACTIVE_JOB_SUCCESS
})


export const deleteJob = (jobId) => ({
    type: DELETE_JOB,
    jobId
})

export const deleteJobSuccess = () => ({
    type: DELETE_JOB_SUCCESS
})

export const loadJobNotificationUsers = () => ({
    type: LOAD_JOB_NOTIFICATION_USERS

})

export const loadJobNotificationUsersSuccess = (accountUsers, users) => ({
    type: LOAD_JOB_NOTIFICATION_USERS_SUCCESS,
    accountUsers, 
    users
})

export const loadJobsList = (page, text, status, sortBy) => ({
    type: LOAD_JOBS_LIST,
    page, text, status, sortBy
})

export const loadJobsListSuccess = (jobsView) => ({
    type: LOAD_JOBS_LIST_SUCCESS,
    jobs: jobsView.jobs,
    totalCountRows: jobsView.totalCountRows,
    totalPages: jobsView.totalPages
})

export const loadCreateJobs = () => ({
    type: LOAD_CREATE_JOBS
})

export const loadCreateJobsSuccess = (schedules) => ({
    type: LOAD_CREATE_JOBS_SUCCESS,
    schedules
})

export const loadSchdule = (scheduleId) => ({
    type: LOAD_SCHEDULE,
    scheduleId
})

export const showRunDetails = (runId) => ({
    type: SHOW_RUN_DETAILS,
    runId
})

export const showEventDetails = (runId, eventId) => ({
    type: SHOW_EVENT_DETAILS,
    runId,
    eventId
})

export const showFileDetails = (runId, eventId, fileId, fileSubId) => ({
    type: SHOW_FILE_DETAILS,
    runId, eventId, fileId, fileSubId
})

export const loadSchduleSuccess = (schedule) => ({
    type: LOAD_SCHEDULE_SUCCESS,
    schedule
})

export const loadJobRuns = (filters, currentRunsPage) => ({
    type: LOAD_JOB_RUNS,
    filters, currentRunsPage
})

export const loadJobRunsSuccess = (runsView) => ({
    type: LOAD_JOB_RUNS_SUCCESS,
    runsView
})

export const loadJobRun = (runId) => ({
    type: LOAD_JOB_RUN,
    runId
})

export const loadJobRunSuccess = (run) => ({
    type: LOAD_JOB_RUN_SUCCESS,
    run
})

export const loadJobRunEvent = (runId, eventId, filterFilesId, currentFilesPage) => ({
    type: LOAD_JOB_RUN_EVENT,
    runId, eventId, filterFilesId, currentFilesPage
})

export const loadJobRunEventSuccess = (event) => ({
    type: LOAD_JOB_RUN_EVENT_SUCCESS,
    event
})

export const loadJobRunFile = (runId, eventId, fileId, fileSubId) => ({
    type: LOAD_JOB_RUN_FILE,
    runId, eventId, fileId, fileSubId
})

export const loadJobRunFileSuccess = (file) => ({
    type: LOAD_JOB_RUN_FILE_SUCCESS,
    file
})

export const fileFilterChanged = (fileFilterId, runId, eventId, currentFilesPage) => ({
    type: FILE_FILTER_CHANGED,
    fileFilterId, runId, eventId, currentFilesPage
});

export const runsFilterChange = (selectedRunsFilter) => ({
    type: RUNS_FILTER_CHANGE,
    selectedRunsFilter
})

export const runsCurrentPageChange = (currentRunsPage) => ({
    type: RUNS_CURRENTPAGE_CHANGE,
    currentRunsPage
})

export const executeActionOnRun = (jobRunId, action, target) => ({
    type: EXECUTE_ACTION_ON_RUN,
    jobRunId, action, target
})

export const loadJobView = (currentJobsAllPage, text, sortAllBy) => ({
    type: LOAD_JOB_VIEW,
    currentJobsAllPage, text,sortAllBy
})

export const loadJobViewSuccess = (jobsView) => ({
    type: LOAD_JOB_VIEW_SUCCESS,
    jobsView
})

export const jobsCurrentPageChange = (currentJobsAllPage) => ({
    type: JOBS_CURRENTPAGE_CHANGE,
    currentJobsAllPage
})

export const executeActionOnJob = (jobId, action, currentJobsPage) => ({
    type: EXECUTE_ACTION_ON_JOB,
    jobId, action, currentJobsPage
})

export const saveJob = (jobForm) => ({
    type: SAVE_JOB,
    request: jobForm
})

export const savedJobSuccess = () => ({
    type: SAVED_JOB_SUCCESS
})

export const loadJob = (id) => ({
    type: LOAD_JOB,
    id
})

export const createNewJob = (job) => ({
    type: CREATE_NEW_JOB
})

export const loadJobSuccess = (job) => ({
    type: LOAD_JOB_SUCCESS,
    job
})

export const initJob = data => ({ type: INIT_JOB, data })