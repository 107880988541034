import React from 'react'
import './Login.css'
import CbLogo from "../images/logo_white.png";

const PrivacyPage = () => {
    return (
        <div className="login-wrapper">
            <div className="terms align-self-center flex-fill d-flex justify-content-center">
                <div className="text-white">
                <img
                        src={CbLogo}
                        height="30px"
                        alt="Cloudbuz Manager"
                        className="logo_login"
                        />
                    <hr className="border border-white"></hr>
                <h4>Privacy Policy</h4>

<p className="mt-3">
<i><em>Effective: July 19, 2020</em></i>
</p>
<p>
    We care about our customers' integrity and security. Our Data Protection
    Policy explains how we collect and use your personal information and what
    rights you have towards us.
</p>
<p>
    We work continuously to improve our process and the handling of your
    personal data. This means that we may update our Data Protection Policy and
    we recommend that you read the document at regular intervals. In the event
    of significant changes to the policy, we always inform our customers.
</p>
<h1>
    What is personal data and what is the processing of personal data?
</h1>
<p>
    Personal information is all kinds of information that can be directly or
    indirectly attributed to a physically living person. For example, images
    and sound recordings that are processed on a computer can be personal data
    even if no names are mentioned. Every action taken with personal data
    constitutes a processing, regardless of whether it is performed
    automatically or not. Examples of common processing are collection,
    registration, organization, structuring, storage, processing, transfer and
    deletion.
</p>
<h1>
    Who is responsible for the personal data we collect?
</h1>
<p>
    When you become a customer of Zavanto AB, org. no. 556752-5836, you accept
    the processing described in our Data Protection Policy. Zavanto AB is the
    Personal Data Manager for the information we handle about you as a
    customer. That relationship is governed by our general terms and conditions
    and this Data Protection Policy.
</p>
<h1>
    How do we handle your personal information?
</h1>
<p>
    Zavanto AB registers and handles personal data in cases where it is
    required for us to be able to deliver a good experience and a functioning
    service to you as a customer, or to fulfill agreements and obligations
    according to law. The information is stored for as long as we need it to
    meet the above requirements.
</p>
<h1>
    What information about you do we collect and why?
</h1>
<p>
    In connection with you entering into a service agreement with Zavanto AB,
    we need the following information: Name, organization number, e-mail
    address, telephone number and postal address. We handle and save this
    information in order to invoice you as a customer in a correct and legal
    manner.
</p>
<p>
    When an organization adds other contact information or gives more users
    access to their account, that information is also saved.
</p>
<p>
    We may send e-mails to all customers, for example notifications of
    malfunctions or changes in terms of use. Such messages are sent to the
    e-mail address specified by the customer.
</p>
<p>
    We may also send out emails about product news or information that we
    believe you as a customer are interested in. You can always opt out of such
    mailings from us by clicking on "unsubscribe" at the end of each email.
</p>
<p>
    We also document which IP addresses make changes (logins, updates, etc.)
    and operate cloudbuz.com and zavanto.se to be able to prevent misuse of a
    service or to avoid, prevent and investigate crimes against the company and
    / or the customer.
</p>
<h1>
    Voluntary information
</h1>
<p>
    We save the first and last name (as well as in some cases the organization
    number and name of the organization you represent) you enter in various
    contact forms on zavanto.se and/or cloudbuz.com. It can, for example, be an
    expression of interest for a webinar or registration to take part in
    various offers and promotions. We do this to be able to perform our
    services and to maintain smooth communication.
</p>
<p>
    When you contact our support, the personal information you enter is saved.
    This includes in addition to the e-mail address you email us from personal
    information in text format, such as a telephone number you wish to be
    called. We save the information to be able to offer a personal and relevant
    support service at future contact times.
</p>
<p>
    You can also communicate with us through social media. When you do this,
    the respective platform's data protection policy (or equivalent policy)
    applies. We use social media and the information you provide is on a par
    with what we are allowed to do according to their policy.
</p>
<h1>
    Automatically collected data
</h1>
<p>
    When you use our services, we save and manage data about how you use them.
    In some cases, the information is shared with selected third-party
    providers of marketing and analytics tools, in order to offer you a better
    experience. This can be done by using cookies. Read our Cookie Policy.
</p>
<p>
    Examples of other automatically collected data include:
</p>
<p>
    · IP addresses from logs in the systems we use to manage our
    infrastructure.
</p>
<p>
    · Information in logs about the type of device you are connecting from. The
    information may not identify you personally but in combination with other
    data can potentially do so.
</p>
<p>
    · Information from social media in accordance with the owner company's
    policy.
</p>
<h1>
    Does Zavanto AB have a Personal Data Assistant Agreement?
</h1>
<p>
    Yes. To make it easier for you as a customer in your work with GDPR, we
    have developed a Personal Data Assistant Agreement based on the IT &amp;
    Telecom companies' terms and conditions "Special provisions for the
    processing of personal data in connection with Cloud Services" which you
    can sign with us.
</p>
<p>
    Contact support@cloudbuz.com if you have questions about the Personal Data
    Assistant Agreement.
</p>
<h1>
    What rights do I have as a customer?
</h1>
<p>
    As a customer of Zavanto AB, you always have the right to take part of the
    information we have stored about you. You create your request via our
    support website and we usually process it within 30 days. If we notice that
    it will take longer, you will be informed why and if there are any other
    obstacles.
</p>
<p>
    To ensure that we share the information with the right person, you as a
    customer must log in to the support web and create a case.
</p>
<p>
    You have the right to ask us to delete all information we have stored about
    you (The right to be forgotten). Note that due to the Accounting Act (1999:
    1078) and other relevant laws, we sometimes have to save information even
    after such a request has been handled.
</p>
<h1>
    Supervisory authority
</h1>
<p>
    If you want to make a complaint concerning Zavantos' handling of personal
    data, you should contact the supervisory authority Datainspektionen. You
    can find their contact information at datainspektionen.se.
</p>

                </div>
            </div> 
        </div>
    )
}

export default PrivacyPage