import {
    LOAD_GENERAL_SETTINGS_SUCCESS,
    SET_FORM_DATA_GENERAL_SETTINGS,

    UPDATE_GENERAL_SETTINGS_SUCCESS,
    LEAVE_ORGANISATION_SUCCESS,
    
    LEAVE_ACCOUNT_SUCCESS,
    UPDATE_GENERAL_SETTINGS_FAILED

} from '../actions/generalsettings'

export const generalsettings = (state = { }, action) => {
    switch (action.type) {
        case UPDATE_GENERAL_SETTINGS_SUCCESS:
        case LEAVE_ORGANISATION_SUCCESS:
        case LEAVE_ACCOUNT_SUCCESS:
            return {
                ...state,
                generalSettingsError: undefined
            }
        case UPDATE_GENERAL_SETTINGS_FAILED:
            return {
                ...state,
                generalSettingsError: action.generalSettingsError
            }
        case LOAD_GENERAL_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.settings
            }

        case SET_FORM_DATA_GENERAL_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.key]: action.value
                }

            }
        default:
            return state
    }
}