import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderDropDown, renderField } from '../common/ReduxFormHelper'
import { required, email } from '../common/ReduxFormFieldValidation';
import Card, { Header, Body } from '../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';

const CreateRequestNewInstallment = ({
    connectorTypes,
    organisations,
    submitting,
    handleSubmit }) => {
    
    for(var i=0; i < connectorTypes.length; i++) {
        connectorTypes[i].name = connectorTypes[i].name.replace(/Fileserver connector/g, 'File server connector for Windows');
        }
    
    return (
        <PageContent>
            <ContentHeader title="Network / Connectors"/>
            <ContentBody>
                <Card>
                    <Header title="File server connector installation request">
                        <div className="alert highlight" role="alert">
                            <span>
                                If you want to install a connector on a server you can not access, just send the server admin a request by completeing this form. The receiver will get an e-mail with installation media and instructions. The request is valid for one installation and registration. You will be notified when the receiver has installed and registreed the connector. You can always revoke the request here.
                            </span>
                        </div>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit}>
                            <Field name="installersEmail" component={renderField} type="text" label="Server admin e-mail (the person installing the connector)" placeholder="name@company.com" validate={[required, email]} />
                            <Field name="message" component={renderField} type="text" label="Your message to the server admin" placeholder="Add a personal message.." />
                            <Field component={renderDropDown} name="connectorType" options={connectorTypes.filter(connectorTypes => parseInt(connectorTypes.id,10) === 10)} label="Installer" emptyOption={true} validate={[required]} />
                            <Field component={renderDropDown} name="organisation" options={organisations} label="Select organisation (connector will be registered on this organisation)" emptyOption={true} validate={[required]} />
                            <button type="submit" className="btn btn-primary btn-lg" disabled={submitting} >
                                <span className="icon icon-paper-plane"></span> Send request
                            </button>
                        </form>
                    </Body>
                </Card> 
            </ContentBody>
        </PageContent>
    )
}


export default reduxForm({
    form: 'create-new-installment'
})(CreateRequestNewInstallment)
