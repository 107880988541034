import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import {
    loadExploreView,
    navigateExploreView,
    searchInExplore,
    exploreViewChanged,
    exploreActionSelected,
    executeExploreCommand,
    resetExploreView,
    exploreChangePage
} from '../../../actions/explore'
import { isFetching } from '../../../selectors/Fetching'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators'


import ExploreSelect from './ExploreSelect';
import ExploreDefault from './ExploreDefault';

import { getActiveAccount } from '../../../selectors/Auth'
import downloadFile from '../../../common/downloadFile';
import { showNotificationError, showNotificationSuccess } from '../../../actions/notification';

const mapStateToProps = (state, ownProps) => ({
    view: state.explore.view,
    isFetching: isFetching(state.fetching, loadExploreView, searchInExplore),
    currentPage: state.explore.currentPage || 1,
    isSearching: state.explore.isSearching,
    sortBy: state.explore.sortBy,
    searchText: state.explore.searchText,
    command: state.explore.command,
    activeAccount: getActiveAccount(state.auth),
    initParams: ownProps.mode === "select" ? {
        id: state.explore.id || ownProps.initParams.id,
        shareId: state.explore.shareId || ownProps.initParams.shareId,
        type: state.explore.type || ownProps.initParams.type
    } : ownProps.initParams
})

const onSearchSub$ = new Subject()
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())
    .pipe(filter(f => f && f.length > 3));

const locked = (item, next) => {
    return !item.isLockedByJob && next();
}


const mapDispatchToProps = (dispatch, ownProps) => ({
    onRowClick: (mode, currentPage, sortBy) =>
        (item) => locked(item, () => item.type === "File" && !item.parent ? 
                    dispatch(exploreViewChanged({...item, isSelected: !item.isSelected }, item)) :
                mode === "explore" ?
                    dispatch(navigateExploreView(item.id, item.shareId, item.type, item.parent)) :
                    dispatch(loadExploreView(item.id, item.shareId, item.type, currentPage, sortBy, ownProps.filters))
            ),
    initialState: (id, shareId, type, currentPage, sortBy, mode) =>
        {
            console.log("init load")
            return dispatch(loadExploreView(id, shareId, type, currentPage, sortBy, mode, ownProps.filters))
        },
    onPageChange: (id, shareId, type, sortBy) => (currentPage) =>
        dispatch(exploreChangePage(id, shareId, type, currentPage, sortBy, ownProps.filters)),
    onSearchChange: (value) => dispatch(searchInExplore(value, ownProps.filters)),
    onSortChange: (id, shareId, type, currentPage) => (sortBy) =>
        dispatch(loadExploreView(id, shareId, type, currentPage, sortBy, ownProps.filters)),
    onChange: (newItem, oldItem) =>
        dispatch(exploreViewChanged(newItem, oldItem)),
    onAction: (sourceId) => (command) => {
        dispatch(exploreActionSelected(command ? { ...command, sourceId } : null))
    },
    executeCommand: (targetId) => (command) =>
        dispatch(executeExploreCommand(targetId,
            {
                ...command,
                items: command.items?.map(c => ({ id: c.id, type: c.type }))
            })
        ),
    reset: () => dispatch(resetExploreView()),
    download: () => {
        downloadFile(
          process.env.REACT_APP_DOWNLOAD_CONNECTOR_URL,
          "FileServerConnector_Setup.exe"
        )
          .then((msg) => {
            dispatch(showNotificationSuccess(msg));
          })
          .catch((msg) => {
            dispatch(showNotificationError(msg));
          });
        }
})

const renderMode = (mode) => props => mode === "select" ?
    <ExploreSelect {...props} /> : <ExploreDefault {...props} />;

const ExploreContainer = ({
    initialState,
    isFetching,
    view,
    onRowClick,
    currentPage,
    onPageChange,
    onSearchChange,
    searchText,
    isSearching,
    onSortChange,
    sortBy,
    onChange,
    command,
    onAction,
    executeCommand,
    mode,
    onSelect,
    initParams,
    reset,
    filters,
    selectName,
    activeAccount,
    download,
    match
}) => {

    return renderMode(mode)({
        initialState,
        onSearchChange,
        view,
        onRowClick: onRowClick(mode, currentPage, sortBy),
        currentPage,
        onPageChange,
        searchText,
        onSortChange,
        sortBy,
        onChange,
        command,
        onAction,
        executeCommand,
        isSearching,
        onSelect,
        onSearchSub$,
        isFetching,
        initParams,
        reset,
        filters,
        selectName,
        activeAccount,
        download,
        match
    }
    )
}

ExploreContainer.propTypes = {
    mode: PropTypes.oneOf(["explore", "select"])
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreContainer)