export const LIST_ACCOUNT_USERS = "REQ_LIST_ACCOUNT_USERS"
export const LIST_PENDING_USERS = "REQ_LIST_PENDING_USERS"
export const LIST_ORGANISATION_USERS = "REQ_LIST_ORGANISATION_USERS"

export const LIST_ACCOUNT_USERS_SUCCESS = "LIST_ACCOUNT_USERS_SUCCESS"
export const LIST_ACCOUNT_USERS_FAILED = "LIST_ACCOUNT__USERS_FAILED"
export const LIST_ORGANISATION_USERS_SUCCESS = "LIST_ORGANISATION_USERS_SUCCESS"
export const LIST_ORGANISATION_USERS_FAILED = "LIST_ORGANISATION_USERS_FAILED"

export const LIST_PENDING_USERS_SUCCESS = "LIST_PENDING_USERS_SUCCESS"
export const EDIT_USER = "EDIT_USER"
export const CREATE_USER = "CREATE_USER"
export const SAVE_USER = "REQ_SAVE_USER"
export const GET_USER = "REQ_GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILED = "GET_USER_FAILED"
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS"
export const SAVE_USER_FAILED = "SAVE_USER_FAILED"
export const LIST_CREATE_USER_PRIVILEGES = "REQ_LIST_CREATE_USER_PRIVILEGES"
export const LIST_CREATE_USER_PRIVILEGES_SUCCESS = "LIST_CREATE_USER_PRIVILEGES_SUCCESS"
export const LIST_CREATE_USER_PRIVILEGES_FAILED = "LIST_CREATE_USER_PRIVILEGES_FAILED"
export const USER_NOTIFICATION_FETCHED_SUCCESS = "USER_NOTIFICATION_FETCHED_SUCCESS"
export const CHANGE_ACCOUNT = "REQ_CHANGE_ACCOUNT"
export const CHANGE_ACCOUNT_SUCCESS = "CHANGE_ACCOUNT_SUCCESS"
export const USER_FETCH_NOTIFICATIONS = 'USER_FETCH_NOTIFICATIONS'
export const USER_SIGNUP = 'REQ_USER_SIGNUP';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR'
export const signUpUser = (model) => ({
    type: USER_SIGNUP,
    model
})

export const signUpUserSuccess = (model) => ({
    type: USER_SIGNUP_SUCCESS,
    model
})

export const signUpUserError = (errors) => ({
    type: USER_SIGNUP_ERROR,
    errors
})

export const getUser = (accountId, userId) => ({
    type: GET_USER,
    accountId,
    userId
})

export const getUserSuccess = (user) => ({
    type: GET_USER_SUCCESS,
    user
})

export const getUserFailed = (error) => ({
    type: GET_USER_FAILED,
    error
})

export const editUser = (accountId, userId) => ({
    type: EDIT_USER,
    accountId,
    userId
})

export const listAccountUsers = (accountId) => ({
    type: LIST_ACCOUNT_USERS,
    accountId
})

export const listOrganisationUsers = (organisationId) => ({
    type: LIST_ORGANISATION_USERS,
    organisationId
})

export const listPendingAccountUsers = () => ({
    type: LIST_PENDING_USERS
})

export const listUsersFailed = (error) => ({
    type: LIST_ORGANISATION_USERS_FAILED,
    error
})

export const listOrganisationUsersSuccess = (users) => ({
    type: LIST_ORGANISATION_USERS_SUCCESS,
    users
})

export const listAccountUsersFailed = (error) => ({
    type: LIST_ACCOUNT_USERS_FAILED,
    error
})

export const listAccountUsersSuccess = (users) => ({
    type: LIST_ACCOUNT_USERS_SUCCESS,
    users
})

export const listPendingUsersSuccess = (users) => ({
    type: LIST_PENDING_USERS_SUCCESS,
    users
})

export const revokedUserInviteSuccess = () => ({
    type: LIST_PENDING_USERS
})

export const saveUser = (user) => ({
    type: SAVE_USER,
    user
})

export const saveUserSuccess = (accountId, userId) => ({
    type: SAVE_USER_SUCCESS,
    accountId,
    userId
})

export const saveUserFailed = (error) => ({
    type: SAVE_USER_FAILED,
    error
})

export const createUser = (accountId, user) => ({
    type: CREATE_USER,
    user,
    accountId
})

export const listCreateUserPrivileges = () => ({
    type: LIST_CREATE_USER_PRIVILEGES
})

export const changeAccount = (accountId) => ({
    type: CHANGE_ACCOUNT,
    accountId
});

export const changeAccountSuccess = () => ({
    type: CHANGE_ACCOUNT_SUCCESS
});


export const listCreateUserPrivilegesSuccess = (accountPrivileges, organisationPrivileges) => ({
    type: LIST_CREATE_USER_PRIVILEGES_SUCCESS,
    accountPrivileges,
    organisationPrivileges
})

export const listCreateUserPrivilegesFailed = (error) => ({
    type: LIST_CREATE_USER_PRIVILEGES_FAILED,
    error
})

export const userNotificationFetchedSuccess = (notifications) => ({
    type: USER_NOTIFICATION_FETCHED_SUCCESS,
    notifications
})

export const fetchNotifications = () => ({
    type: USER_FETCH_NOTIFICATIONS
})