import React, { useCallback, useEffect, useRef, useState } from "react";
import Table from "../common/table/Table";
import Privilege from "../../containers/Privilege";
import { NavLink } from "react-router-dom";
import Card, { Body, Header } from "../common/Card";
import qs from "query-string";

const ConnectorsFreeTextFilter = ({ text, onChange }) => {
  const ref = useRef();

  return (
    <div className="input-group">
      <input
        ref={ref}
        className="form-control form-control-lg"
        type="text"
        placeholder="Filter this list, type something.."
        defaultValue={text}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <div className="input-group-append">
        <span
          className="input-group-text pointer"
          onClick={(e) => {
            onChange("");
            ref.current.value = "";
          }}
        >
          clear
        </span>
      </div>
    </div>
  );
};

const ConnectorsList = ({
  connectors,
  onConnectorSelect,
  schema,
  download,
}) => {
  const [cons, setConnectors] = useState(connectors);

  useEffect(() => {
    if (!connectors || !connectors.length) {
      return;
    }

    const filter = qs.parse(window.location.search, {
      arrayFormat: "bracket",
      parseBooleans: true,
      parseNumbers: true,
    });

    console.log("filter", filter);

    if (filter && filter.statuses) {
      setConnectors(
        connectors.filter((c) => filter.statuses.includes(c.status))
      );
      return;
    }

    setConnectors(connectors);
  }, [connectors]);

  const onFilter = useCallback(
    (text) => {
      if (!text) {
        setConnectors(connectors);
        return;
      }

      setConnectors(
        connectors.filter(
          (c) =>
            (c.description ?? "").toLowerCase().includes(text) ||
            (c.name ?? "").toLowerCase().includes(text)
        )
      );
    },
    [connectors]
  );

  return (
    <Card>
      <Header title="Connectors in my network">
        <div className="alert highlight" role="alert">
          {cons.length < 1 ? (
            <React.Fragment>
              <h6>No connectors registered on this account?</h6>
              <span>
                Seems like there are no connectors registered on this account?
                To start transferring files you need at least one connector
                installed and registered.
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h6>Install and register a new connector?</h6>
            </React.Fragment>
          )}

          <Privilege type="Network extend page" privilege="read">
            <p> There are two ways to do this:</p>
            <ul>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/admin/connectors/requestnewinstallment"
                >
                  Send an install request to a server administrator
                </NavLink>
                - install and register with a token included in the request
                e-mail.
              </li>
              <li>
                Download installer from this page - install and register with
                username and password.
              </li>
            </ul>
            <hr></hr>
            <button className="btn btn-dark" onClick={(e) => download()}>
              <i className="icon icon-download"></i> Download 32-bit x86
            </button>
          </Privilege>
        </div>
        <ConnectorsFreeTextFilter onChange={onFilter} />
      </Header>
      <Body>
        <div className="table-responsive table-full-width">
          <Table
            data={cons}
            schema={schema}
            onRowClick={onConnectorSelect}
            infoIfEmpty="No connectors installed.."
          />
        </div>
      </Body>
    </Card>
  );
};

export default ConnectorsList;
