import React from 'react'
import './Login.css'
import SignupCreated from '../components/signup/SignupCreated';

const SignUpCreated = ({ location }) => {
    return (
        <div className="login-wrapper">
            <div className="login align-self-center flex-fill">
                <SignupCreated email={location.state.email} />
            </div>
        </div>
    )

}

export default SignUpCreated