
import {
    USER_SIGNUP_ERROR,
    USER_SIGNUP_SUCCESS
} from '../actions/user'

export const signup = (state = { user: null, notifications: [], users: [], pending: [] }, action) => {
    switch (action.type) {
        case USER_SIGNUP_ERROR:
            return {
                ...state,
                errors: action.errors
            }
        case USER_SIGNUP_SUCCESS:
            return {
                ...state,
                errors:null
            }
        default: return state
    }
};