import { get$ as get, post$, url } from "./http";

import qs from "query-string";

export const list$ = () => {
  return get("/subscriptions");
};

export const get$ = (id) => {
  if (id) {
    return get("/subscriptions/" + id);
  }
  return get("/subscriptions/my");
};

export const changePlan$ = (id, stripePaymentMethod = null) => {
  return post$("/subscriptions/my", { id, stripePaymentMethod });
};

export const getBillingInfo$ = () => {
  return get("/subscriptions/billinginfo");
};

export const saveBillingInfo$ = (info) => {
  return post$("/subscriptions/billinginfo", info);
};

export const changeOnDemand$ = (info) => {
  return post$("/subscriptions/billinginfo/ondemand", info);
};

export const listPeriods$ = () => {
  return get("/subscriptions/periods");
};

export const listPeriodSummaries$ = (start, end) => {
  return get(
    "/subscriptions/periods/summaries?" + qs.stringify({ start, end })
  );
};

export const getPeriodSummary$ = (start, end) => {
  return get("/subscriptions/periods/summary?" + qs.stringify({ start, end }));
};

export const getPeriodInfo$ = (start, end) => {
  return get("/subscriptions/periods/info?" + qs.stringify({ start, end }));
};

/* stripe */

export const getPaymentMethod$ = () => {
  return get("/subscriptions/paymentmethod");
};

export const getPreviewInvoice$ = (subscriptionId) => {
  return get("/subscriptions/invoice/preview/" + subscriptionId);
};


export const getInvoiceUrl = (start, end) => {
  return url("/subscriptions/invoice?" + qs.stringify({ start, end }));
};
