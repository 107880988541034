import React from 'react'
import './Card.css'
const Card = ({ className, children }) => {
    return (
        <div className={(className || "") + " content-card"}>
            {children}
        </div>);
}

export const Header = ({ title, children }) => {
    return (
        <div className="header">
            <h2 className="cb-card-title">{title}</h2>
            <div className="mt-4">
                {children}
            </div>
        </div>);
}

export const Body = ({ children }) => {
    return (
        <div className="pt-2">
            {children}
        </div>);
}


export default Card;
