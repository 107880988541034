import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

const MOBILE_WIDTH = 992;

const Context = React.createContext();

export const ViewPortProvider = ({ children }) => {
  const debounceSizeChange = useMemo(() => {
    return new Subject();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  const resize = useCallback((e) => {
    debounceSizeChange.next(e.currentTarget.innerWidth);
  }, [debounceSizeChange]);

  const checkWidth = useCallback((width) => {
    setIsMobile(width < MOBILE_WIDTH);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    debounceSizeChange.pipe(debounceTime(500)).subscribe(checkWidth);
    debounceSizeChange.next(window.innerWidth);
    return () => {
      window.removeEventListener("resize", resize);
      debounceSizeChange.unsubscribe();
    };
  }, [checkWidth, debounceSizeChange, resize]);

  return <Context.Provider value={{ isMobile }}>{children}</Context.Provider>;
};

export const useViewPortContext = () => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      "useViewPortContext must be used within a ViewPortProvider"
    );
  }
  return context;
};
