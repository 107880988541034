export const DELETE_USER = 'DELETE_USER'

export const LEAVE_ORGANISATION = 'REQ_LEAVE_ORGANISATION'
export const LEAVE_ORGANISATION_SUCCESS = 'LEAVE_ORGANISATION_SUCCESS'

export const LEAVE_ACCOUNT = 'REQ_LEAVE_ACCOUNT'
export const LEAVE_ACCOUNT_SUCCESS = 'LEAVE_ACCOUNT_SUCCESS'

export const LOAD_GENERAL_SETTINGS = 'REQ_LOAD_GENERAL_SETTINGS'
export const LOAD_GENERAL_SETTINGS_SUCCESS = 'LOAD_GENERAL_SETTINGS_SUCCESS'

export const SET_FORM_DATA_GENERAL_SETTINGS = 'SET_FORM_DATA_GENERAL_SETTINGS'

export const UPDATE_GENERAL_SETTINGS = 'REQ_UPDATE_GENERAL_SETTINGS'
export const UPDATE_GENERAL_SETTINGS_SUCCESS = 'UPDATE_GENERAL_SETTINGS_SUCCESS'
export const UPDATE_GENERAL_SETTINGS_FAILED = 'UPDATE_GENERAL_SETTINGS_FAILED'

export const UPDATE_TIMEZONE = 'REQ_UPDATE_TIMEZONE'
export const UPDATE_TIMEZONE_SUCCESS = 'UPDATE_TIMEZONE_SUCCESS'
export const UPDATE_TIMEZONE_FAILED = 'UPDATE_TIMEZONE_FAILED'

export const updateGeneralSettingsFailed = (errors) => ({
    type: UPDATE_GENERAL_SETTINGS_FAILED,
    generalSettingsError: errors
})

export const leaveOrganisation = (orgId) => ({
    type: LEAVE_ORGANISATION,
    orgId
})

export const leaveOrganisationSuccess = () => ({
    type: LEAVE_ORGANISATION_SUCCESS
})

export const leaveAccount = (accountId) => ({
    type: LEAVE_ACCOUNT,
    accountId
})

export const leaveAccountSuccess = () => ({
    type: LEAVE_ACCOUNT_SUCCESS
})


export const updateGeneralSettings = (model) => ({
    type: UPDATE_GENERAL_SETTINGS,
    model
})

export const updateGeneralSettingsSuccess = () => ({
    type: UPDATE_GENERAL_SETTINGS_SUCCESS
})

export const updateTimezone = (timezone) => ({
    type: UPDATE_TIMEZONE,
    timezone
})  

export const updateTimezoneSuccess = () => ({
    type: UPDATE_TIMEZONE_SUCCESS
})

export const deleteUser = () => ({
    type: DELETE_USER
})


export const loadGeneralSettings = () => ({
    type: LOAD_GENERAL_SETTINGS
})

export const loadGeneralSettingsSuccess = (settings) => ({
    type: LOAD_GENERAL_SETTINGS_SUCCESS,
    settings
})

export const setFormData = (value, key) => ({
    type: SET_FORM_DATA_GENERAL_SETTINGS,
    value: value,
    key: key
})
