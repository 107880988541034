const right = {
    read: 1,
    read_write: 2

    
}
export const getPrivilege = (state, type, privilege) => {
    if(state && state.auth && state.auth.info && state.auth.info.privileges) {
        return state.auth.info.privileges.filter(p => p.objectName === type && p.privilege >= right[privilege])[0] || null;
    }

    return null;
}