const freeEmailDomains = require('free-email-domains')

export const required = value => value || value === 0 ? undefined : 'Required';

export const email = value => {
  if(!value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)){
    return 'Invalid email address';
  }
  if(freeEmailDomains.includes(value.toLowerCase().split('@')[1])){
    return 'Invalid work email address';
  }
  return undefined;
}
  
/*
(!value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ?
    'Invalid email address' : undefined*/


export const requiredArray = array => array && array.length > 0 && Array.isArray(array) ? undefined : 'Required'

