export const DELETE_ACCOUNT = 'REQ_DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const CHANGE_ACCOUNT_OWNER = 'REQ_CHANGE_ACCOUNT_OWNER'
export const CHANGE_ACCOUNT_OWNER_SUCCESS = 'CHANGE_ACCOUNT_OWNER_SUCCESS'

export const deleteAccount = (model) => ({
    type: DELETE_ACCOUNT, model
})

export const deleteAccountSuccess = () => ({
    type: DELETE_ACCOUNT_SUCCESS
})

export const changeAccountOwner = (model) => ({
    type: CHANGE_ACCOUNT_OWNER,
    model
})


export const changeAccountOwnerSuccess = (model) => ({
    type: CHANGE_ACCOUNT_OWNER_SUCCESS
})

