import React from "react";
import EndpointsContainer from "../containers/endpoints/Endpoints";
import PageContent, {
  ContentHeader,
  ContentBody,
} from "../components/common/PageContent";

const EndpointsPage = () => (
  <PageContent className="border-0">
    <ContentHeader title="Network / Endpoints" />
    <ContentBody>
      <EndpointsContainer />
    </ContentBody>
  </PageContent>
);

export default EndpointsPage;
