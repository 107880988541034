
import React from 'react'
import Table from '../common/table/Table'
import Card, { Body, Header } from '../common/Card';

const Membership = ({ schema, membership }) => {

    return (
        <Card className="card-settings">
            <Header title="Account membership">
                <div className="alert highlight" role="alert"><span>You are a member of these accounts/organisations:</span></div>
            </Header>
            <Body>
                {membership.length > 0 ?

                    <div>
                        <div className="table-responsive table-full-width">
                            <Table data={membership}
                                schema={schema} />
                        </div>
                    </div> :
                    <p>You dont belong to any organisations yet.</p>
                }
            </Body>
        </Card>
    )
}

export default Membership
