export const LOAD_EXPLORE_VIEW = "REQ_LOAD_EXPLORE_VIEW"
export const LOAD_EXPLORE_VIEW_SUCCESS = "LOAD_EXPLORE_VIEW_SUCCESS"
export const LOAD_EXPLORE_VIEW_FAILED = "LOAD_EXPLORE_VIEW_FAILED"

export const SEARCH_IN_EXPLORE = "REQ_SEARCH_IN_EXPLORE"
export const SEARCH_IN_EXPLORE_SUCCESS = "SEARCH_IN_EXPLORE_SUCCESS"
export const SEARCH_IN_EXPLORE_FAILED = "SEARCH_IN_EXPLORE_FAILED"

export const GET_SHARE_FOLDER = "REQ_GET_SHARE_FOLDER"
export const GET_SHARE_FOLDER_SUCCES = "GET_SHARE_FOLDER_SUCCESS"
export const GET_SHARE_FOLDER_FAILED = "GET_SHARE_FOLDER_FAILED"

export const SHARE_FOLDER = "REQ_SHARE_FOLDER"
export const SHARE_FOLDER_SUCCES = "SHARE_FOLDER_SUCCESS"
export const SHARE_FOLDER_FAILED = "SHARE_FOLDER_FAILED"

export const NAVIGATE_EXPLORE_VIEW = "NAVIGATE_EXPLORE_VIEW"

export const EXPLORE_VIEW_CHANGED = "EXPLORE_VIEW_CHANGED"
export const EXPLORE_ACTION_SELECTED = "EXPLORE_ACTION_SELECTED"

export const EXECUTE_EXPLORE_COMMAND = "REQ_EXECUTE_EXPLORE_COMMAND"
export const EXECUTE_EXPLORE_COMMAND_SUCCESS = "EXECUTE_EXPLORE_COMMAND_SUCCESS"

export const RESET_EXPLORE_VIEW = "RESET_EXPLORE_VIEW"

export const EXPLORE_VIEW_CHANGE_PAGE = "EXPLORE_VIEW_CHANGE_PAGE"

export const loadExploreView = (id, shareId, exploreType, currentPage, sortBy, filters) => ({
    type: LOAD_EXPLORE_VIEW,
    id, shareId, exploreType, currentPage, sortBy, filters
})

export const exploreChangePage = (id, shareId, exploreType, currentPage, sortBy, filters) => ({
    type: EXPLORE_VIEW_CHANGE_PAGE,
    id, shareId, exploreType, currentPage, sortBy, filters
})

export const resetExploreView = () => ({
    type : RESET_EXPLORE_VIEW
})

export const loadExploreViewFailed = () => ({
    type : LOAD_EXPLORE_VIEW_FAILED
})

export const loadExploreViewSuccess = (exploreView) => ({
    type: LOAD_EXPLORE_VIEW_SUCCESS,
    view : exploreView
})

export const navigateExploreView = (id, shareId, exploreType, parent) => ({
    type: NAVIGATE_EXPLORE_VIEW,
    id, shareId, exploreType, parent
})


export const searchInExplore = (text, filters) => ({
    type: SEARCH_IN_EXPLORE,
    text, filters
})

export const searchInExploreSuccess = (exploreView) => ({
    type: SEARCH_IN_EXPLORE_SUCCESS,
    view : exploreView
})

export const getShareFolder = (id, exploreType) => ({
    type: GET_SHARE_FOLDER,
    id, exploreType
})

export const getShareFolderSuccess = (share) => ({
    type: GET_SHARE_FOLDER_SUCCES,
    share
})

export const shareFolder = (id, name, sharedWith) => ({
    type: SHARE_FOLDER,
    id, name, sharedWith
})

export const shareFolderSuccess = (share) => ({
    type: SHARE_FOLDER_SUCCES,
    share
})

export const exploreViewChanged = (newItem, oldItem) => ({
    type: EXPLORE_VIEW_CHANGED,
    newItem, oldItem
})

export const exploreActionSelected = (command) => ({
    type: EXPLORE_ACTION_SELECTED,
    command
})

export const executeExploreCommand = (targetId, command) => ({
    type: EXECUTE_EXPLORE_COMMAND,
    targetId, command
})

export const executeExploreCommandSuccess = () => ({
    type: EXECUTE_EXPLORE_COMMAND_SUCCESS
})