import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getFormValues } from 'redux-form'
import CreateJobWizzardStepFive from '../../components/jobs/wizzard/CreateJobWizzardStepFive'
import stepThreeValidation from '../../components/jobs/wizzard/stepThreeValidation'
import { saveJob } from '../../actions/jobs'
import Loader from '../../components/common/Loader'
import { isFetching } from '../../selectors/Fetching';

const JobWizzardStepFiveContainer = ({ isPreviousStepValid, job, saveJob, isFetching }) => {

    if (isFetching)
        return <Loader />

    return (
        !isPreviousStepValid ? <Redirect to="./frequency" /> :
            <CreateJobWizzardStepFive job={job} onSubmit={saveJob} />
    )
}

const mapStateToProps = (state) => {
    const formValues = getFormValues("create-job-wizzard")(state) || {};
    return {
        isPreviousStepValid: Object.keys(stepThreeValidation(formValues)).length === 0,
        isFetching: isFetching(state.fetching, saveJob),
        job: formValues
    }
} 

const mapDispatchToProps = (dispatch) => {
    return {
        saveJob: (values) => {
            for (let index = 0; index < values.events.length; index++) {
                const element = values.events[index];
                element.executionOrder = index;
            }
            dispatch(saveJob(values))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobWizzardStepFiveContainer)
