import React, { useEffect } from "react";
import { connect } from "react-redux";

import CreateJobWizzardStepOne from "../../components/jobs/wizzard/CreateJobWizzardStepOne";
import { loadJob } from "../../actions/jobs";

import { isFetching } from "../../selectors/Fetching";

import Loader from "../../components/common/Loader";

const mapStateToProps = (state) => {
  return {
    job: state.jobs.job,
    isFetching: isFetching(state.fetching, loadJob),
  };
};

const mapDispatchToProps = (dispatch) => ({
  initialState: (jobId) => dispatch(loadJob(jobId)),
});

const JobWizzardStepOneContainer = ({
  job,
  isFetching,
  match,
  initialState,
}) => {
  useEffect(() => {
    if (
      match.params.jobId &&
      (!job || job.id !== parseInt(match.params.jobId, 10))
    ) {
      initialState(match.params.jobId);
    }
  }, [initialState, job, match.params.jobId]);
  if (isFetching) return <Loader />;

  return <CreateJobWizzardStepOne job={job} />;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobWizzardStepOneContainer);
