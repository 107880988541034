import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import {
  showNotificationError,
  showNotificationSuccess,
} from "../../actions/notification";
import {
  generateReport$,
  listReports$,
  deleteReport$,
  downloadReportUrl,
} from "../../api/history";
import downloadFile from "../../common/downloadFile";
import ReportGeneratorComponent from "../../components/reports/ReportGenerator";
import ReportListComponent from "../../components/reports/ReportList";
import ReportsSchema from "./ReportsSchema";
import qs from "query-string";

function useQuery() {
  const location = useLocation();
  return useMemo(() => {
    return qs.parse(location.search);
  }, [location.search]);
}

const ReportsContainer = () => {
  const query = useQuery();

  const initialFilter = useMemo(() => {
    return { ...query, reportType: ".html" };
  }, [query]);

  const [page, setPage] = useState(1);
  const [view, setView] = useState();

  const dispatch = useDispatch();

  const listReports = useCallback((page) => {
    listReports$(page).subscribe((view) => {
      setView(view);
    });
  }, []);

  const onDownload = useCallback(
    (report) => {
      const filePath = downloadReportUrl(report.id);
      downloadFile(filePath, report.fileName)
        .then((message) => dispatch(showNotificationSuccess(message)))
        .catch((message) => showNotificationError(message))
        .finally(() => {});
    },
    [dispatch]
  );

  const onDelete = useCallback(
    (report) => {
      deleteReport$(report.id).subscribe(
        (result) => {
          dispatch(showNotificationSuccess("Your report has been deleted"));
          listReports(page);
        },
        (error) => {
          dispatch(
            showNotificationError(
              error?.response?.message ??
                error?.response?.errors[0] ??
                "Unhandled error occured"
            )
          );
        }
      );
    },
    [dispatch, listReports, page]
  );

  const onPageChange = useCallback((page) => setPage(page), []);

  const schema = useMemo(() => ReportsSchema(onDownload, onDelete), [
    onDelete,
    onDownload,
  ]);

  const onOrderReport = useCallback(
    (filter) => {
      generateReport$(filter).subscribe(
        () => {
          dispatch(
            showNotificationSuccess("Your report is now being generated")
          );
          listReports(page);
        },
        (error) =>
          dispatch(
            showNotificationError(
              error?.response?.message ??
                error?.response?.errors[0] ??
                "Unhandled error occured"
            )
          )
      );
    },
    [dispatch, listReports, page]
  );

  useEffect(() => {
    listReports(page);
  }, [listReports, page]);

  return (
    <>
      <ReportGeneratorComponent
        onOrderReport={onOrderReport}
        initialFilter={initialFilter}
      ></ReportGeneratorComponent>
      <ReportListComponent
        onPageChange={onPageChange}
        page={page}
        schema={schema}
        view={view}
      ></ReportListComponent>
    </>
  );
};

export default ReportsContainer;
