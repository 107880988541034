import React from 'react'
import Table from '../common/table/Table'
import Card, { Header, Body } from '../common/Card'

const UserList = ({ title, users, schema, onUserSelect }) => {
  return (
    <Card className="card-settings">
      <Header title={title}>
      </Header>
      <Body>
        <Table data={users} schema={schema} onRowClick={onUserSelect} />
      </Body>
    </Card>
  )
}

export default UserList;