import React from 'react'
import { NavLink } from 'react-router-dom'

const ExploreBreadcrumbs = ({ breadcrumbs }) => {
    if (!breadcrumbs || !breadcrumbs.length) {
        return "";
    }
    const bcs = breadcrumbs.map((bc, i) => (
        <li key={bc.id} className="d-flex align-items-center">
            {
                !bc.id && !bc.type ?
                    <NavLink to={`/explore`} exact activeClassName="active"><span className="icon icon-explorer"></span> {bc.name}</NavLink> :
                    !bc.shareId || bc.shareId <= 0 ?
                        <NavLink to={`/explore/${bc.id}/${bc.type}`}>{bc.name}</NavLink> :
                        <NavLink to={`/explore/${bc.id}/${bc.type}/${bc.shareId}`}>{bc.name}</NavLink>
            }
            {
                i !== (breadcrumbs.length - 1) &&
                <span className="pl-1 pr-1 primary-color">/</span>
            }
        </li>
    ))

    return <ol className="breadcrumb border">
        {bcs}
    </ol>
}

export default ExploreBreadcrumbs;