import React from 'react'
import './NavbarPopupMenu.css'

/*
const NavbarPopupMenu = ({ title, children, isOpen, toggleMenu }) => {
    return (
        <li className="ml-5">
        <div className="pointer" onClick={() => toggleMenu()}>
            {title}
        </div>
        {
            isOpen &&
            <div className="popup-menu-wrapper" onClick={() => toggleMenu()}>
                <div className="popup-menu" onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
            </div>
        }
    </li>
    )
}
*/

const NavbarPopupMenu = ({ title, children, isOpen, toggleMenu }) => {
    const mobileClass = isOpen ? 'visible' : '';
    return (
        <React.Fragment>
            <div className="pointer" onClick={() => toggleMenu()}>
                {title}
            </div>
            <div id="popup-menu" className={mobileClass}  onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
            {
                isOpen &&
                <div className="popup-menu-wrapper" onClick={() => toggleMenu()}>
                </div>
            }
        </React.Fragment>
    )
}

export default NavbarPopupMenu;