import { throwError } from "rxjs";
import { refreshAuth$ } from "./auth";
import { map, mergeMap, tap } from "rxjs/operators";
import * as authHelper from "../helpers/auth";
import { acquireTokenSilent$ } from "./sso";

const handleRefresh$ = (source) => {
  const { loginType } = authHelper.getUserInfo();

  if (loginType === 2) {
    return acquireTokenSilent$().pipe(
      map((result) => {
        authHelper.save(result);
      })
    );
  }
  return refreshAuth$().pipe(
    map((apiResponse) => {
      authHelper.save(apiResponse.response);
    })
  );
};

export const handleError = (err$) =>
  err$.pipe(
    tap(console.error),
    mergeMap((error) => {
      return error.status === 401
        ? handleRefresh$()
        : throwError(error);
    })
  );
