/*import React, { useState } from 'react'*/
import React from 'react'
import PropTypes from 'prop-types'
import Card, { Body } from '../../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../../common/PageContent';
import Refresh from './Refresh';
import './FileDetails.css'
import DateFormat from '../../common/DateFormat';
import { volumeFormatter } from '../../../common/volumeFormatter'
import Progress from '../../common/progress/Progress';
import { NavLink } from 'react-router-dom'
import RunStatusInfo from './RunStatusInfo';


//const getProgressColor = (status) => status==="1" || status==="10" ? "success" : status==="0" || status==="20" || status==="21" || status==="10000" ? "primary" : "danger";

const getStatusType = (status) => status === "1" ? "success" : status === "0" || status === "20" || status === "21" || status === "10000" ? "inProgress" : status === "10" ? "cancelledOk" : "error";


const Details = ({ runId, eventId, details }) => {
    const hasErrors = details.statusId === "9000" || details.statusId === "9001" || details.statusId === "9010" || details.statusId === "9020" || details.statusId === "9021" || details.statusId === "9999" ?
        <div className="alert alert-danger">
            <strong>Error: </strong>
            {details.errorMessage}
        </div>
        : ""

    /*
    0	Queued
    1	Finished OK
    10	Cancelled OK
    20	Started.. Children must not execute
    21	Started.. Children, execute allowed
    100	MixedResult
    9000	Error Queued
    9001	Error Finished OK
    9010	Error Assigned to client, waiting for completion
    9020	Error Started.. Children must not execute
    9021	Error Started.. Children, execute allowed
    9999	GeneralError 
    10000	RemovedQueued
    19000	Removed Error Queued
    19001	Removed Error Finished OK
    19010	Removed Error Assigned to client, waiting for completion
    19020	Removed Error Started.. Children must not execute
    19021	Removed Error Started.. Children, execute allowed
    19999	RemovedGeneralError 
    */

    return (

        <div className="row m-0 runSummary">
            <div className="col-md-3">
                <div className="card">
                    <div className="card-header">
                        Command
              </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">{details.type}</li>
                    </ul>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <div className="card-header">
                        Status
              </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <RunStatusInfo type={getStatusType(details.statusId)} />
                            {hasErrors}
                            {details.errorMessage ? details.errorMessage : ""}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <div className="card-header">
                        Start
              </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><DateFormat date={details.start} /></li>
                    </ul>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <div className="card-header">
                        Stop
              </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><DateFormat date={details.stop} /></li>
                    </ul>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <div className="card-header">
                        Source filename
              </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><i className="icon icon-file_1"></i> {details.source.fileName}</li>
                    </ul>
                </div>
            </div>
            {details.target.fileName
                ?
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header">
                            Target filename
                                    </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><i className="icon icon-file_1"></i> {details.target.fileName}</li>
                        </ul>
                    </div>
                </div>
                : ""
            }

            <div className="col-md-3">
                <div className="card">
                    <div className="card-header">
                        File size
              </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">{volumeFormatter(details.totalBytes)}</li>
                    </ul>
                </div>
            </div>
            <div className="col-md-3">
            </div>
            <div className={details.source.account ? "col-md-6" : "col-md-12"}>
                <div className="card">
                    <div className="card-header">
                        Source place
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <i className="icon icon-folder"></i> {details.source.path}
                            <span className="badge badge-primary mx-2">On</span>
                            <i className="icon icon-fs_connector"></i> {details.source.connector} <i className="icon icon-left-dir"></i> <i className="icon icon-organization"></i> {details.source.organisation} <i className="icon icon-left-dir"></i>  <i className="icon icon-home"></i>{details.source.account}
                        </li>
                    </ul>
                </div>
            </div>
            {details.target.account ?
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            Target place
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <i className="icon icon-folder"></i> {details.target.path}
                                <span className="badge badge-primary mx-2">On</span>
                                <i className="icon icon-fs_connector"></i> {details.target.connector} <i className="icon icon-left-dir"></i> <i className="icon icon-organization"></i> {details.target.organisation} <i className="icon icon-left-dir"></i>  <i className="icon icon-home"></i>{details.target.account}
                            </li>
                        </ul>
                    </div>
                </div>
                : ""

            }
        </div>
    )
}

const FileDetails = ({ runId, eventId, title, details, onRefresh }) => {
    return (
        <PageContent className="file-details border-0">
            <ContentHeader title={title}>
                <ol className="breadcrumb border">
                    <li className="breadcrumb-item d-flex align-items-center">
                        <NavLink to={`/jobs/runs/`} exact>
                            <span>Runs</span>
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item d-flex align-items-center">
                        <NavLink to={`/jobs/runs/${runId}`} exact>
                            <span>Job run {runId}</span>
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item d-flex align-items-center">
                        <NavLink to={`/jobs/runs/${runId}/events/${eventId}`} exact>
                            <span>Event run {eventId}</span>
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active d-flex align-items-center" aria-current="page">
                        <span>File run {details.sysCommandId}</span>
                    </li>
                </ol>
            </ContentHeader>
            <ContentBody>
                <Card className="p-0 m-0 border-0">
                    <Body className="p-0">
                        <div className="row m-0">
                            <div className="col-6 p-0">
                                <h6>File run progress</h6>
                            </div>
                            <div className="col-6 p-0">
                                <div className="float-right"><Refresh onRefresh={onRefresh} /></div>
                            </div>
                        </div>
                        <Progress value={details.progress} className="bg-success" />
                        <h6 className="mt-3">File run summary</h6>
                        <Details runId={runId} eventId={eventId} details={details} />
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>
    )
}

FileDetails.propTypes = {
    title: PropTypes.string.isRequired,
    details: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    onPrintDetails: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired
}

export default FileDetails

