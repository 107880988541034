import React, { useRef, useState } from "react";
import Card, { Header, Body } from "../common/Card";
import PageContent, { ContentHeader, ContentBody } from "../common/PageContent";
import Table from "../common/table/Table";
import { dateFormat } from "../../common/dateFormatter";
import { Link, NavLink } from "react-router-dom";
import { useModal } from "../modal/Modal";

const EditDescription = ({ description, onChange }) => {
  const ref = useRef(null);
  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        ref={ref}
        defaultValue={description}
      />
      <div className="input-group-append">
        <span
          className="input-group-text pointer"
          onClick={() => onChange(ref.current.value)}
        >
          Save
        </span>
      </div>
    </div>
  );
};

const RequestLogFiles = ({ getLogFiles, details }) => {
  const [date, setDate] = useState();
  const { showModal } = useModal();
  return (
    <div className="mt-3">
      <label>Select date and request a log file</label>
      <div className="input-group">
        <input
          placeholder="Select date..."
          className="form-control form-control-lg"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <div className="input-group-append">
          <span
            //onClick={(event) => getLogFiles(details.clientKey, date)}
            className="input-group-text pointer"
            onClick={(event) =>
              showModal("Confirm", {
                title: "Request log files",
                message: `This action will send a request to connector to upload local log files. Reload page in a couple of minutes and the requested logfiles should show up in the list below.`,
                confirmBtnText: "Ok",
                init: { next: getLogFiles, args: [details.clientKey, date] },
              })
            }
          >
            Request log files
          </span>
        </div>
      </div>
    </div>
  );
};

const CommandLogs = ({ logs, schema }) => {
  return <Table data={logs} schema={schema}></Table>;
};

const ConnectorDetailsComponent = ({
  details,
  transferToNewOrgEvent,
  unregisterAndDeleteConnectorEvent,
  connectorLogLevels,
  organisations,
  setConnectorTransferOrgEvent,
  newOrgId,
  changeConnectorDescription,
  changeLogLevel,
  logFiles,
  logFilesSchema,
  getLogFiles,
  restart,
  kill,
  removeExcludeFolders,
  commandLogs,
  commandLogsSchema,
}) => {
  const { showModal } = useModal();
  const [showAll, setShowAll] = useState(false);

  return (
    <PageContent>
      <ContentHeader title={`Network / Connectors / ` + details.serverName} /> 
      <ContentBody>
        <Card>
          <Header title="Details"></Header>
          <Body>
            <div className="detail-table">
              <div className="row">
                <div className="col-sm-2">Description</div>
                <div className="col-sm-10">
                  <EditDescription
                    description={details.description}
                    onChange={(value) =>
                      changeConnectorDescription(details.clientKey, value)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">Status</div>
                <div className="col-sm-10">
                  <span
                    className={
                      details.status === "Online"
                        ? "badge badge-success"
                        : "badge badge-danger"
                    }
                  >
                    <i
                      className={
                        details.status === "Online"
                          ? "icon icon-ok-circled"
                          : "icon icon-attention-circled"
                      }
                    ></i>{" "}
                    {details.status}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">Response time</div>
                <div className="col-sm-10">{details.responseTime}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Last heartbeat</div>
                <div className="col-sm-10">
                  {dateFormat(details.lastHeartbeat)}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">Last sync</div>
                <div className="col-sm-10">{dateFormat(details.lastSync)}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Info disk</div>
                <div className="col-sm-10">{details.infoDisk}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Info Cpu</div>
                <div className="col-sm-10">{details.infoCpu} %</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Id</div>
                <div className="col-sm-10">{details.id}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Registered</div>
                <div className="col-sm-10">
                  {dateFormat(details.registered)}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">Server Name</div>
                <div className="col-sm-10">{details.serverName}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">OS</div>
                <div className="col-sm-10">{details.os}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">IP</div>
                <div className="col-sm-10">{details.ip}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Organisation</div>
                <div className="col-sm-10">{details.organization}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Type</div>
                <div className="col-sm-10">{details.type}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">Connector service version</div>
                <div className="col-sm-10">{details.versionInformation}</div>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-12">
                <button
                  className="btn btn-light btn-lg"
                  onClick={() =>
                    showModal("Confirm", {
                      title: "Retart connector",
                      message: `Are you sure you want to restart ${details.serverName}?`,
                      confirmBtnText: "Restart",
                      init: { next: restart, args: [details.clientKey] },
                    })
                  }
                >
                  <span className="icon icon-arrows-cw"></span> Restart
                </button>
                <button
                  className="btn btn-danger btn-lg ml-3"
                  onClick={() =>
                    showModal("Confirm", {
                      title: "Force shutdown",
                      message: `Are you sure you want to shutdown ${details.serverName}?`,
                      confirmBtnDanger: true,
                      confirmBtnText: "Force shutdown",
                      init: { next: kill, args: [details.clientKey] },
                    })
                  }
                >
                  <span className="icon icon-onoff"></span> Force shutdown
                </button>
                <Link className="btn btn-light btn-lg ml-3" to={`/history?clientFrom=${details.serverName}`}>History & reports</Link>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="alert highlight" role="alert">
                  <span className="icon icon-warn2 text-warning"></span> Note!
                  Forced shutdown could result in job errors.
                </div>
              </div>
            </div>
          </Body>
        </Card>

        <Card>
          <Header title={`Root folders on ` + details.serverName}></Header>
          <Body>
            <div className="alert highlight break-word" role="alert">
              The list of folders is representing the root folders shared
              locally where this connector is installed. It can be any type of
              folders (like filesystem folders or FTP foolders or other). To add
              or remove shared root folders you need to login on the server and
              use the configuration application. Normally found at:
              c:\Programfiles\Cloudbuz\ConfigApp.exe
            </div>
            <ul className="list-border">
              {(showAll
                ? details.sharedFolders
                : details.sharedFolders.slice(0, 5)
              ).map((s) => (
                <li key={s.pathKey}>
                  <NavLink
                    activeClassName="active"
                    to={"/explore/" + s.pathKey + "/Folder"}
                  >
                    <i className="icon icon-folder"></i> {s.folder}
                  </NavLink>
                </li>
              ))}
            </ul>
            {details.sharedFolders.length > 5 && (
              <button
                className="btn btn-light  mt-2"
                onClick={(e) => setShowAll(!showAll)}
              >
                {showAll ? "hide all" : "show all"}
              </button>
            )}

            {false && (
              <div>
                {details.execludedPaths && details.execludedPaths.length > 0 && (
                  <div>
                    <h3>Excluded folders</h3>
                    <ul className="list-border mt-5 mb-5">
                      {details.execludedPaths.map((s) => (
                        <li key={s.pathKey}>
                          <div className="d-flex align-items-center">
                            <span className="flex-fill pointer">{s.path}</span>
                            <button
                              className="btn btn-danger float-right"
                              onClick={() =>
                                removeExcludeFolders(details.clientKey, s.path)
                              }
                            >
                              Do not exclude
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <button className="btn btn-default">Add exclude folder</button>
                <button className="btn btn-danger ml-3">
                  Clear all exclude folders
                </button>

                <p className="text-warning mt-3">
                  Note! If you have subfolders in your root folders with lots of
                  objects that you don't need to sync you can add excluide
                  folders to speed up syncronization.
                </p>
              </div>
            )}
          </Body>
        </Card>

        <Card>
          <Header title={`Local log on ` + details.serverName}></Header>
          <Body>
            <label>Change log level</label>
            <select
              className="form-control form-control-lg border-input"
              onChange={(event) =>
                parseInt(event.target.value, 10) > -1
                  ? showModal("Confirm", {
                      title: "Set loglevel",
                      message: `Are you sure you want to remotely set connector loglevel to ${
                        parseInt(event.target.value, 10) === 0
                          ? "Error"
                          : parseInt(event.target.value, 10) === 1
                          ? "Warning"
                          : parseInt(event.target.value, 10) === 2
                          ? "Information"
                          : "Detail"
                      }?`,
                      confirmBtnText: "Ok",
                      init: {
                        next: changeLogLevel,
                        args: [
                          details.clientKey,
                          parseInt(event.target.value, 10),
                        ],
                      },
                    })
                  : ""
              }
              /*
              onChange={(event) =>
                changeLogLevel(
                  details.clientKey,
                  parseInt(event.target.value, 10)
                )
              }*/
            >
              <option>Select new loglevel</option>
              {Object.keys(connectorLogLevels).map((ll) => (
                <option key={ll} value={connectorLogLevels[ll]}>
                  {ll}
                </option>
              ))}
            </select>

            <RequestLogFiles getLogFiles={getLogFiles} details={details} />
            <Table
              data={logFiles}
              schema={logFilesSchema}
              infoIfEmpty="No logfiles requested.."
            />
          </Body>
        </Card>

        <Card>
          <Header
            title={`Transfer ${details.serverName} to other organisation`}
          ></Header>
          <Body>
            <p>Connected to organisation</p>
            <select
              className="form-control form-control-lg"
              onChange={(event) =>
                setConnectorTransferOrgEvent(event.target.value)
              }
            >
              <option>Select new Organisation</option>
              {organisations.map((o) => {
                return (
                  <option key={o.organisationId} value={o.organisationId}>
                    {o.name}
                  </option>
                );
              })}
            </select>

            <button
              onClick={(event) =>
                transferToNewOrgEvent(details.clientKey, newOrgId)
              }
              className="btn btn-primary btn-lg  mt-3"
            >
              Transfer
            </button>
          </Body>
        </Card>

        <Card>
          <Header title="Unregister and delete">
            <div className="alert highlight" role="alert">
              <span>
                Note! If you unregister and delete a connector all jobs and
                shares with a relation to this connector will be deleted.
              </span>
            </div>
          </Header>
          <Body>
            <button
              onClick={(event) =>
                showModal("Confirm", {
                  title: "Unregister and delete connector",
                  message: (
                    <span>
                      This action will break all existing jobs, folders and
                      shares connected to this connector.{" "}
                      <strong>Are you sure</strong>?
                    </span>
                  ),
                  confirmBtnText: "Unregister and delete",
                  confirmBtnDanger: true,
                  init: {
                    next: unregisterAndDeleteConnectorEvent,
                    args: [details.clientKey],
                  },
                })
              }
              /*onClick={(event) =>
                unregisterAndDeleteConnectorEvent(details.clientKey)
              }*/
              className="btn btn-danger btn-lg"
            >
              <span className="icon icon-trash"></span> Unregister and delete
            </button>
          </Body>
        </Card>
        <Card>
          <Header>Command logs</Header>
          <Body>
            <CommandLogs logs={commandLogs} schema={commandLogsSchema} />
          </Body>
        </Card>
      </ContentBody>
    </PageContent>
  );
};

export default ConnectorDetailsComponent;
