import React from 'react'
import { connect } from 'react-redux'
import { lifecycle } from 'recompose'
import { getActiveAccount } from '../selectors/Auth'
import Loader from '../components/common/Loader'

import {
    loadConnectionsDetails
} from '../actions/connections'

import ConnectionsDetailsComponent from '../components/connections/ConnectionsDetails'
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state) => {
    return {
        isFetching: isFetching(state.fetching, loadConnectionsDetails),
        sharesFrom: state.connections.sharesFrom, 
        sharesTo: state.connections.sharesTo,
        jobsFrom: state.connections.jobsFrom,
        jobsTo: state.connections.jobsTo,
        details: state.connections.details,
        activeAccount: getActiveAccount(state.auth),
        user: state.auth.user
    }
}

const mapDispatchToPros = (dispatch) => {
    return {
        loadConnectionsDetails: (accountKey) => dispatch(loadConnectionsDetails(accountKey))
    }
}

let ConnectionsDetailsContainer = ({
    isFetching,
    sharesFrom,
    sharesTo,
    jobsFrom,
    jobsTo,
    details,
    activeAccount,
    user,
}) => {
    return isFetching ?
        <Loader /> :
        <div>
           <ConnectionsDetailsComponent details={details}
                                        sharesFrom={sharesFrom}
                                        sharesTo={sharesTo}
                                        jobsFrom={jobsFrom}
                                        jobsTo={jobsTo}
                                        activeAccount={activeAccount}
                                        user={user} />
        </div>
}


ConnectionsDetailsContainer = lifecycle({
    componentDidMount() {
        const {  accountKey, loadConnectionsDetails } = this.props;
        loadConnectionsDetails(accountKey)
    }
})(ConnectionsDetailsContainer)

export default connect(mapStateToProps, mapDispatchToPros)(ConnectionsDetailsContainer)