import React from 'react'
import { connect } from 'react-redux'
//import { lifecycle } from 'recompose'

import Loader from '../components/common/Loader'
import ConnectThroughInvitationToken from '../components/invites/ConnectThroughInvitationToken'


import {
    loadAccountFromToken,
    connectWithAccount,
    setSearchAccountToken
} from '../actions/invite'
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state) => {
    return {
        isFetching: isFetching(state.fetching, loadAccountFromToken),
        accountToken: state.invite.accountToken,
        account: state.invite.account
    }
}

const mapDispatchToPros = (dispatch, ownProps) => {
    return {
        invitationTokenHandler: (inputValue) => {
            return dispatch(setSearchAccountToken(inputValue))
        },
        viewAccount: (accountToken) => dispatch(loadAccountFromToken(accountToken)),
        connectWithAccount: (accountToken) => dispatch(connectWithAccount(accountToken))
    }
}

let ExtendNetworkConnectByTokenContainer = ({
    isFetching,
    invitationTokenHandler,
    viewAccount,
    accountToken,
    connectWithAccount,
    account
}) => {
    return isFetching ?
        <Loader /> :
            <ConnectThroughInvitationToken invitationTokenHandler={invitationTokenHandler}
                                           viewAccount={viewAccount}
                                           accountToken={accountToken}
                                           account={account} 
                                           connectWithAccount={connectWithAccount}/>
}

export default connect(mapStateToProps, mapDispatchToPros)(ExtendNetworkConnectByTokenContainer)