import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotificationSuccess } from "../../actions/notification";
import { changeOnDemand$ } from "../../api/subscriptions";
import OnDemand from "../../components/subscriptions/ondemand/OnDemand";

const OnDemandContainer = ({ isEnabled, includedCVU, costOnDemandCVU, onChange }) => {
  const [enabled, setEnabled] = useState(isEnabled);
  const dispatch = useDispatch();

  const update = useCallback(
    (enbld) => {
      setEnabled(enbld);
      changeOnDemand$({ enableOnDemandCVU: enbld }).subscribe((result) => {
        dispatch(
          showNotificationSuccess("Enabled ondemand cvu has been changed")
        );

        onChange && onChange(enbld);
      });
    },
    [dispatch, onChange]
  );

  useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled])

  return (
    <OnDemand
      isEnabled={enabled}
      includedCVU={includedCVU}
      costOnDemandCVU={costOnDemandCVU}
      onChange={update}
    />
  );
};

export default OnDemandContainer;
