import React from 'react'
import { connect } from 'react-redux'
import { getFormValues, arrayPush } from 'redux-form'
import { lifecycle } from 'recompose'
import { Redirect } from 'react-router-dom'
import CreateJobWizzardStepFour from '../../components/jobs/wizzard/CreateJobWizzardStepFour'
import stepThreeValidation from '../../components/jobs/wizzard/stepThreeValidation'
import { loadJobNotificationUsers } from '../../actions/jobs'; 

let JobWizzardStepFourContainer = ({ isPreviousStepValid, 
                                     addEmailNotification,
                                     notificationUsers,
                                     notificationAccounts,
                                     }) => {
    return ( 
        !isPreviousStepValid ? <Redirect to="./frequency" /> :
        <div>
            <CreateJobWizzardStepFour addEmailNotification={addEmailNotification}
                                      notificationUsers={notificationUsers}
                                      notificationAccounts={notificationAccounts} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const formValues = getFormValues("create-job-wizzard")(state) || {};
    return {
        isPreviousStepValid: Object.keys(stepThreeValidation(formValues)).length === 0,
        notificationUsers: state.jobs.notificationUsers,
        notificationAccounts: state.jobs.notificationAccounts
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadJobNotificationUsers: () => dispatch(loadJobNotificationUsers()),
    addEmailNotification: (values) => dispatch(arrayPush('create-job-wizzard', 'notificationEmails', values))
})

JobWizzardStepFourContainer = lifecycle({
    componentDidMount() {
        const { loadJobNotificationUsers } = this.props;
        loadJobNotificationUsers();
    }
})(JobWizzardStepFourContainer);



export default connect(mapStateToProps, mapDispatchToProps)(JobWizzardStepFourContainer)