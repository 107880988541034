import React from 'react'
import OrganisationEditContainer from '../containers/OrganisationEdit'
import UserListContainer from '../containers/UserList'
import { NavLink } from 'react-router-dom'

const OrganisationPage = ({ match }) => {
    return (
        <React.Fragment>
            <OrganisationEditContainer match={match}  />
            <UserListContainer title="Users on this organisation" match={match} />
            <span>Invite a new user from the user <NavLink to="/admin/settings/users" className="">admin page</NavLink></span>
        </React.Fragment>
    )
}

export default OrganisationPage