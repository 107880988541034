export const getMembership = (settings) => {
  if (!settings || !settings.accounts || !settings.organisations) {
    return [];
  }

  return settings.accounts.flatMap((a) => {
    if (a.privilege === 201) {
      return { ...a, organisationName: "All", privilegeName: getPrivilegeName(a.privilege) };
    }

    return settings.organisations
      .filter((o) => o.accountId === a.accountId)
      .map((o) => ({ ...o, accountName: a.accountName, privilegeName: getPrivilegeName(o.privilege) }));
  });
};

const privileges = {
    0	: "None",
    101	: "System admin",
    201	: "Account admin",
    304	: "Normal user",
    305	: "Readonly user"
}

const getPrivilegeName = (privilege) => {
    if(!privileges[privilege]) {
        return "N/A";
    }
    return privileges[privilege]
}