import {
    LOAD_CONNECTORS_LIST,
    LOAD_CONNECTORS_LIST_SUCCESS,
    LOAD_CONNECTOR_DETAILS,
    LOAD_CONNECTOR_DETAILS_SUCCESS,
    SET_FORM_DATA_REQEST_NEW_INSTALLMENT,
    SET_FORM_DATA_CONNECTOR_TRANSFER_ORG,
    SET_FORM_DATA_CONNECTOR_NEW_LOGLEVEL,
    LOAD_CONNECTOR_INSTALLMENT_DATA,
    LOAD_CONNECTOR_INSTALLMENT_DATA_SUCCESS
} from '../actions/connectors';

export const connectors = (state = {
    connectorLogLevels: [],
    organisations: [],
    connectorTypes: [],
    details: {
        sharedFolders: [],
        execludedPaths: []
    },
    connectors: [],
    pendingRequests: []
}, action) => {
    switch (action.type) {
        case LOAD_CONNECTORS_LIST:
        case LOAD_CONNECTOR_INSTALLMENT_DATA:
            return {
                ...state
            }
        case LOAD_CONNECTORS_LIST_SUCCESS:
            return {
                ...state,
                connectors: action.connectors || [],
                pendingRequests: action.pendingRequests || []
            }
        case LOAD_CONNECTOR_INSTALLMENT_DATA_SUCCESS:
            return {
                ...state,
                connectorTypes: action.connectorTypes,
                organisations: action.organisations
            }
        case LOAD_CONNECTOR_DETAILS:
            return {
                ...state
            }
        case LOAD_CONNECTOR_DETAILS_SUCCESS:
            return {
                ...state,
                details: { ...action.details.details, execludedPaths: action.details.execludedPaths, sharedFolders: action.details.sharedFolders },
                organisations: action.organisations,
                logFiles: action.logFiles,
                commandLogs: action.details.commandLogs,
                connectorLogLevels: action.connectorLogLevels
            }
        case SET_FORM_DATA_REQEST_NEW_INSTALLMENT:
            return {
                ...state,
                createRequestNewInstallment: {
                    ...state.createRequestNewInstallment,
                    [action.key]: action.value
                }
            }
        case SET_FORM_DATA_CONNECTOR_TRANSFER_ORG:
            return {
                ...state,
                transferNewOrg: action.newOrgId
            }
        case SET_FORM_DATA_CONNECTOR_NEW_LOGLEVEL:
            return {
                ...state,
                newLogLevel: action.logLevel
            }
        default:
            return state
    }
}
