import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../components/common/Loader";
import downloadFile from "../common/downloadFile";
import {
  showNotificationError,
  showNotificationSuccess,
} from "../actions/notification";
import Download from "../components/common/Download";

const mapStateToProps = (state, ownProps) => ({
  filePath: ownProps.filePath,
  fileName: ownProps.fileName,
});

const mapDispatchToProps = (dispatch) => ({
  success: (message) => {
    dispatch(showNotificationSuccess(message));
  },
  error: (message) => {
    dispatch(showNotificationError(message));
  },
});

const DownloadContainer = ({ filePath, fileName, success, error }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [message, setMessage] = useState(true);

  const download = useCallback(() => {
    downloadFile(filePath, fileName)
      .then((message) => {
        success(message);
        setMessage(message);
      })
      .catch((message) => {
        error(message);
        setMessage(message);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [filePath, fileName, success, error]);

  useEffect(() => {
    download();
  }, [filePath, fileName, download]);

  return isFetching ? (
    <Loader title="Downloading connector" />
  ) : (
    <Download message={message} download={download} />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadContainer);
