import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form';
import LoginCard, { Header, Body } from '../common/LoginCard';
import CbLogo from '../../images/logo_white.png'

const required = value => value ? undefined : 'Required'

const renderField = ({ input, label, type, name, autoComplete, meta: { touched, error, warning }, icon, tooltip }) => (
    <div className="form-group">
        <input className="form-control form-control-lg border-input" {...input} placeholder={label} type={type} autoComplete={autoComplete} />
        {touched && ((error && <em htmlFor={name} className="invalid">{tooltip}</em>) || (warning && <span>{warning}</span>))}
    </div>
)

const CreateForgotPassword = ({ handleSubmit, pristine, reset, submitting,
    errors }) => {

    return (
        <div>
            <div className="logo_login_placeholder">
                <img src={CbLogo} height="30px" alt="Cloudbuz Manager" className="logo_login" />
            </div>
            <LoginCard>
                <Header title="Reset password">
                    <div className="alert alert-primary" role="alert">
                        <span>Please enter a new password. Be sure to use a strong password with a minimum of 8 char containing 3/4 of the following items:</span>
                        <ul>
                            <li>
                                Uppercase Letters
                            </li>
                            <li>
                                Lowercase Letters
                            </li>
                            <li>
                                Numbers
                            </li>
                            <li>
                                Symbols
                            </li>
                        </ul>
                    </div>
                </Header>
                <Body>
                    <form onSubmit={handleSubmit}>
                        <fieldset>
                            <Field
                                name="password"
                                component={renderField}
                                type="password"
                                label="New password"
                                icon="fa-envelope"
                                validate={[required]}
                                tooltip="Please enter your new password"
                            />

                            <Field
                                name="repeatPassword"
                                component={renderField}
                                type="password"
                                label="Repeat new password"
                                icon="fa-envelope"
                                validate={[required]}
                                tooltip="Please repeat your new password"
                            />

                            <button type="submit" className="btn btn-secondary btn-block btn-lg" id="btnLogin" disabled={submitting}>
                                <i className="fa fa-refresh"></i>
                                Reset password
                            </button>

                            {
                                <div className="text-danger">
                                    {
                                        errors && errors.map((e, index) => (<li key={index}>{e}</li>))
                                    }
                                </div>

                            }
                        </fieldset>
                        <footer>
                            <div className="text-center">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            <Link to="/login">I remembered my password!</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </form>
                </Body>
            </LoginCard>
            <p className="greetings">Made with <i className="icon icon-heart love"></i>  by <a className="text-white" href="https://www.zavanto.se">Zavanto</a></p>
        </div>
    )

}

export default reduxForm({
    form: 'createforgotpassword', // a unique identifier for this form
})(CreateForgotPassword);

