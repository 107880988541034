import * as api from "../api";

import { switchMap, catchError, map, mapTo, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { of } from "rxjs";

import {
  EDIT_ORGANISATION,
  GET_ORGANISATION,
  getOrganisationSuccess,
  SAVE_ORGANISATION,
  SAVE_ORGANISATION_SUCCESS,
  SAVE_ORGANISATION_FAILED,
  saveOrganisationSuccess,
  LIST_ORGANISATIONS,
  listOrganisationsSuccess,
  REMOVE_USER_FROM_ORGANISATION,
  REMOVE_USER_FROM_ORGANISATION_SUCCESS,
  removeUserFromOrganisatiSuccess,
  getOrganisation,
} from "../actions/organisation";

import {
  LIST_ORGANISATION_USERS,
  listOrganisationUsersSuccess,
  listOrganisationUsers,
} from "../actions/user";

import {
  showNotificationSuccess,
  showNotificationError,
} from "../actions/notification";

import { handleError } from "./common";

export const editOrganisationEpic = (action$, state) =>
  action$.pipe(
    ofType(EDIT_ORGANISATION),
    map((action) =>
      push(`/admin/settings/organisations/edit/${action.organisationId}`)
    )
  );

export const getOrganisationEpic = (action$, state) =>
  action$.pipe(
    ofType(GET_ORGANISATION),
    switchMap((action) =>
      api.getOrganisation$(action.organisationId).pipe(
        map((response) => getOrganisationSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const saveOrganisationEpic = (action$, state) =>
  action$.pipe(
    ofType(SAVE_ORGANISATION),
    switchMap((action) =>
      api.saveOrganisation$(action.organisationId, action.model).pipe(
        mergeMap((response) =>
          of(
            saveOrganisationSuccess(response),
            getOrganisation(action.organisationId)
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const saveOrganisationSuccessNotificationEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_ORGANISATION_SUCCESS),
    mapTo(showNotificationSuccess("Save organisation was successful"))
  );

export const listOrganisationUsersEpic = (action$, state) => {
  return action$.pipe(
    ofType(LIST_ORGANISATION_USERS),
    switchMap((action) =>
      api.listOrganisationUsers$(action.organisationId).pipe(
        map((response) => listOrganisationUsersSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const saveOrganisationFailedNotificationEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_ORGANISATION_FAILED),
    mapTo((action) => showNotificationError("Save organisation failed :("))
  );

export const listOrganisationsEpic = (action$) =>
  action$.pipe(
    ofType(LIST_ORGANISATIONS),
    switchMap((action) =>
      api.listOrganisations$(action.accountId).pipe(
        map((response) => listOrganisationsSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const removeUserEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_USER_FROM_ORGANISATION),
    switchMap((action) =>
      api
        .removeUserFromOrganisation(
          action.user.organisationId,
          action.user.userId
        )
        .pipe(
          map((ur) => removeUserFromOrganisatiSuccess(action.user)),
          catchError(handleError(action$, action.type))
        )
    )
  );

export const removeUserSuccessEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_USER_FROM_ORGANISATION_SUCCESS),
    mergeMap((action) =>
      of(
        showNotificationSuccess(`${action.user.fullName} has been removed`),
        listOrganisationUsers(action.user.organisationId)
      )
    )
  );
