import React from 'react'
import Table from '../common/table/Table'
import Privilege from '../../containers/Privilege'
import { NavLink } from 'react-router-dom'
import Card, { Body, Header } from '../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';

const InvitationTokenList = ({ tokens,
    createTokenEvent,
    schema }) => {


    return (
            <PageContent>
                <ContentHeader title="Network / Connection tokens"/>
                <ContentBody>
                    <Card>
                        <Header title="Tokens">
                            <div className="alert highlight" role="alert">
                                <span>
                                If your counterpart has a Cloudbuz account you can create a token to enable auto connect with that account. Your counterpart validates your account and can choose to create a connection with your token. 
                                A token can only be used once.
                                </span>
                            </div>
                        </Header>
                        <Body>
                            <p>
                                Have you received a token from someone?<span> </span>
                                <Privilege type="Network extend page" privilege="read">
                                    <NavLink activeClassName="active" to="/admin/extendnetwork/tokens/connectbytoken">
                                        Create a connection with your received token
                                    </NavLink>
                                </Privilege>
                            </p>
                            
                            <Table data={tokens} schema={schema} infoIfEmpty="No tokens created.." />
                            <button onClick={createTokenEvent} className="btn btn-primary btn-lg mt-3">Generate new token</button>
                        </Body>
                    </Card>
                </ContentBody>
            </PageContent>
    )
}

export default InvitationTokenList;