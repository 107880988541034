import React, { useState } from "react";
import { usePasswordVerification } from "../../hooks/hooks";
import Card, { Header, Body } from "../common/Card";
import { PasswordVerificationDialog } from "../common/dialogs/PasswordVerificationDialog";

const ChangeAccountOwner = ({ adminUsers, username, onChange }) => {
  const [
    verificationState,
    showVerification,
    hideVerification,
  ] = usePasswordVerification(onChange);
  const [toUserId, setUserId] = useState(0);

  const usersDropdown = adminUsers.map((au) => (
    username !== au.mailAdress ?  
    <option key={au.userId} value={au.userId}>
      {au.fullName} - {au.mailAdress}
    </option> : ""
  ));

  const accountOwner = adminUsers.find((e) => e.isOwner);
  const disabled = (toUserId > 1 ) ? (username === accountOwner.mailAdress) ? "" : "disabled" : "disabled";

  return (
    <Card className="card-settings">
      <PasswordVerificationDialog
        onHide={hideVerification}
        init={verificationState}
        username={username}
      />
      <Header title="Account owner">
        <div className="highlight" role="alert"> 
          <p className="pt-3">
            Only the current Cloudbuz account owner can transfer ownership to another account administrator.
          </p>
          {accountOwner && (
            <p>
              Current owner of this account is{" "}
              <span className="text-success">
                {accountOwner.fullName} ({accountOwner.mailAdress})
              </span>
              .
            </p>
          )}
        </div>
      </Header>
      <Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            showVerification({ toUserId });
          }}
        >
          <div className="table-responsive">
            <div className="form-group">
              <label>Account owner</label>
              <select
                className="form-control form-control-lg border-input"
                value={toUserId}
                onChange={(e) => setUserId(e.target.value)}
              >
                <option key={0}>--- Change account owner ---</option>
                {usersDropdown}
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-danger btn-lg"
            disabled={disabled}
          >
            Transfer
          </button>
        </form>
      </Body>
    </Card>
  );
};

export default ChangeAccountOwner;
