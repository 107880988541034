import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import Loader from '../components/common/Loader'
import CreateRequestNewInstallment from '../components/connectors/CreateRequestNewInstallment'


import {
    createConnectorInstallmentRequest,
    loadConnectorInstallmentData
} from '../actions/connectors'
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state) => {
    return {
        isFetching: isFetching(state.fetching, loadConnectorInstallmentData, createConnectorInstallmentRequest),
        ...state.connectors.createRequestNewInstallment,
        connectorTypes: state.connectors.connectorTypes,
        organisations: !state.connectors.organisations ? [] : state.connectors.organisations.map(item => {
            return {
                id: item.organisationId, name: item.name
            }
        })
    }
}

const mapDispatchToPros = (dispatch) => {
    return {
        initRequestInstallmentData: () => dispatch(loadConnectorInstallmentData()),
        createRequestInstallment: (values) => {
            return dispatch(createConnectorInstallmentRequest(values.installersEmail, values.message, values.connectorType, values.organisation))
        }
    }
}

const RequestNewInstallmentContainer = ({
    initRequestInstallmentData,
    isFetching,
    createRequestInstallment,
    organisations,
    connectorTypes
}) => {

    useEffect(() => {
        initRequestInstallmentData();
    }, [initRequestInstallmentData])

    return isFetching ?
        <Loader /> :
        <div>
            <CreateRequestNewInstallment 
                onSubmit={createRequestInstallment}
                connectorTypes={connectorTypes}
                organisations={organisations} />
        </div>
}

export default connect(mapStateToProps, mapDispatchToPros)(RequestNewInstallmentContainer)