import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";

import JobWizzardStepOneContainer from '../containers/Job/JobWizzardStepOne'
import JobWizzardStepTwoContainer from '../containers/Job/JobWizzardStepTwo'
import JobWizzardStepThreeContainer from '../containers/Job/JobWizzardStepThree'
import JobWizzardStepFourContainer from '../containers/Job/JobWizzardStepFour'
import JobWizzardStepFiveContainer from '../containers/Job/JobWizzardStepFive'
import JobBreadCrumbsContainer from '../containers/Job/JobBreadCrumbs'
import PageContent, { ContentBody, ContentHeader} from '../components/common/PageContent';

const JobsCreatePage = ({ match, location }) => {

    return (
        <PageContent className="border-0">
            <ContentHeader title="Jobs / Configuration">
                <JobBreadCrumbsContainer location={location} match={match} />
            </ContentHeader>
            <ContentBody>

                <Switch>
                    <Redirect exact from={`${match.url}`} to={`${match.url}/general`} />
                    <Route path={`${match.path}/general`} component={JobWizzardStepOneContainer} />
                    <Route path={`${match.path}/events`} component={JobWizzardStepTwoContainer} />
                    <Route path={`${match.path}/frequency`} component={JobWizzardStepThreeContainer} />
                    <Route path={`${match.path}/notifications`} component={JobWizzardStepFourContainer} />
                    <Route path={`${match.path}/summary`} component={JobWizzardStepFiveContainer} />
                </Switch>

            </ContentBody>
        </PageContent>
    )
}

export default JobsCreatePage