import React from 'react'
import { Route, Switch } from "react-router-dom";
import OrganisationListContainer from '../containers/OrganisationList';
import OrganisationCreateContainer from '../containers/OrganisationCreate';

const OrganisationsPage = ({ match }) => {
    return (
        <Switch>
            <Route path='/admin/settings/organisations/new' component={OrganisationCreateContainer} />
            <Route path={`/admin/settings/organisations`} component={OrganisationListContainer} />
        </Switch>
    )
}

export default OrganisationsPage