import React from 'react'
import PropTypes from 'prop-types'
import Privilege from '../../../containers/Privilege'
import { NavLink } from 'react-router-dom'
import './Menu.css'

const isExpanded = (path, item) => {
    return path.includes(item.to) || (item.children && [].concat(...item.children).some(c => path.includes(c.to))) 
}

const HasPrivilege = ({ privilege, type, children }) => {
    return privilege && type ?
        <Privilege
            privilege={privilege}
            type={type}>
            {children}
        </Privilege> :
        children || null
}

const MenuItem = ({ item, expanded }) => {
    return (
        <HasPrivilege privilege={item.privilege} type={item.privilegeType}>
            <li>
                <div className="d-flex align-items-center">
                    <NavLink className="flex-grow-1" to={item.to} >
                        { item.icon && <span className={"primary-color " + item.icon}></span>}
                        <span>{item.title}</span>
                    </NavLink>
                    {
                        item.children &&
                        (
                            expanded ?
                                <span className="icon icon-up-open align-right pr-5 pointer chevronmenu"></span> :
                                <span className="icon icon-down-open align-right pr-5 pointer chevronmenu"></span>
                        )
                    }
                </div>
                {
                    expanded
                    &&
                    item.children &&

                    item.children.map((items, j) =>
                        <ul key={j}>
                            {
                                items.filter(c => !c.hidden).map((item, i) => <MenuItem key={i} item={item} />)
                            }
                        </ul>

                    )
                }
            </li>
        </HasPrivilege>
    )
}

const Menu = ({ items, path = "" }) => {

    const menuItems = items.map(
        (item, i) => <MenuItem key={i} item={item} expanded={isExpanded(path, item)} />);

    return (
        <div className="menu flex-fill overflow-auto">
            <ul>
                {menuItems}
                <li className="bottomspacer"><span> </span></li>
            </ul>
        </div>
    )
}

Menu.propTypes = {
    items: PropTypes.array.isRequired
}

export default Menu;