import React from 'react'
import Card, { Body } from '../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';
import './Explore.css'


const Wrapper = ({ title, children}) => {
    return title ? 
    <PageContent>
        <ContentHeader title={title}/>
        <ContentBody>
            <Card className="p-0 m-0 border-0">
                <Body className="p-0">
                    {children}
                </Body>
            </Card>
        </ContentBody>
    </PageContent> 
    : children
}

const Explore = ({ view, header, title, children }) => {
   return <Wrapper title={title}>
                <div className="1">
                    {view?.path}
                </div>
                <div className="2">
                    { header }
                </div>
                <div className="3">
                    {children}
                </div>
            </Wrapper>
}

export  { default  as ExploreCommands } from './ExploreCommands'
export  { default  as ExploreBreadcrumbs } from './ExploreBreadcrumbs'
export  { default  as ExploreButtonBreadcrumbs } from './ExploreButtonBreadcrumbs'
export  { default  as ExploreSearch } from './ExploreSearch'
export  { default  as ExploreTable } from './ExploreTable'
export default Explore