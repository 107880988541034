import React from "react";
import Row from "./Row";
import Column from "./Column";
import "./Table.css";

/*
const schema = [
{header: 'Id', accessor: 'id', type: 'link'},
{header: 'Foo Custom Link', type: 'link', linkTo: (item) => `users/foo/${id}`},
{header: 'Email', accessor: 'email'},
{header: 'Created At', accessor: 'createdAt', type: 'date'},
{header: 'Anoter date', accessor: 'fooAt', type: 'date', format: 'yy-mm'},
{type: 'button', css: 'btn-delete', action: (item) => dispatch(delete(item.id))}
];

const data = [
{id: 1, email: '1@foo.com', createdAt: '2018-01-01:00:30', fooAt: '2018-01-01:00:30'},
{id: 2, email: '2@foo.com', createdAt: '2018-01-01:00:30', fooAt: '2018-01-01:00:30'}
];

const myTable = (
<Table schema={schema} data={data} />
);

// render rows (inside table)
data.map(d => <Row schema={schema} data={d}/>)

// render Column (header) (inside table)
schema.map(d => <Column schema={schema} />)
*/
const sortOrder = (schema, sortBy = []) => {
  const hasSort = sortBy.filter((sb) => sb.accessor === schema.accessor);
  return hasSort.length > 0
    ? hasSort.map((sb) => ({ ...schema, sortOrder: sb.sortOrder }))[0]
    : schema;
};

const applySortOrder = (sortBy) => (schema) => sortOrder(schema, sortBy);

const onSortClick = (onSortChange) => (schemaItem) =>
  onSortChange &&
  onSortChange([
    {
      accessor: schemaItem.accessor,
      sortOrder: schemaItem.sortOrder === "desc" ? "asc" : "desc",
    },
  ]);

const tryCollapseEmptyColumns = (data) => (schema) => {
  if (!schema.collapseIfEmpty) {
    return true;
  }
  return data.find((d) => d[schema.accessor]);
};

const Table = ({
  schema,
  data,
  onRowClick,
  onSortChange,
  sortBy,
  onChange,
  infoIfEmpty,
}) => {
  schema = schema?.filter(tryCollapseEmptyColumns(data));

  const rows =
    data !== undefined
      ? data?.map((r, i) => (
          <Row
            rowIndex={i}
            key={i}
            schema={schema}
            data={r}
            onRowClick={onRowClick}
            onChange={onChange}
          />
        ))
      : [];

  const columns = schema
    ?.map(applySortOrder(sortBy))
    .map((c, i) => (
      <Column key={i} schema={c} onClick={onSortClick(onSortChange)} />
    ));

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>{columns}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      {rows.length < 1 && (
        <div className="alert alert-empty text-center" role="alert">
          {infoIfEmpty}
        </div>
      )}
    </div>
  );
};

export default Table;
