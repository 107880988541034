import React from 'react'
import Table from '../common/table/Table'
import Privilege from '../../containers/Privilege'
import { NavLink } from 'react-router-dom'
import Card, { Header, Body } from '../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';

const ConnectionList = ({
    connections,
    schema,
    onConnectionSelect }) => {


    return (
        <PageContent>
            <ContentHeader title="Network / Connections"/>
            <ContentBody>
                <Card>
                    <Header title="Connected accounts">
                        <div className="alert highlight" role="alert">
                            <span>You can share folders and jobs with connected accounts.</span>
                        </div>
                        <Privilege type="Network extend page" privilege="read">
                            <p>
                                <NavLink activeClassName="active" to="/admin/extendnetwork/tokens" >
                                    Use a token 
                                </NavLink> or <NavLink activeClassName="active" to="/admin/extendnetwork/invites/createinvite" >
                                    send an e-mail request 
                                </NavLink> to create a new connection.
                            </p>
                        </Privilege>
                    </Header>
                    <Body>
                        <div className="table-responsive table-full-width">
                            <Table data={connections}
                                schema={schema}
                                onRowClick={onConnectionSelect}
                                infoIfEmpty="No connected accounts.." />
                        </div>
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>
    )
}

export default ConnectionList;