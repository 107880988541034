import React from 'react'

import AccountEditContainer from '../containers/AccountEdit'
import Privilege from '../containers/Privilege'
import { Route, Redirect, Switch } from "react-router-dom";
import { NavLink } from 'react-router-dom'

const AccountPage = ({ match }) => {
    return <div className="content">
        <div className="row">
            <div className="col-sm-12">
                <ul className="nav nav-tabs bordered">
                    <li>
                        <Privilege type="Account page" privilege="read_write">
                            <NavLink activeClassName="active" to={`${match.url}/edit`}>Edit</NavLink>
                        </Privilege>
                    </li>
                </ul>

                <div className="tab-content bg-color-white padding-10" >
                    <Switch>
                        <Redirect exact from={match.url} to={`${match.url}/edit`} />
                        <Route path={`/admin/account/edit`} component={AccountEditContainer} />
                    </Switch>
                </div>
            </div>
        </div>
    </div>
}

export default AccountPage