import React from "react";
import { Link } from "react-router-dom";
import { dateFormat } from "../../../common/dateFormatter";
import Card, { Body, Header } from "../../common/Card";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.



const Subscription = ({ subscription, iscurrent }) => {
  return (

    <div className="card bg-light mb-3">
      <div className="card-header">
        <h5>{subscription?.description} - {subscription?.includedCVU} CVU / month</h5>
      </div>
      <div className="card-body">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: subscription?.includedHtml }}
        ></div>
      </div>
      <div className="card-footer">
        <small className="text-muted">{iscurrent ? "Current plan" : "New plan"} - {subscription?.cost} EUR / month</small>
      </div>
    </div>
  );
};

const CompareChangePlan = ({ currentPlan, newPlan, onConfirm, info, invoice }) => {
  return (
    <Card>
      <Header title="Review changes"></Header>
      <Body>
        <div className="card-deck">
          <Subscription subscription={currentPlan} iscurrent={true} />
          <div
            className="col-sm-2 d-flex justify-content-center align-items-center"
            style={{ fontSize: "8rem" }}
          >
            &#8594;
          </div>
          <Subscription subscription={newPlan} iscurrent={false} />
        </div>
        <h4 className="mt-5">Breakdown of changes</h4>
        <div className="detail-table">
          <div className="row">
            <div className="col-sm-12 border-right border-white">
              {newPlan?.includedCVU > currentPlan?.includedCVU ? "Upgrade" : "Downgrade"} from {currentPlan?.description} {currentPlan?.includedCVU}{" "}
              CVU / month to {newPlan?.description} {newPlan?.includedCVU} CVU /
              month <br />


              <div className="p-5">
                <strong>Current Plan: </strong> {currentPlan?.description}{" "}
                {currentPlan?.includedCVU} CVU / month - {currentPlan?.cost} EUR
                per month <br />
                <strong>New Plan: </strong> {newPlan?.description}{" "}
                {newPlan?.includedCVU} CVU / month - {newPlan?.cost} EUR per month
              </div>


              {
                newPlan?.includedCVU < currentPlan?.includedCVU &&
                <div>
                  We'll record the plan change and renew the plan {newPlan?.description} {newPlan?.includedCVU} CVU /
                  month on {dateFormat(currentPlan?.currentPeriodEnd, 'YYYY-MM-DD')}. Nothing will be charged at this time.
                </div>
              }
            </div>

          </div>
          {
            invoice?.lines?.map((l, i) =>
              <div className="row" key={i}>
                <div className="col-sm-10 border-right border-white text-right">
                  {l.description}
                </div>
                <div className="col-sm-2 d-flex justify-content-center align-items-center">
                  {l.amount} {l.currency}
                </div>
              </div>
            )
          }
          <div className="row">
            <div className="col-sm-10 border-right border-white text-right">
              Sub total
            </div>
            <div className="col-sm-2 d-flex justify-content-center align-items-center">
              {invoice?.subTotal} {invoice?.currency}
            </div>
          </div>
          {
            invoice?.discount &&
            <div className="row">
              <div className="col-sm-10 border-right border-white text-right">
                Discount
              </div>
              <div className="col-sm-2 d-flex justify-content-center align-items-center">

                {invoice?.discount} {invoice?.currency}

              </div>
            </div>

          }
          {
            invoice?.discountPercent &&
            <div className="row">
              <div className="col-sm-10 border-right border-white text-right">
                Discount
              </div>
              <div className="col-sm-2 d-flex justify-content-center align-items-center">

                {invoice?.discountPercent}%

              </div>
            </div>

          }
          <div className="row">
            <div className="col-sm-10 border-right border-white text-right">
              Tax
            </div>
            <div className="col-sm-2 d-flex justify-content-center align-items-center">
              {invoice?.tax} {invoice?.currency}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 border-right border-white text-right">
              Total
            </div>
            <div className="col-sm-2 d-flex justify-content-center align-items-center">
              {invoice?.total} {invoice?.currency}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 border-right border-white text-right">
              Amount due
            </div>
            <div className="col-sm-2 d-flex justify-content-center align-items-center">
              {invoice?.amount} {invoice?.currency}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 border-right border-white text-right">
              Due date
            </div>
            <div className="col-sm-2 d-flex justify-content-center align-items-center">
              {dateFormat(invoice?.dueDate, "YYYY-MM-DD")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 pt-5">
            <button
              className="btn btn-lg btn-success mr-3"
              onClick={(e) => onConfirm(newPlan)}
              data-testid="confirm"
            >
              {info?.stripePaymentId ? "Confirm" : "Proceed to payment"}
            </button>
            <Link
              to={`/admin/subscription/your-plan/change-plan/${currentPlan?.id}`}
              className="btn btn-lg btn-light"
            >
              Go back
            </Link>
          </div>
        </div>
      </Body>
    </Card>
  );
};

export default CompareChangePlan;
