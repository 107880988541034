import React from "react";
import { connect } from "react-redux";
import { lifecycle } from "recompose";

import UserListPending from "../components/users/UserListPending";

import { listPendingAccountUsers } from "../actions/user";
import { editUser } from "../actions/user";

import Loader from "../components/common/Loader";

import { revokeUserInvite } from "../actions/invite";
import { isFetching } from "../selectors/Fetching";
import { useModal } from "../components/modal/Modal";

const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state.fetching, listPendingAccountUsers),
  users: state.user.pending,
  accountId: state.auth.user.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  onUserSelect: (user) => dispatch(editUser(user.accountId, user.userId)),
  onRemove: (invite) => dispatch(revokeUserInvite(invite.token)),
  initialState: () => dispatch(listPendingAccountUsers()),
});

let UserListPendingContainer = ({ title, users, onUserSelect, onRemove }) => {
  const { showModal } = useModal();

  if (!users) {
    return <Loader />;
  }

  if (!users.length) {
    return "";
  }

  let schema = [
    { accessor: "inviteeMail", header: "Email" },
    {
      accessor: "created",
      header: "Created",
      type: "date",
      format: "YYYY-MM-DD HH:mm",
    },
    {
      accessor: "expires",
      header: "Expires",
      type: "date",
      format: "YYYY-MM-DD HH:mm",
    },
    {
      type: "button",
      css: "btn btn-danger float-right",
      action: (user) => {
        showModal("Confirm", {
          title: "Revoke pendning invitation?",
          message:
            "The user will not be able to join your account. Are you sure?",
          confirmBtnText: "Revoke",
          confirmBtnDanger: true,
          init: { next: onRemove, args: [user] },
        });
      },
      actionText: "Revoke invitation",
    },
  ];

  return (
    <React.Fragment>
      <UserListPending users={users} schema={schema} title={title} />
    </React.Fragment>
  );
};

UserListPendingContainer = lifecycle({
  componentDidMount() {
    const { initialState } = this.props;
    initialState();
  },
})(UserListPendingContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListPendingContainer);
