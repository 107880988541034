import * as api from '../api'

import { switchMap, catchError, map, mapTo } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import {
    SAVE_NOTIFICATION_SETTINGS,
    LOAD_NOTIFICATION_SETTINGS,
    saveNotificationSettingsSuccess,
    loadNotificationSettingsSuccess,
    SAVE_JOB_NOTIFICATION_SETTINGS,
    SAVE_JOB_NOTIFICATION_SETTINGS_SUCCESS,
    SAVE_NOTIFICATION_SETTINGS_SUCCESS,
    saveJobNotificationSettingsSuccess
} from '../actions/notificationsettings'

import { handleError } from './common';

import { showNotificationSuccess } from '../actions/notification';

export const saveNotificationsSettingsEpic$ = action$ => {
    return action$.pipe(
        ofType(SAVE_NOTIFICATION_SETTINGS),
        switchMap((action) =>
            api.saveNotificationSettings$(action.wantsEmail,
                                          action.wantsSms,
                                          action.wantsWeb,
                                          action.wantsJob,
                                          action.wantsSystem,
                                          action.wantsPromotional,
                                          action.emailNotificationType,
                                          action.emailNotificationSpec).pipe(
                map(response => saveNotificationSettingsSuccess()),
                catchError(handleError(action$, action.type))
            )
        )
    )
}

export const saveNotificationsSettingsSuccessNotificationEpic = (action$) => action$.pipe(
    ofType(SAVE_NOTIFICATION_SETTINGS_SUCCESS),
    mapTo(showNotificationSuccess("Save notification settings was successful"))
)

export const loadNotificaionSettings$ = action$ => {
    return action$.pipe(
        ofType(LOAD_NOTIFICATION_SETTINGS),
        switchMap((action) =>
            api.getNotificationSettings$().pipe(
                map(response => {
                    return loadNotificationSettingsSuccess(response)
                })
            )
        )
    )
}
  
export const saveJobNotificationsSettingsEpic$ = action$ => {
    return action$.pipe(
        ofType(SAVE_JOB_NOTIFICATION_SETTINGS),
        switchMap((action) =>
            api.saveJobNotificationSettings$(action.settings).pipe(
                map(response => saveJobNotificationSettingsSuccess()),
                catchError(handleError(action$, action.type))
            )
        )
    )
}

export const saveJobNotificationsSettingsSuccessNotificationEpic = (action$) => action$.pipe(
    ofType(SAVE_JOB_NOTIFICATION_SETTINGS_SUCCESS),
    mapTo(showNotificationSuccess("Save job notification subscription settings was successful"))
)

