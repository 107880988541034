import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dateFormat } from "../../../common/dateFormatter";
import CurrentCreditCardContainer from "../../../containers/subscription/credit-card/CurrentCreditCard";
import OnDemandContainer from "../../../containers/subscription/OnDemand";
import Card, { Body, Header } from "../../common/Card";
import CountrySelection from "./CountrySelection";
import TaxSelection from "./TaxSelection";

const BillingInfo = ({ info: inf, onChange }) => {
  const [info, setInfo] = useState();

  useEffect(() => {
    if (!inf) {
      return;
    }
    setInfo({
      billingContactEmail: inf?.billingContactEmail,
      extraBillingInfo: inf?.extraBillingInfo,
      taxId: inf?.taxId,
      taxType: inf?.taxType ?? 'eu_vat',
      address: inf?.address,
      city: inf?.city,
      postal: inf?.postal,
      state: inf?.state,
      country: inf?.country,
      stripePaymentMethod: inf?.stripePaymentId,
    });
  }, [inf]);

  return (
    <Card>
      <Header title="Billing info - details"></Header>
      <Body>
        {inf?.stripePaymentId && (
          <div>
            <CurrentCreditCardContainer
              onChange={(paymentId) => {
                setInfo({ ...info, stripePaymentMethod: paymentId });
                onChange({ ...info, stripePaymentMethod: paymentId });
              }
              }
            />
          </div>
        )}
        <div>
          {inf?.stripeSubscriptionId && (
            <>
              <span>Next billed on: </span>
              <strong>{dateFormat(inf?.currentPeriodEnd, "YYYY-MM-DD")}</strong>
            </>
          )}
        </div>
        <hr />
        <div>
          <span>You are currently on the </span>
          <strong>
            {inf?.description} -{" "}
            {inf?.includedCVU === -1 ? "unlimited" : inf?.includedCVU} CVU/month
            plan
          </strong>
          {inf?.id >= 40 ? (
            <button className="btn btn-link disabled">Change plan</button>
          ) : (
            <Link
              to={`/admin/subscription/your-plan/change-plan/${inf?.id}`}
              className="btn btn-link"
            >
              Change plan
            </Link>
          )}
        </div>
        <div>
          <span>Your account is set to </span>
          <strong>monthly billing</strong>
        </div>
        {info?.enableOnDemandCVU && (
          <div>
            <span>Once you reach </span>
            <strong>{inf?.includedCVU} </strong>
            <span>CVU/month, we charge you </span>
            <strong>{inf?.costOnDemandCVU * 1000} EUR </strong>
            <span>per thousand additional CVU</span>
          </div>
        )}
        {
          !inf?.stripeCustomerId &&
          <div className="alert alert-warning mt-3">
            You will need to atleast select a <strong>country</strong> to be able to upgrade your subscription.
          </div>
        }
        <hr></hr>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onChange(info);
          }}
          data-testid="formInfo"
        >
          <div className="form-group">
            <label htmlFor="name">Billing contact e-mail</label>
            <input
              type="email"
              className="form-control"
              id="name"
              placeholder="Email"
              aria-describedby="emailHelp"
              data-testid="email"
              defaultValue={inf?.billingContactEmail}
              onChange={(e) => {
                setInfo({ ...info, billingContactEmail: e.target.value });
              }}
            />
            <small id="emailHelp" className="form-text text-muted">
              Receipts, product updates, etc. will be sent to this address. It
              will not receive job or system notifications. To enter multiple
              addresses, separate them with commas
            </small>
          </div>
          <CountrySelection country={info?.country} onChange={iso => setInfo({ ...info, country: iso })}></CountrySelection>
          <TaxSelection country={info?.country} taxId={info?.taxId} taxType={info?.taxType} onChange={tax => setInfo({ ...info, ...tax })}></TaxSelection>
          <div className="form-group">
            <label htmlFor="name">Address</label>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Address"
              data-testid="address"
              defaultValue={inf?.address}
              onChange={(e) => {
                setInfo({ ...info, address: e.target.value });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">State</label>
            <input
              type="text"
              className="form-control"
              id="state"
              placeholder="State"
              data-testid="state"
              defaultValue={inf?.state}
              onChange={(e) => {
                setInfo({ ...info, state: e.target.value });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Postal</label>
            <input
              type="text"
              className="form-control"
              id="postal"
              placeholder="Postal"
              data-testid="postal"
              defaultValue={inf?.postal}
              onChange={(e) => {
                setInfo({ ...info, postal: e.target.value });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">City</label>
            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="City"
              data-testid="city"
              defaultValue={inf?.city}
              onChange={(e) => {
                setInfo({ ...info, city: e.target.value });
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="info">Extra billing info</label>
            <textarea
              type="text"
              className="form-control"
              id="info"
              placeholder="Info"
              data-testid="info"
              defaultValue={info?.extraBillingInfo}
              maxLength={30}
              onChange={(e) => {
                setInfo({ ...info, extraBillingInfo: e.target.value });
              }}
            />
            <small id="infoHelp" className="form-text text-muted">
              If you need additionals information included on your invoices,
              such as your full business name, address, or VAT number, enter it
              here. We'll include it on all future invoices
            </small>
          </div>
          <button className="btn btn-secondary btn-lg">Save</button>
          {inf?.allowAdditionalCVU && inf?.id < 40 && (
            <OnDemandContainer
              isEnabled={inf?.enableOnDemandCVU}
              includedCVU={inf?.includedCVU}
              costOnDemandCVU={inf?.costOnDemandCVU}
            />
          )}
        </form>
      </Body>
    </Card>
  );
};

export default BillingInfo;
