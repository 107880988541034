import * as api from "../api";

import { switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";

import { handleError } from "./common";

import {
  LOAD_GENERAL_SETTINGS,
  UPDATE_GENERAL_SETTINGS,
  DELETE_USER,
  LEAVE_ORGANISATION,
  LEAVE_ACCOUNT,
  loadGeneralSettings,
  updateGeneralSettingsSuccess,
  loadGeneralSettingsSuccess,
  updateTimezoneSuccess,
  UPDATE_TIMEZONE,
} from "../actions/generalsettings";

import { logout, refreshAuth } from "../actions/auth";
import { showNotificationSuccess } from "../actions/notification";
import { of } from "rxjs";

export const leaveOrganisationEpic$ = (action$) => {
  return action$.pipe(
    ofType(LEAVE_ORGANISATION),
    switchMap((action) =>
      api.leaveOrganisation$(action.orgId).pipe(
        mergeMap((response) => of(refreshAuth(), loadGeneralSettings())),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const leaveAccountEpic$ = (action$) => {
  return action$.pipe(
    ofType(LEAVE_ACCOUNT),
    switchMap((action) =>
      api.leaveAccount$(action.accountId).pipe(
        mergeMap((response) => of(refreshAuth(), loadGeneralSettings())),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const deleteUserEpic$ = (action$) => {
  return action$.pipe(
    ofType(DELETE_USER),
    switchMap((action) =>
      api.deleteUser$().pipe(
        map((response) => logout()),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const loadGeneralSettingsEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_GENERAL_SETTINGS),
    switchMap((action) =>
      api.loadGeneralSettings$().pipe(
        map((response) => loadGeneralSettingsSuccess(response.settings)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const updateGeneralSettingsEpic$ = (action$) => {
  return action$.pipe(
    ofType(UPDATE_GENERAL_SETTINGS),
    switchMap((action) =>
      api.updateGeneralSettings$(action.model).pipe(
        mergeMap((response) =>
          of(
            updateGeneralSettingsSuccess(),
            showNotificationSuccess("Settings has been updated")
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const updateTimezoneEpic$ = (action$) => {
  return action$.pipe(
    ofType(UPDATE_TIMEZONE),
    switchMap((action) =>
      api.updateTimezone$(action.timezone).pipe(
        mergeMap((response) =>
          of(
            updateTimezoneSuccess(),
            showNotificationSuccess("Timezone has been updated")
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );
};
