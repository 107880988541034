import React from "react";
import { useModal } from "../../modal/Modal";

export const Dialog = ({
  title,
  confirmBtnText,
  confirmBtnDanger,
  children,
  onConfirm,
  onClose,
}) => {
  const { closeModal } = useModal();
  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm(closeModal);
        }}
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                onClose && onClose();
                closeModal();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="modal-message">{children}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light btn-lg"
              onClick={() => {
                onClose && onClose();
                closeModal();
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={
                confirmBtnDanger
                  ? "btn btn-danger btn-lg"
                  : "btn btn-primary btn-lg"
              }
            >
              {confirmBtnText ?? "Confirm"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Dialog;
