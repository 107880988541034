import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { getPrivilege } from "../selectors/Privilege";

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  hasPrivilege: ownProps.type && ownProps.privilege,
  routerPrivilege:  getPrivilege(state, ownProps.type, ownProps.privilege),
  ...ownProps,
});

const AuthorizationRoute = ({
  routerPrivilege,
  isAuthenticated,
  hasPrivilege,
  component,
  ...rest
}) => {

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated && (!hasPrivilege || routerPrivilege) ? (
          component(props)
        ) : isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/",
              state: { referrer: props.location.pathname },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: props.location.pathname },
            }}
          />
        );
      }}
    />
  );
};

export default withRouter(connect(mapStateToProps)(AuthorizationRoute));
