export const getHeaders = () => {
    return [
        {
            key : "name",
            description : "Name"
        }, 
        {
            key: "orgNo",
            description: "Organization number"
        },
        {
            key: "created",
            description: "Created"
        }
        
    ];
}