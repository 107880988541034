import React from 'react'
import { Field, reduxForm } from 'redux-form';
import './AcceptInvite.css'
import { required } from '../common/ReduxFormFieldValidation';
import { renderField } from '../common/ReduxFormHelper';
import LoginCard, { Header, Body } from '../common/LoginCard';
import CbLogo from '../../images/logo_white.png'

const AcceptSignupUserComponent = ({ initialValues, handleSubmit, errors, submitting }) => {
    const showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""
    return (
        <div>
            <div className="logo_login_placeholder">
                <img src={CbLogo} height="30px" alt="Cloudbuz Manager" className="logo_login" />
            </div>
            <LoginCard>
                <Header title="Complete registration">
                    <div className="alert alert-primary" role="alert"> 
                        <p>Your company email <strong>{initialValues.inviteeMail}</strong> has been confirmed!</p>
                        <p>Please complete the form to create your new Cloudbuz account.</p>
                    </div>
                </Header>
                <Body>
                    <form onSubmit={handleSubmit}>
                        <fieldset>
                            <Field
                                name="fullName"
                                component={renderField}
                                type="text"
                                placeholder="Your full name"
                                icon="fa-user"
                                validate={[required]}
                                tooltip="Please enter your full name"
                            />
                            <Field
                                name="accountName"
                                component={renderField}
                                type="text"
                                placeholder="Name of your organization"
                                icon="fa-user"
                                validate={[required]}
                                tooltip="Please enter a name for your account"
                            />

                            {showError}

                            <button type="submit" className="btn btn-success btn-block btn-lg" disabled={submitting}>
                                Create account
                            </button>
                        </fieldset>
                        <footer className="text-center">

                        </footer>
                    </form>
                </Body>
            </LoginCard>
        </div>
    )

}

export default reduxForm({
    form: 'accept-invite-signupuser', // a unique identifier for this form
})(AcceptSignupUserComponent);

