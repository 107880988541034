import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotificationError } from "../../../actions/notification";
import Dialog from "../../../components/common/dialogs/Dialog";
import NewCreditCard from "../../../components/subscriptions/credit-card/NewCreditCard";

const NewCreditCardContainer = ({ onSubmit, onClose, title }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [bi, setBillingInfo] = useState({ name: "", email: "", phone: "" });
  const dispatch = useDispatch();

  const getPaymentMethod = useCallback(
    async (bi) => {
      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: bi
      });

      return { error, paymentMethod };
    },
    [elements, stripe]
  );

  const onConfirm = useCallback(async (close) => {
    if (!bi.name || !bi.phone || !bi.email) {
      dispatch(showNotificationError("Please provide correct billing info"))
      return;
    }
    const { error, paymentMethod } = await getPaymentMethod(bi);
    
    if (error) {
      dispatch(showNotificationError(error.message  ))
      return;
    }
    onSubmit(paymentMethod);
    close();
  }, [bi, dispatch, getPaymentMethod, onSubmit]);

  return (
    <Dialog onConfirm={onConfirm} onClose={onClose} title={title}>
      <NewCreditCard info={bi} onChange={setBillingInfo}></NewCreditCard>
    </Dialog>
  );
};

export default NewCreditCardContainer;
