import React, { useMemo } from "react";
import { volume } from "../../../common/volumeFormatter";
import Table from "../../common/table/Table";

const schema = [
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Description",
    accessor: "description",
  },
  {
    header: "Used",
    accessor: "used",
  },
  {
    header: "Unit",
    accessor: "unit",
  },
];

const types = {
  runs: {
    type: "Runs",
    description: "Executed event runs",
    unit: "pcs",
  },
  files: {
    type: "Files",
    description: "Processed Files (Copy, Move and Delete)",
    unit: "pcs",
  },
  bytes: {
    type: "Data",
    description: "Processed Data (Copy, Move and Delete)",
    unit: "mbs",
    convert: (value) => {
      const vol = volume(value, 2);
      return { unit: vol.volume, used: vol.data };
    },
  }
};

const PeriodSummary = ({ summary }) => {
  const periodSummary = useMemo(() => {
    if (!summary) {
      return [];
    }
    return Object.entries(summary)
      .filter((arg) => arg[1] != null && types[arg[0]])
      .map((arg) => {
        if (types[arg[0]].convert) {
          const format = types[arg[0]].convert(arg[1]);
          return {
            ...types[arg[0]],
            ...format
          };
        }
        return {
          ...types[arg[0]],
          used: arg[1],
        };
      });
  }, [summary]);

  return (
    <>
      <Table schema={schema} data={periodSummary}></Table>
      <div className="alert highlight" role="alert">
        <p><strong>CVU calculation</strong></p>
        (No. of files) + (Data in MB) + (No of event runs * 10) = <strong>{summary?.cvu} CVU</strong>
      </div>
    </>
  );
};

export default PeriodSummary;
