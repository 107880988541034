import React from 'react'
import { connect } from 'react-redux'
import CreateForgotPasswordComponent from '../components/forgot-password/CreateForgotPassword'
import Loader from '../components/common/Loader'
import { createForgotPassword } from '../actions/auth'
import { isFetching } from '../selectors/Fetching'

const mapStateToProps = (state) => ({
    isFetching: isFetching(state.fetching, createForgotPassword),
    message: state.auth.message,
    errors: state.auth.errors
});

const mapDispatchToProps = dispatch => ({
    forgotPassword:  (values) => { dispatch(createForgotPassword(values.email)); }
})

const CreateForgotPasswordContainer = ({forgotPassword, isFetching}) => {

    return isFetching ? <Loader /> : 
            <CreateForgotPasswordComponent onSubmit={forgotPassword}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateForgotPasswordContainer)