export const getHeaders = () => [
    {
        accessor: "companyName",
        description: "Company name"
    },
    {
        accessor: "accountType",
        description: "Account type"
    },
    {
        accessor: "created",
        description: "Created"
    }

];

export const getAccountTypes = () => {
    return [
        { value : 1	, description: "Enterprise"},
        { value : 2	, description: "Business"},
        { value : 3	, description: "Lite"},
        { value : 4	, description: "Free"},
        { value : 5	, description: "Trial"}
    ]
}

export const getAccountTypeDescription = (accountId) => {
    return getAccountTypes().filter(f => f.value === accountId)[0].description;
}