import React from 'react'
import ConnectorsListContainer from '../containers/ConnectorsList'
import PageContent, { ContentHeader, ContentBody } from '../components/common/PageContent';

const ConnectorsPage = ({ match }) => {
    return (
        <PageContent className="border-0">
            <ContentHeader title="Network / Connectors"/>
            <ContentBody>
                <ConnectorsListContainer />
            </ContentBody>
        </PageContent>
    )
}

export default ConnectorsPage