import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { list$ } from "../../api/endpoints";
import EndpointsComponent from "../../components/endpoints/Endpoints";
import EndpointsSchema from "./EndpointsSchema";
import qs from "query-string";

const EndpointsContainer = () => {
  const [text, setText] = useState();
  const [page, setPage] = useState(1);
  const history = useHistory();

  const schema = useMemo(() => {
    return EndpointsSchema;
  }, []);

  const status = useMemo(() => {
    const filter = qs.parse(window.location.search, {
      arrayFormat: "bracket",
      parseBooleans: true,
      parseNumbers: true,
    });

    return filter.status;
  }, []);

  const [endpointsView, setEndpointsView] = useState([]);

  useEffect(() => {
    list$(text, status, page).subscribe(setEndpointsView, (error) =>
      console.log(error)
    );
  }, [text, status, page]);

  const onFilterChange = useCallback((text) => {
    setText(text);
  }, []);

  const onPageChange = useCallback((page) => {
    setPage(page);
  }, []);

  return (
    <EndpointsComponent
      view={endpointsView}
      schema={schema}
      onFilterChange={onFilterChange}
      onPageChange={onPageChange}
      onEndpointSelect={(e) => e.shareId ?  history.push(`/admin/endpoints/${e.id}/${e.shareId}`) : history.push("/admin/endpoints/" + e.id)}
      page={page}
    ></EndpointsComponent>
  );
};

export default EndpointsContainer;
