import React from "react";
import { Link } from "react-router-dom";
import Card, { Body, Header } from "../../common/Card";

const CancelSubscription = () => {
  return <Card>
  <Header title="Cancel subscription">
      Your subscription may be canceled at the end of your current monthly billing cycle. Your account will not be deleted and can be placed on a new subscription in the future.
  </Header>
  <Body>
    <Link className="btn btn-danger btn-lg mt-5" to="/admin/subscription/your-plan/compare-change-plan/10">
      Cancel subscription
    </Link>
  </Body>
</Card>
};

export default CancelSubscription;
