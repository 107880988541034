import React from 'react'
import { connect } from 'react-redux'
import ForgotPasswordComponent from '../components/forgot-password/ForgotPassword'
import Loader from '../components/common/Loader'
import { forgotPassword } from '../actions/auth'
import { isFetching } from '../selectors/Fetching'

const mapStateToProps = (state) => ({
    isFetching: isFetching(state.fetching, forgotPassword),
    message: state.auth.message,
    errors: state.auth.forgottPasswordErrors
});

const mapDispatchToProps = dispatch => ({
    forgottPassword: (token) => (values) => dispatch(forgotPassword(token, values))
})

let ForgotPasswordContainer = ({ match, forgottPassword, isFetching, errors }) => {

    return isFetching ? <Loader /> :
        <ForgotPasswordComponent errors={errors} onSubmit={forgottPassword(match.params.token)} />
}

ForgotPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer)

export default ForgotPasswordContainer