import {
    AUTOCOMPLETE_DO_SEARCH,
    AUTOCOMPLETE_DO_SEARCH_SUCCESS,
    AUTOCOMPLETE_CLEAR
} from '../actions/autocomplete';

export const autocomplete = (state = {}, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_DO_SEARCH:
            return {
                ...state,
                [action.key]: {
                    isFetching: true,
                    searchFor: action.searchFor,
                }
            }
        case AUTOCOMPLETE_DO_SEARCH_SUCCESS:
            return {
                ...state,
                [action.key]: {
                    result: action.result,
                    searchFor: action.searchFor,
                    isFetching: false
                },
            }
        case AUTOCOMPLETE_CLEAR:
            return {
                ...state,
                [action.key]: {
                    result: [],
                    searchFor: '',
                    isFetching: false
                },
            }
        default:
            return state
    }
}