import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import InvitationTokenList from '../components/invites/InvitationTokenList'

import {
    loadInvitesList,
    loadInvitationTokens,
    createInvitationToken,
    deleteInvitationToken
} from '../actions/invite'
import Loader from '../components/common/Loader'
import { isFetching } from '../selectors/Fetching';


const mapStateToProps = (state) => {
    return {
        invitationTokens: state.invite.invitationTokens,
        isFetching: isFetching(state.fetching, loadInvitesList, loadInvitationTokens),
        sentInvites: state.invite.sentInvites,
        receivedInvites: state.invite.recivedInvites
    }
}

const mapDispatchToPros = (dispatch) => {
    return {
        initalStateInvitationTokens: () => dispatch(loadInvitationTokens()),
        createToken: () => dispatch(createInvitationToken()),
        deleteToken: (invite) => dispatch(deleteInvitationToken(invite.token))
    }
}


const ExtendedNetworkTokensListContainer = ({
    initalStateInvitationTokens,
    isFetching,
    invitationTokens,
    createToken,
    deleteToken,
}) => {

    const tokensSchema = [
        { accessor: "token", header: "Token" },
        { accessor: "created", header: "Created", type: "date" },
        { accessor: "expires", header: "Expires in", type: "date" },
        //{ type: 'button', css: 'btn btn-danger', action: deleteToken, actionText: "Revoke" },
        {
            type: 'custom', render: (data, schema, rowIndex, onRowClick, onChange) => 
                <button className="btn btn-danger float-right text-nowrap" onClick={() => { deleteToken(data); }}><i className="icon icon-trash"></i> Revoke</button> 
        }
    ]

    useEffect(() => {
        initalStateInvitationTokens();
    }, [initalStateInvitationTokens])

    return isFetching ?
        <Loader /> :
        <InvitationTokenList
            schema={tokensSchema}
            tokens={invitationTokens}
            createTokenEvent={createToken}
        />

}

export default connect(mapStateToProps, mapDispatchToPros)(ExtendedNetworkTokensListContainer)