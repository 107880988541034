import {
    LOAD_JOBS_LIST,
    LOAD_JOBS_LIST_SUCCESS,
    LOAD_CREATE_JOBS_SUCCESS,
    LOAD_SCHEDULE_SUCCESS,
    LOAD_JOB_NOTIFICATION_USERS_SUCCESS,
    LOAD_JOB_RUNS_SUCCESS,
    LOAD_JOB_RUN_EVENT_SUCCESS,
    LOAD_JOB_RUN_SUCCESS,
    LOAD_JOB_RUN_FILE_SUCCESS,
    FILE_FILTER_CHANGED,
    RUNS_FILTER_CHANGE,
    RUNS_CURRENTPAGE_CHANGE,
    LOAD_JOB_VIEW,
    LOAD_JOB_VIEW_SUCCESS,
    JOBS_CURRENTPAGE_CHANGE,
    LOAD_JOB_SUCCESS,
    NEW_JOB,
    SAVED_JOB_SUCCESS,
    CREATE_NEW_JOB,
    LOAD_JOB_RUN_EVENT,
} from '../actions/jobs';
import { START_AUTO_REFRESH, CANCEL_AUTO_REFRESH } from '../actions/autorefresh';

import qs from "query-string";

export const jobs = (state = {
    jobs: [],
    currentJobsPage: 1,
    totalCountRow: 0,
    totalPages: 0,
    schedules: [],
    notificationUsers: [],
    notificationAccounts: [],
    fileFilterId: 0,
    currentFilesPage: 1,
    selectedRunsFilter: qs.parse(window.location.search, {
        arrayFormat: "bracket",
        parseBooleans: true,
        parseNumbers: true,
    })
}, action) => {
    switch (action.type) {
        case LOAD_JOBS_LIST:
            return {
                ...state,
                currentJobsPage: action.page,
                text: action.text,
                sortBy: action.sortBy
            }
        case LOAD_CREATE_JOBS_SUCCESS:
            return {
                ...state,
                schedules: action.schedules
            }
        case LOAD_SCHEDULE_SUCCESS:
            return {
                ...state,
                schedule: action.schedule
            }
        case LOAD_JOBS_LIST_SUCCESS:
            return {
                ...state,
                jobs: action.jobs || [],
                totalPages: action.totalPages,
                totalCountRow: action.totalCountRows
            }
        case LOAD_JOB_NOTIFICATION_USERS_SUCCESS:
            return {
                ...state,
                notificationAccounts: action.accountUsers,
                notificationUsers: action.users
            }
        /*case LOAD_JOB_RUNS:
            return {
                ...state,
                selectedRunsFilter: action.filters
            }*/
        case LOAD_JOB_RUNS_SUCCESS:
            return {
                ...state,
                runsView: action.runsView
            }
        case LOAD_JOB_RUN_SUCCESS:
            return {
                ...state,
                run: action.run
            }
        case LOAD_JOB_RUN_EVENT:
            return {
                ...state,
                currentFilesPage: action.currentFilesPage
            }
        case LOAD_JOB_RUN_EVENT_SUCCESS:
            return {
                ...state,
                event: action.event,
                fileFilterId: action.event.filterFilesId
            }
        case LOAD_JOB_RUN_FILE_SUCCESS:
            return {
                ...state,
                file: action.file
            }
        case FILE_FILTER_CHANGED:
            return {
                ...state,
                fileFilterId: action.fileFilterId,
                currentFilesPage: 1
            }
        case START_AUTO_REFRESH:
            return {
                ...state,
                autoRefresh: true
            }
        case CANCEL_AUTO_REFRESH:
            return {
                ...state,
                autoRefresh: false
            }
        case RUNS_FILTER_CHANGE:
            return {
                ...state,
                selectedRunsFilter: action.selectedRunsFilter,
                currentRunsPage: 1
            }
        case RUNS_CURRENTPAGE_CHANGE:
            return {
                ...state,
                currentRunsPage: action.currentRunsPage
            }
        case LOAD_JOB_VIEW:
            return {
                ...state,
                jobViewSearchText: action.text,
                sortAllBy: action.sortAllBy
            }
        case LOAD_JOB_VIEW_SUCCESS:
            return {
                ...state,
                jobsView: action.jobsView
            }
        case CREATE_NEW_JOB:
            return {
                ...state,
                job: null
            }
        case NEW_JOB:
        case LOAD_JOB_SUCCESS:
            return {
                ...state,
                job: action.job
            }
        case SAVED_JOB_SUCCESS:
            return {
                ...state,
                job: null
            }
        case JOBS_CURRENTPAGE_CHANGE:
            return {
                ...state,
                currentJobsAllPage: action.currentJobsAllPage
            }
        default:
            return state
    }
}