import { post$, get$, put$, delete$ } from './http';

export const createOrganisation$ = (model) => {
    return post$('/organisations', model);
}

export const getOrganisation$ = (organisationId) => {
    return get$(`/organisations/${organisationId}`)
}

export const saveOrganisation$ = (organisationId, model) => {
    return put$(`/organisations/${organisationId}`, model)
}

export const listOrganisationUsers$ = (organisationId) => {
    return get$(`/organisations/${organisationId}/users`);
}

export const listOrganisationPrivileges$ = () => {
    return get$(`/organisation/privileges`)
}

export const removeUserFromOrganisation = (organisationId, userId) => {
    return delete$(`/organisations/${organisationId}/users/${userId}`)
}