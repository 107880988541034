import React from "react";

const OnDemand = ({ isEnabled, includedCVU, costOnDemandCVU, onChange }) => {

  return (
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="enableOnDemandCVU"
          checked={isEnabled}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          data-testid="enableOnDemandCVU"
        ></input>
        <label className="form-check-label" htmlFor="enableOnDemandCVU">
          Enable On-Demand CVU's <br/>CVU'S over {includedCVU} / month will be billed
          at {(costOnDemandCVU * 100).toFixed(2)} EUR per hundred.
        </label>
      </div>
  );
};

export default OnDemand;
