export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION"; 
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
export const DISMISS_ALL_NOTIFICATION = "DISMISS_ALL_NOTIFICATION";

export const showNotificationSuccess = (notification) => ({
    type: SHOW_NOTIFICATION,
    notification, 
    notificationType: "success" 
})

export const showNotificationError = (notification) => ({
    type: SHOW_NOTIFICATION,
    notification, 
    notificationType: "error"
})


export const showNotificationWarning = (notification) => ({
    type: SHOW_NOTIFICATION,
    notification, 
    notificationType: "warning"
})


export const hideNotification = () => ({
    type: HIDE_NOTIFICATION,
    notification: null, 
    notificationType: "none"
})

export const dismiss = (notificationId) => ({
    type: DISMISS_NOTIFICATION,
    notificationId
})

export const dismissAll = (systemType) => ({
    type: DISMISS_ALL_NOTIFICATION,
    systemType
})