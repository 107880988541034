import React, { useEffect, useRef } from 'react'

const $ = window.$;

export const InfoButton = ({ children, ...rest }) =>
    <button type="button" className="btn btn-info btn-wd" {...rest}>{children}</button>;

const buttonStyles = {danger:" btn btn-outline-danger", info:" btn btn-outline-info", dark:" btn btn-outline-dark", success:"  btn btn-outline-success"}

export const IconButton = ({ icon, prefixText, postfixText, className, type, submit, tooltip, tooltipplacement = "top",  ...rest }) => {

    const ref = useRef(null);

    useEffect(() => {
        const rc = ref.current;
        $(rc).tooltip();
        return () => {
            $(rc).tooltip("dispose")
        }
    }, [tooltip])

    return (
        <button
            ref={ref}
            type={submit ? "submit" : "button"}
            data-toggle="tooltip"
            data-placement={tooltipplacement}
            title={tooltip}
            className={className + (buttonStyles[type]||" btn btn-outline-dark")} {...rest}>
            {prefixText}
            <span className={"icon " + icon} />{postfixText}
        </button>
    );
}

