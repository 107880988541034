import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { showNotificationError } from "../../actions/notification";
import { getBillingInfo$, list$ } from "../../api/subscriptions";
import LoaderComponent from "../../components/common/Loader";
import ChangePlanInfo from "../../components/subscriptions/your-plan/ChangePlanInfo";

const ChangePlanContainer = () => {
  const match = useRouteMatch();
  const [subscriptions, setSubscriptions] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    list$().subscribe(
      (list) => {
        list = list.map((l) => ({
          ...l,
          selectable: l.description !== "Enterprise",
        }));
        setSubscriptions(list);
      },
      (error) => {
      }
    );
  }, []);

  useEffect(() => {
    getBillingInfo$().subscribe(b => {
      if(!b.stripeCustomerId) {
        history.push('/admin/subscription/billing', { redirect: '/admin/subscription/your-plan/change-plan/' + match.params.id})
        dispatch(showNotificationError("You need to update your billing info to be able to change subscription"))
      }
    })
  }, [dispatch, history, match.params.id])

  
  if(!subscriptions || !subscriptions.length) {
    return <LoaderComponent />
  }

  return (
    <div>
      <ChangePlanInfo
        subscriptionId={match.params.id}
        subscriptions={subscriptions}
      />
    </div>
  );
};

export default ChangePlanContainer;
