import React from 'react'
import Card, { Header, Body } from '../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';
import './ConnectThroughInvitationToken.css'

const ConnectThroughInvitationToken = ({ invitationTokenHandler, viewAccount, connectWithAccount, accountToken, account }) => {

    const accountInformation =
        account ? (
            <React.Fragment>
                <div className="card mt-3" style={{maxWidth: "40rem" }}>
                    <div className="card-header"><i className="icon icon-mail"></i> Contact information</div>
                    <div className="card-body">
                        <div className="row mb-3 mt-3 border-bottom">
                            <div className="col-md-4 border-right text-muted">
                                Account/Company
                            </div>
                            <div className="col-md-8">
                                {account.name}
                            </div>
                        </div>
                        <div className="row mb-3 border-bottom">
                            <div className="col-md-4 border-right text-muted">
                                Org. No
                            </div>
                            <div className="col-md-8">
                                {account.organisationNumber}
                            </div>
                        </div>
                        <div className="row mb-3 border-bottom">
                            <div className="col-md-4 border-right text-muted">
                                Account owner
                            </div>
                            <div className="col-md-8">
                                {account.accountOwner}
                            </div>
                        </div>
                        <div className="row mb-3 border-bottom">
                            <div className="col-md-4 border-right text-muted">
                                Account owner email
                            </div>
                            <div className="col-md-8">
                                <span className="badge badge-success">{account.accountEmail}</span> <small><i>Verified e-mail</i></small>
                            </div>
                        </div>
                        <div className="row mb-3 border-bottom">
                            <div className="col-md-4 border-right text-muted">
                                Phone number
                            </div>
                            <div className="col-md-8">
                                
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <small className="text-muted">Some info: </small>
                    </div>
                </div>

                <button className="btn btn-primary btn-lg mt-3" onClick={(event) => connectWithAccount(accountToken)}><i className="icon icon-link"></i> Connect</button>
            </React.Fragment>
            ) : (null);

    return (
        <PageContent className="border-0">
            <ContentHeader title="Network / Connection tokens"/>
            <ContentBody>
                <Card>
                    <Header title="Connect by token">
                        <div className="alert highlight" role="alert">
                            <span>
                            To be able to send files between endpoints on different accounts you need to have a connection between the accounts. When a connection is made between two accounts an administrator can share acces to an endpoint and a job can be created to send or receive files between different accounts.
                            </span>
                        </div>
                    </Header>
                    <Body>

                            <div className="form-group">
                                <input 
                                    className="form-control form-control-lg" 
                                    type="text" 
                                    placeholder="Apply token here" 
                                    onChange={(event) => invitationTokenHandler(event.target.value)} /> <span> </span>
                            </div>
                            <button className="btn btn-light btn-lg" onClick={() => viewAccount(accountToken)}><i className="icon icon-eye"></i> View account details</button>

                        {accountInformation}
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>

    )
}

export default ConnectThroughInvitationToken;