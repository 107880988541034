import React from 'react'
import { Field, reduxForm } from 'redux-form';
import './AccountEdit.css'
import Card, { Body, Header } from '../common/Card';
import PageContent, { ContentBody, ContentHeader } from '../common/PageContent';

const required = value => value ? undefined : 'Required'

const renderInputField = ({ input, label, type, name, meta: { touched, error, warning }, icon, tooltip }) => {
    return <div className="form-group col-md-6 padding">
        <input className="form-control form-control-lg border-input" {...input} placeholder={label} type={type} />
        {touched && ((error && <em htmlFor={name} className="invalid">{tooltip}</em>) || (warning && <span>{warning}</span>))}
    </div>
}

let AccountCreateComponent = ({ handleSubmit, errors, submitting, accountTypes, match }) => {

    let showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""
    return (
        <PageContent>
            <ContentHeader title="New account"/>
            <ContentBody>
                <Card>
                    <Header title="Create new Cloudbuz account">
                        <div className="highlight">
                            <span>When the new account is created, you can switch between all your accessible accounts from Settings/switch account. All new accounts are created as "Free" accounts. To upgrade an account go to Account Settings/Subscription.</span>
                        </div>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                <Field
                                    name="name"
                                    component={renderInputField}
                                    type="text"
                                    label="Account name"
                                    icon="fa-user"
                                    validate={[required]}
                                    tooltip="Please enter account name"
                                />

                                <Field
                                    name="organisationName"
                                    component={renderInputField}
                                    type="text"
                                    label="Organisation name"
                                    icon="fa-user"
                                    validate={[required]}
                                    tooltip="Please enter organisation name"
                                />


                                <div className="note">
                                    {showError}
                                </div>
                            </fieldset>
                            <div className="col-md-6 padding">
                                <footer>
                                    <button type="submit" className="btn btn-success btn-lg" id="btnLogin" disabled={submitting}>
                                        Create account
                                    </button>
                                </footer>
                            </div>
                        </form>
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>
    )

}

export default reduxForm({
    form: 'create-account', // a unique identifier for this form
})(AccountCreateComponent);

