import { connect } from 'react-redux'
import Notification from '../components/notification/Notification'
import { hideNotification } from '../actions/notification'
import React from 'react' 

const mapStateToProps = (state, ownProps) => 
{
    return {
        notification: state.notification.notification,
        type: state.notification.notificationType 
    }
}

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(hideNotification()) 
});

const NotificationContainer = ({ notification, type, close}) => {
    return <Notification notification={notification} type={type} onClose={close} />
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer)
