import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card, { Body } from '../../common/Card'
import PageContent, { ContentHeader, ContentBody } from '../../common/PageContent';
import './EventDetails.css'
import Table from '../../common/table/Table';
import DateFormat from '../../common/DateFormat';
import { volumeFormatter } from '../../../common/volumeFormatter'
import TabFilter from '../../common/tab/TabFilter';
import Progress from '../../common/progress/Progress';
import { NavLink } from 'react-router-dom'
import RunStatusInfo from './RunStatusInfo';
import TablePaginator from '../../common/table/Paginator';
import Refresh from './Refresh';

const getProgressColor = (status) => status === "1" ? "success" : status === "0" || status === "10" || status === "100" || status === "101" ? "primary" : "danger";
const getStatusType = (status) => status === "1" ? "success" : status === "0" || status === "10" || status === "100" || status === "101" ? "inProgress" : "error";

/*
0	Queued
1	Finished OK
10	TransferedToCommandQueue
100	TransferedToCommandQueueWaitingForSyncCompletion
101	TransferedToCommandQueueWaitingForCommandCompletion
9000	Error Queued
9001	Error Finished OK
9010	Error TransferedToCommandQueue
9100	Error TransferedToCommandQueueWaitingForSyncCompletion
9999	GeneralError
10000	RemovedQueued
10010	RemovedTransferedToCommandQueue
10100	RemovedTransferedToCommandQueueWaitingForSyncCompletion
19999	RemovedGeneralError

*/

const Details = ({ runId, details }) => {
  return (
    <div className="row m-0 runSummary">
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Event description
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">{details.description}</li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Event run id
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">{details.sysEventId}</li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Type (Copy/Move/Delete)
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">{details.type}</li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Total volume
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">{volumeFormatter(details.totalBytes)}</li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Processed files
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">{details.totalHandeledFiles} of {details.totalFiles}</li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Event run status
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item"><RunStatusInfo type={getStatusType(details.statusId)} /></li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Event run started
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item"><DateFormat date={details.start} /></li>
          </ul>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-header">
            Event run stopped
              </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item"><DateFormat date={details.stop} /></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const SourceAndTarget = ({ source, target, eventtype }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <React.Fragment>



      {
        isExpanded &&
        <React.Fragment>
          <h6 className="mt-3">Source & target</h6>
          <div className="row m-0 runSummary">
            <div className={target.account ? "col-md-6" : "col-md-12"}>
              <div className="card">
                <div className="card-header">
                  {eventtype} from
                            </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <i className="icon icon-folder"></i> {source.path}
                    <span className="badge badge-primary mx-2">On</span>
                    <i className="icon icon-fs_connector"></i> {source.connector} <i className="icon icon-left-dir"></i> <i className="icon icon-organization"></i> {source.organisation} <i className="icon icon-left-dir"></i>  <i className="icon icon-home"></i>{source.account}
                  </li>
                </ul>
              </div>
            </div>
            {target.account ?
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    {eventtype} to
                                                </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <i className="icon icon-folder"></i> {target.path}
                      <span className="badge badge-primary mx-2">On</span>
                      <i className="icon icon-fs_connector"></i> {target.connector} <i className="icon icon-left-dir"></i> <i className="icon icon-organization"></i> {target.organisation} <i className="icon icon-left-dir"></i>  <i className="icon icon-home"></i>{target.account}
                    </li>
                  </ul>
                </div>
              </div>

              : ""
            }

          </div>
        </React.Fragment>
      }

      <button className="btn btn-link float-right pr-0" onClick={() => setIsExpanded(!isExpanded)}>
        {
          isExpanded ? "Hide source & target" : "Show source & target"
        }
      </button>

    </React.Fragment>

  )
}

const Files = ({ files, schema, onSelect, currentPage, totalPages, onPageChange }) => {
  return <div>
    <Table data={files} schema={schema} onRowClick={onSelect} infoIfEmpty="Event run is empty.." />
    <TablePaginator
      currentPage={currentPage}
      onPageChange={onPageChange}
      totalPages={totalPages}
      pageNeighbours={1} />
  </div>
}

const EventDetails = ({ runId, title, details, files, filesSchema, onFileSelect, onRefresh, filters, onFilterChange, selectedFilter, currentFilesPage, onPageChange }) => {

  const hasErrors = details.statusId === "9000" || details.statusId === "9001" || details.statusId === "9010" || details.statusId === "9100" || details.statusId === "9999" ?
    <div className="alert highlight alert-dismissible fade show">
        <h6 className="alert-heading text-danger">
            <i className="icon icon-attention-circled"></i> An error occurred
        </h6>
        <strong>Message:</strong> {details.errorMessage}
      <button type="button" className="close p-2" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    </div>
    : ""

  
  return (
    <PageContent className="event-details border-0">
      <ContentHeader title={title}>
        <ol className="breadcrumb border">
          <li className="breadcrumb-item d-flex align-items-center">
            <NavLink to={`/jobs/runs/`} exact>
              <span>Runs</span>
            </NavLink>
          </li>
          <li className="breadcrumb-item d-flex align-items-center">
            <NavLink to={`/jobs/runs/${runId}`} exact>
              <span>Job run {runId}</span>
            </NavLink>
          </li>
          <li className="breadcrumb-item active d-flex align-items-center" aria-current="page">
            <span>Event run {details.sysEventId}</span>
          </li>
        </ol>
        {hasErrors}
      </ContentHeader>
      <ContentBody>
        <Card className="p-0 m-0 border-0">
          <Body className="p-0">
            <div className="row m-0">
              <div className="col-6 p-0">
                <h6>Event run progress</h6>
              </div>
              <div className="col-6 p-0">
                <div className="float-right"><Refresh  onRefresh={onRefresh} /></div>
              </div>
            </div>
            <Progress value={details.progress} className={"bg-" + getProgressColor(details.statusId)} />
            <SourceAndTarget source={details.source} target={details.target} eventtype={details.type} />
            <h6 className="mt-3">Event run summary</h6>
            <Details runId={runId} details={details} />
            <h6 className="mt-3">Event run Files</h6>
            <TabFilter filters={filters} onChange={onFilterChange} selectedFilter={selectedFilter}>
              <Files
                files={files}
                schema={filesSchema}
                onSelect={onFileSelect}
                currentPage={currentFilesPage}
                totalPages={details.totalPages}
                onPageChange={onPageChange} />
            </TabFilter>

          </Body>
        </Card>
      </ContentBody>

    </PageContent>
  )
}

EventDetails.propTypes = {
  details: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  filesSchema: PropTypes.array.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  selectedFileFilter: PropTypes.number,
  currentFilesPage: PropTypes.number, 
  onPageChange: PropTypes.func.isRequired
}

export default EventDetails