import { get$, post$, delete$, url } from "./http";
import qs from "query-string";

export const downloadReportUrl = (id) => {
  return url(`/history/reports/${id}/download`);
};

export const generateReport$ = (filter) => {
  return post$("/history/reports", filter);
};

export const deleteReport$ = (id) => {
  return delete$("/history/reports/" + id);
};

export const listReports$ = (page = 1) => {
  return get$("/history/reports?" + qs.stringify({ page }));
};
