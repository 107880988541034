import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import * as authHelper from "../../helpers/auth";
import { useDispatch, useSelector } from "react-redux";
import { loginFailed, userFound } from "../../actions/auth";
import { useHistory } from "react-router";

import Loader from "../common/Loader";
import { handleRedirect$ } from "../../api/sso";
import { first } from "rxjs/operators";

const Sso = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { accounts } = useMsal();
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    if (!auth) {
      return;
    }

    if (auth.isAuthenticated) {
      history.push(redirect);
    }

    if (auth.authFailed) {
      history.push("/login");
    }
  }, [auth, history, redirect]);

  useEffect(() => {
    const sub = handleRedirect$();

    sub.pipe(first()).subscribe(
      ({ state, ...rest }) => {
        if (rest.connectType === 1) {
          authHelper.save(rest);
          dispatch(userFound());
          setRedirect(state ?? "/");
          return;
        }

        if (rest.connectType === 2) {
          history.push("/invite/accept/" + rest.token);
        }
      },
      (error) => {
        dispatch(
          loginFailed(
            error?.response?.errors ?? [error.errorMessage ?? error.message ?? "Unhandeled error"]
          )
        );
      }
    );
  }, [accounts, dispatch, history]);

  return <Loader title="Still logging in" />;
};

export default Sso;
