import React from 'react'
import { Field } from 'redux-form'
import { renderCheckbox } from '../../common/ReduxFormHelper'

export const renderValue = ({ input, label, type, placeholder, meta: { touched, error }, disabled }) => {

    return <span>{input.value}</span>
}

export const renderField = ({ input, label, type, placeholder, meta: { touched, error }, disabled }) => {

    var className = (error && !disabled) ? 'has-error' : ''
    className += ' form-group'

    if (label && !placeholder)
        placeholder = label;

    return (<div className={className}>
        <label className="control-label">{label}</label>

        {
            disabled ? <input {...input} placeholder={placeholder} type={type} className="form-control form-control-lg border-input" disabled /> :
                <input {...input} placeholder={placeholder} type={type} className="form-control form-control-lg border-input" />
        }
        {error && <span className="help-block">{error}</span>}

    </div>)
}



const TriggerTypeForm = () => {
    return (
        <React.Fragment>
            <h6 className="wiz-form-header">Trigger settings</h6>
            <p>Job will run upon detection of a trigger file found at:</p>

            <div className="wiz-event-folder-card">
                <div className="row mr-0 ml-0">
                    <div className="col-4">
                        Account:
                    </div>
                    <div className="col-8 break-word">
                        <Field component={renderValue} type="text" disabled={true} name="trigger.account" />
                    </div>
                    <div className="col-4">
                        Organisation:
                    </div>
                    <div className="col-8 break-word">
                        <Field component={renderValue} type="text" disabled={true} name="trigger.organisationName" />
                    </div>
                    <div className="col-4">
                        Connector:
                    </div>
                    <div className="col-8 break-word">
                        <Field component={renderValue} type="text" disabled={true} name="trigger.connector" />
                    </div>
                    <div className="col-4">
                        Path:
                    </div>
                    <div className="col-8 break-word">
                        <Field component={renderValue} type="text" disabled={true} name="trigger.source" />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <Field component={renderField} type="text" name="trigger.fileNamePattern" label="Trigger file name pattern" />
                </div>
                <div className="col-md-4">
                    <Field component={renderField} type="number" name="trigger.triggerDelay" label="Trigger delay" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Field component={renderCheckbox} name="trigger.delete" label="Delete trigger after run" />
                </div>
            </div>
        </React.Fragment>
    )
}

export default TriggerTypeForm