import React, { useCallback, useEffect, useState } from "react";
import { getPaymentMethod$ } from "../../../api/subscriptions";
import { useModal } from "../../../components/modal/Modal";
import CurrentCreditCard from "../../../components/subscriptions/credit-card/CurrentCreditCard";

const CurrentCreditCardContainer = ({ onChange }) => {
  const [paymentMethod, setPaymentMethod] = useState();

  const { showModal }= useModal();

  useEffect(() => {
    getPaymentMethod$().subscribe((paymentMethod) => {
      setPaymentMethod(paymentMethod);
    });
  }, []);

  const getPaymentMethod = useCallback(() => {
    return new Promise((resolve, reject) => {
      showModal("NewCreditCard", {
        title: "Change credit card",
        onSubmit: (paymentId) => {
          resolve(paymentId);
        },
        onClose: () => reject(),
      });
    });
  }, [showModal]);

  const changeCreditCard = useCallback(async () => {
    const payment = await getPaymentMethod();
    setPaymentMethod(payment);
    onChange(payment.id);
  }, [getPaymentMethod, onChange]);

  if(!paymentMethod) {
    return "";
  }

  return (
    <div>
          <CurrentCreditCard paymentMethod={paymentMethod}></CurrentCreditCard>
          <div className="d-flex justify-content-end">
            <button className="btn btn-link pr-0" onClick={e => changeCreditCard()}>change credit card</button>
          </div>
    </div>
  );
};

export default CurrentCreditCardContainer;
