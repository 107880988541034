import React from 'react'
import ForgotPasswordContainer from '../containers/ForgotPassword'
import './Login.css'

const ForgotPasswordPage = ({ match }) => {
    return (
        <div className="login-wrapper">
            <div className="login align-self-center flex-fill">
                <ForgotPasswordContainer match={match} />
            </div>
        </div>
    )
}

export default ForgotPasswordPage