import React from 'react'
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import './UserEdit.css'
import { renderField, renderDropDown } from '../common/ReduxFormHelper';
import { required, requiredArray } from '../common/ReduxFormFieldValidation';
import Card, { Header, Body } from '../common/Card';

const valid = (fields, selectedOrganisation, selectedOrganisationPrivilege) => {
    return selectedOrganisation > 0 &&
        selectedOrganisationPrivilege > 0 &&
        !fields
            .map((f, i) => fields.get(i).organisationId)
            .includes(selectedOrganisation)
}

const renderOrganisationSection = ({
    selectedOrganisation,
    selectedOrganisationPrivilege,
    fields,
    organisations,
    privileges, meta: { touched, warning, error, dirty } }) => {

    const privilegesOptions = privileges.map(i => {
        return { id: i.id, name: i.name }
    })

    const organisationOptions = organisations.map(o => {
        return { id: o.organisationId, name: o.name }
    })

    return (
        <React.Fragment>
            <h6 className="d-none d-md-block"><span className="badge badge-primary"><i className="icon icon-info-circled"></i> Set user privilege</span></h6>
            <div className="row user-priv-tableheader">
                <div className="col-md-6 d-none d-md-block">
                    Organization
                </div>
                <div className="col-md-4 d-none d-md-block">
                    Privilege
                </div>
                <div className="col-md-2 d-none d-md-block"/>
            </div>
            {
                fields.map((field, i) => (
                    <div className="row user-priv-table" key={i}> 
                        <div className="col-5 d-md-none">
                            <strong>Organization</strong>
                        </div>
                        <div className="col-7 col-md-6 d-flex align-items-center">
                            {
                                organisations.find(o =>
                                    o.organisationId === fields.get(i).organisationId).name
                            }
                        </div>
                        <div className="col-5 d-md-none">
                            <strong>Privilege</strong>
                        </div>
                        <div className="col-7 col-md-4 d-flex align-items-center">
                            {
                                privileges.find(p =>
                                    p.id === fields.get(i).privilege).name
                            }
                        </div>
                        <div className="col-12 col-md-2 text-right align-items-center">
                            <button type="button" className="btn btn-outline-danger" onClick={() => fields.remove(i)}><i className="icon icon-trash"></i> Delete privilege</button>
                        </div>
                    </div>
                ))
            }
            <h6 className="d-md-none"><span className="badge badge-primary"><i className="icon icon-info-circled"></i> Set user privilege</span></h6>

            <div className="row user-priv-table">
                <div className="col-md-6 align-items-center">
                    <Field
                        name="organisationId"
                        component={renderDropDown}
                        type="text"
                        tooltip="Select organisation"
                        validate={[required]}
                        options={organisationOptions}
                        emptyOption={true}
                        emptyOptionLabel="Select organisation"
                        noSpace={true}
                    >
                    </Field>
                </div>
                <div className="col-md-4 align-items-center">
                    <Field
                        name="organisationPrivilege"
                        component={renderDropDown}
                        type="text"
                        tooltip="Select privilege"
                        validate={[required]}
                        options={privilegesOptions}
                        emptyOption={true}
                        emptyOptionLabel="Select privilege"
                        noSpace={true}
                    >
                    </Field>
                </div>

                <div className="col-md-2 col-12 align-items-center text-right">
                    <button type="button" className="btn btn-outline-success" onClick={() => {
                            valid(
                                fields, 
                                selectedOrganisation, 
                                selectedOrganisationPrivilege
                            ) && 
                            fields.push({ 
                                organisationId: selectedOrganisation, 
                                privilege: selectedOrganisationPrivilege 
                            })
                        }}><i className="icon icon-plus"></i> Add privilege</button>
                </div>
            </div>
        </React.Fragment>)
}

let UserEditComponent = ({
    selectedAccountPrivilege,
    selectedOrganisation,
    selectedOrganisationPrivilege,
    handleSubmit,
    errors,submitting, organisations, accountPrivileges, organisationPrivileges }) => {

    const showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""

    const privileges = [{ id: 0, name: "User" }, ...accountPrivileges]

    return (
        <form onSubmit={handleSubmit}>
            <Card className="card-settings">
                <Header title="Edit user">
                <div className="highlight" role="alert">
                    <span>Only the user can edit his/hers e-mail and name.</span>
                </div>
                </Header>
                <Body>
                    <fieldset>
                        <Field
                            name="name"
                            type="text"
                            disabled={true}
                            label="Name"
                            component={renderField}
                            className="form-group"
                        >
                        </Field>

                        <Field
                            name="email"
                            type="text"
                            disabled={true}
                            label="Email"
                            component={renderField}
                            className="form-group"
                        >
                        </Field>

                        <Field
                            name="accountPrivilege"
                            component={renderDropDown}
                            type="text"
                            label="Privilege"
                            tooltip="Select account privilege"
                            className="form-group col-md-6 padding"
                            validate={[required]}
                            options={privileges}
                            emptyOption={true}
                            emptyOptionLabel="-- Select account privilege ---"
                        >
                        </Field>

                        {
                            parseInt(selectedAccountPrivilege, 10) === 0 &&
                            <FieldArray
                                name="organisations"
                                organisations={organisations}
                                component={renderOrganisationSection}
                                privileges={organisationPrivileges}
                                validate={[requiredArray]}
                                tooltip="Please select an organisation"
                                selectedOrganisation={selectedOrganisation}
                                selectedOrganisationPrivilege={selectedOrganisationPrivilege}
                            />

                        }

                        <div className="note">
                            {showError}
                        </div>

                        <button type="submit" className="btn btn-dark btn-lg" disabled={submitting}>
                            Save
                        </button>
                    </fieldset>
                </Body>
            </Card>
        </form>
    )
}

const selector = formValueSelector("edit-user");

UserEditComponent = connect(state => {
    const selectedAccountPrivilege = parseInt(selector(state, 'accountPrivilege'), 10)
    const selectedOrganisation = parseInt(selector(state, 'organisationId'), 10)
    const selectedOrganisationPrivilege = parseInt(selector(state, 'organisationPrivilege'), 10)

    return { selectedAccountPrivilege, selectedOrganisation, selectedOrganisationPrivilege }
})(UserEditComponent)

export default reduxForm({
    form: 'edit-user'
})(UserEditComponent);
