import React, { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router";
import { loginRequest } from "../../config/azureConfig";
import MicrosoftLogo from "../../images/microsoft.svg";

const SsoLogin = () => {
  const { instance } = useMsal();
  const location = useLocation();

  const ssoLogin = useCallback(() => {
    console.log("loginRequest", loginRequest);
    instance.loginRedirect({
      ...loginRequest,
      state: location?.state?.referrer,
    });
  }, [instance, location?.state?.referrer]);

  return (
    <button
      type="button"
      onClick={ssoLogin}
      className="btn btn-light btn-lg btn-block"
    >
      <img alt="sso" className="ms-logo" src={MicrosoftLogo} />
      Continue with Microsoft
    </button>
  );
};

export default SsoLogin;
