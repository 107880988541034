import { ajax } from "rxjs/ajax";
import { msalConfig, loginRequest } from "../config/azureConfig";
import { baseUrl } from "./http";
import { PublicClientApplication } from "@azure/msal-browser";
import { from } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

export const msalInstance = new PublicClientApplication(msalConfig);

export const connect$ = (accessToken) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  return ajax.post(baseUrl("/sso/connect"), null, headers);
};

export const acquireTokenSilent$ = () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  const acquire = from(
    msalInstance.acquireTokenSilent({
      account: msalInstance.getActiveAccount(),
      scopes: loginRequest.scopes,
    })
  );

  return acquire.pipe(
    mergeMap((resp) =>
      {
        return connect$(resp.accessToken).pipe(
          map((result) => ({
            ...result.response,
            accessToken: resp.accessToken,
            state: resp.state
          }))
        )
      }
    )
  );
};

export const handleRedirect$ = () => {
  const handleRedirect = from(msalInstance.handleRedirectPromise());

  return handleRedirect.pipe(
    mergeMap((resp) =>
      {
        return connect$(resp.accessToken).pipe(
          map((result) => ({
            ...result.response,
            accessToken: resp.accessToken,
            state: resp.state
          }))
        )
      }
    )
  );
}