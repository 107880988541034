import { get$, post$ } from './http';


export const listAllInvitationTokens$ = () => {
    return get$('/invitationtokens');
}

export const generateInvitationToken$ = () => {
    return post$('/invitationtokens/generate');
}

export const deleteInvitationToken$ = (token) => {
    return get$('/invitationtokens/delete/'  + token);
}

export const viewInvitationTokenAccount$ = (token) => {
    return get$('/invitationtokens/viewAccount/' + token);
}

export const connectThroughInvitationToken$ = (token) => {
    return post$('/invitationtokens/connect/' + token);
}