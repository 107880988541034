import React from 'react'
import PropTypes from 'prop-types'
import './Refresh.css'

const Refresh = ({ onRefresh }) => {
    return (
        <div
            title="autorefresh"
            onClick={() => { onRefresh() }}
            className="icon-big text-center pointer"
        >
            <button className="btn btn-link">
                <i className="icon icon-arrows-cw pr-1"></i> 
                 Refresh
            </button>

        </div>
    )
}

Refresh.propTypes = {
    onRefresh: PropTypes.func.isRequired
}

export default Refresh;