import {
    LOAD_INVITATIONTOKEN_LIST_SUCCESS,
    LOAD_INVITES_LIST_SUCCESS,
    LOAD_INVITES_LIST,
    LOAD_INVITATIONTOKEN_LIST,
    LOAD_INVITATION_TOKEN_ACCOUNT_SUCCESS,
    LOAD_INVITATION_TOKEN_ACCOUNT,
    SET_INVITATION_TOKEN_ACCOUNT,
    SET_FORM_DATA_INVITE,
    REVOKE_INVITE,
    CONNECT_WITH_INVITATION_TOKEN,
    DECLINE_INVITE,
    ACCEPT_INVITE,
    GET_INVITE_SUCCESS,
    GET_INVITE
} from '../actions/invite'


export const invite = (state = { isFetching: false, sentInvites: [], recivedInvites: [], invitationTokens: [] }, action) => {
    switch (action.type) {
        case DECLINE_INVITE:
        case ACCEPT_INVITE:
        case REVOKE_INVITE:
            return {
                ...state
            }
        case SET_INVITATION_TOKEN_ACCOUNT:
            return {
                ...state,
                accountToken: action.token
            }
        case LOAD_INVITES_LIST_SUCCESS:
            return {
                ...state,
                sentInvites: [...(action.sentInvites || []) ],
                recivedInvites: [...(action.recivedInvites || []) ]
            }
        case LOAD_INVITATIONTOKEN_LIST_SUCCESS:
            return {
                ...state,
                invitationTokens: [...action.invitationTokens]
            }
        case LOAD_INVITATIONTOKEN_LIST:
        case LOAD_INVITES_LIST:
            return {
                ...state
            }
        case LOAD_INVITATION_TOKEN_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.account
            }
        case LOAD_INVITATION_TOKEN_ACCOUNT:
            return {
                ...state,
                account: null,
            }
        case SET_FORM_DATA_INVITE:
            return {
                ...state,
                createInvite: {
                    ...state.createInvite,
                    [action.key]: action.value
                }
            }
        case CONNECT_WITH_INVITATION_TOKEN:
            return {
                ...state,
            }
        case GET_INVITE:
            return {
                ...state,
                invite: null
            }
        case GET_INVITE_SUCCESS:
            return {
                ...state,
                invite: action.invite
            }
        default:
            return state
    }
}