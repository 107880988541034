import { of } from "rxjs";
import { retryWhen, switchMap } from "rxjs/operators";
import { handleError } from "./error";
import { post$, get$, put$, delete$ } from "./http";

export const listAllAccounts$ = () => {
  return get$("/system/accounts");
};

export const createAccount$ = (model) => {
  return post$("/accounts", model);
};

export const getAccount$ = (accountId) => {
  return get$(`/accounts/${accountId}`);
};

export const saveAccount$ = (accountId, model) => {
  return put$(`/accounts/${accountId}`, model);
};

export const listOrganisations$ = (accountId) => {
  return get$(`/accounts/${accountId}/organisations`);
};

export const listAccountUsers$ = (accountId) => {
  return get$(`/accounts/${accountId}/users`);
};

export const listAccountAdmins$ = (accountId) => {
  return get$(`/accounts/${accountId}/users?privileges=201`);
};

export const listPendingAccountUsers$ = () => {
  return get$(`/accounts/users/pending`);
};

export const createOrganisation$ = (accountId, model) => {
  return post$(`/accounts/${accountId}/organisations`, model);
};

export const listAccountPrivileges$ = () => {
  return get$(`/account/privileges`);
};

export const createUser = (accountId, model) => {
  return post$(`/accounts/${accountId}/users`, model);
};

export const getUser = (accountId, userId) => {
  return get$(`/accounts/${accountId}/users/${userId}`);
};

export const saveUser = (accountId, userId, model) => {
  return put$(`/accounts/${accountId}/users/${userId}`, model);
};

export const removeOrganisation = (accountId, organisationId) => {
  return delete$(`/accounts/${accountId}/organisations/${organisationId}`);
};

export const getNotifications$ = () => {
  return get$(`/notifications`);
};

export const getNotificationsCount$ = () => {
  return get$(`/notifications/count`).pipe(
    switchMap((res) => of(res)),
    retryWhen(handleError)
  );
};

export const dismissNotification$ = (notificationId) => {
  return put$(`/notifications/${notificationId}/dismissed`);
};

export const dismissAllNotification$ = (systemType) => {
  return put$(`/notifications/type/${systemType}/dismissed`);
};

export const removeUserFromAccount = (accountId, userId) => {
  return delete$(`/accounts/${accountId}/users/${userId}`);
};
