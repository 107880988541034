import React from "react";
import Dialog from "./Dialog";

export const ConfirmDialog = ({
  title,
  message,
  init,
  dynamicMessage,
  confirmBtnText,
  confirmBtnDanger,
}) => {
  return (
    <Dialog
      onConfirm={(close) => {
        init.next.apply(null, init.args);
        close();
      }}
      title={title}
      confirmBtnDanger={confirmBtnDanger}
      confirmBtnText={confirmBtnText}
    >
      {message || (dynamicMessage && dynamicMessage.apply(null, init.args))}
    </Dialog>
  );
};
