import React from 'react';
import PropTypes from 'prop-types'
import './Column.css'

const Column = ({ schema, onClick }) => {
    
    const sortIcon = !schema.sortOrder ? <i></i> :
        schema.sortOrder === "desc" ? 
        <i className="icon icon-up-dir"></i> : <i className="icon icon-down-dir"></i>
    
    return (
        <th className="header-column" onClick={() => { onClick(schema); }}>
            {schema.header}
            {sortIcon}
        </th>
    )
}

Column.propTypes = {
    schema: PropTypes.object.isRequired
}

export default Column