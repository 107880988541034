import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { required } from '../common/ReduxFormFieldValidation';
import Card, { Body, Header } from '../common/Card';
import { renderField } from '../common/ReduxFormHelper';


const OrganisationCreateComponent = ({ handleSubmit, errors, pristine, reset, submitting, initialValues }) => {
    
    const showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""

    return (
        <Card className="card-settings">
            <Header title="New organisation">
                <div className="alert highlight" role="alert">
                    <span>Create a new organization on this account.</span>
                </div>
            </Header>
            <Body>
            <form onSubmit={handleSubmit}>
                <div className="content">
                    <fieldset>
                        <Field
                            name="name"
                            component={renderField}
                            type="text"
                            label="Organisation name"
                            validate={[required]}
                            tooltip="Please enter organisation name"
                        />

                        <Field
                            name="description"
                            component={renderField}
                            type="text"
                            label="Description"
                            validate={[required]}
                            tooltip="Please enter description"
                        />

                        <Field
                            name="orgNumber"
                            component={renderField}
                            type="text"
                            label="Organisation number"
                            validate={[required]}
                            tooltip="Please enter organisation number"
                        />

                        <div className="note">
                            {showError}
                        </div>
                    </fieldset>
                </div>
                <div className="content">
                    <footer>
                        <button type="submit" className="btn btn-dark btn-lg" disabled={submitting}>
                            Save
                        </button>
                    </footer>
                </div>
            </form>
            </Body> 
        </Card>
    )
}

export default reduxForm({
    form: 'create-organisation', // a unique identifier for this form
})(OrganisationCreateComponent);

