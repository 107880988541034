import React from 'react'
import Card, { Header, Body } from '../common/Card';

const NotificationCategoriesComponent = ({
    wantsJob,
    wantsSystem,
    wantsPromotional,
    checkboxChangeEvent,
    onSave
}) => {
    return (
        <Card className="card-settings">
            <Header title="Enable notification categories">
            <div className="alert highlight" role="alert"><span>Select the categories you want to subscribe to.</span></div>
            </Header>
            <Body>
                {/* 
                <div>
                    <label className="check">
                        <input type="checkbox" onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsJob')
                        }} defaultChecked={wantsJob} />
                        Job notifications (notifications, warnings and errors)
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div>
                    <label className="check">
                        <input type="checkbox" onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsSystem')
                        }} defaultChecked={wantsSystem} />
                        System notifications
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div>
                    <label className="check">
                        <input type="checkbox" onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsPromotional')
                        }} defaultChecked={wantsPromotional} />
                        Newsletter, product announcement and promotional
                        <span className="checkmark"></span>
                    </label>
                </div>
                */}

                <div className="custom-control custom-checkbox mb-2">
                    <input  
                        id="catwantsJob"
                        type="checkbox" 
                        className="custom-control-input" 
                        onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsJob')
                        }} defaultChecked={wantsJob}
                        />
                    <label className="custom-control-label" htmlFor="catwantsJob">Job notifications (notifications, warnings and errors)</label>
                </div>

                <div className="custom-control custom-checkbox mb-2">
                    <input  
                        id="catwantsSystem"
                        type="checkbox" 
                        className="custom-control-input" 
                        onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsSystem')
                        }} defaultChecked={wantsSystem}
                        />
                    <label className="custom-control-label" htmlFor="catwantsSystem">System notifications</label>
                </div>

                <div className="custom-control custom-checkbox mb-4">
                    <input  
                        id="catwantsPromotional"
                        type="checkbox" 
                        className="custom-control-input" 
                        onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsPromotional')
                        }} defaultChecked={wantsPromotional}
                        />
                    <label className="custom-control-label" htmlFor="catwantsPromotional">Newsletter, product announcement and promotional</label>
                </div>
                
                <button onClick={() => onSave()} className="btn btn-dark btn-lg">Save</button>
            </Body>
        </Card>
    )
}

export default NotificationCategoriesComponent;