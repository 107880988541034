import React from 'react'

const Progress = ({ value, height, className, fontSize }) => {
    className = (className || "") + ' progress-bar';
    return (
        <div className="progress" style={{ height: height || "30px" }}>
            <div className={className}
                role="progressbar"
                style={{ width: value + '%', fontSize: fontSize || "1.5rem" }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100">
                {value}%
            </div>
        </div>
    )
}

export default Progress
