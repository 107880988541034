import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Privilege from '../../containers/Privilege'
import Loader from '../common/Loader';

const SwitchAccount = ({ info, user, changeAccount, isChangingAccount }) => {

    const [expanded, setExpanded] = useState(true)

    const noaccounts =() => {
        return (
                <li>
                    <div className="d-flex align-items-center pointer">
                        <span className="link danger"><span className="text-danger"><small><i className="icon icon-attention-circled"></i> No valid Cloudbuz accounts..</small></span></span>
                    </div>
                </li>   
            )
        }


    const accounts = isChangingAccount ? <li><Loader startDelay={0} title="Changing account"  /></li> : info.accounts.map((a, i) => 
        {
            let cropAccNameLength = (a.accountId + '') !== (user.accountId + '') ? 50 : 20;
            return(
                <li key={i} >
                    <div className="d-flex align-items-center pointer">
                        <span onClick={() => changeAccount(a.accountId)} className={(a.accountId + '') === (user.accountId + '') ? "link active" : "link inactive"}> 
                            {(a.accountId + '') === (user.accountId + '') ? <span><i className="icon icon-ok"></i> </span> : ""}
                            {a.companyName.length > cropAccNameLength ? a.companyName.substring(0, cropAccNameLength) + ".." : a.companyName}
                        </span>
                    </div>
                </li>   
            )
        }
    )


    return (
        <div>

            <div className="d-flex align-items-center">
                <span className="link flex-grow-1 pointer" onClick={() => setExpanded(!expanded)}>
                    <span>Accounts</span>
                </span>
                {

                    expanded ?
                        <span onClick={() => setExpanded(false)} className="icon icon-up-open align-right pr-5 pointer chevronmenu"></span> :
                        <span onClick={() => setExpanded(true)} className="icon icon-down-open align-right pr-5 pointer chevronmenu"></span>
                }
            </div>
            <ul>
                {
                    expanded ? (!info.accounts || !info.accounts.length) ? noaccounts() : accounts : ""
                }
            </ul>

        </div>
    )
}

const SettingsMenu = ({ info, signOut, changeAccount, user, isChangingAccount, toggleUserMenu }) => {
    return (
        <div className="menu flex-fill overflow-auto">
            <ul>
                <li>
                    <h6 className="mt-3"><i className="icon icon-logo_mark text-primary"></i></h6>
                    <h5 className="mb-3">Settings</h5>
                </li>
                <Privilege type="Accounts page" privilege="read_write">
                <li>
                    <NavLink activeClassName="active" to="/system/accounts" onClick={() => toggleUserMenu()}>
                        System settings
                    </NavLink>
                </li>
                </Privilege>
                <li>
                    <NavLink to="/settings/my-settings/general" onClick={() => toggleUserMenu()}>My settings</NavLink>
                </li>
                
                {
                    (!info.accounts || !info.accounts.length) ? "" : 
                        <Privilege type="Account page" privilege="read_write">
                            <li>
                                
                                    <NavLink activeClassName="active" to="/admin/settings/general" onClick={() => toggleUserMenu()}>
                                        Account settings
                                    </NavLink>
                                
                            </li>
                        </Privilege>
                }
                
                <li>
                    <SwitchAccount info={info} user={user} changeAccount={changeAccount} isChangingAccount={isChangingAccount} />
                </li>
                <li><NavLink to="/admin/accounts/new" onClick={() => toggleUserMenu()}>Create new account</NavLink></li>
                <li><span className="nohref" onClick={signOut}><i className="icon icon-logout"></i> Log out</span></li>
                <li><span> </span></li>
            </ul>
        </div>
    )
}

export default SettingsMenu