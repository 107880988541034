import { get$, post$ } from './http';

export const getNotificationSettings$ = () => {
    return get$('/notificationsettings');
}

export const saveNotificationSettings$ = (wantsEmail,
                                          wantsSms,
                                          wantsWeb,
                                          wantsJob,
                                          wantsSystem,
                                          wantsPromotional,
                                          emailNotificationType,
                                          emailNotificationSpec) => {
    return post$('/notificationsettings', {
        wantsEmail,
        wantsSms,
        wantsWeb,
        wantsJob,
        wantsSystem,
        wantsPromotional,
        emailNotificationType,
        emailNotificationSpec
    });
}



export const saveJobNotificationSettings$ = (settings) => {
    return post$('/notificationsettings/jobs', { settings });
}