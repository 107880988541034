import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import PageContent, {
  ContentBody,
  ContentHeader,
} from "../../common/PageContent";
import Card, { Body } from "../../common/Card";
import Table from "../../common/table/Table";
import TablePaginator from "../../common/table/Paginator";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

const JobsView = ({
  view,
  schema,
  onRowClick,
  currentPage,
  onPageChange,
  sortBy,
  onSortChange,
}) => {
  return (
    <React.Fragment>
      <Table
        data={view ? view.jobs : []}
        schema={schema}
        onRowClick={onRowClick}
        sortBy={sortBy}
        onSortChange={onSortChange}
        infoIfEmpty="No jobs found.."
      />
      <TablePaginator
        currentPage={currentPage}
        totalPages={view ? view.totalPages : 0}
        pageNeighbours={3}
        onPageChange={onPageChange}
      />
    </React.Fragment>
  );
};

const JobsViewFreeText = ({ text, onChange }) => {
  const debounceSearchChanged = useMemo(() => {
    return new Subject();
  }, []);

  useEffect(() => {
    const subscription = debounceSearchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((text) => {
        onChange(text);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [debounceSearchChanged, onChange]);

  const ref = useRef();

  return (
    <div className="input-group">
      <input
        ref={ref}
        className="form-control form-control-lg"
        type="text"
        placeholder="Filter this list, type something.."
        defaultValue={text}
        onChange={(e) => {
          debounceSearchChanged.next(e.target.value);
        }}
      />
      <div className="input-group-append">
        <span
          className="input-group-text pointer"
          onClick={(e) => {
            onChange("");
            ref.current.value = "";
          }}
        >
          clear
        </span>
      </div>
    </div>
  );
};

const Jobs = ({
  view,
  schema,
  onRowClick,
  currentPage,
  onPageChange,
  onSearch,
  searchText,
  sortBy,
  onSortChange,
}) => {
  return (
    <PageContent>
      <ContentHeader title="Jobs / All">
        <JobsViewFreeText onChange={onSearch} text={searchText} />
      </ContentHeader>
      <ContentBody>
        <Card className="p-0 m-0 border-0">
          <Body className="p-0">
            <JobsView
              view={view}
              schema={schema}
              onRowClick={onRowClick}
              currentPage={currentPage}
              onPageChange={onPageChange}
              sortBy={sortBy}
              onSortChange={onSortChange}
            />
          </Body>
        </Card>
      </ContentBody>
    </PageContent>
  );
};

Jobs.propTypes = {
  schema: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Jobs;
