import * as api from '../api'
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { LOAD_EXPLORE_VIEW, 
    LOAD_EXPLORE_VIEW_FAILED, 
    loadExploreViewSuccess, 
    NAVIGATE_EXPLORE_VIEW, 
    SEARCH_IN_EXPLORE, 
    searchInExploreSuccess, 
    GET_SHARE_FOLDER, 
    getShareFolderSuccess, 
    SHARE_FOLDER, 
    shareFolderSuccess,
    EXECUTE_EXPLORE_COMMAND,
    executeExploreCommandSuccess,
    EXECUTE_EXPLORE_COMMAND_SUCCESS,
    loadExploreView
} from "../actions/explore";
import { handleError } from './common';
import { push } from 'connected-react-router';
import { showNotificationSuccess } from '../actions/notification';
import { of } from 'rxjs';

export const loadExploreViewEpic$ = (action$, state$) => action$.pipe(
    ofType(LOAD_EXPLORE_VIEW),
    switchMap(action => {
        return api.loadExploreView$(
            action.id, 
            action.exploreType, 
            action.shareId, 
            action.currentPage ?? state$.currentPage ?? 1, 
            action.sortBy, 
            action.filters)
        .pipe(
            map(response => {
                return loadExploreViewSuccess(response)
            }),
            catchError(handleError(action$, action.type))
        );
    })
)

export const loadExploreViewFailedEpic$ = (action$, state$) => action$.pipe(
    ofType(LOAD_EXPLORE_VIEW_FAILED),
    switchMap(action => {
        return api.loadExploreView$(
            action.id, 
            action.exploreType, 
            action.shareId, 
            action.currentPage ?? state$.currentPage ?? 1, 
            action.sortBy, 
            action.filters)
        .pipe(
            map(response => loadExploreViewSuccess(response)),
            catchError(handleError(action$, action.type))
        );
    })
)

export const navigateExploreViewEpic$ = action$ => action$.pipe(
    ofType(NAVIGATE_EXPLORE_VIEW),
    map(action => 
        {

            if(action.exploreType === "File" && action.parent > 0) {
             return push(`/explore/${action.parent}/Folder`)
            }

            if(action.shareId && action.shareId > 0) {
                return push(`/explore/${action.id}/${action.exploreType}/${action.shareId}`)
            }

            return push(`/explore/${action.id}/${action.exploreType}`)
        }
    )
)

export const searchInExploreEpic$ = action$ => action$.pipe(
    ofType(SEARCH_IN_EXPLORE),
    switchMap(action => api.searchInExplore$(action.text, action.filters).pipe(
        map(response => searchInExploreSuccess(response)),
        catchError(handleError(action$, action.type))
    ))
)

export const getShareFolderEpic$ = action$ => action$.pipe(
    ofType(GET_SHARE_FOLDER),
    switchMap(action => api.getShareFolder$(action.id, action.exploreType).pipe(
        map(response => getShareFolderSuccess(response)),
        catchError(handleError(action$, action.type))
    ))
)

export const shareFolderEpic$ = action$ => action$.pipe(
    ofType(SHARE_FOLDER),
    switchMap(action => api.shareFolder$(action.id, action.name, action.sharedWith).pipe(
        map(response => shareFolderSuccess(response.response)),
        catchError(handleError(action$, action.type))
    ))
)

export const executeExploreCommandEpic$ = action$ => action$.pipe(
    ofType(EXECUTE_EXPLORE_COMMAND),
    switchMap(action => api.executeExploreCommand$(action.targetId, action.command).pipe(
        map(response => executeExploreCommandSuccess(response.response)),
        catchError(handleError(action$, action.type))
    ))
)

export const executeExploreCommandSuccessEpic$ = (action$, state$) => action$.pipe(
    ofType(EXECUTE_EXPLORE_COMMAND_SUCCESS),
    mergeMap(m => of(
        showNotificationSuccess("Your action is now being handeled"), 
        loadExploreView(
            state$.value.explore.id, 
            state$.value.explore.shareId, 
            state$.value.explore.type, 
            state$.value.explore.currentPage,
            state$.value.explore.sortBy,
            state$.value.explore.filters
        ))
    )
)