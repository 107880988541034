import { get$ } from '../api/http';

import { switchMap, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { handleError } from './common'

import {
    AUTOCOMPLETE_DO_SEARCH,
    autocompleteDoSearchSuccess
} from '../actions/autocomplete'

export const autocompleteDoSearchEpic$ = action$ => {
    return action$.pipe(
        ofType(AUTOCOMPLETE_DO_SEARCH),
        switchMap((action) =>
            get$(action.path + "?search=" + action.searchFor).pipe(
                map(response => autocompleteDoSearchSuccess(action.key, action.searchFor, response)),
                catchError(handleError(action$, action.type))
            )
        )
    )
}