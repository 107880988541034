import { Link } from "react-router-dom";
import PageContent, { ContentHeader, ContentBody } from "../common/PageContent";
import Card, { Body, Header } from "../common/Card";
import { dateFormat } from "../../common/dateFormatter";
import { useEffect, useState } from "react";
import ScheduleValueTemplate, {
  ScheduleDurationTypes,
  ScheduleTimeTypes,
} from "./ScheduleValueTemplate";

const ScheduleDetails = ({ schedule: s, onChange }) => {
  return (
    <div className="detail-table">
      <div className="row">
        <div className="col-sm-2">Status</div>
        <div className="col-sm-10">
          {s?.isDisabled ? (
            <span className={"badge badge-danger"}>
              <i className="icon icon-ok-circled"></i> Disabled
            </span>
          ) : (
            <span className={"badge badge-success"}>
              <i className="icon icon-attention-circled"></i> Enabled
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">Name</div>
        <div className="col-sm-10">
          <input
            className="form-control"
            defaultValue={s?.name}
            onChange={(e) => {
              onChange({ ...s, name: e.target.value });
            }}
          ></input>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">Description</div>
        <div className="col-sm-10">
          <input
            className="form-control"
            defaultValue={s?.description}
            onChange={(e) => {
              onChange({ ...s, description: e.target.value });
            }}
          ></input>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">Last activity</div>
        <div className="col-sm-10">{dateFormat(s?.lastExecTimeUtc)}</div>
      </div>

      <h2 className="mt-5">Duration and frequency</h2>

      <div className="row mt-4">
        <div className="col-sm-8">
          <div className="form-group">
            <label>Type</label>
            <select
              className="form-control"
              value={s?.dateTypeId}
              onChange={(e) => {
                onChange({
                  ...s,
                  dateType: e.target.value,
                  dateTypeId: e.target.value,
                  dateValue: 1
                });
              }}
            >
              {ScheduleDurationTypes.map((d) => (
                <option key={d.id} value={parseInt(d.id)}>
                  {d.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-4">
          <ScheduleValueTemplate
            onChange={(e) => {
              onChange({ ...s, dateValue: e.target.value });
            }}
            defaultValue={s?.dateValue}
            typeId={s?.dateTypeId}
            type="duration"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label>Start</label>
            <input
              name="dateStart"
              placeholder="Start *"
              type="date"
              className="form-control border-input"
              defaultValue={dateFormat(s?.dateStart, "YYYY-MM-DD")}
              onChange={(e) => {
                onChange({ ...s, dateStart: e.target.value });
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label>Stop</label>
            <input
              name="dateStop"
              placeholder="Stop *"
              type="date"
              className="form-control border-input"
              defaultValue={dateFormat(s?.dateStop, "YYYY-MM-DD")}
              onChange={(e) => {
                onChange({ ...s, dateStop: e.target.value });
              }}
            />
          </div>
        </div>
      </div>

      <h2 className="mt-5">Daily frequency</h2>

      <div className="row mt-4">
        <div className="col-sm-8">
          <div className="form-group">
            <label>Type</label>
            <select
              className="form-control"
              value={s?.timeTypeId}
              onChange={(e) => {
                onChange({
                  ...s,
                  timeType: e.target.value,
                  timeTypeId: e.target.value,
                  timeValue: 1
                });
              }}
            >
              {ScheduleTimeTypes.map((d) => (
                <option key={d.id} value={parseInt(d.id)}>
                  {d.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-4">
          <ScheduleValueTemplate
            onChange={(e) => {
              onChange({ ...s, timeValue: e.target.value });
            }}
            defaultValue={s?.timeValue}
            typeId={s?.timeTypeId}
            type="daily"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label>Start</label>
            <input
              name="timeStart"
              placeholder="Start *"
              type="time"
              className="form-control border-input"
              defaultValue={s?.timeStart}
              onChange={(e) => {
                onChange({ ...s, timeStart: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label>Stop</label>
            <input
              name="timeStop"
              placeholder="Stop *"
              type="time"
              className="form-control border-input"
              defaultValue={s?.timeStop}
              onChange={(e) => {
                onChange({ ...s, timeStop: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ScheduleJobs = ({ jobs }) => {
  return (
    <div className="detail-table">
      {jobs ? (
        jobs.length < 1 ? (
          <div className="row">
            <div className="col-sm-12">n/a</div>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {jobs?.map((j) => (
        <div key={j.jobId} className="row">
          <div className="col-sm-10"> {j.jobName}</div>
          <div className="col-sm-2 d-flex justify-content-end">
            <Link className="mr-3" to={`/admin/jobs/edit/${j.jobId}/frequency`}>
              <i className="icon icon-edit"></i> Edit
            </Link>{" "}
            <Link to={`/jobs/runs?jobConfigId=${j.jobId}`}>
              <i className="icon icon-exchange"></i> Runs history
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

const ScheduleComponent = ({ schedule: s, onSave, onDelete }) => {
  const [schedule, setSchedule] = useState();

  useEffect(() => {
    setSchedule(s);
  }, [s]);

  return (
    <PageContent className="border-0">
      <ContentHeader title={`Jobs / Schedules / ${schedule?.name}`} />
      <ContentBody>
        <Card>
          <Header title="Details" />
          <Body>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSave(schedule);
              }}
            >
              <ScheduleDetails schedule={schedule} onChange={setSchedule} />
              <div className="row mt-5">
                <div className="col-sm-12">
                  <button type="submit" className="btn btn-dark btn-lg">
                    Save schedule
                  </button>
                </div>
              </div>
            </form>
          </Body>
        </Card>
        <Card>
          {schedule?.jobs && schedule?.jobs.length > 0 ? (
            <>
              <Header title="Jobs using this schedule" />
              <Body>
                <div>
                  <ScheduleJobs jobs={schedule?.jobs} />
                </div>
              </Body>
            </>
          ) : (
            <>
              <Header title="No jobs using this schema" />
              <Body>
                <button className="btn btn-danger" onClick={(e) => onDelete(s)}>
                  Remove schema
                </button>
              </Body>
            </>
          )}
        </Card>
      </ContentBody>
    </PageContent>
  );
};

export default ScheduleComponent;
