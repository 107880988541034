import React from "react";
import { createBinaryString } from "../../common/decimalToBinary";

const OneTimeTemplate = () => {
  return <div></div>;
};

const InputTemplate = ({ defaultValue, onChange }) => {
  return (
    <>
      <label>Value of X</label>
      <input
        className="form-control"
        type="text"
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </>
  );
};

export const WeekDays = [
  { id: 1, name: "Mon" },
  { id: 2, name: "Tue" },
  { id: 4, name: "Wed" },
  { id: 8, name: "Thu" },
  { id: 16, name: "Fri" },
  { id: 32, name: "Sat" },
  { id: 64, name: "Sun" },
];

const WeekdaysTemplate = ({ defaultValue, onChange }) => {
  const dayMask = createBinaryString(parseInt(defaultValue, 10));
  return (
    <>
      {WeekDays.map((wd, index) => (
        <div className="form-check" key={wd.id}>
          <input
            checked={
              parseInt(dayMask.charAt(dayMask.length - index - 1), 10) === 1
            }
            name={wd.name}
            id={wd.name}
            type="checkbox"
            className="form-check-input"
            defaultValue={defaultValue + ""}
            onChange={(e) => {
              const newValue = e.target.checked
                ? (defaultValue += wd.id)
                : (defaultValue -= wd.id);
              onChange({ target: { value: newValue } });
            }}
          />
          <span className="form-check-label" htmlFor={wd.name}>
            {wd.name}
          </span>
        </div>
      ))}
    </>
  );
};

const ScheduleValueTemplate = ({ defaultValue, onChange, typeId, type }) => {
  if (!typeId) {
    return <div></div>;
  }

  if (type === "daily") {
    const TimeTemplate = ScheduleTimeTypes.filter(
      (d) => d.id === parseInt(typeId)
    ).map((d) => d.template)[0];
    return <TimeTemplate defaultValue={defaultValue} onChange={onChange} />;
  }

  if (type === "duration") {
    const DurationTemplate = ScheduleDurationTypes.filter(
      (d) => d.id === parseInt(typeId)
    ).map((d) => d.template)[0];
    return <DurationTemplate defaultValue={defaultValue} onChange={onChange} />;
  }

  return <div></div>;
};

export const ScheduleDurationTypes = [
  { id: 1, name: "One time", template: OneTimeTemplate },
  { id: 101, name: "Repeat every X days", template: InputTemplate },
  { id: 102, name: "Repeat specific weekdays", template: WeekdaysTemplate },
  { id: 103, name: "Repeat every X days in month", template: InputTemplate },
];

export const ScheduleTimeTypes = [
  { id: 1, name: "Once", template: OneTimeTemplate },
  { id: 100, name: "Repeat every X hours", template: InputTemplate },
  { id: 101, name: "Repeat every X minutes", template: InputTemplate },
];

export default ScheduleValueTemplate;
