import { mapTo, delay } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import {
    SHOW_NOTIFICATION, 
    hideNotification
} from '../actions/notification'

export const notificationEpic = action$ => action$.pipe(
    ofType(SHOW_NOTIFICATION),
    delay(5000), 
    mapTo(hideNotification())
  );
  