import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import InvitesList from '../components/invites/InvitesList'
import {
    loadInvitesList,
    loadInvitationTokens,
    createInvitationToken,
    deleteInvitationToken,
    revokeInvite,
    declineInvite,
    resendInvite
} from '../actions/invite'
import Loader from '../components/common/Loader'
import { isFetching } from '../selectors/Fetching';
import { push } from 'connected-react-router';
import TabFilter from '../components/common/tab/TabFilter';

import PageContent, { ContentHeader, ContentBody } from '../components/common/PageContent';

const mapStateToProps = (state) => {
    return {
        invitationTokens: state.invite.invitationTokens,
        isFetching: isFetching(state.fetching, loadInvitesList, loadInvitationTokens),
        sentInvites: state.invite.sentInvites,
        receivedInvites: state.invite.recivedInvites
    }
}

const mapDispatchToPros = (dispatch) => {
    return {
        initalStateInvites: () => dispatch(loadInvitesList()),
        initalStateInvitationTokens: () => dispatch(loadInvitationTokens()),
        createToken: () => dispatch(createInvitationToken()),
        deleteToken: (invite) => dispatch(deleteInvitationToken(invite.token)),
        revokeInvite: (invite) => dispatch(revokeInvite(invite.token)),
        declineInvite: (invite) => dispatch(declineInvite(invite.token)),
        resendInvite: (invite) => dispatch(resendInvite(invite.token)),
        showReceivedInvite: (invite) => invite.status === "Pending" ? dispatch(push(`/invite/accept/${invite.token}`)) : ""
    }
}


const ExtendedNetworkListContainer = ({
    initalStateInvites,
    isFetching,
    sentInvites,
    receivedInvites,
    revokeInvite,
    declineInvite,
    resendInvite,
    showReceivedInvite
}) => {

    const invitesSchema = [
        { accessor: "to", header: "To" },
        { accessor: "company", header: "Account/Company" },
        { accessor: "created", header: "Date", type: "date" },
        { accessor: "expires", header: "Expires in", type: "date" },
        { accessor: "status", header: "Status" },
        //{ type: 'button', css: 'btn btn-danger btn-fill btn-wd', action: revokeInvite, actionText: "Revoke", disabled: (item) => !["Pending", "Expired"].includes(item.status) },
        {
            type: 'custom', render: (data) => ["Expired"].includes(data.status) ?
                <div className="float-right text-nowrap">
                    <button className="btn btn-danger" onClick={() => { revokeInvite(data); }}><i className="icon icon-trash"></i> Delete</button> 
                    <button className="btn btn-primary ml-2" onClick={() => { resendInvite(data); }}><i className="icon icon-paper-plane"></i> Resend</button>
                </div> 
                : 
                ["Pending"].includes(data.status) ? 
                <button className="btn btn-danger float-right" onClick={() => { revokeInvite(data); }}><span className="text-nowrap"><i className="icon icon-trash"></i> Revoke</span></button>
                : ""
        }
    ]

    const receivedSchema = [
        { accessor: "from", header: "From" },
        { accessor: "company", header: "Account/Company" },
        { accessor: "created", header: "Date", type: "date" },
        { accessor: "expires", header: "Expires in", type: "date" },
        { accessor: "status", header: "Status" },
        { type: 'button', css: 'btn btn-danger float-right', action: declineInvite, actionText: "Decline", disabled: (item) => !item.status.match(/pending/i) },
    ]


    useEffect(() => {
        initalStateInvites();
    }, [initalStateInvites])

    const [filter, setFilter] = useState("received")

    return isFetching ?
        <Loader /> :
        <PageContent className="border-0">
            <ContentHeader title="Network / Connection requests"/>
            <ContentBody>
                <TabFilter onChange={setFilter} filters={[{ id: "received", description: <div><i className="icon icon-inbox"></i> <span>Received</span></div> }, { id: "send", description: <div><i className="icon icon-paper-plane"></i> <span>Sent</span></div> }]} selectedFilter={filter}>
                {
                    filter === "received" ?
                        <InvitesList
                            title="Received connection requests"
                            schema={receivedSchema}
                            invites={receivedInvites}
                            showInvite={showReceivedInvite}
                        /> 
                        :
                        <InvitesList
                            title="Sent connection requests"
                            schema={invitesSchema}
                            invites={sentInvites}
                        /> 
                }
                </TabFilter>

            </ContentBody>
        </PageContent>
}

export default connect(mapStateToProps, mapDispatchToPros)(ExtendedNetworkListContainer)