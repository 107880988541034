import React from 'react'
import { NavLink } from 'react-router-dom'
import './TabLinks.css'

const TabLinks = ({ header, children }) => (
    <React.Fragment>
        {header}
        {children}
    </React.Fragment>
)

export const TabLink = ({ to, title }) => {
    return <li className="nav-item"><NavLink className="nav-link" to={to}>{title}</NavLink></li>
}

export const TabHeader = ({ children }) => {
    return <ul className="nav nav-tabs mb-3">
        {children}
    </ul>
}

export const TabContent = ({ children }) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
   )
}


export default TabLinks

