import React from "react";
import Card, { Header, Body } from "../common/Card";
import { useModal } from "../modal/Modal";

const DeleteUser = ({ onDeleteUser }) => {
  const { showModal } = useModal();
  return (
    <Card className="card-settings">
      <Header title="Delete user">
        <div className="alert highlight" role="alert">
          <span>
            After confirmation, we will permanently delete your user, any
            Cloudbuz accounts in which you are the account owner, and all data
            about your user in external systems.
          </span>
        </div>
      </Header>
      <Body>
        <button
          onClick={() => {
            showModal("Confirm", {
              confirmBtnText: "Delete",
              title: "Delete user account?",
              confirmBtnDanger: true, 
              message:(
                <span>
                  <p>After confirmation, we will permanently delete your user, any Cloudbuz accounts in which you are the account owner, and all data about your user in external systems. This action can not be undone.</p>
                  <p><strong>Are you really sure?</strong></p>
                </span>
              ),
              init: { next: onDeleteUser },
            });
          }}
          className="btn btn-danger btn-lg"
        >
          Delete user
        </button>
      </Body>
    </Card>
  );
};

export default DeleteUser;
