import React from 'react'
import { connect } from 'react-redux'
import Loader from '../components/common/Loader'

import { setChangePasswordData, changePassword } from '../actions/auth'

import ChangePasswordComponent from '../components/users/ChangePassword'
import { isFetching } from '../selectors/Fetching';
import AuthTwofactor from './AuthTwofactor'

const mapStateToProps = (state) => {
    return {
        isFetching: isFetching(state.fetching, changePassword),
        oldPassword: state.auth.oldPassword,
        newPassword: state.auth.newPassword,
        newPasswordRetype: state.auth.newPasswordRetype,
        errors: state.auth.changePasswordErrors
    }
}

const mapDispatchToPros = (dispatch) => {
    return {
        inputChangeHandler: (value, key) => dispatch(setChangePasswordData(value, key)),
        changePasswordClick: (oldPassword, newPassword, newPasswordRetype) => dispatch(changePassword(oldPassword, newPassword, newPasswordRetype))
    }
}

let AuthOptionsContainer = ({
    isFetching,
    inputChangeHandler,
    changePasswordClick,
    newPasswordRetype,
    newPassword,
    oldPassword,
    errors
}) => {
    return isFetching ?
        <Loader /> :
        <div>
             <ChangePasswordComponent inputChangeHandler={inputChangeHandler}
            changePasswordClick={changePasswordClick}
            newPasswordRetype={newPasswordRetype}
            newPassword={newPassword}
            oldPassword={oldPassword}
            errors={errors}
            />
            <AuthTwofactor />
        </div>
        
}

export default connect(mapStateToProps, mapDispatchToPros)(AuthOptionsContainer)