import React, { useState } from "react";
import "./ReportGenerator.css";

const ReportGeneratorComponent = ({ onOrderReport, initialFilter }) => {
  const [filter, setFilter] = useState(initialFilter);
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onOrderReport(filter);
        }}
        data-testid="form"
      >
        <div className="row">
          <div className="col-sm-4 mt-3">
            <input
              className="form-control"
              type="date"
              placeholder="Date from"
              defaultValue={filter?.dateFrom}
              onChange={(e) => {
                setFilter({ ...filter, dateFrom: e.target.value });
              }}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <input
              className="form-control"
              type="date"
              placeholder="Date to"
              defaultValue={filter?.dateTo}
              onChange={(e) => {
                setFilter({ ...filter, dateTo: e.target.value });
              }}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <input
              className="form-control"
              type="text"
              placeholder="Job name"
              defaultValue={filter?.jobName}
              onChange={(e) => {
                setFilter({ ...filter, jobName: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 mt-3">
            <input
              className="form-control"
              type="text"
              placeholder="Connector from"
              defaultValue={filter?.clientFrom}
              onChange={(e) => {
                setFilter({ ...filter, clientFrom: e.target.value });
              }}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <input
              className="form-control"
              type="text"
              placeholder="Connector to"
              defaultValue={filter?.clientTo}
              onChange={(e) => {
                setFilter({ ...filter, clientTo: e.target.value });
              }}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <input
              className="form-control"
              type="text"
              placeholder="File name"
              defaultValue={filter?.fileName}
              onChange={(e) => {
                setFilter({ ...filter, fileName: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-4 mt-3">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="reportType"
                id="html"
                value=".html"
                checked={filter?.reportType === ".html"}
                onChange={(e) => {
                  setFilter({ ...filter, reportType: e.target.value });
                }}
              />
              <label className="form-check-label" htmlFor="html">
                Html
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="reportType"
                id="excel"
                value=".xls"
                checked={filter?.reportType === ".xls"}
                onChange={(e) => {
                  setFilter({ ...filter, reportType: e.target.value });
                }}
              />
              <label className="form-check-label" htmlFor="excel">
                Excel
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-6 mt-3">
            <input
              className="form-control"
              type="text"
              placeholder="Set title ( optional )"
              onChange={(e) => {
                setFilter({ ...filter, title: e.target.value });
              }}
            />
          </div>
          <div className="col-sm-6 mt-3">
            <button type="submit" className="btn btn-dark">
              Order report
            </button>
          </div>
        </div>
      </form>
      <hr />
    </>
  );
};

export default ReportGeneratorComponent;
