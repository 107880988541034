export const SET_FORM_DATA_NOTIFICATION_SETTINGS = 'SET_FORM_DATA_NOTIFICATION_SETTINGS'
export const SAVE_NOTIFICATION_SETTINGS = 'SAVE_NOTIFICATION_SETTINGS'
export const SAVE_NOTIFICATION_SETTINGS_SUCCESS = 'SAVE_NOTIFICATION_SETTINGS_SUCCESS'

export const LOAD_NOTIFICATION_SETTINGS = 'REQ_LOAD_NOTIFICATION_SETTINGS'
export const LOAD_NOTIFICATION_SETTINGS_SUCCESS = 'LOAD_NOTIFICATION_SETTINGS_SUCCESS'

export const SET_JOB_NOTIFICATION_SETTINGS = 'SET_JOB_NOTIFICATION_SETTINGS'

export const SAVE_JOB_NOTIFICATION_SETTINGS = 'REQ_SAVE_JOB_NOTIFICATION_SETTINGS'
export const SAVE_JOB_NOTIFICATION_SETTINGS_SUCCESS = 'SAVE_JOB_NOTIFICATION_SETTINGS_SUCCESS'


export const saveNotificationSettings = (wantsEmail,
    wantsSms,
    wantsWeb,
    wantsJob,
    wantsSystem,
    wantsPromotional,
    emailNotificationType,
    emailNotificationSpec) => ({
        type: SAVE_NOTIFICATION_SETTINGS,
        wantsEmail,
        wantsSms,
        wantsWeb,
        wantsJob,
        wantsSystem,
        wantsPromotional,
        emailNotificationType,
        emailNotificationSpec
    })

export const setJobNotificationSettings = (value, key) => ({
    type: SET_JOB_NOTIFICATION_SETTINGS,
    value: value,
    key: key
})

export const setFormDataNotificationSettings = (value, key) => ({
    type: SET_FORM_DATA_NOTIFICATION_SETTINGS,
    value: value,
    key: key
})

export const saveNotificationSettingsSuccess = () => ({
    type: SAVE_NOTIFICATION_SETTINGS_SUCCESS
})

export const loadNotificationSettings = () => ({
    type: LOAD_NOTIFICATION_SETTINGS
})

export const loadNotificationSettingsSuccess = ({ settings, jobSettings }) => ({
    type: LOAD_NOTIFICATION_SETTINGS_SUCCESS,
    settings, jobSettings

})

export const saveJobNotificationSettings = (settings) => ({
    type: SAVE_JOB_NOTIFICATION_SETTINGS,
    settings
})

export const saveJobNotificationSettingsSuccess = () => ({
    type: SAVE_JOB_NOTIFICATION_SETTINGS_SUCCESS
})