import { get$, post$, delete$, url } from "./http";

export const listAllConnectors$ = () => {
  return get$("/connectors");
};

export const loadRequestInstallmentData$ = () => {
  return get$("/connectors/installmentData");
};

export const getConnectorDetails$ = (clientId) => {
  return get$("/connectors/details/" + clientId);
};

export const recyleConnector$ = (clientId) => {
  return post$("/connectors/recycle/" + clientId);
};

export const changeConnectorLogLevel$ = (clientId, logLevel) => {
  return post$("/connectors/changeLogLevel/" + clientId, { level: logLevel });
};

export const requestConnectorLogFiles$ = (clientId) => {
  return post$("/connectors/requestLogFiles/" + clientId);
};

export const deleteConnectorLogFile$ = (clientId, path) => {
  return post$("/connectors/deleteFile/" + clientId, { filePath: path });
};

export const downloadConnectorLogFilePath = (clientId, fileId) => {
  return url(`/connectors/${clientId}/command/logfile/${fileId}`);
};

export const transferConnectorToNewOrg$ = (clientId, newOrgId) => {
  return post$("/connectors/transfer/" + clientId, {
    newOrganisationId: newOrgId,
  });
};

export const unregisterAndDeleteConnector$ = (clientId) => {
  return post$("/connectors/delete/" + clientId);
};

export const createConnectorInstallmentRequest$ = (
  email,
  message,
  orgId,
  type
) => {
  return post$("/connectors/create/", {
    email: email,
    message: message,
    organisationId: orgId,
    type: type,
  });
};

export const deleteConnectorInstallmentRequest$ = (token) => {
  return post$("/connectors/delete/", {
    token,
  });
};

export const restart$ = (clientId) => {
  return post$(`/connectors/${clientId}/command/restart`);
};

export const logfile$ = (clientId, date) => {
  return post$(`/connectors/${clientId}/command/logfile?date=${date}`);
};

export const removeLogfile$ = (clientId) => {
  return delete$(`/connectors/${clientId}/command/logfile`);
};

export const recycl$ = (clientId) => {
  return post$(`/connectors/${clientId}/command/recycle`);
};

export const kill$ = (clientId) => {
  return post$(`/connectors/${clientId}/command/kill`);
};

export const changeLogLevel$ = (clientId, level) => {
  return post$(`/connectors/${clientId}/command/loglevel`, { level });
};

export const clearExcludeFolders$ = (clientId) => {
  return post$(`/connectors/${clientId}/command/folders/clear`);
};

export const addExcludeFolders$ = (clientId, path) => {
  return post$(`/connectors/${clientId}/command/folders`, { path });
};

export const removeExcludeFolders$ = (clientId, path) => {
  return post$(`/connectors/${clientId}/command/folders/remove`, { path });
};

export const changeConnectorDescription$ = (clientId, description) => {
  return post$(`/connectors/${clientId}`, { description });
};
