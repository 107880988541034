import React from "react";
import Table from "../common/table/Table";
import TablePaginator from "../common/table/Paginator";
import Privilege from "../../containers/Privilege";
import { NavLink } from "react-router-dom";

const ExploreTable = ({
  view,
  schema,
  onRowClick,
  currentPage,
  onPageChange,
  onSortChange,
  sortBy,
  onChange,
  download,
}) => {
  if (view.items && view.items.length) {
    return (
      <div>
        <Table
          data={view.items}
          schema={schema}
          onRowClick={onRowClick}
          onSortChange={onSortChange}
          sortBy={sortBy}
          onChange={onChange}
        />
        {view.items && view.items.length && (
          <TablePaginator
            currentPage={currentPage}
            totalPages={view.totalPages}
            pageNeighbours={1}
            onPageChange={onPageChange}
          />
        )}
      </div>
    );
  }

  if (
    view.breadcrumbs &&
    view.breadcrumbs.length &&
    view.breadcrumbs[view.breadcrumbs.length - 1].type === "Organization"
  ) {
    return (
      <div className="alert highlight mt-3 text-left" role="alert">
        <h5>No connectors registered on this account?</h5>
        <span>
          Seems like there are no connectors registered on this account? To
          start transferring files you need at least one connector installed and
          registered.
        </span>
        <Privilege type="Network extend page" privilege="read">
          <p> There are two ways to do this:</p>
          <ul>
            <li>
              <NavLink
                activeClassName="active"
                to="/admin/connectors/requestnewinstallment"
              >
                Send an install request to a server administrator
              </NavLink>
              - install and register with a token included in the request
              e-mail.
            </li>
            <li>
              Download installer from this page - install and register with
              username and password.
            </li>
          </ul>
          <hr></hr>
          <button className="btn btn-dark" onClick={(e) => download()}>
            <i className="icon icon-download"></i> Download 32-bit x86
          </button>
        </Privilege>
      </div>
    );
  }

  return (
    <div className="text-center empty-folder mt-3">
      <div className="alert alert-empty text-center" role="alert">
        Nothing found..
      </div>
    </div>
  );
};

export default ExploreTable;
