import { post$, get$ } from "./http";
import * as storage from "../helpers/auth";

export const signUpUser$ = (model) => post$("/user/signup", model);

export const login$ = (model) => {
  return post$("/user/login", model, true);
};

export const createForgotPassword$ = (model) => {
  return post$("/user/forgotpassword", model);
};

export const forgotPassword$ = (token, model) => {
  return post$(`/user/forgotpassword/${token}`, model);
};

export const getUserInfo$ = () => {
  return get$("/user/info");
};

export const refreshAuth$ = () => {
  const { refreshToken } = storage.get();
  return post$("/user/refresh/login", { refreshToken });
};

export const changeAccount$ = (accountId) => {
  const { refreshToken } = storage.get();
  return post$("/user/refresh/account", { accountId, refreshToken });
};  

export const changePassword$ = (
  oldPassword,
  newPassword,
  newPasswordRetype
) => {
  return post$("/user/changepassword", {
    oldPassword,
    newPassword,
    newPasswordRetype,
  });
};

export const showTwofactor$ = (password) => {
  return post$("/user/twofactor/show", { password });
};  

export const changeTwofactor$ = ({enabled, password, twofactorCode, twofactorKey}) => {
  return post$("/user/twofactor", { enabled, password, twofactorCode, twofactorKey });
};  
