import * as api from '../api'

import { switchMap, catchError, map, mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'

import {
    LOAD_CONNECTIONS_LIST,
    LOAD_CONNECTIONS_DETAILS,
    loadConnectionsListSuccess,
    loadConnectionsDetailsSuccess,
    DISCONNECT_CONNECTION,
    disconnectConnectionSuccess,
    DISCONNECT_CONNECTION_SUCCESS,
    loadConnectionsList
} from '../actions/connections'


import { handleError } from './common';
import { showNotificationSuccess } from '../actions/notification';

export const listInvitesEpic$ = action$ => {
    return action$.pipe(
        ofType(LOAD_CONNECTIONS_LIST),
        switchMap((action) =>
            api.listAllConnections$().pipe(
                map(response => {
                    return loadConnectionsListSuccess(response.connections)
                }),
                catchError(handleError(action$, action.type))
            )
        )
    )
}

export const loadConnectionDetailsEpic$ = action$ => {
    return action$.pipe(
        ofType(LOAD_CONNECTIONS_DETAILS),
        switchMap((action) =>
            api.loadConnectionDetails$(action.accountRelationId).pipe(
                map(response => {
                    return loadConnectionsDetailsSuccess(response.details,
                        response.jobsFrom,
                        response.jobsTo,
                        response.sharesFrom,
                        response.sharesTo)
                }),
                catchError(handleError(action$, action.type))
            )
        )
    )
}

export const disconnectConnectionEpic$ = action$ => {
    return action$.pipe(
        ofType(DISCONNECT_CONNECTION),
        switchMap((action) => api.disconnectConnection(action.connection.accountKey).pipe(
            map(response => disconnectConnectionSuccess()),
            catchError(handleError(action$, action.type))
        )
        )
    )
}

export const disconnectConnectionSuccessEpic$ = action$ => {
    return action$.pipe(
        ofType(DISCONNECT_CONNECTION_SUCCESS),
        mergeMap(action => of(showNotificationSuccess("Connection has been disconnected"), loadConnectionsList()))
    )
}