export const CREATE_ACCOUNT = "REQ_CREATE_ACCOUNT"
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS"
export const CREATE_ACCOUNT_FAILED = "CREATE_ACCOUNT_FAILED"
export const LOAD_ACCOUNT_LIST = 'REQ_LOAD_ACCOUNT_LIST'
export const LOAD_ACCOUNT_LIST_SUCCESS = 'LOAD_ACCOUNT_LIST_SUCCESS'
export const LOAD_ACCOUNT_LIST_FAILED = 'LOAD_ACCOUNT_LIST_FAILED'
export const LOAD_ACCOUNT = 'REQ_LOAD_ACCOUNT'
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT'
export const GET_ACCOUNT = 'REQ_GET_ACCOUNT'
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_FAILED = 'GET_ACCOUNT_FAILED'
export const SAVE_ACCOUNT = "REQ_SAVE_ACCOUNT"
export const SAVE_ACCOUNT_SUCCESS = "SAVE_ACCOUNT_SUCCESS"
export const LOAD_ACCOUNT_PRIVILEGES_SUCCESS = "LOAD_ACCOUNT_PRIVILEGES_SUCCESS"
export const REMOVE_ORGANISATION = "REQ_REMOVE_ORGANISATION"
export const REMOVE_ORGANISATION_SUCCESS = "REMOVE_ORGANISATION_SUCCESS"
export const REMOVE_USER_FROM_ACCOUNT = "REQ_REMOVE_USER_FROM_ACCOUNT"
export const REMOVE_USER_FROM_ACCOUNT_SUCCESS = "REMOVE_USER_FROM_ACCOUNT_SUCCESS"

export const createAccount = (model, referrer) => ({
    type: CREATE_ACCOUNT,
    model, referrer
})

export const createAccountSuccess = (accountId, referrer) => ({
    type: CREATE_ACCOUNT_SUCCESS,
    accountId, referrer
})

export const createAccountFailed = (response) => ({
    type: CREATE_ACCOUNT_FAILED
})

export const loadAccountList = () => ({
    type: LOAD_ACCOUNT_LIST,
})

export const loadAccountsListSuccess = (accounts) => ({
    type: LOAD_ACCOUNT_LIST_SUCCESS,
    accounts: accounts
})

export const loadAccountsListFailed = (error) => ({
    type: LOAD_ACCOUNT_LIST_FAILED,
    error: error
})

export const loadAccount = (accountId) => ({
    type: LOAD_ACCOUNT,
    accountId: accountId
})

export const editAccount = (accountId, path) => ({
    type: EDIT_ACCOUNT,
    accountId: accountId,
    path
})

export const getAccount = (accountId) => ({
    type: GET_ACCOUNT,
    accountId: accountId
})

export const getAccountSuccess = (account) => ({
    type: GET_ACCOUNT_SUCCESS,
    account: account
})

export const getAccountFailed = (accountId) => ({
    type: GET_ACCOUNT_FAILED,
    accountId: accountId
})

export const loadAccountPrivilegesSuccess = (privileges) => ({
    type: LOAD_ACCOUNT_PRIVILEGES_SUCCESS,
    privileges
})

export const saveAccount = (accountId, model) => ({
    type: SAVE_ACCOUNT,
    model,
    accountId
})

export const saveAccountSuccess = () => ({
    type: SAVE_ACCOUNT_SUCCESS
})

export const removeOrganisation = (organisation) => ({
    type: REMOVE_ORGANISATION,
    organisation
})

export const removeOrganisationSuccess = (organisation) => ({
    type: REMOVE_ORGANISATION_SUCCESS,
    organisation
})

export const removeUserFromAccount = (user) => ({
    type: REMOVE_USER_FROM_ACCOUNT,
    user
})

export const removeUserFromAccountSuccess = (user) => ({
    type: REMOVE_USER_FROM_ACCOUNT_SUCCESS,
    user
})
