import React from 'react'
import CreateForgotPasswordContainer from '../containers/CreateForgotPassword'
import './ForgotPassword.css'

const ForgotPasswordPage = () => {
    return (
        <div className="login-wrapper">
            <div className="login align-self-center flex-fill">
                <CreateForgotPasswordContainer />
            </div>
        </div>
    )
}

export default ForgotPasswordPage