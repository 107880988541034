
import {
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    LOAD_ACCOUNT_LIST_SUCCESS,
    LOAD_ACCOUNT,
    EDIT_ACCOUNT,
    GET_ACCOUNT,
    GET_ACCOUNT_SUCCESS
} from '../actions/account';

import {
    LIST_CREATE_USER_PRIVILEGES_SUCCESS
} from '../actions/user'

export const account = (state = { accounts: [] }, action) => {
    switch (action.type) {
        case CREATE_ACCOUNT:
            return {
                ...state
            }
        case CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                accounts: [...state.accounts, action.account]
            }
        case LOAD_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                accounts: action.accounts
            }
        case LOAD_ACCOUNT:
            return {
                ...state,
                accountId: action.accountId
            }
        case EDIT_ACCOUNT:
            return {
                ...state,
                accountId: action.accountId,
                account: null
            }
        case GET_ACCOUNT:
            return {
                ...state,
                accountId: action.accountId,
                account: null
            }
        case GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.account
            }
        case LIST_CREATE_USER_PRIVILEGES_SUCCESS: 
            return {
                ...state,
                privileges: action.accountPrivileges
            }
        default:
            return state
    }
};