// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_ClientId,
    authority: process.env.REACT_APP_AZURE_AD_Authority,
    redirectUri: process.env.REACT_APP_AZURE_AD_RedirectUrl,
    consent: process.env.REACT_APP_AZURE_AD_Consent,
    navigateToLoginRequestUrl: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  prompt: process.env.REACT_APP_AZURE_AD_Consent ? "consent":  "",
  scopes: [
    "api://53d037e6-1d4e-4f5f-8ac2-8fdaabfe2788/access_as_user",
  ],
};
