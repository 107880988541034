import { get$, post$ } from './http';

export const listAllInvites$ = () => {
    return get$('/invite');
}

export const createAccountRelationInvite$ = (guestEmail, message) => {
    return post$('/invite/create/accountrelation', {
        guestEmail, message
    });
}


export const createUserInvite$ = (guestEmail, inviteInfo) => {
    return post$('/invite/create/user', {
        guestEmail,
        inviteInfo
    });
}

export const acceptInvite$ = (token, acceptInfo) => {
    return post$(`/invite/accept/${token}`, acceptInfo);
}

export const declineInvite$ = (token) => {
    return post$('/invite/decline/' + token);
}

export const revokeInvite$ = (token) => {
    return post$('/invite/revoke/' + token);
}

export const resendInvite$ = (token) => {
    return post$('/invite/resend/' + token);
}


export const getInvite$ =  (token) => {
    return get$(`/invite/${token}`);
}