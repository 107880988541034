import React from 'react'
import Table from '../common/table/Table'
import Card, { Header, Body } from '../common/Card'
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';
import { dateFormat } from '../../common/dateFormatter';
//import ExploreContainer from '../../containers/user/explore/Explore'
//import ShareContainer from '../../containers/user/explore/Share'
import Privilege from '../../containers/Privilege'
import { NavLink } from 'react-router-dom'

const schemaShares = [
    { type: "custom", header: "Path", render: (data) => <span><i className="icon icon-folder_shared"></i> { data.path }</span> },
    { accessor: "server", header: "Server" },
    { accessor: "organization", header: "Organisation" },
    { accessor: "permission", header: "Permission" },
]

const schemaShared = [
    { type: "custom", header: "Path", render: (data) => <span><i className="icon icon-folder_shared"></i> { data.path }</span> },
    { accessor: "server", header: "Server" },
    { accessor: "organization", header: "Organisation" },
    { accessor: "permission", header: "Permission" },
    { type: "custom", render: (data) => <EditShare pathKey={data.pathKey} /> }
]

const schemaJobs = [
    { accessor: "id", header: "Id" },
    { accessor: "name", header: "Name" },
    { accessor: "description", header: "Description" },
    { accessor: "permission", header: "Permission" }
]

const schemaSharedJobs = [
    { accessor: "id", header: "Id" },
    { accessor: "name", header: "Name" },
    { accessor: "description", header: "Description" },
    { accessor: "permission", header: "Permission" },
    { type: "custom", render: (data) => <EditShareJob jobKey={data.jobKey} /> }
]

const EditShare = ({ pathKey }) => {
    return (
        <NavLink className="btn btn-sm btn-primary float-right" to={"/explore/" + pathKey +  "/Folder/share"}>
            <i className="icon icon-edit"></i> <span> Edit share</span>
        </NavLink>
    )
}

const EditShareJob = ({ jobKey }) => {
    return (
        <NavLink className="btn btn-sm btn-primary float-right" to={"/admin/jobs/edit/" + jobKey + "/general"}>
            <i className="icon icon-edit"></i> <span> Edit job configuration</span>
        </NavLink>
    )
}

/*
const EditShare_OLD = ({ init }) => {
    const [showShare, setShowShare] = useState(false);

    const popup = showShare ?
        <SharePopup match={{ params: init }}
            onClose={() => {
                setShowShare(false);
            }}
        /> : ""

    return (
        <div>
            <button className="btn btn-primary" onClick={() => { setShowShare(true  ) }}>Edit share</button>
            {popup}
        </div>
    )
}
*/
/*
const SharePopup = ({ match, onClose }) => {
    return <div className="popup fixed">
        <Card className="popup-content fixed">
            <Header>
                <h2>Share folder</h2>
            </Header>
            <Body>
                <ShareContainer
                    match={match}
                    hideBreadcrumbs={true}
                />
            </Body>
            <footer>
                <button className="btn btn-info" onClick={() => { onClose(); }}>Back</button>
            </footer>
        </Card>
    </div>;
}
*/
/*
<ShareEndpoint title="Share folder" init={{}} /> //changed to navlink -> Explore & share 

const ShareEndpoint = ({ title, init }) => {
    const [showPopup, setShowPopup] = useState(false)
    const [share, setShare] = useState({ show: false });

    const onSelect = (source) => {
        setShare({ show: true, match: { params: source } });
    }

    let explore = showPopup ?
        <div className="popup fixed">
            <Card className="popup-content fixed">
                <Header>
                    <h2>{title}</h2>
                </Header>
                <Body>
                    {
                        share.show &&
                        <SharePopup
                            match={share.match}
                            onClose={() => {
                                setShare({ show: false });
                            }}
                        />
                    }
                    {
                        <ExploreContainer
                            mode="select"
                            filters={["Folder"]}
                            selectName="share"
                            initParams={init}
                            onSelect={(source) => { onSelect(source); }} />
                    }

                </Body>
                <footer>
                    <button className="btn btn-info" onClick={() => { setShowPopup(false); }}>Close</button>
                </footer>
            </Card>
        </div> : "";
    return (
        <div>
            <button className="btn btn-primary btn-lg" onClick={() => { setShare({ show: false }); setShowPopup(true); }}><i className="icon icon-share"></i> Share folder</button>
            {explore}
        </div>
    )
}

*/

const ConnectionsDetailsComponent = ({
    sharesFrom,
    sharesTo,
    jobsFrom,
    jobsTo,
    details,
    activeAccount, 
    user }) => {

    return (
        <PageContent>
            <ContentHeader title={'Network / Connections / ' + details.accountName}/>
            <ContentBody>
                <Card>
                    <Header title="Details">

                    </Header>
                    <Body>
                        <div className="card mb-3" style={{maxWidth: "40rem" }}>
                            <div className="card-header"><i className="icon icon-mail"></i> Contact information</div>
                            <div className="card-body">
                                <div className="row mb-3 mt-3 border-bottom">
                                    <div className="col-md-4 border-right text-muted">
                                        Account/Company
                                    </div>
                                    <div className="col-md-8">
                                        {details.accountName}
                                    </div>
                                </div>
                                <div className="row mb-3 border-bottom">
                                    <div className="col-md-4 border-right text-muted">
                                        Org. No
                                    </div>
                                    <div className="col-md-8">
                                        {details.organisationNumber}
                                    </div>
                                </div>
                                <div className="row mb-3 border-bottom">
                                    <div className="col-md-4 border-right text-muted">
                                        Account owner
                                    </div>
                                    <div className="col-md-8">
                                        {details.accountOwner}
                                    </div>
                                </div>
                                <div className="row mb-3 border-bottom">
                                    <div className="col-md-4 border-right text-muted">
                                        Account owner e-mail
                                    </div>
                                    <div className="col-md-8">
                                        <span className="badge badge-success">{details.accountOwnerEmail}</span> <small><i>Verified e-mail</i></small>
                                    </div>
                                </div>
                                <div className="row mb-3 border-bottom">
                                    <div className="col-md-4 border-right text-muted">
                                        Phone number
                                    </div>
                                    <div className="col-md-8">
                                        {details.accountOwnerPhone}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">Connection created: {dateFormat(details.connectedSince)}</small>
                            </div>
                        </div>

                    </Body>
                </Card>

                <Card>
                    <Header title="Shared folders">

                    </Header>
                    <Body>
                        <div className="alert highlight" role="alert">
                            <span>
                                Account admins can access shared resources according to premission granted by the other account.
                            </span>
                        </div>
                        <div className="mt-4">
                            <h6>Folders shared by account admins on {details.accountName} to account admins on your account</h6>
                        </div>
                        <div className="table-responsive table-full-width">
                            <Table data={sharesFrom}
                                schema={schemaShares}
                                infoIfEmpty={details.accountName + ' has not shared any folders to account admins on your account'} />
                        </div>
                        <div className="mt-3">
                            <h6>Folders shared by account admins on your account to account admins on {details.accountName}</h6>
                        </div>
                        <div className="table-responsive table-full-width">
                            <Table data={sharesTo}
                                schema={schemaShared}
                                infoIfEmpty={'Account admins on your account has not shared any folders to ' + details.accountName} />
                        </div>
                        <Privilege type="Network extend page" privilege="read">
                            <div className="alert highlight" role="alert">
                                Note! Share new endpoints or folders from <span> </span>
                                <NavLink activeClassName="active" to={"/explore/" + activeAccount.accountId +  "/Account"}>
                                    <span>Explore & share</span>
                                </NavLink>
                                .
                            </div>
                        </Privilege>                         
                    </Body>
                </Card>

                <Card>
                    <Header title="Shared jobs"></Header>
                    <Body>
                        <div className="alert highlight" role="alert">
                            <span>
                                Account admins can access shared resources according to premission granted by the other account.
                            </span>
                        </div>
                        <div className="mt-4">
                            <h6>Jobs shared by account admins on {details.accountName} to account admins on your account</h6>
                        </div>
                        <div className="table-responsive table-full-width">
                            <Table data={jobsFrom}
                                schema={schemaJobs}
                                infoIfEmpty={details.accountName + ' has not shared any jobs to account admins on your account'} />
                        </div>
                        <div className="mt-3">
                            <h6>Jobs shared by account admins on your account to account admins on {details.accountName}</h6>
                        </div>
                        <div className="table-responsive table-full-width">
                            <Table data={jobsTo}
                                schema={schemaSharedJobs}
                                infoIfEmpty={'Account admins on your account has not shared any jobs to ' + details.accountName} />
                        </div>
                        <Privilege type="Network extend page" privilege="read">
                            <div className="alert highlight" role="alert">
                                Note! If you would like account administrators on another Cloudbuz account to receive notifications 
                                or even have the possibillity to run a certain job manual you can share the job from the <span> </span>
                                <NavLink activeClassName="active" to="/admin/jobs">
                                    job configuration page
                                </NavLink>
                                .<span> </span> (Step four in the job config wizard)
                            </div>
                        </Privilege>
                    </Body>
                </Card>

            </ContentBody>
        </PageContent>
    )
}

export default ConnectionsDetailsComponent;