import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../components/common/Loader";

import ChangeNameAndEmail from "../components/generalsettings/ChangeNameAndEmail";
import DeleteUser from "../components/generalsettings/DeleteUser";
import Membership from "../components/generalsettings/Membership";

import {
  loadGeneralSettings,
  deleteUser,
  updateGeneralSettings,
  leaveOrganisation,
  leaveAccount,
  updateTimezone,
} from "../actions/generalsettings";
import { isFetching } from "../selectors/Fetching";
import { getMembership } from "../selectors/GeneralSettings";
import Avatar from "../components/generalsettings/Avatar";
import TimeZone from "../components/generalsettings/TimeZone";
import { useModal } from "../components/modal/Modal";

const mapStateToProps = (state) => {
  return {
    isFetching: isFetching(state.fetching, loadGeneralSettings),
    settings: state.generalsettings.settings,
    generalSettingsError: state.generalsettings.generalSettingsError,
    membership: getMembership(state.generalsettings.settings),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInitialState: () => dispatch(loadGeneralSettings()),
  deleteUser: () => dispatch(deleteUser()),
  saveUserInfo: (model) => dispatch(updateGeneralSettings(model)),
  leaveOrganisation: (org) => dispatch(leaveOrganisation(org.organisationId)),
  leaveAccount: (account) => dispatch(leaveAccount(account.accountId)),
  updateTimezone: (timeZone) => dispatch(updateTimezone(timeZone)),
});

const GeneralSettingsContainer = ({
  loadInitialState,
  isFetching,
  settings,
  deleteUser,
  saveUserInfo,
  leaveOrganisation,
  leaveAccount,
  updateTimezone,
  membership,
}) => {
  useEffect(() => {
    loadInitialState();
  }, [loadInitialState]);

  const { showModal } = useModal();

  const schema = [
    { accessor: "accountName", header: "Account" },
    { accessor: "privilegeName", header: "Privilege" },
    { accessor: "organisationName", header: "Organisation" },
    {
      type: "button",
      css: "btn btn-danger float-right",
      action: (data) => {
        if (data.privilege === 201) {
          showModal("Confirm", {
            title: "Leave account",
            message: (
              <span>
                Are you sure you want to leave <strong>{data.accountName}</strong>?
              </span>
            ),
            confirmBtnDanger: true,
            confirmBtnText: "Leave account",
            init: {
              next: () => {
                leaveAccount(data);
              },
            },
          });
          return;
        }

        showModal("Confirm", {
          title: "Leave organisation",
          message: (
            <span>
              Are you sure you want to leave <strong>{data.organisationName}</strong>?
            </span>
          ),
          confirmBtnDanger: true,
          confirmBtnText: "Leave organisation",
          init: {
            next: () => {
              leaveOrganisation(data);
            },
          },
        });
      },
      actionText: "Leave",
      disabled: (data) => data.isOwner, 
    },
  ];

  return isFetching || !settings ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Avatar hash={settings.gravatarHash} email={settings.email} />
      <ChangeNameAndEmail
        name={settings.name}
        email={settings.email}
        save={saveUserInfo}
      />
      <TimeZone initTimeZone={settings.timeZone} onSave={updateTimezone} />
      <Membership membership={membership} schema={schema} />
      <DeleteUser onDeleteUser={deleteUser} />
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSettingsContainer);
