import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import JobsComponent from "../../../components/user/jobs/Jobs";
import { dateFormat } from "../../../common/dateFormatter";
import { loadJobView, executeActionOnJob } from "../../../actions/jobs";
import { isFetching } from "../../../selectors/Fetching";
import { jobsCurrentPageChange } from "../../../actions/jobs";
import { push } from "connected-react-router";

const mapStateToProps = (state, ownProps) => ({
  view: state.jobs.jobsView,
  currentPage: state.jobs.currentJobsAllPage || 1,
  isFetching: isFetching(state.fetching, loadJobView),
  searchText: state.jobs.jobViewSearchText,
  sortBy: state.jobs.sortAllBy,
});

const mapDispatchToProps = (dispatch, state) => ({
  onRowClick: (currentPage) => (job, action) =>
    action === "select"
      ? dispatch(push(`/jobs/runs?jobConfigId=${job.jobId}`))
      : dispatch(executeActionOnJob(job.jobId, action, currentPage)),
  initialState: (currentPage, searchText, sortBy) =>
    dispatch(loadJobView(currentPage, searchText, sortBy)),
  onPageChange: (page) => dispatch(jobsCurrentPageChange(page)),
  onSearch: (sortBy) => (searchText) =>
    dispatch(loadJobView(1, searchText, sortBy)),
  onSortChange: (searchText) => (sortBy) => {
    dispatch(loadJobView(1, searchText, sortBy));
  },
});

const schema = [
  { accessor: "jobId", header: "Id" },
  { accessor: "name", header: "Name" },
  { accessor: "description", header: "Description" },
  {
    header: "Status",
    accessor: "isActive",
    type: "custom",
    render: (data) => {
      return (
        <React.Fragment>
          {data.isShared ? (
            <span>
              {" "}
              <span className="badge badge-primary">
                <i className="icon icon-info-circled"></i>{" "}
                {`Shared by: ${data.accountOwner}`}
              </span>{" "}
            </span>
          ) : (
            ""
          )}
          {data.isActive ? (
            data.autoEnable ? (
              <span className="badge badge-danger">
                <i className="icon icon-attention-circled"></i>{" "}
                {`Autodisabled until ${dateFormat(data.autoEnable)}`}
              </span>
            ) : (
              <span className="badge badge-success">
                <i className="icon icon-ok-circled"></i> Enabled
              </span>
            )
          ) : (
            <span className="badge badge-secondary">
              <i className="icon icon-stop-circle"></i> Disabled
            </span>
          )}
        </React.Fragment>
      );
    },
  },
  {
    header: "Next run",
    accessor: "nextExecution",
    type: "custom",
    render: (row) => {
      return row.nextExecution && row.isActive && !row.autoEnable
        ? dateFormat(row.nextExecution)
        : row.triggerName && row.isActive && !row.autoEnable
        ? row.triggerName
        : "n/a";
    },
  },
  {
    header: "",
    type: "custom",
    render: (row, schema, rowIndex, onRowClick) =>
      row.jobRight === "Run" && row.isActive && !row.autoEnable ? (
        <button
          className="btn btn-light float-right"
          onClick={(e) => {
            e.stopPropagation();
            onRowClick(row, "run");
          }}
        >
          <span className="icon icon-play-circled text-primary"></span>
          <span> Run</span>
        </button>
      ) : (
        ""
      ),
  },
];

const JobsContainer = ({
  initialState,
  view,
  onRowClick,
  currentPage,
  onPageChange,
  onSearch,
  searchText,
  sortBy,
  onSortChange,
}) => {
  useEffect(() => {
    initialState(currentPage, searchText, sortBy);
  }, [initialState, currentPage, searchText, sortBy]);

  const doSearch = useCallback(
    (s) => onSearch(sortBy)(s),
    [onSearch, sortBy],
  )

  return (
    <JobsComponent
      view={view}
      onRowClick={onRowClick(currentPage)}
      schema={schema}
      currentPage={currentPage}
      onPageChange={onPageChange}
      onSearch={doSearch}
      sortBy={sortBy}
      onSortChange={onSortChange(searchText)}
      searchText={searchText}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsContainer);
