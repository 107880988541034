import React from 'react'
import Table from '../common/table/Table'
import Card, { Body, Header} from '../common/Card';
import { NavLink } from 'react-router-dom'

const PendingRequestsList = ({
    pendingRequests,
    schema }) => {
    return (
        <Card>
            <Header title="Pending requests">
                <div className="alert highlight" role="alert">
                    <span>
                        This list shows sent requests not yet handled by the receiver. When a connector is installed and registred it will show in the list "Connectors in my network". If you revoke a sent request the token will be disabled and the receiver will not be able to install and register the connector.
                    </span>
                </div>
            </Header>
            <Body>
                <NavLink className="btn btn-lg btn-primary mb-3" to="/admin/connectors/requestnewinstallment">
                    <i className="icon icon-plus"></i> New request
                </NavLink> 
                <div className="table-responsive table-full-width">
                    <Table data={pendingRequests} schema={schema} infoIfEmpty="No pending requests.." />
                </div>
            </Body>
        </Card>
    )
}

export default PendingRequestsList;