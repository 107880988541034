import React from 'react'
import PropTypes from 'prop-types'
import './Paginator.css'

const PrevPage = ({ onClick, currentPage }) => {

    if (currentPage === 1) {
        return <div className="page-item"><span className="icon icon-left-open"></span></div>
    }

    return <div className="page-item" onClick={() => onClick(currentPage - 1)}><span className="icon icon-left-open"></span></div>
}

const NextPage = ({ onClick, currentPage, totalPages }) => {

    if (currentPage >= totalPages) {
        return <div className="page-item"><span className="icon icon-right-open"></span></div>
    }

    return <div className="page-item" onClick={() => onClick(currentPage + 1)}><span className="icon icon-right-open"></span></div>
}

const PageItem = ({ page, currentPage, onClick }) => {
    return <div onClick={() => { onClick(page) }} className={page === currentPage ? "page-item selected" : "page-item"}>{page}</div>
}

const PageItems = ({ currentPage, totalPages, onClick, pageNeighbours }) => {
    if (pageNeighbours && totalPages > (pageNeighbours * 2)) {

        let pages = [...Array(totalPages)].map((_, p) => p + 1);

        let maximumPages = pageNeighbours * 2 + 1;

        let start = currentPage - pageNeighbours;
        let end = currentPage + pageNeighbours

        if (start <= 0) {
            start = 1;
            end = maximumPages;
        }

        if (end >= totalPages) {
            start = (totalPages - maximumPages + 1);
            end = totalPages;
        }

        let batch = pages.slice(start - 1, end);

        return batch.map((p) =>
            <PageItem key={p} page={p} onClick={onClick} currentPage={currentPage} />
        )
    }

    return [...Array(totalPages)].map((_, p) =>
        <PageItem key={p} page={p + 1} onClick={onClick} currentPage={currentPage} />
    )
}

const StartPage = ({ onClick, currentPage, pageNeighbours }) => {
    if (currentPage >= (pageNeighbours + 2)) {
        return <div className="page-item start-page" onClick={() => onClick(1)}>1 |</div>
    }

    return "";
}

const LastPage = ({ onClick, currentPage, pageNeighbours, totalPages }) => {
    if (currentPage <= (totalPages - (pageNeighbours + 1))) {
        return <div className="page-item last-page" onClick={() => onClick(totalPages)}>| {totalPages}</div>
    }

    return "";
}

const TablePaginator = ({ currentPage, totalPages, onPageChange, pageNeighbours }) => {

    if (!totalPages || totalPages <= 1) {
        return "";
    }

    return (
        <div className="paginator pagination">
            <PrevPage onClick={onPageChange} currentPage={currentPage} />
            <StartPage onClick={onPageChange} currentPage={currentPage} pageNeighbours={pageNeighbours} />
            <PageItems onClick={onPageChange} currentPage={currentPage} totalPages={totalPages} pageNeighbours={pageNeighbours} />
            <LastPage onClick={onPageChange} totalPages={totalPages} currentPage={currentPage} pageNeighbours={pageNeighbours}/>
            <NextPage onClick={onPageChange} currentPage={currentPage} totalPages={totalPages} pageNeighbours={pageNeighbours} />
        </div>
    )
}

TablePaginator.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
    pageNeighbours: PropTypes.number
}

export default TablePaginator

