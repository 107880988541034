
export const LOGIN_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_USER_FAILED'
export const LOGIN_USER = 'REQ_LOGIN_USER'

export const LOGOUT = 'LOGOUT'
export const USER_FOUND = 'USER_FOUND'
export const USER_LOGGEDOUT = "USER_LOGGEDOUT"
export const REFRESH_TOKEN = "REFRESH_TOKEN"
export const REFRESH_USER_INFO = "REFRESH_USER_INFO"
export const REFRESH_USER_INFO_SUCCESS = "REFRESH_USER_INFO_SUCCESS"
export const USER_CHANGE_PASSWORD = 'REQ_USER_CHANGE_PASSWORD'
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS'
export const SET_CHANGE_PASSWORD_DATA = 'REQ_SET_CHANGE_PASSWORD_DATA'
export const USER_CHANGE_PASSWORD_FAILED = 'USER_CHANGE_PASSWORD_FAILED'
export const USER_FORGOT_PASSWORD = 'REQ_USER_FORGOT_PASSWORD'
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_FAILED = 'USER_FORGOT_PASSWORD_FAILED'
export const ENSURE_LOGGEDOUT = "ENSURE_LOGGEDOUT"

export const USER_CREATE_FORGOT_PASSWORD = "REQ_USER_CREATE_FORGOT_PASSWORD"
export const USER_CREATE_FORGOT_PASSWORD_SUCCESS = "USER_CREATE_FORGOT_PASSWORD_SUCCESS"
export const USER_CREATE_FORGOT_PASSWORD_FAILED = "USER_CREATE_FORGOT_PASSWORD_FAILED"

export const USER_TWO_FACTOR_VALIDATION = "USER_TWO_FACTOR_VALIDATION_SUCCESS";

export const forgotPassword = (token, model) => ({
    type: USER_FORGOT_PASSWORD,
    token, model
})

export const forgotPasswordSuccess = () => ({
    type: USER_FORGOT_PASSWORD_SUCCESS
})

export const forgotPasswordFailed = (errors) => ({
    type: USER_FORGOT_PASSWORD_FAILED,
    forgottPasswordErrors : errors
})

export const createForgotPassword = (email) => ({
    type: USER_CREATE_FORGOT_PASSWORD,
    email
})

export const createForgotPasswordSuccess = () => ({
    type: USER_CREATE_FORGOT_PASSWORD_SUCCESS
})

export const createForgotPasswordFailed = () => ({
    type: USER_CREATE_FORGOT_PASSWORD_FAILED
})


export const userFound = () => ({
    type: USER_FOUND,
    isAuthenticated: false
})

export const loginUser = (model) => ({
    type: LOGIN_USER,
    isAuthenticated: false,
    data: model
})

export const userTwoFactorValidation = (model) => ({
    type: USER_TWO_FACTOR_VALIDATION,
    isAuthenticated: false,
    data: model
})


export const loginSuccess = (user, info) => ({
    type: LOGIN_SUCCESS,
    isAuthenticated: true,
    user: user,
    info: info
})

export const loginFailed = (errors) => ({
    type: LOGIN_FAILED,
    isAuthenticated: false,
    errors
})

export const logout = (ref) => ({
    type: LOGOUT,
    ref
})

export const logoutUser = (ref) => ({
    type: USER_LOGGEDOUT,
    isAuthenticated: false,
    ref
})

export const ensureLoggedOut = () => ({
    type: ENSURE_LOGGEDOUT
})

export const refreshAuth = () => ({
    type: REFRESH_TOKEN
})

export const refreshUserInfo = () => ({
    type: REFRESH_USER_INFO
})

export const refreshUserInfoSuccess = (info) => ({
    type: REFRESH_USER_INFO_SUCCESS,
    info: info
})

export const changePassword = (oldPassword,
    newPassword,
    newPasswordRetype) => ({
        type: USER_CHANGE_PASSWORD,
        oldPassword,
        newPassword,
        newPasswordRetype
    })

export const setChangePasswordData = (value, key) => ({
    type: SET_CHANGE_PASSWORD_DATA,
    value,
    key
})    

export const changePasswordSuccess = () => ({
    type: USER_CHANGE_PASSWORD_SUCCESS
})

export const changePasswordFailed = (errors) => ({
    type: USER_CHANGE_PASSWORD_FAILED,
    changePasswordErrors: errors
})
