export const GET_ALL_STATS = 'REQ_GET_ALL_STATS'
export const GET_ALL_STATS_SUCCESS = 'REQ_GET_ALL_STATS_SUCCESS'

export const getAllStats = (utcDate, dateOffset, statisticHours) => ({
    type: GET_ALL_STATS,
    utcDate, dateOffset, statisticHours
})

export const getAllStatsSuccess = (stats) => ({
    type: GET_ALL_STATS_SUCCESS,
    stats
})