
import {
    GET_ALL_STATS_SUCCESS
} from '../actions/stats'

export const stats = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_STATS_SUCCESS:
            return {
                ...state,
                stats:action.stats
            }
        default: return state
    }
};