import React, { useRef } from 'react'
import Card, { Header, Body } from '../common/Card';
import { usePasswordVerification } from '../../hooks/hooks';
import { PasswordVerificationDialog } from '../common/dialogs/PasswordVerificationDialog';

const ChangeNameAndEmail = ({ name, email, save }) => {

    const [verificationState, showVerification, hideVerification] = usePasswordVerification(save);

    const nameRef = useRef(null);
    const emailRef = useRef(null);

    return (
        <Card className="card-settings">
            <Header title="Name and email">
                <div className="alert highlight" role="alert"><span>Edit your name and/or e-mail.</span></div>
            </Header>
            <PasswordVerificationDialog
                onHide={hideVerification}
                init={verificationState} />
            <Body>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label>Name</label>
                        <input className="form-control form-control-lg border-input" placeholder="Name" type="text" defaultValue={name} ref={nameRef} />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>E-mail</label>
                        <input className="form-control form-control-lg border-input" placeholder="E-mail" type="email" defaultValue={email} ref={emailRef} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-dark btn-lg"
                            onClick={() => showVerification({
                                name: nameRef.current.value,
                                email: emailRef.current.value
                            })}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Body>
        </Card>)
}

export default ChangeNameAndEmail
