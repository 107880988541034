import React from 'react'

const RadioButtons = ({ selectedValue, changeEvent, options }) => {
    return (
        <React.Fragment>
            {options &&
                options.map((option, i) => (
                    <div key={i} className="custom-control custom-radio mb-2">
                        <input type="radio"
                            id={option.id + "_radio"}
                            className="custom-control-input" 
                            checked={selectedValue.toString() === option.id.toString()}
                            onChange={changeEvent}
                            value={option.id} />
                        <label className="custom-control-label" htmlFor={option.id + "_radio"}>{option.name}</label>
                    </div>
                ))
            }
        </React.Fragment>
    );

    /* 
        <div class="custom-control custom-radio">
        <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
        <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
        </div>
        <div class="custom-control custom-radio">
        <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
        <label class="custom-control-label" for="customRadio2">Or toggle this other custom radio</label>
        </div>

        <div>
            {options &&
                options.map((option, i) => (
                    <div key={i}>
                        <label className="radio">
                            <input type="radio"
                                checked={selectedValue.toString() === option.id.toString()}
                                onChange={changeEvent}
                                value={option.id} />
                            {option.name}
                            <span className="checkround"></span>
                        </label>
                    </div>
                ))
            }
        </div>
        */
}

export default RadioButtons;