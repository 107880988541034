const stepTwoValidation = values => {
    const errors = {}
      

    if (!values.events || !values.events.length) {
        errors.events = { _error: 'You need at least one event to continue..' }
    } else {

        const eventArrayErrors = []

        values.events.forEach((event, index) => {

            let eventErrors = {};

            let previousEvent = values.events[index - 1];

            if (previousEvent === undefined)
                previousEvent = { source: {} };


            if (!event.eventType) {
                eventErrors.eventType = ' ';
                eventArrayErrors[index] = eventErrors;
            }

            if (!event.description) {
                eventErrors.description = ' ';
                eventArrayErrors[index] = eventErrors;
            }

            if(event.destination) {
                if ((parseInt(event.eventType, 10) === 1 || parseInt(event.eventType, 10) === 2) && (!event.destination.sourceId || !event.destination.path || event.destination.isDeleted)) {

                    if (!eventErrors.destination)
                        eventErrors.destination = {}

                    eventErrors.destination.sourceId = ' ';
                    eventErrors.destination.path = ' ';

                    eventArrayErrors[index] = eventErrors;
                }
            }
            if (!event.source.sourceId || !event.source.path || event.source.isDeleted) {

                if (!eventErrors.source)
                    eventErrors.source = {}

                eventErrors.source.sourceId = ' ';
                eventErrors.source.path = ' ';
                eventArrayErrors[index] = eventErrors;
            }

            if (!event.onSuccess) {
                eventErrors.onSuccess = ' ';
                eventArrayErrors[index] = eventErrors;
            }

            if (!event.onFailure) {
                eventErrors.onFailure = ' ';
                eventArrayErrors[index] = eventErrors;
            }

            else if (!/^\d+$/.test(event.maxNumberOfError)) {
                eventErrors.maxNumberOfError = 'Only numeric value is allowed'
                eventArrayErrors[index] = eventErrors;
            }

            if (event.source.filesFromPreviousEvent !== true) {
                if (!event.source.minMinutesModified) {

                    if (!eventErrors.source)
                        eventErrors.source = {}

                    eventErrors.source.minMinutesModified = ' ';
                    eventArrayErrors[index] = eventErrors;
                }
                else if (!/^\d+$/.test(event.source.minMinutesModified)) {

                    if (!eventErrors.source)
                        eventErrors.source = {}

                    eventErrors.source.minMinutesModified = 'Only numeric value is allowed'
                    eventArrayErrors[index] = eventErrors;
                }
            }

            if (event.source.filesFromPreviousEvent && index > 0 && parseInt(values.events[index - 1].eventType, 10) !== 1) {

                if (!eventErrors.source)
                    eventErrors.source = {}

                eventErrors.source.filesFromPreviousEvent = ' '
                eventArrayErrors[index] = eventErrors;
            }


            if (event.source.filesFromPreviousEvent !== true) {
                if (!event.source.fileNamePattern) {

                    if (!eventErrors.source)
                        eventErrors.source = {}

                    eventErrors.source.fileNamePattern = ' '
                    eventArrayErrors[index] = eventErrors;
                }
            }


            if (event.source && event.options.sendAlarmIfTotalAmountDataIsLessThan && event.options.sendAlarmIfTotalAmountDataIsLessThan.isActive) {
                let sendAlarmIfTotalAmountDataIsLessThanErrors = {}

                if (!event.options.sendAlarmIfTotalAmountDataIsLessThan.data) {
                    if (!eventErrors.source)
                        eventErrors.source = {}

                    sendAlarmIfTotalAmountDataIsLessThanErrors.data = ' ';
                }

                if (!event.options.sendAlarmIfTotalAmountDataIsLessThan.size) {
                    if (!eventErrors.source)
                        eventErrors.source = {}

                    sendAlarmIfTotalAmountDataIsLessThanErrors.size = ' ';
                }

                if (!event.options.sendAlarmIfTotalAmountDataIsLessThan.pastTime) {
                    if (!eventErrors.source)
                        eventErrors.source = {}

                    sendAlarmIfTotalAmountDataIsLessThanErrors.pastTime = ' ';
                }

                if (!event.options.sendAlarmIfTotalAmountDataIsLessThan.time) {
                    if (!eventErrors.source)
                        eventErrors.source = {}

                    sendAlarmIfTotalAmountDataIsLessThanErrors.time = ' ';
                }

                if (Object.keys(sendAlarmIfTotalAmountDataIsLessThanErrors).length !== 0) {
                    eventErrors.source.sendAlarmIfTotalAmountDataIsLessThan = sendAlarmIfTotalAmountDataIsLessThanErrors;
                    eventArrayErrors[index] = eventErrors;
                }
            }


            if (event.source && event.options.sendAlarmIfTotalAmountDataIsGreaterThan && event.options.sendAlarmIfTotalAmountDataIsGreaterThan.isActive) {
                if (event.options.sendAlarmIfTotalAmountDataIsGreaterThan.isActive) {
                    let sendAlarmIfTotalAmountDataIsGreaterThanErrors = {}

                    if (!event.options.sendAlarmIfTotalAmountDataIsGreaterThan.data) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalAmountDataIsGreaterThanErrors.data = ' ';
                    }

                    if (!event.options.sendAlarmIfTotalAmountDataIsGreaterThan.size) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalAmountDataIsGreaterThanErrors.size = ' ';
                    }

                    if (!event.options.sendAlarmIfTotalAmountDataIsGreaterThan.pastTime) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalAmountDataIsGreaterThanErrors.pastTime = ' ';
                    }

                    if (!event.options.sendAlarmIfTotalAmountDataIsGreaterThan.time) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalAmountDataIsGreaterThanErrors.time = ' ';
                    }

                    if (Object.keys(sendAlarmIfTotalAmountDataIsGreaterThanErrors).length !== 0) {
                        eventErrors.source.sendAlarmIfTotalAmountDataIsGreaterThan = sendAlarmIfTotalAmountDataIsGreaterThanErrors;
                        eventArrayErrors[index] = eventErrors;
                    }
                }
            }

            if (event.source && event.options.sendAlarmIfTotalNumberFilesIsLessThan && event.options.sendAlarmIfTotalNumberFilesIsLessThan.isActive) {
                if (event.options.sendAlarmIfTotalNumberFilesIsLessThan.isActive) {
                    let sendAlarmIfTotalNumberFilesIsLessThanErrors = {}

                    if (!event.options.sendAlarmIfTotalNumberFilesIsLessThan.data) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalNumberFilesIsLessThanErrors.data = ' ';
                    }

                    if (!event.options.sendAlarmIfTotalNumberFilesIsLessThan.pastTime) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalNumberFilesIsLessThanErrors.pastTime = ' ';
                    }

                    if (!event.options.sendAlarmIfTotalNumberFilesIsLessThan.time) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalNumberFilesIsLessThanErrors.time = ' ';
                    }

                    if (Object.keys(sendAlarmIfTotalNumberFilesIsLessThanErrors).length !== 0) {
                        eventErrors.source.sendAlarmIfTotalNumberFilesIsLessThan = sendAlarmIfTotalNumberFilesIsLessThanErrors;
                        eventArrayErrors[index] = eventErrors;
                    }
                }
            }

            if (event.source && event.source.sendAlarmIfTotalNumberFilesIsGreaterThan && event.source.sendAlarmIfTotalNumberFilesIsGreaterThan.isActive) {
                if (event.source.sendAlarmIfTotalNumberFilesIsGreaterThan.isActive) {
                    let sendAlarmIfTotalNumberFilesIsGreaterThanErrors = {}

                    if (!event.source.sendAlarmIfTotalNumberFilesIsGreaterThan.data) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalNumberFilesIsGreaterThanErrors.data = ' ';
                    }

                    if (!event.source.sendAlarmIfTotalNumberFilesIsGreaterThan.pastTime) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalNumberFilesIsGreaterThanErrors.pastTime = ' ';
                    }

                    if (!event.source.sendAlarmIfTotalNumberFilesIsGreaterThan.time) {
                        if (!eventErrors.source)
                            eventErrors.source = {}

                        sendAlarmIfTotalNumberFilesIsGreaterThanErrors.time = ' ';
                    }

                    if (Object.keys(sendAlarmIfTotalNumberFilesIsGreaterThanErrors).length !== 0) {
                        eventErrors.source.sendAlarmIfTotalNumberFilesIsGreaterThan = sendAlarmIfTotalNumberFilesIsGreaterThanErrors;
                        eventArrayErrors[index] = eventErrors;
                    }
                }
            }
        });

        if (eventArrayErrors.length) {
            errors.events = eventArrayErrors
        }
    }

    return errors;
}

export default stepTwoValidation

