import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../actions/auth'
import { changeAccount } from '../actions/user'
import { toggleHeaderContextMenu, toggleNotificationsMenu } from '../actions/menu'
import Header from '../components/header/Header'

import { toggleRightMenu } from '../actions/menu' 
import { withRouter } from 'react-router-dom'
import { isFetching } from '../selectors/Fetching'; 
import { toggleLeftMenu } from '../actions/menu' 

const HeaderContainer = ({
    isAuthenticated,
    signOut,
    changeAccount,
    user,
    notifications,
    info,
    userMenuOpen,
    toggleUserMenu,
    toggleNotificationsMenu,
    notificationsMenuIsOpen,
    toggleRightMenu,
    toggleLeftMenu,
    location, 
    isChangingAccount}) => { 

    return isAuthenticated ?
        <Header
            signOut={signOut}
            changeAccount={changeAccount}
            isChangingAccount={isChangingAccount}
            user={user}
            info={info}
            notifications={notifications || []}
            toggleUserMenu={toggleUserMenu}
            userMenuOpen={userMenuOpen}
            toggleNotificationsMenu={toggleNotificationsMenu}
            notificationsMenuIsOpen={notificationsMenuIsOpen}
            toggleRightMenu={toggleRightMenu}
            toggleLeftMenu={toggleLeftMenu}
            location={location} /> : ""
}

const mapStateToProps = (state) => {
    return {
        isChangingAccount : isFetching(state.fetching, changeAccount),
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        notifications: state.user.notifications,
        info: state.auth.info,
        userMenuOpen: state.menu.userMenuOpen,
        notificationsMenuIsOpen: state.menu.notificationsMenuIsOpen
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => { dispatch(logout()) },
        changeAccount: (accountId) => { dispatch(changeAccount(accountId)) },
        toggleUserMenu: () => dispatch(toggleHeaderContextMenu()),
        toggleNotificationsMenu: () => dispatch(toggleNotificationsMenu()),
        toggleRightMenu: (context) => dispatch(toggleRightMenu(context)),
        toggleLeftMenu: (context) => dispatch(toggleLeftMenu(context))
    }
}

export default (withRouter(connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false
})(HeaderContainer)))