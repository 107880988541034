import React from 'react'
import { Route, Switch } from "react-router-dom"
import TabLinks, { TabHeader, TabLink, TabContent } from '../components/common/tab/TabLinks';
import PageContent, { ContentBody, ContentHeader } from '../components/common/PageContent';
import GeneralPage from './General';
import UsersPage from './Users';
import OrganisationsPage from './Organisations';
import OrganisationPage from './Organisation';
import UserEdit from '../containers/UserEdit';

const AccountSettingsPage = () => {
    return (
        <PageContent>
            <ContentHeader title="Account settings"/>
            <ContentBody>
                <TabLinks header={
                    <TabHeader>
                        <TabLink to="/admin/settings/general" title={<span><i className="icon icon-cog"></i><span className="d-none d-md-inline"> General</span></span>}/>
                        <TabLink to="/admin/settings/users" title={<span><i className="icon icon-users"></i><span className="d-none d-md-inline"> Users</span></span>} />
                        <TabLink to="/admin/settings/organisations" title={<span><i className="icon icon-organization"></i><span className="d-none d-md-inline"> Organizations</span></span>} />
                        <TabLink to="/admin/subscription/your-plan" title={<span><i className="icon icon-chart-bar"></i><span className="d-none d-md-inline"> Subscription</span></span>} />
                    </TabHeader>
                }>
                    <TabContent>
                        <Switch>
                            <Route path="/admin/settings/general" component={GeneralPage} />
                            <Route path="/admin/settings/users/:userId/edit" component={UserEdit} />
                            <Route path="/admin/settings/users" component={UsersPage} />
                            <Route path="/admin/settings/organisations/edit/:organisationId" component={OrganisationPage} />
                            <Route path="/admin/settings/organisations" component={OrganisationsPage} />
                        </Switch>
                    </TabContent>
                </TabLinks>
            </ContentBody>
        </PageContent>
    )
}

export default AccountSettingsPage;