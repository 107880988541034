import React from 'react'
import { reduxForm } from 'redux-form';
import './AcceptInvite.css'
import Card, { Body, Header } from '../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';

const AcceptInviteComponent = ({ initialValues, handleSubmit, errors, submitting, onDecline }) => {
    const showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""


    return (
        <PageContent className="border-0">
             <ContentHeader title="Invitation to join account"/>
             <ContentBody>
                <Card className="card-settings">
                    <Header title={`You are invited to join the Cloudbuz account ${initialValues.invitedBy}`}>
                        <div className="alert alert-primary" role="alert">
                            <p>
                                <strong>{initialValues.invitorName} ({initialValues.invitorEmail})</strong> would like you to join the Cloudbuz account <strong>{initialValues.invitedBy}</strong>.
                            </p>
                            <p>
                                Once accepted, you will be a part of the Cloudbuz account <strong>{initialValues.invitedBy}</strong>. You can at any time leave <strong>{initialValues.invitedBy}</strong>.
                            </p>
                        </div>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit}>
                            <div className="content text-center">
                                <fieldset>
                                    <div className="note">
                                        {showError}
                                    </div>
                                </fieldset>
                                <footer className="text-center">
                                    <button type="submit" className="btn btn-success btn-block btn-lg mt-0" disabled={submitting}>
                                        Accept
                                    </button>
                                    or 
                                    <button type="button" className="btn btn-light btn-block btn-lg mt-0" disabled={submitting} onClick={onDecline}>
                                        Decline
                                    </button>
                                </footer>
                            </div>
                        </form>
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>
    )

}

export default reduxForm({
    form: 'accept-invite', // a unique identifier for this form
})(AcceptInviteComponent);

