import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotificationError, showNotificationSuccess } from "../../actions/notification";
import { getBillingInfo$, saveBillingInfo$ } from "../../api/subscriptions";
import LoaderComponent from "../../components/common/Loader";
import BillingInfo from "../../components/subscriptions/billing/BillingInfo";

const Billing = () => {
  const [billingInfo, setBillingInfo] = useState();
  const dispatch = useDispatch();

  const onChange = useCallback(
    (info) => {
      if(!info.country) {
        dispatch(showNotificationError("You must atleast select a country"));
        return;
      }
      saveBillingInfo$(info).subscribe(
        (result) => {
          dispatch(
            showNotificationSuccess("Your billing info has been updated")
          );
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getBillingInfo$().subscribe(
      (info) => {
        setBillingInfo(info);
      }
    );
  }, []);

  if(!billingInfo) {
    return <LoaderComponent />
  }

  return (
    <BillingInfo info={billingInfo} onChange={onChange} />
  );
};

export default Billing;
