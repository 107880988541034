import React from "react";
import DownloadContainer from "../containers/Download";

const DownloadPage = () => {
  return (
    <div className="login-wrapper downloadconnector">
      <div className="login align-self-center flex-fill">
        <DownloadContainer
          filePath={process.env.REACT_APP_DOWNLOAD_CONNECTOR_URL}
          fileName="FileServerConnector_Setup.exe"
        ></DownloadContainer>
      </div>
    </div>
  );
};

export default DownloadPage;
