import * as api from '../api'

import { switchMap, catchError, map, mergeMap } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { push } from 'connected-react-router'

import {
    USER_FOUND
} from '../actions/auth'

import {
    LIST_CREATE_USER_PRIVILEGES,
    USER_FETCH_NOTIFICATIONS,
    listCreateUserPrivilegesSuccess,
    listCreateUserPrivilegesFailed,
    userNotificationFetchedSuccess,
    USER_SIGNUP,
    signUpUserSuccess,
    USER_SIGNUP_SUCCESS,
    signUpUserError
} from '../actions/user'

import { handleError } from './common';

export const getCreateUserPrivilegesEpic = (action$, state) =>
    action$.pipe(
        ofType(LIST_CREATE_USER_PRIVILEGES),
        switchMap(action =>
            forkJoin(
                api.listAccountPrivileges$(),
                api.listOrganisationPrivileges$(),
                (accountPrivileges, organisationPrivileges) => ({
                    accountPrivileges, organisationPrivileges
                })).pipe(
                    map(result => listCreateUserPrivilegesSuccess(result.accountPrivileges, result.organisationPrivileges),
                        catchError(error => listCreateUserPrivilegesFailed(error))
                    )))
    )


export const getLoginNotificationsEpic = (action$, state) =>
    action$.pipe(
        ofType(USER_FOUND, USER_FETCH_NOTIFICATIONS),
        switchMap((action) => {
            return api.getNotifications$().pipe(
                map(apiResponse => {
                    return userNotificationFetchedSuccess(apiResponse.notifications)
                }),
                catchError(handleError(action$, action.type))
            )
        })
    )


export const signUpUserEpic$ = (action$, state) =>
    action$.pipe(
        ofType(USER_SIGNUP),
        switchMap((action) => {
            return api.signUpUser$(action.model).pipe(
                map(apiResponse => signUpUserSuccess(action.model)),
                catchError((err) => {
                    return of(signUpUserError(err.response ? err.response.errors : null))
                })
            )
        })
    )

export const signUpUserSuccessEpic$ = (action$, state) =>
    action$.pipe(
        ofType(USER_SIGNUP_SUCCESS),
        mergeMap(action => of(push("/signup/created", action.model)))
    )