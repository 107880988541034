import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { search$, searchInExplore$ } from "../../api";
import TabFilter from "../../components/common/tab/TabFilter";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import LoaderComponent from "../../components/common/Loader";
import { dateFormat } from "../../common/dateFormatter";
import { NavLink } from "react-router-dom";
import { volumeFormatter } from "../../common/volumeFormatter";
import { useModal } from "../../components/modal/Modal";
import { useViewPortContext } from "../../contexts/ViewPortContext";

const filters = [
  {
    id: 1,
    description: "Files run",
  },
  {
    id: 2,
    description: "Files and folder",
    type: "Explore page",
    privilege: "read_write",
  },
];

const ExploreNav = ({ result }) => {
  const { closeModal } = useModal();
  const { isMobile } = useViewPortContext();

  return result.shareId && result.shareId > 0 ? (
    <NavLink
      onClick={(e) => (isMobile ? closeModal() : null)}
      className="ml-1"
      to={`/explore/${result.id}/${result.type}/${result.shareId}`}
    >
      {result.name}
    </NavLink>
  ) : (
    <NavLink
      onClick={(e) => (isMobile ? closeModal() : null)}
      className="ml-1"
      to={`/explore/${result.id}/${result.type}`}
    >
      {result.name}
    </NavLink>
  );
};

const renderType = (result) => {
  switch (result.type) {
    case "Folder":
      return (
        <React.Fragment>
          <div className="row mt-2">
            <div className="col-md-12 ">
              <i className="table-icon icon icon-folder"></i>
              <ExploreNav result={result} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Last activity:{" "}
                <span className="badge badge-light">
                  {dateFormat(result.lastActivity)}
                </span>
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Indexed by:{" "}
                <span className="badge badge-light">{result.connector}</span>
              </small>
            </div>
          </div>
        </React.Fragment>
      );
    case "FTP root":
      return (
        <React.Fragment>
          <div className="row mt-2">
            <div className="col-md-12 ">
              <i className="table-icon icon icon-folder"></i>
              <ExploreNav result={result} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Last activity:{" "}
                <span className="badge badge-light">
                  {dateFormat(result.lastActivity)}
                </span>
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Indexed by:{" "}
                <span className="badge badge-light">{result.connector}</span>
              </small>
            </div>
          </div>
        </React.Fragment>
      );
    case "File":
      return (
        <React.Fragment>
          <div className="row mt-2">
            <div className="col-md-12 ">
              <i className="table-icon icon icon-file_1"></i>
              {
                result.isShared ? <NavLink to={`/explore/${result.parent}/Folder/${result.shareId}`}>
                  {result.name}
                </NavLink> :
                  <NavLink to={`/explore/${result.parent}/Folder`}>
                    {result.name}
                  </NavLink>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Size:{" "}
                <span className="badge badge-light">
                  {volumeFormatter(result.size)}
                </span>
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Last modified:{" "}
                <span className="badge badge-light">
                  {dateFormat(result.date)}
                </span>
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Indexed by:{" "}
                <span className="badge badge-light">{result.connector}</span>
              </small>
            </div>
          </div>
        </React.Fragment>
      );
    case "File Server":
      return (
        <React.Fragment>
          <div className="row mt-2">
            <div className="col-md-12 ">
              <i className="table-icon icon icon-fs_connector"></i>
              <ExploreNav result={result} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <small>
                Last activity:{" "}
                <span className="badge badge-light">
                  {dateFormat(result.lastActivity)}
                </span>
              </small>
            </div>
          </div>
        </React.Fragment>
      );
    case "FTP Client":
      return (
        <div className="icon-warning">
          <i className="table-icon ti-cloud"></i>
          <ExploreNav result={result} />
        </div>
      );
    default:
      return (
        <div className="icon-success">
          <i className="table-icon ti-panel"></i>
          <ExploreNav result={result} />
        </div>
      );
  }
};

const FileResult = ({ result }) => {
  return (
    <React.Fragment>
      {renderType(result)}
      <hr />
    </React.Fragment>
  );
};

const FileRunResult = ({ result }) => {
  const { closeModal } = useModal();
  const { isMobile } = useViewPortContext();
  return (
    <React.Fragment>
      <div className="row mt-2">
        <div className="col-md-12">
          <NavLink
            onClick={(e) => (isMobile ? closeModal() : null)}
            to={
              "/jobs/runs/" +
              result.sysJobId +
              "/events/" +
              result.sysEventId +
              "/files/" +
              result.sysCommandId +
              "/" +
              result.sysCommandSubId
            }
          >
            {result.file}
          </NavLink>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <small>
            Size:{" "}
            <span className="badge badge-light">
              {volumeFormatter(result.size)}
            </span>
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <small>
            Start:{" "}
            <span className="badge badge-light">
              {dateFormat(result.start)}
            </span>
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <small>
            Stop:{" "}
            <span className="badge badge-light">{dateFormat(result.stop)}</span>
          </small>
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case "reset":
      return { loading: false, result: null };
    case "loading":
      return { loading: true, result: null };
    case "set-result":
      return { loading: false, result: action.result };
    default:
      throw new Error();
  }
}

const SearchFileRun = ({ onNavigation }) => {
  const debounceSearchChanged = useMemo(() => {
    return new Subject();
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    result: null,
  });

  const doSearch = useCallback(
    async (text) => {
      if (!text) {
        dispatch({ type: "reset" });
        return;
      }

      dispatch({ type: "loading" });

      search$(text).subscribe((res) => {
        dispatch({ type: "set-result", result: res });
      });
    },
    []
  );

  useEffect(() => {
    debounceSearchChanged
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(doSearch);
    return () => {
      debounceSearchChanged.unsubscribe();
    };
  }, [debounceSearchChanged, doSearch]);

  return (
    <React.Fragment>
      <input
        className="form-control  mt-3"
        placeholder="Filename"
        type="text"
        onChange={(e) => {
          debounceSearchChanged.next(e.target.value);
        }}
      />
      <React.Fragment>
        {state.loading && <LoaderComponent title="Searching" startDelay={0} />}
        {!state.loading && state.result && (
          <React.Fragment>
            <div className="mt-2">
              Search result for File runs (
              <span className="search-result-no">
                {state.result.length > 99
                  ? "more than " + state.result.length
                  : state.result.length}{" "}
                results
              </span>
              ).
              {state.result.length > 99 && (
                <span>
                  {" "}
                  Try using filter in &nbsp;
                  <NavLink activeClassName="active" to="/jobs/runs">
                    runs view
                  </NavLink>
                  &nbsp; for a more spcified search.
                </span>
              )}
              <hr />
            </div>

            <div className="tab-content-wrapper-search">
              {state.result &&
                state.result.map((r, i) => (
                  <FileRunResult key={i} result={r} />
                ))}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

const SearchFiles = ({ onNavigation }) => {
  const debounceSearchChanged = useMemo(() => {
    return new Subject();
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    result: null,
  });

  const doSearch = useCallback(
    async (text) => {
      if (!text) {
        dispatch({ type: "reset" });
        return;
      }

      dispatch({ type: "loading" });

      searchInExplore$(text).subscribe((res) => {
        dispatch({ type: "set-result", result: res.items });
      });
    },
    []
  );

  useEffect(() => {
    debounceSearchChanged
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(doSearch);
    return () => {
      debounceSearchChanged.unsubscribe();
    };
  }, [debounceSearchChanged, doSearch]);

  return (
    <React.Fragment>
      <input
        className="form-control  mt-3"
        placeholder="Files & Folders"
        type="text"
        onChange={(e) => {
          debounceSearchChanged.next(e.target.value);
        }}
      />
      {state.loading && <LoaderComponent title="Searching" startDelay={0} />}
      {!state.loading && state.result && (
        <React.Fragment>
          <div className="mt-2">
            Search result for Files & Folders (
            <span className="search-result-no">
              {state.result.length} results
            </span>
            )
            <hr />
          </div>
          <div className="tab-content-wrapper-search">
            {state.result &&
              state.result.map((r, i) => <FileResult key={i} result={r} />)}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Search = ({ filter, onNavigation }) => {
  if (filter.id === 1) {
    return <SearchFileRun onNavigation={onNavigation} />;
  }

  return <SearchFiles onNavigation={onNavigation} />;
};

const SearchContainer = ({ onNavigation }) => {
  const [filter, setFilter] = useState(filters[0]);

  return (
    <div className="row">
      <div className="col-md-12">
        <TabFilter
          filters={filters}
          selectedFilter={filter.id}
          onChange={(id) => setFilter(filters.find((f) => f.id === id))}
        >
          <Search filter={filter} onNavigation={onNavigation} />
        </TabFilter>
      </div>
    </div>
  );
};

export default SearchContainer;
