

export const TOGGLE_ADMIN_MENU = 'TOGGLE_ADMIN_MENU'
export const TOGGLE_HEADER_CONTEX_MENU = 'TOGGLE_HEADER_CONTEX_MENU'
export const TOGGLE_NOTIFICATIONS_MENU = 'TOGGLE_NOTIFICATIONS_MENU'
export const TOGGLE_RIGHT_MENU = 'TOGGLE_RIGHT_MENU'
export const TOGGLE_LEFT_MENU = 'TOGGLE_LEFT_MENU'


export const toggleRightMenu = (context) => ({
    type: TOGGLE_RIGHT_MENU,
    context 
})

export const toggleAdminMenu = () => ({
    type: TOGGLE_ADMIN_MENU
})

export const toggleHeaderContextMenu = () => ({
    type: TOGGLE_HEADER_CONTEX_MENU
})

export const toggleNotificationsMenu = () => ({
    type: TOGGLE_NOTIFICATIONS_MENU
}) 

export const toggleLeftMenu = () => ({
    type: TOGGLE_LEFT_MENU
})