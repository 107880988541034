export const AUTOCOMPLETE_DO_SEARCH = "AUTOCOMPLETE_DO_SEARCH"
export const AUTOCOMPLETE_DO_SEARCH_SUCCESS = "AUTOCOMPLETE_DO_SEARCH_SUCCESS"
export const AUTOCOMPLETE_CLEAR = 'AUTOCOMPLETE_CLEAR'

export const autocompleteDoSearch = (key, searchFor, path) => ({
    type: AUTOCOMPLETE_DO_SEARCH,
    key,
    searchFor,
    path
})

export const autocompleteDoSearchSuccess = (key, searchFor, result) => ({
    type: AUTOCOMPLETE_DO_SEARCH_SUCCESS,
    key, 
    searchFor,
    result
})


export const clearAutoCompleteSearch = (key)  => ({
    type: AUTOCOMPLETE_CLEAR,
    key
})