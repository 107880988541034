import { connect } from 'react-redux'
import { getPrivilege } from '../selectors/Privilege'

const mapStateToProps = (state, ownProps) => ({
    privilege: getPrivilege(state, ownProps.type, ownProps.privilege)
})

const mapDispatchToProps = {
    
}

const Privilege = ({privilege, children}) => {
    return privilege ? children : null;
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false
})(Privilege)
