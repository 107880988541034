import React from 'react'
import Card, { Body } from '../common/Card'
import './Avatar.css'

const Avatar = ({ hash, email }) => {

    return (
        <Card className="change-avatar card-settings">
            <Body>
                <div className="row">
                    <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
                        <img className="avatar border-white" alt="Gravatar" src={`https://s.gravatar.com/avatar/${hash}?d=https%3A%2F%2Fapp.cloudbuz.com%2Favatar_default.png&?s=120`} />
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center">
                        <h2 className="cb-card-title">
                            Avatar
                        </h2>
                        <div className="alert highlight" role="alert"><span>We are using the Gravatar associated with your primary email address <strong>{email}</strong></span></div>
                        <p className="pt-3">
                            <a href="https://gravatar.com" rel="noopener noreferrer" target="_blank">Change your avatar att Gravatar.com</a>
                        </p>
                    </div>
                </div>
            </Body>
        </Card>
    )
}

export default Avatar
