import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card, { Body, Header } from "../../common/Card";
import Slider from "../../common/slider/Slider";

const Subscription = ({ subscription, selected, onSelect }) => {
  return (
    <React.Fragment>
      <div className="card-header">
        <h5>{subscription.description}</h5>
      </div>
      <div className="card-body">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: subscription?.includedHtml }}
        ></div>
      </div>
      <div className="card-footer">
        <small className="text-muted">
          {subscription.selectable ? (
            !selected ? (
              <button
                href="#"
                className="btn btn-link"
                onClick={(e) => onSelect(subscription)}
              >
                Select plan
              </button>
            ) : (
              <button href="#" className="btn btn-link disabled">
                Your selection
              </button>
            )
          ) : (
            <button href="#" className="btn btn-link disabled">
              Contact support
            </button>
          )}
        </small>
      </div>
    </React.Fragment>
  );
};

const ChangePlanInfo = ({ subscriptionId, subscriptions }) => {
  const [nodeSubscriptions, setNodeSubscriptions] = useState();
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [currentSubscription, setCurrentSubscription] = useState();

  const selectPlan = useCallback(
    (plan) => {
      setSelectedSubscription(
        subscriptions?.filter((s) => s.id === plan.id)[0]
      );
    },
    [subscriptions]
  );

  useEffect(() => {
    const sub = subscriptions?.filter(
      (s) => s.id === parseInt(subscriptionId)
    )[0];
    setSelectedSubscription(sub);
    setCurrentSubscription(sub);
  }, [subscriptionId, subscriptions]);

  useEffect(() => {
    if (!selectedSubscription) {
      return;
    }

    setNodeSubscriptions(
      subscriptions
        ?.filter((s) => s.groupId === selectedSubscription.groupId)
        .map((s) => ({ ...s, selected: s.id === selectedSubscription.id }))
    );
  }, [selectedSubscription, selectedSubscription?.id, subscriptions]);

  return (
    <Card>
      <Header title="Change plan"></Header>
      <Body>
        <div className="card-deck">
          {subscriptions
            ?.filter((s) =>
              s.groupId === selectedSubscription?.groupId
                ? s.id === selectedSubscription?.id
                : s.id % 10 === 0
            )
            .map((s) => (
              <div className="card bg-light mb-3" key={s.id}>
                <Subscription
                  onSelect={selectPlan}
                  subscription={s}
                  selected={s.groupId === selectedSubscription?.groupId}
                ></Subscription>
              </div>
            ))}
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <h5 className="mb-4">Choose CVU limit / month</h5>
            <Slider
              onSelect={selectPlan}
              nodes={nodeSubscriptions?.map((ns) => ({
                ...ns,
                value: ns.includedCVU,
              }))}
            />
          </div>
          <div className="col-md-12">
            <h6 className="align-top">
              Total: {currentSubscription?.cost} EUR (Billed monthly)
              &nbsp;&nbsp;<span style={{ fontSize: "2rem" }}>&#8594;</span>
              &nbsp;&nbsp;
              {selectedSubscription?.cost} EUR (Billed monthly)
            </h6>
            {selectedSubscription?.cost > 0 && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="pay"
                  id="monthly"
                  checked="checked"
                  readOnly={true}
                />
                <label className="form-check-label" htmlFor="monthly">
                  {`Pay Monthly ${selectedSubscription?.cost} EUR (VAT excluded)`}
                </label>
              </div>
            )}
          </div>
          <div className="col-md-12">
            {parseInt(subscriptionId) !== selectedSubscription?.id && (
              <Link
                className="mt-5 mb-3 mr-3 btn btn-success btn-lg"
                to={`/admin/subscription/your-plan/compare-change-plan/${selectedSubscription?.id}`}
              >
                Review changes
              </Link>
            )}
            <Link
              to="/admin/subscription/your-plan"
              className="mt-5 mb-3 btn btn-lg btn-light"
            >
              Cancel
            </Link>
          </div>
          <div className="col-md-12">
            <hr></hr>
            <h6 className="mt-5">What payment methods do you accept?</h6>
            <span>
              We're able to accept Visa, Mastercard, American Express, JCB,
              Discover, and Diners Club credit cards from any country
            </span>

            <h6 className="mt-5">Can I pay by invoice?</h6>
            <span>
              Invoice payment is available for monthly billing on Enterprise
              plans. For more information, please contact our Customer Success
              Team at customercare@cloudbuz.com
            </span>
          </div>
        </div>
      </Body>
    </Card>
  );
};

export default ChangePlanInfo;
