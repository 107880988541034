import React from 'react'
import ExtendNetworkConnectByTokenContainer from '../containers/ExtendNetworkConnectByToken'

const ExtendNetworkConnectByTokenPage = ({ match }) => {
    return <ExtendNetworkConnectByTokenContainer />
}

export default ExtendNetworkConnectByTokenPage


