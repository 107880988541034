import React from 'react'
import UserListContainer from '../containers/UserList'
import UserInviteContainer from '../containers/UserInvite'
import UserListPending from '../containers/UserListPending';

const UsersPage = ({ match }) => {
    return (
        <React.Fragment>
            <UserListContainer match={match} title="Users" /> 
            <UserListPending match={match} title="Pending invitations" />
            <UserInviteContainer match={match} />
        </React.Fragment>
    )
}

export default UsersPage