import React, { useEffect, useState } from 'react'
import './Loader.css';

const LoaderComponent = ({ title, startDelay = 250 }) => {

    const [longRunningRequest, setLongRunningRequest] = useState(false)
    const [shortRunningRequest, setShortRunningReqeust] = useState(startDelay === 0);

    useEffect(() => {

        const longRunningTimeout = setTimeout(() => {
            setLongRunningRequest(true)
        }, 1500)

        const shortRunningTimeout = setTimeout(() => {
            setShortRunningReqeust(true);
        }, startDelay);

        return () => {
            clearTimeout(longRunningTimeout);
            clearTimeout(shortRunningTimeout);
        };
    })

    const showLongRunningRequest = longRunningRequest ?
        <h5 className="loading-dots text-center">
            { title || "Still working"}
        </h5>
        : "";

    return (
        shortRunningRequest ?
        <div className="loader">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            {showLongRunningRequest}
        </div> : ""
    )
}


export default LoaderComponent