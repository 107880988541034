import React, { useContext, useState } from "react";
import "./DropDownButton.css";

const DropdownContext = React.createContext();

const DropDownButton = ({
  title,
  children,
  className = "",
  icon = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  className = className + " btn btn-link dropdown-toggle p-0";

  return (
    <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
      <div className="dropdown">
        <button
          data-testid="dropdown-button"
          className={className}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className={"icon " + icon}></i>
          {title}
        </button>

        {isOpen && (
          <div>
            <div
              className="dropdown-menu-overlay"
              data-testid="dropdown-menu-overlay"
              onClick={(e) => {
                setIsOpen(false);
              }}
            ></div>
            <div
              className="dropdown-menu show"
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    </DropdownContext.Provider>
  );
};

export const DropDownButtonClose = () => {
  const { setIsOpen } = useContext(DropdownContext);
  return (
    <span data-testid="dropdown-close" className="dropdown-close" title="Close" onClick={(e) => setIsOpen(false)}>
      <i className="icon icon-cross"></i>
    </span>
  );
};

export default DropDownButton;
