import React from 'react'
import './PageContent.css'
const PageContent = ({ className, children }) => {
    return (
        <div className={(className || "") + " page-content"}>
            {children}
        </div>);
}

export const ContentHeader = ({ title, children }) => {
    return <div className="header">
        <h1 className="content-title no-print">{title}</h1>
        {children ? 
                    <div className="mt-4">
                        {children}
                    </div>
                :""
        }
    </div>
}

export const ContentBody = ({ children }) => {
    return <div className="content-body">
        {children}
    </div>
}


export default PageContent;