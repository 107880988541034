import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { cloudbuz } from "./reducers";
import { rootEpic } from "./epics";
import Root from "./root/root";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { USER_LOGGEDOUT } from "./actions/auth";

const epicMiddleware = createEpicMiddleware();

const history = createBrowserHistory();

const configureStore = (preloadedState) => {
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const appReducer = combineReducers({
    ...cloudbuz,
    router: connectRouter(history),
  });

  const rootReducer = (state, action) => {
    if (action.type === USER_LOGGEDOUT) {
      state = undefined
    }

    return appReducer(state, action);
  };

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancer(
      applyMiddleware(epicMiddleware),
      applyMiddleware(routerMiddleware(history)),
      applyMiddleware(thunk)
    )
  );

  epicMiddleware.run(rootEpic);

  return store;
};

const store = configureStore();

render(
  <Root store={store} history={history} />,
  document.getElementById("root")
);
