import React from 'react'
import PropTypes from 'prop-types'
import './Notification.css'  

const Error = ({ notification, onClose }) => {
    return (
        <div data-notify="container" className="notification" role="alert" data-notify-position="top-right" >
            <div className="notification-header bg-danger text-white">
                <i className="icon icon-warn2 text-white"></i>
                <strong className="mr-auto">Warning!</strong>
                <button data-notify="dismiss" type="button" className="ml-2 mb-1 close text-white" aria-label="Close" onClick={() => onClose()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="notification-body message" data-notify="message">
                {notification}
            </div>
        </div> 
        
    )
}

const Warning = ({ notification , onClose}) => {
    return (
        <div data-notify="container" className="notification" role="alert" data-notify-position="top-right" >
            <div className="notification-header bg-warning text-white">
                <i className="icon icon-warn2 text-white"></i>
                <strong className="mr-auto"> Warning!</strong>
                <button data-notify="dismiss" type="button" className="ml-2 mb-1 close text-dark" aria-label="Close" onClick={() => onClose()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="notification-body message" data-notify="message">
                {notification}
            </div>
        </div> 
        
    )
}
    
const Success = ({ notification, onClose}) => {
    return (
        <div data-notify="container" className="notification" role="alert" data-notify-position="top-right" >
            <div className="notification-header bg-success text-white">
                <i className="icon icon-info-circled"></i>
                <strong className="mr-auto">Message</strong>
                <button data-notify="dismiss" type="button" className="ml-2 mb-1 close text-white" aria-label="Close" onClick={() => onClose()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="notification-body message" data-notify="message">
                {notification}
            </div>
        </div>
        
    )
}

const NotificationType = (notification, type, onClose) => {
    switch (type) {
        case "none":
            return null
        case "error":
            return <Error notification={notification} onClose={onClose}/>
        case "warning":
            return <Warning notification={notification} onClose={onClose}/>
        case "success":
            return <Success notification={notification} onClose={onClose}/>
        default:
            return <Success notification={notification} onClose={onClose}/>
    }
}

const Notification = ({ notification, type, onClose }) => NotificationType(notification, type, onClose)

Notification.propTypes = {
    type: PropTypes.oneOf(["success", "error", "warning", "none"]),
    notification: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    onClose: PropTypes.func
}

export default Notification;

