import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux';
import { showNotificationError, showNotificationSuccess } from '../actions/notification';
import { changeTwofactor$, showTwofactor$ } from '../api/auth'
import EditTwoFactorComponent from '../components/Twofactor/EditTwofactor'

const AuthTwofactor = () => {

    const dispatch = useDispatch();
    const [twofactor, setTwofactor] = useState(null);


    const onShowTwofactor = useCallback((input) => {
        showTwofactor$(input.password).subscribe(result => {
            setTwofactor({ ...result.response, ...input });
        }, error => {
            dispatch(
                showNotificationError("Failed to show two-factor, please validate your password")
            );
        });
    }, [dispatch])

    const onSaveTwoFactor = useCallback(model => {
        changeTwofactor$({
            enabled: model.enabled,
            twofactorCode: model.twofactorCode,
            twofactorKey: twofactor.twofactorKey,
            password: twofactor.password
        }).subscribe(result => {
            dispatch(
                showNotificationSuccess("Your two-factor has been updated")
            );
            setTwofactor(null);
        }, error => {
            dispatch(
                showNotificationError("Failed to save two-factor, please validate your password and your authenticator code")
            );
        })
    }, [dispatch, twofactor]);

    return <EditTwoFactorComponent
        twofactor={twofactor}
        showTwofactor={onShowTwofactor}
        saveTwofactor={onSaveTwoFactor} />
}

export default AuthTwofactor;