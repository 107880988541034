import React from 'react'
import { Field } from 'redux-form'
import moment from 'moment'

export const renderField = ({ input, label, placeholder, type, className, meta: { touched, error }, disabled }) => {

  className = className + " " + ((touched && error && !disabled) ? 'has-error' : '')
  className += ' form-group'

  if (label && !placeholder)
    placeholder = label;

  return (<div className={className}>
    {label && <label >{label}</label>}
    {
      disabled ? <input {...input} placeholder={placeholder} type={type} className="form-control form-control-lg border-input" disabled /> :
        <input {...input} placeholder={placeholder} type={type} className="form-control form-control-lg border-input" />
    }
    {touched && error && !disabled && <span className="help-block text-danger">{error}</span>}

  </div>)
}


export const renderTextArea = ({ input, label, placeholder, type, className, meta: { touched, error }, disabled }) => {

  className = className + " " + ((touched && error && !disabled) ? 'has-error' : '')
  className += ' form-group'

  return (<div className={className}>
    <label>{label}</label>

    {
      disabled ? <textarea {...input} placeholder={placeholder} type={type} className="form-control form-control-lg border-input" disabled></textarea> :
        <textarea {...input} placeholder={placeholder} type={type} className="form-control form-control-lg border-input"></textarea>
    }
    {touched && error && !disabled && <span className="help-block text-danger">{error}</span>}

  </div>)
}

export const renderDateBox = ({ input, label, type, meta: { touched, error }, disabled }) => {

  if (input.value) {
    var date = moment(new Date(input.value), 'yyyy-mm-dd').format();
    input.value = date.substr(0, 10);
  }

  return (<div className="form-group">
    <label>{label}</label>
    <div>
      {
        disabled ? <input {...input} placeholder={label} type={type} className="form-control form-control-lg border-input" disabled /> :
          <input {...input} placeholder={label} type="date" className="form-control form-control-lg border-input" />
      }
      {touched && error && !disabled && <span>{error}</span>}
    </div>
  </div>)
}


export const renderTextBox = ({ input, label, type, meta: { touched, error }, disabled, defaultValue }) => {

  return (<div>
    <label>{label}</label>
    <div>
      {
        disabled ? <input {...input} placeholder={label} type={type} className="form-control form-control-lg border-input" disabled value={defaultValue} /> :
          <input {...input} placeholder={label} type="text" className="form-control form-control-lg border-input" value={defaultValue} />
      }
      {touched && error && !disabled && <span>{error}</span>}
    </div>
  </div>)
}

export const renderDropDown = ({
  input,
  label,
  meta: { touched, error },
  options,
  emptyOption,
  emptyOptionLabel,
  disabled,
  noSpace
}) => {
  var className = (touched && error) ? 'has-error' : ''
  className += 'form-group'

  return (<div className={className}>

    {
      label && !noSpace &&
      <label>{label || (<span>&nbsp;</span>)}</label>
    }
    <div>

      {
        disabled ? <select disabled {...input} className="form-control form-control-lg border-input">
          {emptyOption && <option value="">{emptyOptionLabel || "Select..."}</option>}
          {options && options.map((o, index) => <option key={index} value={o.id}>{o.name}</option>)}
        </select> :
          <select {...input} className="form-control form-control-lg border-input">
            {emptyOption && <option value="">{emptyOptionLabel || "Select..."}</option>}
            {options && options.map((o, index) => <option key={index} value={o.id}>{o.name}</option>)}
          </select>
      }

      {touched && error && <span className="help-block">{error}</span>}
    </div>
  </div>
  )
}



export const renderCheckbox = ({ input, label, disabled, className }) => {
  /* 
  <label className="check">
    {
      disabled ? <Field {...input} component="input" type="checkbox" disabled /> :
      <Field {...input} component="input" type="checkbox" />
    }
    <span className="checkmark"></span> {label} 
  </label>*/
  
  let randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  return(
  <div className="custom-control custom-checkbox mb-2">
     {
      disabled ? 
        <Field {...input} component="input"
        id={randomId}
        type="checkbox"
        className="custom-control-input" 
        disabled /> :
      <Field {...input} component="input" 
      id={randomId}
      type="checkbox"
      className="custom-control-input"  />
    }
    <label className="custom-control-label" htmlFor={randomId}>{label}</label>
  </div>
  )
}

export const renderCheckboxWithChecked = ({ input, label, disabled, className, checked }) => {
  let randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  return(
  <div className="custom-control custom-checkbox mb-2">
     {
      disabled ? 
        <Field {...input} component="input"
        id={randomId}
        type="checkbox"
        className="custom-control-input" 
        checked={checked}
        disabled /> :
      <Field {...input} component="input" 
      id={randomId}
      type="checkbox"
      className="custom-control-input"
      checked={checked}  />
    }
    <label className="custom-control-label" htmlFor={randomId}>{label}</label>
  </div>
  )
}

export const renderRadioButtons = ({ input, options, meta: { touched, error }, onChange, defaultValue }) => {

  return <div>
    {options && options.map(o => <div key={o.id} >
      <label className="radio">
        {input.value === defaultValue ? <Field {...input} component="input" type="radio" value={o.id.toString()} className="" defaultChecked /> :
          <Field {...input} component="input" type="radio" value={o.id.toString()} className="" />}
        {' '}{o.name}
        <span className="checkround"></span>
      </label>
    </div>)}
  </div>
}