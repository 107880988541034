import React, { useReducer } from 'react'
import Card, { Body, Header } from '../common/Card';

const JobNotificationSettings = ({ jobSettings, onSave }) => {


    const [settings, dispatch] = useReducer((settings, { type, jobNotificationId, jobNotificationJobId, jobNotificationTypeId, withContent }) => {
        const item = settings.find(s => s.jobNotificationId === jobNotificationId);
        return Object.assign([], settings, {
            [settings.indexOf(item)]: { jobName: item.jobName, jobNotificationId, jobNotificationJobId, jobNotificationTypeId, withContent }
        });

    }, jobSettings.settings);

    const [jobs, dispatchJob] = useReducer((jobs, { type, jobNotificationJobId, jobNotificationTypeId, withContent }) => {
        const item = jobs.find(s => s.jobNotificationJobId === jobNotificationJobId);
        return Object.assign([], jobs, {
            [jobs.indexOf(item)]: { jobName: item.jobName, jobNotificationJobId, jobNotificationTypeId, withContent }
        });
    }, jobSettings.jobs);

    return (

        <Card className="card-settings">
            <Header title="Job notification subscription settings">
                <div className="alert highlight" role="alert"><span>Select which jobs you want to subscribe to. If you can´t find a job you expected, please contact your account administrator.</span></div>
            </Header>
            <Body>
                <div className="row">
                    {
                    settings.map(s => (
                        <div className="col-12 col-sm-6 col-md-4 pb-3" key={s.jobNotificationId}>
                            <div className={s.jobNotificationTypeId>0 ? "card h-100 bg-selected" : "card h-100 bg-light"}>
                                <div className="card-body">
                                    <h6 className="card-title">{s.jobName}</h6>
                                    <select className="form-control border-input shrink mb-2"
                                        value={s.jobNotificationTypeId}
                                        onChange={(event) => {
                                            dispatch({
                                                type: "change",
                                                jobNotificationId: s.jobNotificationId,
                                                jobNotificationJobId: s.jobNotificationJobId,
                                                jobNotificationTypeId: event.target.value,
                                                withContent: s.withContent
                                            })
                                        }} >
                                        {
                                            jobSettings.types.map(t => (
                                                <option key={t.jobNotificationTypeId}
                                                    value={t.jobNotificationTypeId}>
                                                    {t.description}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <div className="custom-control custom-checkbox">
                                        <input  
                                            id={s.jobNotificationJobId + "_jobNot"}
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            defaultChecked={s.withContent}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "change",
                                                    jobNotificationId: s.jobNotificationId,
                                                    jobNotificationJobId: s.jobNotificationJobId,
                                                    jobNotificationTypeId: s.jobNotificationTypeId,
                                                    withContent: event.target.checked
                                                })
                                            }}/>
                                        <label className="custom-control-label" htmlFor={s.jobNotificationJobId + "_jobNot"}>Skip if empty</label>
                                    </div>

                                    {/* 

                                    <label className="check">
                                        <input 
                                            id="catwantsPromotional"
                                            type="checkbox" defaultChecked={s.withContent} onChange={(event) => {
                                            dispatch({
                                                type: "change",
                                                jobNotificationId: s.jobNotificationId,
                                                jobNotificationJobId: s.jobNotificationJobId,
                                                jobNotificationTypeId: s.jobNotificationTypeId,
                                                withContent: event.target.checked
                                            })
                                        }} />
                                        Skip if empty
                                        <span className="checkmark"></span>
                                    </label>
                                    */}
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <div className="float-left">Job id: {s.jobNotificationJobId}</div>
                                        <div className="float-right">
                                            {s.jobNotificationTypeId>0 ? <i className="icon icon-eye text-primary"> Subcsription active</i> : <i className="icon icon-eye-off"> Subcsription inactive</i>}
                                        </div>
                                    </small>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>

                {jobs.length>0 &&
                    <div className="row  pt-3 pb-3">
                        <div className="col-md-12 border-bottom">
                            <h5>
                                More jobs you can subscribe to
                            </h5>
                        </div>
                    </div>
                }
                <div className="row">
                    {
                    jobs.map(s => (
                        <div className="col-12 col-sm-6 col-md-4 pb-3" key={s.jobNotificationJobId}>
                            <div className={s.jobNotificationTypeId>0 ? "card h-100 bg-selected" : "card h-100 bg-light"}>
                                <div className="card-body">
                                    <h6 className="card-title">{s.jobName}</h6>
                                    <select className="form-control border-input shrink mb-2" value={s.jobNotificationTypeId} 
                                        onChange={(event) => {
                                            dispatchJob({
                                                type: "change",
                                                jobNotificationJobId: s.jobNotificationJobId,
                                                jobNotificationTypeId: event.target.value,
                                                withContent: s.withContent
                                            })
                                        }}>
                                        {
                                            jobSettings.types.map(t => (
                                                <option key={t.jobNotificationTypeId}
                                                    value={t.jobNotificationTypeId}>
                                                    {t.description}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <div className="custom-control custom-checkbox">
                                        <input  
                                            id={s.jobNotificationJobId + "_jobNot"}
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            value={s.withContent}
                                            onChange={(event) => {
                                                dispatchJob({
                                                    type: "change",
                                                    jobNotificationJobId: s.jobNotificationJobId,
                                                    jobNotificationTypeId: s.jobNotificationTypeId,
                                                    withContent: event.target.checked
                                                })
                                            }}/>
                                        <label className="custom-control-label" htmlFor={s.jobNotificationJobId + "_jobNot"}>Skip if empty</label>
                                    </div>
                                    {/* 
                                    <label className="check">
                                        <input type="checkbox" value={s.withContent} onChange={(event) => {
                                            dispatchJob({
                                                type: "change",
                                                jobNotificationJobId: s.jobNotificationJobId,
                                                jobNotificationTypeId: s.jobNotificationTypeId,
                                                withContent: event.target.checked
                                            })
                                        }} />
                                        Skip if empty
                                        <span className="checkmark"></span>
                                    </label>
                                    */}
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <div className="float-left">Job id: {s.jobNotificationJobId}</div>
                                        <div className="float-right">
                                            {s.jobNotificationTypeId>0 ? <i className="icon icon-eye text-primary"> Subcsription active</i> : <i className="icon icon-eye-off"> Subcsription inactive</i>}
                                        </div>
                                    </small>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            
                <button className="btn btn-dark btn-lg" onClick={() =>{
                    onSave([ ...settings, ...jobs.filter(j => j.jobNotificationTypeId > 0)])
                }}>Save</button>
            </Body>
        </Card>
    )
}

export default JobNotificationSettings;