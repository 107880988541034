import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getOrganisation, saveOrganisation } from '../actions/organisation'
import OrganisationEdit from '../components/organisations/OrganisationEdit'
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state, ownProps) => {
    return {
        values: state.organisation.organisation,
        isFetching: isFetching(state.fetching, saveOrganisation)
    };
};

const mapDispatchToProps = (dispatch) => ({
    initialState: (organisationId) => dispatch(getOrganisation(organisationId)),
    saveOrganisation: (accountId, organisationId) => (values) => dispatch(saveOrganisation(accountId, organisationId, values))
})

const OrganisationEditContainer = ({ initialState, values, isFetching, match,  saveOrganisation}) => {

    useEffect(() => {
        initialState(match.params.organisationId);
    }, [initialState, match.params.organisationId])

    const organisationId = match.params.organisationId
    const accountId = match.params.accountId;

    return <OrganisationEdit isFetching={isFetching} initialValues={values} onSubmit={saveOrganisation(accountId, organisationId)}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationEditContainer)
