import React from 'react'
import { connect } from 'react-redux'
//import { lifecycle } from 'recompose'

import Loader from '../components/common/Loader'
import CreateInvitation from '../components/invites/CreateAccountRelationInvitation'


import {
    createAccountRelationInvite
} from '../actions/invite'
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state) => {

    return {
        isFetching: isFetching(state.fetching, createAccountRelationInvite),
        ...state.invite.createInvite
    }
}

const mapDispatchToPros = (dispatch) => {
    return {
        createInvitation: (values) => {
            return dispatch(createAccountRelationInvite(values.email, values.message))
        }
    }
}

const ExtendNetworkCreateInviteContainer = ({
    isFetching,
    createInvitation,
    guestEmail
}) => {
    return isFetching ?
        <Loader /> :
            <CreateInvitation 
                onSubmit={createInvitation}
                guestEmail={guestEmail}/>

}

export default connect(mapStateToProps, mapDispatchToPros)(ExtendNetworkCreateInviteContainer)