import React from "react";
import PageContent, {
  ContentHeader,
  ContentBody,
} from "../components/common/PageContent";
import SchedulesContainer from "../containers/schedules/Schedules";

const SchedulesPage = () => (
  <PageContent className="border-0">
    <ContentHeader title="Jobs / Schedules" />
    <ContentBody>
      <SchedulesContainer />
    </ContentBody>
  </PageContent>
);

export default SchedulesPage;
