import React, { useState } from 'react'

const ExploreSearch = ({ onSearchChange, searchText, onClear }) => {
    const [text, setText] = useState(searchText);
    return (

            <div className="input-group">
                <input className="form-control form-control-lg" 
                    value={text} onChange={
                        (e) => {
                            setText(e.target.value);
                            onSearchChange(e.target.value)
                        }} placeholder="Search" />
                <div className="input-group-append">
                    <span className="input-group-text pointer" onClick={e => { setText(""); onClear(); }}>clear</span>
                </div>
            </div>
    )
}

export default ExploreSearch