import {
    LOAD_EXPLORE_VIEW,
    LOAD_EXPLORE_VIEW_SUCCESS,
    NAVIGATE_EXPLORE_VIEW,
    SEARCH_IN_EXPLORE_SUCCESS,
    SEARCH_IN_EXPLORE,
    GET_SHARE_FOLDER,
    GET_SHARE_FOLDER_SUCCES,
    SHARE_FOLDER_SUCCES,
    EXPLORE_VIEW_CHANGED,
    EXPLORE_ACTION_SELECTED,
    RESET_EXPLORE_VIEW,
    EXECUTE_EXPLORE_COMMAND_SUCCESS,
    LOAD_EXPLORE_VIEW_FAILED,
    EXPLORE_VIEW_CHANGE_PAGE
} from '../actions/explore';

export const explore = (state = { currentPage: 1 }, action) => {
    switch (action.type) {
        case RESET_EXPLORE_VIEW:
            return {
                ...state,
                currentPage: 1,
                sortBy: [],
                id: null,
                shareId: null,
                type: null,
                isSearching: false,
                searchText: ''
            }
        case LOAD_EXPLORE_VIEW_FAILED:
            return {
                ...state,
                currentPage: 1,
                sortBy: [],
                id: null,
                shareId: null,
                type: null,
                isSearching: false,
                view: null,
                searchText: ''
            }
        case LOAD_EXPLORE_VIEW:
            return {
                ...state,
                currentPage: action.currentPage,
                sortBy: action.sortBy,
                id: action.id,
                shareId: action.shareId,
                type: action.exploreType,
                filters: action.filters,
                searchText: '',
                isSearching: false
            }
        case EXPLORE_VIEW_CHANGE_PAGE:
            return {
                ...state,
                currentPage: action.currentPage,
                sortBy: action.sortBy,
                id: action.id,
                shareId: action.shareId,
                type: action.exploreType,
                filters: action.filters,
                searchText: '',
                isSearching: false
            }
        case LOAD_EXPLORE_VIEW_SUCCESS:
            return {
                ...state,
                view: action.view,
            }
        case SEARCH_IN_EXPLORE:
            return {
                ...state,
                searchText: action.text,
                isSearching: true
            }
        case SEARCH_IN_EXPLORE_SUCCESS:
            return {
                ...state,
                currentPage: 1,
                view: action.view
            }
        case NAVIGATE_EXPLORE_VIEW:
            return {
                ...state,
                currentPage: null
            }
        case EXPLORE_VIEW_CHANGED:
            return {
                ...state,
                view: { ...state.view, items: Object.assign([], state.view.items, { [state.view.items.indexOf(action.oldItem)]: action.newItem }) },
                actionItems: null
            }
        case EXPLORE_ACTION_SELECTED:
            return {
                ...state,
                view: { ...state.view, items: state.view?.items?.map(i => ({ ...i, isSelected: false })) },
                command: action.command
            }
        case EXECUTE_EXPLORE_COMMAND_SUCCESS:
            return {
                ...state,
                command: null
            }
        case GET_SHARE_FOLDER:
            return {
                ...state,
                share: null
            }
        case GET_SHARE_FOLDER_SUCCES:
        case SHARE_FOLDER_SUCCES:
            return {
                ...state,
                share: action.share
            }
        default:
            return state
    }
}