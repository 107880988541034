
import {
    LIST_ACCOUNT_USERS_SUCCESS,
    LIST_ORGANISATION_USERS_SUCCESS,
    GET_USER_SUCCESS,
    USER_NOTIFICATION_FETCHED_SUCCESS,
    GET_USER,
    LIST_PENDING_USERS_SUCCESS
} from '../actions/user'

export const user = (state = { user: null, notifications: [], users: [], pending: [] }, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: null
            }
        case LIST_ACCOUNT_USERS_SUCCESS:
        case LIST_ORGANISATION_USERS_SUCCESS:
            return {
                ...state,
                users: action.users || []
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.user
            }
        case USER_NOTIFICATION_FETCHED_SUCCESS:
            return {
                ...state,
                notifications: action.notifications
            }
        case LIST_PENDING_USERS_SUCCESS:
            return {
                ...state,
                pending: action.users
            }
        default: return state
    }
};