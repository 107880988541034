import React from 'react'

import RadioButtons from '../common/RadioButtons'
import Card, { Header, Body } from '../common/Card';

const JobNotificationsComponent = ({
    jobNotifications,
    changeEvent,
    emailNotificationType,
    emailNotificationSpec,
    onSave
}) => {

    return (
        <Card className="card-settings">
            <Header title="Job notification summations">
                <div className="alert highlight" role="alert"><span>How do you prefer your job notifications to be summarized? Errors and warnings will always be sent as they occur if job notification subscription is active.</span></div>
            </Header>
            <Body>
                <div className="mb-4">
                    <RadioButtons
                        changeEvent={(ev) => changeEvent(ev.target.value, "emailNotificationType")}
                        options={jobNotifications.filter(jobNotifications => parseInt(jobNotifications.id,10) !== 1)}
                        selectedValue={emailNotificationType} />

                    {
                        (parseInt(emailNotificationType, 10) === 2 || parseInt(emailNotificationType, 10) === 1)  &&
                        <div className="form-group">
                            <label>Time span (send summarized notifications every hh:mm)</label>
                            <input type="time" className="form-control form-control-lg border-input" value={emailNotificationSpec} onChange={(ev) => changeEvent(ev.target.value, "emailNotificationSpec")} />
                        </div>
                    }
                </div>
                <button onClick={() => onSave()} className="btn btn-dark btn-lg">Save</button>
            </Body>
        </Card>
    )
}

export default JobNotificationsComponent;