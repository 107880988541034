import React, { useEffect, useMemo } from "react";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import Card, { Body, Header } from "../common/Card";
import TablePaginator from "../common/table/Paginator";
import Table from "../common/table/Table";

const Filter = ({ onChange }) => {
  const debounceTextChanged = useMemo(() => new Subject(), []);
  useEffect(() => {
    debounceTextChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(onChange);

    return () => {
      debounceTextChanged.unsubscribe();
    };
  }, [debounceTextChanged, onChange]);

  return (
    <div className="input-group">
      <input
        className="form-control form-control-lg"
        type="text"
        placeholder="Filter this list, type something.."
        onChange={(e) => {
          debounceTextChanged.next(e.target.value);
        }}
      />
    </div>
  );
};

const EndpointsComponent = ({
  view,
  schema,
  onEndpointSelect,
  onFilterChange,
  onPageChange,
  page,
}) => {
  return (
    <Card>
      <Header title="Endpoints in my network">
        <div className="alert highlight" role="alert">
          An endpoint is a network path used as source or target in one or more job configurations. If an endpoint is offline, jobs using this endpoint will fail.
        </div>
      </Header>
      <Body>
        <div className="table-responsive table-full-width">
          <Filter onChange={onFilterChange} />
          <Table
            data={view.endpoints}
            schema={schema}
            onRowClick={onEndpointSelect}
            infoIfEmpty="No endpoints found.."
          />
          <TablePaginator
            currentPage={page}
            totalPages={view.totalPages}
            onPageChange={onPageChange}
            pageNeighbours={3}
          />
        </div>
      </Body>
    </Card>
  );
};

export default EndpointsComponent;
