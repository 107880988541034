import React from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { IconButton } from '../../common/Buttons';
import { renderDropDown, renderField, renderCheckbox } from '../../common/ReduxFormHelper'

import {
    JobNotificationTypes,
} from '../../../selectors/Job'

const CreateEmailNotificationForm = ({ handleSubmit }) => {

    return (
        <div className="row table-item">
            <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                    <div className="row table-row-item share-not-table">
                        <div className="col-sm-12 d-md-none">
                            <h6>Add new e-mail recipient</h6>
                        </div>
                        <div className="col-sm-3 d-md-none pb-0 pt-0">
                            E-mail
                        </div>
                        <div className="col-sm-9 col-md-6">
                            <Field  component={renderField} 
                                    type="email" 
                                    name="email"
                                    placeholder="Add email"
                                    noSpace={true}  />
                        </div>
                        <div className="col-sm-3 d-md-none pb-0 pt-0">
                            Notifications
                        </div>
                        <div className="col-sm-9 col-md-3">
                            <Field component={renderDropDown} 
                                name="type" options={JobNotificationTypes}  
                                emptyOption={true} noSpace={true} />
                        </div>
                        <div className="col-sm-3 d-md-none pb-0 pt-0">
                            If job runs empty
                        </div>                        
                        <div className="col-sm-9 col-md-2">
                            <Field component={renderCheckbox}
                                name="withContentOnly"
                                label="Skip notification"
                                className="notification-checkbox"
                                noSpace={true} />
                        </div>
                        <div className="col-12 col-md-1 text-right">
                            <IconButton icon="icon-plus" type="success" submit={true} tooltip="Add" tooltipplacement="top"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default reduxForm({
    form: 'create-email-notification-job-wizzard',
    validate: (values) => {
        const errors = {}

        if (!values.email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }

        if (!values.notificationType) {
            errors.notificationType = 'Required'
        }

        return errors
    },
    onSubmitSuccess: (result, dispatch) => {
        dispatch(reset('create-email-notification-job-wizzard'))
    }
})(CreateEmailNotificationForm)