import React from 'react'
import { Field, reduxForm, reset } from 'redux-form';
import { required, email } from '../common/ReduxFormFieldValidation';
import { renderField, renderTextArea } from '../common/ReduxFormHelper';
import Card, { Header, Body } from '../common/Card';
import Privilege from '../../containers/Privilege'
import { NavLink } from 'react-router-dom'
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';

const CreateInvitation = ({ handleSubmit, submitting }) => {
    return (
        <PageContent>
            <ContentHeader title="Network / Connection requests"/>
            <ContentBody>
                <Card>
                    <Header title="Create and send a connection request">
                        <div className="alert highlight" role="alert">
                            <span>
                            To be able to send files between endpoints on different accounts you need to have a connection between the accounts.
                            When a connection is made between two accounts an administrator can share acces to an endpoint and a job can be created to send or receive files between different accounts.
                            </span>
                        </div>
                    </Header>
                    <Body>
                        <form onSubmit={handleSubmit} className="content">
                            <fieldset>
                                <div className="row">
                                    <Field
                                        name="email"
                                        className="col-md-6"
                                        component={renderField}
                                        type="email"
                                        label="Receiver email"
                                        icon="fa-user"
                                        validate={[required, email]}
                                        tooltip="Please enter a valid email"
                                        placeholder="name@company.com"
                                    />
                                </div>
                                <div className="row">
                                    <Field
                                        name="message"
                                        className="col-md-6"
                                        component={renderTextArea}
                                        type="text"
                                        label="Personal message"
                                        icon="fa-user"
                                        tooltip="Message to the invitee"
                                        placeholder="The personal message will appear in the invitation e-mail."
                                    />

                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <button type="submit" className="btn btn-primary btn-lg" disabled={submitting}>
                                            <span className="icon icon-paper-plane"></span> Send request!
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        <div className="highlight">
                            <p className="mt-3">
                                If you know an existing Cloudbuz account or if you are uncertain of your receivers e-mail  you can create a a connection  token and share this with an account admin on the other account to create a connection.
                            </p>
                            <Privilege type="Network extend page" privilege="read">
                                        <NavLink activeClassName="active" to="/admin/extendnetwork/tokens">
                                            <p>Create a connection token</p>
                                        </NavLink>
                            </Privilege>
                        </div>
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>
    )
}


const afterSubmit = (result, dispatch) =>
  dispatch(reset('create-invitation'));


export default reduxForm({
    form: 'create-invitation', // a unique identifier for this form
    onSubmitSuccess: afterSubmit
})(CreateInvitation);
