import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Loader from '../components/common/Loader'

import DeleteAccount from '../components/general/DeleteAccount'
import ChangeAccountOwner from '../components/general/ChangeAccountOwner'

import AccountEditContainer from './AccountEdit'

import { deleteAccount, changeAccountOwner } from '../actions/general'
import { isFetching } from '../selectors/Fetching';
import { getAccount } from '../actions/account';

const mapStateToProps = (state) => ({
    isFetching: isFetching(state.fetching, deleteAccount),
    accountId: state.auth.user.accountId,
    email: state.auth.user.email,
    account: state.account.account,
});

const mapDispatchToProps = dispatch => ({
    deleteAccount: (model) => dispatch(deleteAccount(model)),
    changeAccountOwner: (model) => dispatch(changeAccountOwner(model)),
    initialState: (accountId) => dispatch(getAccount(accountId)),
})

const GeneralContainer = ({ initialState, account, email, accountId, isFetching, deleteAccount, changeAccountOwner }) => {

    useEffect(() => {
        initialState(accountId);
    }, [accountId, initialState]);

    return isFetching || !account ? <Loader /> :
        <React.Fragment>
            <AccountEditContainer />
            <ChangeAccountOwner username={email} adminUsers={account.adminUsers} onChange={changeAccountOwner} />
            <DeleteAccount onDelete={deleteAccount} adminUsers={account.adminUsers} username={email} />
        </React.Fragment>
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralContainer)

