const stepThreeValidation = values => {
    const errors = {}

    if (!values.trigger) {
        values.trigger = {}
    }

    if (!values.schedule) {
        values.schedule = {
            date: {},
            time: {}
        }
    }

    if (!values.triggerType) {
        errors.triggerType = 'Required'
    } else if (values.triggerType === '1' || values.triggerType === '3') { //schedule
        let scheduleErrors = {};

        if (!values.schedule.name)
            scheduleErrors.name = 'Required'

        if (!values.schedule.dateStart) {
            if (!scheduleErrors.date) {
                scheduleErrors.date = {}
            }
            scheduleErrors.dateStart = 'Required'
        }

        if (!values.schedule.dateType) {
            if (!scheduleErrors.date) {
                scheduleErrors.date = {}
            }
            scheduleErrors.dateType = 'Required'
        }

        if (!values.schedule.timeType) {
            if (!scheduleErrors.time) {
                scheduleErrors.time = {}
            }
            scheduleErrors.timeType = 'Required'
        }

        if (!values.schedule.timeZoneId)
            scheduleErrors.timeZone = 'Required'

        if (!values.schedule.timeStart) {

            if (!scheduleErrors.time) {
                scheduleErrors.time = {}
            }

            scheduleErrors.timeStart = 'Required'
        }



        if (Object.keys(scheduleErrors).length !== 0) {
            errors.schedule = scheduleErrors;
        }

    } else if (values.triggerType === '2') { //Trigger
        let triggerErrors = {};

        if (!values.trigger.fileNamePattern) {
            triggerErrors.fileNamePattern = 'Required'
        }

        if (!values.trigger.triggerDelay) {
            triggerErrors.triggerDelay = 'Required'
        }
        else if (!/^\d+$/.test(values.trigger.triggerDelay)) {
            triggerErrors.triggerDelay = 'Only numeric value is allowed'
        }

        if (Object.keys(triggerErrors).length !== 0) {
            errors.trigger = triggerErrors;
        }
    }

    return errors
}

export default stepThreeValidation