import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import qs from "query-string";
import JobsList from "../components/jobs/JobsList";

import {
  deleteJob,
  toggleActiveJob,
  copyJob,
  createNewJob,
} from "../actions/jobs";

import { loadJobsList } from "../actions/jobs";
import { isFetching } from "../selectors/Fetching";
import { dateFormat } from "../common/dateFormatter";
import { IconButton } from "../components/common/Buttons";
import { useModal } from "../components/modal/Modal";

const mapStateToProps = (state) => {
  return {
    isFetching: isFetching(
      state.fetching,
      loadJobsList,
      toggleActiveJob,
      deleteJob,
      copyJob
    ),
    jobs: state.jobs.jobs,
    currentPage: state.jobs.currentJobsPage || 0,
    totalPages: state.jobs.totalPages,
    totalCountRow: state.jobs.totalCountRow,
    sortBy: state.jobs.sortBy,
    searchText: state.jobs.text,
  };
};

const mapDispatchToPros = (dispatch) => {
  return {
    toggleActive: (job) => dispatch(toggleActiveJob(job.id)),
    copyJob: (job) => dispatch(copyJob(job.id)),
    deleteJob: (job) => dispatch(deleteJob(job.id)),
    initalStateJobs: (currentPage, searchText, status, sortBy) =>
      dispatch(loadJobsList(currentPage, searchText, status, sortBy)),
    onJobSelect: (job) => dispatch(push("/admin/jobs/edit/" + job.id)),
    onCreateNew: () => dispatch(createNewJob()),
  };
};

const JobsListContainer = ({
  initalStateJobs,
  jobs,
  onJobSelect,
  deleteJob,
  copyJob,
  toggleActive,
  totalPages,
  onCreateNew,
  searchText,
  currentPage
}) => {
  const { showModal } = useModal();

  const jobsSchema = [
    { accessor: "id", header: "Id" },
    { accessor: "name", header: "Name" },
    { accessor: "description", header: "Description" },
    {
      header: "Status",
      accessor: "isActive",
      type: "custom",
      render: (data) =>
        data.isActive ? (
          data.autoEnable ? (
            <span className="badge badge-danger">
              <i className="icon icon-attention-circled"></i>{" "}
              {`Autodisabled until ${dateFormat(data.autoEnable)}`}
            </span>
          ) : (
            <span className="badge badge-success">
              <i className="icon icon-ok-circled"></i> Enabled
            </span>
          )
        ) : (
          <span className="badge badge-secondary">
            <i className="icon icon-stop-circle"></i> Disabled
          </span>
        ),
    },
    {
      header: "Next run",
      accessor: "nextExecution",
      type: "custom",
      render: (row) => {
        return row.nextExecution && row.isActive && !row.autoEnable
          ? dateFormat(row.nextExecution)
          : row.triggerName && row.isActive && !row.autoEnable
          ? row.triggerName
          : "n/a";
      },
    },
    {
      type: "custom",
      render: (job) => {
        return (
          <div className="float-right">
            <IconButton
              className="m-1"
              icon="icon-onoff"
              type={job.isActive ? "danger" : "success"}
              tooltip={job.isActive ? "Disable" : "Enable"}
              tooltipplacement="top"
              onClick={(ev) => {
                ev.stopPropagation();
                toggleActive(job);
              }}
            />
            <IconButton
              className="m-1"
              icon="icon-copy"
              type="dark"
              tooltip="Copy job"
              tooltipplacement="top"
              onClick={(ev) => {
                ev.stopPropagation();
                copyJob(job);
              }}
            />
            <IconButton
              className="m-1"
              icon="icon-trash"
              type="danger"
              tooltip="Delete job"
              tooltipplacement="top"
              onClick={(ev) => {
                ev.stopPropagation();
                showModal("Confirm", {
                  title: "Delete job configuration?",
                  confirmBtnText: "Delete",
                  confirmBtnDanger: true,
                  message: (
                    <span>
                      Are you sure you want to delete{" "}
                      <strong>{job.id + " - " + job.name}</strong>?
                    </span>
                  ),
                  init: { next: deleteJob, args: [job] },
                });
              }}
            />
          </div>
        );
      },
    },
  ];


  const [search, setSearch] = useState(searchText);
  const [sortBy, setSortBy] = useState();
  const [page, setPage] = useState(currentPage);

  const status = useMemo(() => {
    const filter = qs.parse(window.location.search, {
      arrayFormat: "bracket",
      parseBooleans: true,
      parseNumbers: true,
    });

    return filter.status;
  }, []);

  useEffect(() => {
    initalStateJobs(page, search, status, sortBy);
  }, [page, initalStateJobs, search, sortBy, status]);

  return (
    <React.Fragment>
      <JobsList
        jobs={jobs}
        schema={jobsSchema}
        onJobSelect={onJobSelect}
        currentPage={page}
        sortBy={sortBy}
        totalPages={totalPages}
        onPageChange={setPage}
        onSortChange={setSortBy}
        onCreateNew={onCreateNew}
        onSearch={setSearch}
        searchText={searchText}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToPros)(JobsListContainer);
