export const START_AUTO_REFRESH = "START_AUTO_REFRESH"
export const CANCEL_AUTO_REFRESH = "CANCEL_AUTO_REFRESH"

export const startAutoRefresh = (actionToExecute, interval) => ({
    type: START_AUTO_REFRESH,
    actionToExecute, interval
})

export const cancelAutoRefresh = () => ({
    type: CANCEL_AUTO_REFRESH
})