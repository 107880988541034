import React from 'react'
import LoginCard, { Header, Body } from '../common/LoginCard';
import CbLogo from '../../images/logo_white.png'

const SignUpSuccessComponent = ({ name }) => {

    return (
        <div>
            <div className="logo_login_placeholder">
                <img src={CbLogo} height="30px" alt="Cloudbuz Manager" className="logo_login" />
            </div>
            <LoginCard>
                <Header title={`Thank you ${name}!`}/>
                <Body>
                    <p>
                    Your brand new Cloudbuz account is ready to do some work for you.
    
                    Hope you´ll enyoy it and please let us know if you have any issues and we will do all in our powers to assist and help you.
                    
                    Check your e-mail and use the temporary password to log in the first time. You need to login and change your password within 24 hours or else your account will be terminated.
                    </p>
                    <p>Happy file transferring!</p>
                    <span className="text-primary">The Cloudbuz team <i className="icon icon-logo_mark"></i></span>
                </Body>
            </LoginCard>
        </div>
    )

}

export default SignUpSuccessComponent