import { IconButton } from "../../components/common/Buttons";

const ReportsSchema = (onDownload, onDelete) => [
  { accessor: "title", header: "Title" },
  { accessor: "orderedBy", header: "Ordered by" },
  { accessor: "date", header: "Date", type: "date" },
  {
    type: "custom",
    header: "Status",
    render: (data) =>
      data.status === "Error" ? (
        <span className="text-danger">{data.status}</span>
      ) : (
        <span>{data.status}</span>
      ),
  },
  {
    type: "custom",
    render: (item) => {
      return (
        <div className="float-right">
          <IconButton
            className="m-1"
            icon="icon-download-cloud"
            type={"success"}
            tooltipplacement="top"
            onClick={(ev) => {
              ev.stopPropagation();
              onDownload(item)
            }}
          />
          <IconButton
            className="m-1"
            icon="icon-trash"
            type="danger"
            tooltip="Delete job"
            tooltipplacement="top"
            onClick={(ev) => {
              onDelete(item)
            }}
          />
        </div>
      );
    },
  },
];

export default ReportsSchema;
