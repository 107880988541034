import { combineEpics } from 'redux-observable';

import {
  userLoginEpic,
  userInfoEpic,
  userLogoutEpic,
  userLoggedOutEpic,
  reAuthenticateEpic,
  changeAccountEpic,
  changeAccountSuccessEpic,
  refreshUserInfoEpic,
  changePasswordEpic$,
  changePasswordSuccessNotificationEpic$,
  forgotPasswordEpic$,
  forgotPasswordSuccessEpic$,
  createForgotPasswordEpic$,
  createForgotPasswordSuccessEpic$
} from './auth'

import {
  createAccountEpic,
  listAccountsEpic,
  editAccountEpic,
  getAccountEpic,
  saveAccountEpic,
  createAccountSuccessEpic,
  saveAccountSuccessNotificationEpic,
  listAccountUsersEpic,
  createOrganisationEpic,
  createOrganisationSuccessNotificationEpic,
  createOrganisationSuccessEpic,
  createUserEpic,
  createUserSuccessEpic,
  editUserEpic,
  getUserEpic,
  saveUserEpic,
  createUserSuccessNotificationEpic,
  removeOrganisationEpic,
  removeOrganisationSuccessEpic,
  listPendingAccountUsersEpic,
  removeUserFromAccountEpic,
  removeUserFromAccountSuccessEpic
} from './account'

import {
  editOrganisationEpic,
  getOrganisationEpic,
  saveOrganisationEpic,
  saveOrganisationSuccessNotificationEpic,
  saveOrganisationFailedNotificationEpic,
  listOrganisationsEpic,
  listOrganisationUsersEpic,
  removeUserEpic,
  removeUserSuccessEpic
} from './organisation'

import {
  getCreateUserPrivilegesEpic,
  getLoginNotificationsEpic,
  signUpUserEpic$,
  signUpUserSuccessEpic$,
} from './user'

import {
  notificationEpic
} from './notification'

import {
  listInvitesEpic,
  createInvitationTokenEpic,
  listInvitationTokensEpic,
  deleteInitationTokenEpic,
  loadAccountFromInvitationToken$,
  connectAccountWithInvitationToken$,
  revokeInviteEpic$,
  resendInviteEpic$,
  acceptInviteEpic$,
  declineInviteEpic$,
  getInviteEpic$,
  acceptInviteSuccessEpic$,
  createUserInviteEpic$,
  createAccountRelationInviteEpic$,
  createAccountRelationInviteSuccessEpic$,
  createUserInviteSuccessEpic$,
  revokeUserInviteEpic$,
  declineInviteSuccessEpic$
} from './invite'

import {
  createCustomerEpic,
  createCustomerSuccessEpic,
  createCustomerSuccessNotificationEpic
} from './customer'

import { errorNotificationEpic } from './error';

import {
  listInvitesEpic$,
  loadConnectionDetailsEpic$,
  disconnectConnectionEpic$,
  disconnectConnectionSuccessEpic$
} from './connections'

import {
  listConnectorsEpic$,
  loadConnectorDetailsEpic$,
  recycleConnectorEpic$,
  changeConnectorLogLevelEpic$,
  requestConnectorLogFileEpic$,
  transferConnectorToNewOrgEpic$,
  unregisterAndDeleteConnectorEpic$,
  unregisterAndDeleteConnectorSuccessEpic$,
  createConnectorRequestInstallmentEpic$,
  deleteConnectorRequestInstallmentEpic$,
  getAllConnectorTypesEpic$,
  createConnectorRequestInstallmentSuccessEpic$,
  restartConnectorEpic$,
  killConnectorEpic$,
  clearExcludeFoldersConnectorEpic$,
  addExcludeFoldersConnectorEpic$,
  removeExcludeFoldersConnectorEpic$,
  changeConnectorDescriptionEpic$,
  downloadConnectorLogFileEpic$
} from './connectors'

import {
  saveNotificationsSettingsEpic$,
  loadNotificaionSettings$,
  saveJobNotificationsSettingsEpic$,
  saveJobNotificationsSettingsSuccessNotificationEpic,
  saveNotificationsSettingsSuccessNotificationEpic
} from './notificationsettings'

import {
  deleteAccountEpic$,
  deleteAccountSuccessEpic$,
  changeAccountOwnerEpic$,
  changeAccountOwnerSuccessEpic$
} from './general';

import {
  leaveOrganisationEpic$,
  leaveAccountEpic$,
  loadGeneralSettingsEpic$,
  updateGeneralSettingsEpic$,
  deleteUserEpic$,
  updateTimezoneEpic$,
} from './generalsettings';

import {
  listAllJobsEpic$,
  refreshAllJobsEpic$,
  loadCreateJobInitalDataEpic$,
  showRunDetailEpic$,
  loadScheduleEpic$,
  loadScheduleStep2Epic$,
  showEventDetailEpic$,
  showFileDetailsEpic$,
  loadNotificationUsersEpic$,
  loadJobRunsEpic$,
  loadJobRunEpic$,
  loadJobRunEventEpic$,
  loadJobRunFileEpic$,
  onFilesFilterChangeEpic$,
  onRunsCurrentPageChangeEpic$,
  onExcuteActionOnRunEpic$,
  loadJobsView$,
  onJobsCurrentPageChangeEpic$,
  onExcuteActionOnJobEpic$,
  saveJobEpic$,
  loadJobEpic$,
  savedJobSuccessEpic$,
  toggleActiveJobEpic$,
  deleteJobEpic$,
  copyJobEpic$,
  navigateNewJobEpic$
} from './jobs'
import { startAutoRefreshEpic$ } from './autorefresh';

import { autocompleteDoSearchEpic$ } from './autocomplete'
import {
  loadExploreViewEpic$,
  loadExploreViewFailedEpic$,
  navigateExploreViewEpic$,
  searchInExploreEpic$,
  getShareFolderEpic$,
  shareFolderEpic$,
  executeExploreCommandEpic$,
  executeExploreCommandSuccessEpic$
} from './explore';
import { getAllStatsEpic$ } from './stats';

export const rootEpic = (action$, store) =>
  combineEpics(
    //Customer
    createCustomerEpic,
    createCustomerSuccessEpic,
    createCustomerSuccessNotificationEpic,
    //User
    userLoginEpic,
    userInfoEpic,
    userLogoutEpic,
    userLoggedOutEpic,
    getCreateUserPrivilegesEpic,
    getLoginNotificationsEpic,
    createUserEpic,
    createUserSuccessEpic,
    saveUserEpic,
    createUserSuccessNotificationEpic,
    editUserEpic,
    getUserEpic,
    reAuthenticateEpic,
    changeAccountEpic,
    changeAccountSuccessEpic,
    refreshUserInfoEpic,
    changePasswordSuccessNotificationEpic$,
    changePasswordEpic$,
    forgotPasswordEpic$,
    forgotPasswordSuccessEpic$,
    createForgotPasswordEpic$,
    createForgotPasswordSuccessEpic$,
    signUpUserEpic$,
    signUpUserSuccessEpic$,
    //Account
    createAccountEpic,
    createAccountSuccessEpic,
    listAccountsEpic,
    editAccountEpic,
    getAccountEpic,
    saveAccountEpic,
    listAccountUsersEpic,
    createOrganisationEpic,
    createOrganisationSuccessEpic,
    createOrganisationSuccessNotificationEpic,
    removeOrganisationEpic,
    removeOrganisationSuccessEpic,
    listPendingAccountUsersEpic,
    removeUserFromAccountEpic,
    removeUserFromAccountSuccessEpic,
    //Organisation
    editOrganisationEpic,
    getOrganisationEpic,
    saveOrganisationEpic,
    saveOrganisationSuccessNotificationEpic,
    saveOrganisationFailedNotificationEpic,
    listOrganisationsEpic,
    listOrganisationUsersEpic,
    removeUserEpic,
    removeUserSuccessEpic,
    //Notifications
    notificationEpic,
    saveAccountSuccessNotificationEpic,
    //INVITE
    listInvitesEpic,
    createInvitationTokenEpic,
    listInvitationTokensEpic,
    deleteInitationTokenEpic,
    loadAccountFromInvitationToken$,
    connectAccountWithInvitationToken$,
    createUserInviteEpic$,
    createAccountRelationInviteEpic$,
    createAccountRelationInviteSuccessEpic$,
    revokeInviteEpic$,
    resendInviteEpic$,
    acceptInviteEpic$,
    declineInviteEpic$,
    getInviteEpic$,
    acceptInviteSuccessEpic$,
    createUserInviteSuccessEpic$,
    revokeUserInviteEpic$,
    declineInviteSuccessEpic$,
    //Error
    errorNotificationEpic,
    //Connections
    listInvitesEpic$,
    loadConnectionDetailsEpic$,
    disconnectConnectionEpic$,
    disconnectConnectionSuccessEpic$,
    //connectors
    listConnectorsEpic$,
    loadConnectorDetailsEpic$,
    recycleConnectorEpic$,
    changeConnectorLogLevelEpic$,
    requestConnectorLogFileEpic$,
    transferConnectorToNewOrgEpic$,
    unregisterAndDeleteConnectorEpic$,
    unregisterAndDeleteConnectorSuccessEpic$,
    createConnectorRequestInstallmentEpic$,
    deleteConnectorRequestInstallmentEpic$,
    createConnectorRequestInstallmentSuccessEpic$,
    getAllConnectorTypesEpic$,
    restartConnectorEpic$,
    killConnectorEpic$,
    clearExcludeFoldersConnectorEpic$,
    addExcludeFoldersConnectorEpic$,
    removeExcludeFoldersConnectorEpic$,
    changeConnectorDescriptionEpic$,
    downloadConnectorLogFileEpic$,
    //NotificationSettings
    saveNotificationsSettingsEpic$,
    loadNotificaionSettings$,
    saveJobNotificationsSettingsEpic$,
    saveNotificationsSettingsSuccessNotificationEpic,
    saveJobNotificationsSettingsSuccessNotificationEpic,
    //General
    deleteAccountEpic$,
    deleteAccountSuccessEpic$,
    changeAccountOwnerEpic$,
    changeAccountOwnerSuccessEpic$,
    //GeneralSettings
    loadGeneralSettingsEpic$,
    updateGeneralSettingsEpic$,
    leaveOrganisationEpic$,
    leaveAccountEpic$,
    deleteUserEpic$,
    updateTimezoneEpic$,
    //jobs
    listAllJobsEpic$,
    refreshAllJobsEpic$,
    loadCreateJobInitalDataEpic$,
    showRunDetailEpic$,
    loadScheduleEpic$,
    loadScheduleStep2Epic$,
    showEventDetailEpic$,
    showFileDetailsEpic$,
    loadNotificationUsersEpic$,
    loadJobRunsEpic$,
    loadJobRunEpic$,
    loadJobRunEventEpic$,
    loadJobRunFileEpic$,
    onFilesFilterChangeEpic$,
    onRunsCurrentPageChangeEpic$,
    onExcuteActionOnRunEpic$,
    loadJobsView$,
    onJobsCurrentPageChangeEpic$,
    onExcuteActionOnJobEpic$,
    saveJobEpic$,
    savedJobSuccessEpic$,
    loadJobEpic$,
    toggleActiveJobEpic$,
    deleteJobEpic$,
    copyJobEpic$,
    navigateNewJobEpic$,
    //autorefresh
    startAutoRefreshEpic$,
    //Autocomplete
    autocompleteDoSearchEpic$,
    //Explore
    loadExploreViewEpic$,
    loadExploreViewFailedEpic$,
    navigateExploreViewEpic$,
    searchInExploreEpic$,
    getShareFolderEpic$,
    shareFolderEpic$,
    executeExploreCommandEpic$,
    executeExploreCommandSuccessEpic$,
    //Stats
    getAllStatsEpic$
  )(action$, store);










