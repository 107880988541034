import React from 'react'
import { Field, reduxForm } from 'redux-form';
import './AcceptInvite.css'
import { required } from '../common/ReduxFormFieldValidation';
import { renderField } from '../common/ReduxFormHelper';
import LoginCard, { Header, Body } from '../common/LoginCard';
import CbLogo from '../../images/logo_white.png'

const AcceptInviteNewUserComponent = ({ initialValues, handleSubmit, errors, submitting, onDecline }) => {
    const showError = errors ? <div className="alert alert-danger alert-dismissable">
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {errors}
    </div> : ""


    return (
        <div>
            <div className="logo_login_placeholder">
                <img src={CbLogo} height="30px" alt="Cloudbuz Manager" className="logo_login" />
            </div>
            <LoginCard>
                <Header title="Create user account" >
                    <div className="alert alert-primary" role="alert">
                        <p>
                            Please accept the invitation from  <strong>{initialValues.invitorName}</strong> (<strong>{initialValues.invitorEmail}</strong>) to join the account <strong>{initialValues.invitedBy}</strong>.
                        </p>
                        <p>
                            You can at any time delete your user account.
                        </p>
                    </div>
                </Header>
                <Body>
                    <form onSubmit={handleSubmit}>
                            <fieldset>
                                <Field
                                    name="fullName"
                                    component={renderField}
                                    type="text"
                                    placeholder="Your full name"
                                    icon="fa-user"
                                    validate={[required]}
                                    tooltip="Please enter your full name"
                                />
                                    {showError}
                            </fieldset>
                            <footer className="text-center">
                                <button type="submit" className="btn btn-success btn-block btn-lg" disabled={submitting}>
                                    Accept
                                </button>
                                or
                                <button type="button" className="btn btn-light  btn-block btn-lg" disabled={submitting} onClick={onDecline}>
                                    Decline
                                </button>
                            </footer>
                    </form>
                </Body>
            </LoginCard>
        </div>
    )

}

export default reduxForm({
    form: 'accept-invite-newuser', // a unique identifier for this form
})(AcceptInviteNewUserComponent);

