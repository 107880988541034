import React, { useRef } from 'react'


export const PasswordVerificationDialog = ({ init, onHide, username, confirmBtnText, confirmBtnDanger, }) => {
    const passwordRef = useRef(null);

    return init.show ? <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block"}}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Please enter password to continue</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { onHide(); }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    init.next(init.model ?
                        { ...init.model, password: passwordRef.current.value } :
                        { password: passwordRef.current.value });
                    onHide();
                }
                }>
                    <div className="modal-body">

                        <div className="form-group col-md-12 padding">
                            <label>Password</label>
                            <input type="password"
                                className="form-control border-input"
                                ref={passwordRef} placeholder="Enter password"
                                autoComplete="current-password" />

                            <input type="hidden" name="username" id="username" value={username} />

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light btn-lg" onClick={() => { onHide(); }}>Cancel</button>
                        <button type="submit" className={confirmBtnDanger ? "btn btn-danger btn-lg" : "btn btn-primary btn-lg"}>{confirmBtnText ? confirmBtnText : "Ok"}</button>
                    </div>
                </form>
            </div>
        </div>
    </div > : "";
}