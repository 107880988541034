import React from "react";
import PageContent, {
  ContentHeader,
  ContentBody,
} from "../components/common/PageContent";
import ReportsContainer from "../containers/reports/Reports";

const ReportsPage = () => (
  <PageContent className="border-0">
    <ContentHeader title="Jobs / History & reports" />
    <ContentBody>
      <ReportsContainer />
    </ContentBody>
  </PageContent>
);

export default ReportsPage;
