export const LOAD_CONNECTORS_LIST = 'REQ_LOAD_CONNECTIONS_LIST'
export const LOAD_CONNECTORS_LIST_SUCCESS = 'LOAD_CONNECTORS_LIST_SUCCESS'

export const LOAD_CONNECTOR_DETAILS = 'REQ_LOAD_CONNECTOR_DETAILS'
export const LOAD_CONNECTOR_DETAILS_SUCCESS = 'LOAD_CONNECTOR_DETAILS_SUCCESS'

export const RECYCLE_CONNECTOR = 'REQ_RECYCLE_CONNECTOR'
export const RECYCLE_CONNECTOR_SUCCESS = 'RECYCLE_CONNECTOR_SUCCESS'

export const CHANGE_CONNECTOR_LOGLEVEL = 'REQ_CHANGE_CONNECTOR_LOGLEVEL'
export const CHANGE_CONNECTOR_LOGLEVEL_SUCCESS = 'CHANGE_CONNECTOR_LOGLEVEL_SUCCESS'

export const REQUEST_CONNECTOR_LOGFILE = 'REQ_REQUEST_CONNECTOR_LOGFILE'
export const REQUEST_CONNECTOR_LOGFILE_SUCCESS = 'REQUEST_CONNECTOR_LOGFILE_SUCCESS'

export const TRANSFER_CONNECTOR_TO_NEW_ORG = 'REQ_TRANSFER_CONNECTOR_TO_NEW_ORG'
export const TRANSFER_CONNECTOR_TO_NEW_ORG_SUCCESS = 'TRANSFER_CONNECTOR_TO_NEW_ORG_SUCCESS'

export const UNREGISTER_DELETE_CONNECTOR = 'REQ_UNREGISTER_DELETE_CONNECTOR'
export const UNREGISTER_DELETE_CONNECTOR_SUCCESS = 'UNREGISTER_DELETE_CONNECTOR_SUCCESS'

export const CREATE_CONNECTOR_INSTALLMENT_REQUEST = 'REQ_CREATE_CONNECTOR_INSTALLMENT_REQUEST'
export const CREATE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS = 'CREATE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS'

export const DELETE_CONNECTOR_INSTALLMENT_REQUEST = 'REQ_DELETE_CONNECTOR_INSTALLMENT_REQUEST'
export const DELETE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS = 'DELETE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS'

export const SET_FORM_DATA_REQEST_NEW_INSTALLMENT = 'SET_FORM_DATA_REQEST_NEW_INSTALLMENT'

export const SET_FORM_DATA_CONNECTOR_TRANSFER_ORG = 'SET_FORM_DATA_CONNECTOR_TRANSFER_ORG'
export const SET_FORM_DATA_CONNECTOR_NEW_LOGLEVEL = 'SET_FORM_DATA_CONNECTOR_NEW_LOGLEVEL'

export const LOAD_CONNECTOR_INSTALLMENT_DATA = 'REQ_LOAD_CONNECTOR_INSTALLMENT_DATA'
export const LOAD_CONNECTOR_INSTALLMENT_DATA_SUCCESS = 'LOAD_CONNECTOR_INSTALLMENT_DATA_SUCCESS'

export const RESTART_CONNECTOR = 'REQ_RESTART_CONNECTOR'
export const RESTART_CONNECTOR_SUCCESS = 'RESTART_CONNECTOR_SUCCESS'

export const KILL_CONNECTOR = 'REQ_KILL_CONNECTOR'
export const KILL_CONNECTOR_SUCCESS = 'RESTART_KILL_SUCCESS'

export const CLEAR_EXCLUDE_FOLDERS_CONNECTOR = 'REQ_CLEAR_EXCLUDE_FOLDERS_CONNECTOR'
export const CLEAR_EXCLUDE_FOLDERS_CONNECTOR_SUCCESS = 'CLEAR_EXCLUDE_FOLDERS_CONNECTOR_SUCCESS'

export const ADD_EXCLUDE_FOLDERS_CONNECTOR = 'REQ_ADD_EXCLUDE_FOLDERS_CONNECTOR'
export const ADD_EXCLUDE_FOLDERS_CONNECTOR_SUCCESS = 'ADD_FOLDERS_CONNECTOR_SUCCESS'

export const REMOVE_EXCLUDE_FOLDERS_CONNECTOR = 'REQ_REMOVE_EXCLUDE_FOLDERS_CONNECTOR'
export const REMOVE_EXCLUDE_FOLDERS_CONNECTOR_SUCCESS = 'REMOVE_FOLDERS_CONNECTOR_SUCCESS'

export const CHANGE_DESCRIPTION_CONNECTOR = 'REQ_CHANGE_DESCRIPTION_CONNECTOR'
export const CHANGE_DESCRIPTION_CONNECTOR_SUCCESS = 'CHANGE_DESCRIPTION_CONNECTOR_SUCCESS'

export const DOWNLOAD_LOGFILE = 'REQ_DOWNLOAD_LOGFILE'
export const DOWNLOAD_LOGFILE_SUCCESS = 'DOWNLOAD_LOGFILE_SUCCESS'

export const downloadLogFile =(clientId, fileId, fileName) => ({
    type: DOWNLOAD_LOGFILE, clientId, fileId, fileName
})

export const downloadLogFileSuccess =() => ({
    type: DOWNLOAD_LOGFILE_SUCCESS
})

export const setConnectorTransferOrg = (newOrgId) => ({
    type: SET_FORM_DATA_CONNECTOR_TRANSFER_ORG,
    newOrgId
})

export const setConnectorNewLogLevel = (logLevel) => ({
    type: SET_FORM_DATA_CONNECTOR_NEW_LOGLEVEL,
    logLevel
})

export const loadConnectorsList = () => ({
    type: LOAD_CONNECTORS_LIST,
})

export const loadConnectorInstallmentData = () => ({
    type: LOAD_CONNECTOR_INSTALLMENT_DATA,
})

export const loadConnectorInstallmentDataSuccess = (connectorTypes, organisations) => ({
    type: LOAD_CONNECTOR_INSTALLMENT_DATA_SUCCESS,
    connectorTypes,
    organisations
})

export const loadConnectorsListSuccess = (connectors, pendingRequests) => ({
    type: LOAD_CONNECTORS_LIST_SUCCESS,
    connectors,
    pendingRequests
})

export const loadConnectorDetails = (clientId) => ({
    type: LOAD_CONNECTOR_DETAILS,
    clientId
})

export const loadConnectorDetailsSuccess = (details, organisations, logFiles, connectorLogLevels) => ({
    type: LOAD_CONNECTOR_DETAILS_SUCCESS,
    details,
    organisations,
    connectorLogLevels,
    logFiles
})

export const recycleConnector = (clientId) => ({
    type: RECYCLE_CONNECTOR,
    clientId
})

export const recycleConnectorSuccess = () => ({
    type: RECYCLE_CONNECTOR_SUCCESS
})

export const changeConnectorLogLevel = (clientId, logLevel) => ({
    type: CHANGE_CONNECTOR_LOGLEVEL,
    clientId,
    logLevel
})

export const changeConnectorLogLevelSuccess = () => ({
    type: CHANGE_CONNECTOR_LOGLEVEL_SUCCESS
})

export const requestConnectorLogFiles = (clientId, date) => ({
    type: REQUEST_CONNECTOR_LOGFILE,
    clientId, date
})

export const requestConnectorLogFilesSuccess = () => ({
    type: REQUEST_CONNECTOR_LOGFILE_SUCCESS
})

export const restart = (clientId) => ({
    type: RESTART_CONNECTOR, clientId
})

export const restartSuccess = () => ({
    type: RESTART_CONNECTOR_SUCCESS
})

export const kill = (clientId) => ({
    type:KILL_CONNECTOR, clientId
})

export const killSuccess = () => ({
    type:KILL_CONNECTOR_SUCCESS
})

export const clearExcludeFolders = (clientId) => ({
    type:CLEAR_EXCLUDE_FOLDERS_CONNECTOR, clientId
})

export const clearExcludeFoldersSuccess = (clientId) => ({
    type:CLEAR_EXCLUDE_FOLDERS_CONNECTOR_SUCCESS
})


export const addExcludeFolders = (clientId, path ) => ({
    type:ADD_EXCLUDE_FOLDERS_CONNECTOR, clientId, path
})

export const addExcludeFoldersSuccess = () => ({
    type: ADD_EXCLUDE_FOLDERS_CONNECTOR_SUCCESS
})

export const removeExcludeFolders = (clientId, path ) => ({
    type: REMOVE_EXCLUDE_FOLDERS_CONNECTOR, clientId, path
})

export const removeExcludeFoldersSuccess = () => ({
    type: REMOVE_EXCLUDE_FOLDERS_CONNECTOR_SUCCESS
})


export const transferConnectorToNewOrg = (clientId, orgId) => ({
    type: TRANSFER_CONNECTOR_TO_NEW_ORG,
    clientId,
    orgId
})

export const transferConnectorToNewOrgSuccess = () => ({
    type: TRANSFER_CONNECTOR_TO_NEW_ORG_SUCCESS
})

export const unregisterAndDeleteConnector = (clientId) => ({
    type: UNREGISTER_DELETE_CONNECTOR,
    clientId
})

export const unregisterAndDeleteConnectorSuccess = () => ({
    type: UNREGISTER_DELETE_CONNECTOR_SUCCESS
})

export const createConnectorInstallmentRequest = (email, message, connectorType, orgId) => ({
    type: CREATE_CONNECTOR_INSTALLMENT_REQUEST,
    email,
    message,
    connectorType,
    orgId
})

export const createConnectorInstallmentRequestSuccess = () => ({
    type: CREATE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS
})

export const deleteConnectorInstallmentRequest = (token) => ({
    type: DELETE_CONNECTOR_INSTALLMENT_REQUEST,
    token
})

export const deleteConnectorInstallmentRequestSuccess = () => ({
    type: DELETE_CONNECTOR_INSTALLMENT_REQUEST_SUCCESS
})

export const setFormDataRequestNewInstallment = (value, key) => ({
    type: SET_FORM_DATA_REQEST_NEW_INSTALLMENT,
    value: value,
    key: key
})

export const changeConnectorDescription =(clientId, description) => ({
    type: CHANGE_DESCRIPTION_CONNECTOR, clientId, description
})

export const changeConnectorDescriptionSuccess =() => ({
    type: CHANGE_DESCRIPTION_CONNECTOR_SUCCESS
})