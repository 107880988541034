import React from "react";
import { connect } from "react-redux";
import { createAccount } from "../actions/account";

import AccountCreate from "../components/accounts/AccountCreate";

import { getHeaders as getOrganisationHeaders } from "../selectors/Organisation";
import { getAccountTypes } from "../selectors/Account";
import { isFetching } from "../selectors/Fetching";
import { withRouter } from "react-router";

const mapStateToProps = (state) => ({
  values: state.account.account,
  isFetching: isFetching(state.fetching, createAccount),
  accountId: state.auth.user.accountId,
  organizationHeaders: getOrganisationHeaders(),
  accountTypes: getAccountTypes(),
});

const mapDispatchToProps = (dispatch) => ({
  createAccount: (referrer) => (values) =>
    dispatch(createAccount(values, referrer)),
});

let AccountCreateContainer = ({ accountTypes, createAccount, location }) => {
  return (
    <div>
      <AccountCreate
        accountTypes={accountTypes}
        onSubmit={createAccount((location.state || {}).referrer)}
      />
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountCreateContainer)
);
