import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { listCreateUserPrivileges, saveUser, getUser } from '../actions/user'
import Loader from '../components/common/Loader'
import UserEdit from '../components/users/UserEdit'
import { listOrganisations } from '../actions/organisation';
import { getActiveAccount } from '../selectors/Auth';
import { isFetching } from '../selectors/Fetching';

const mapStateToProps = (state) => {
    return {
        isFetching: isFetching(state.fetching, getUser, saveUser, listCreateUserPrivileges, listOrganisations),
        organisations: state.organisation.organisations,
        accountPrivileges: state.account.privileges,
        organisationPrivileges: state.organisation.privileges,
        activeAccount: getActiveAccount(state.auth),
        values: state.user.user
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveUser: (accountId) => (values) => dispatch(saveUser({ ...values, accountId })),
    listOrganisations: (accountId) => dispatch(listOrganisations(accountId)),
    listPrivileges: () => dispatch(listCreateUserPrivileges()),
    initialState: (accountId, userId) => dispatch(getUser(accountId, userId))
})

const UserEditContainer = ({ listOrganisations, listPrivileges, initialState, match, values, organisations, accountPrivileges, organisationPrivileges,  isFetching, activeAccount, saveUser}) => {

    useEffect(() => {
        listOrganisations(activeAccount.accountId);
        listPrivileges();
        initialState(activeAccount.accountId, match.params.userId);
    }, [activeAccount.accountId, initialState, listOrganisations, listPrivileges, match.params.userId])

    if (!values || !organisations || !accountPrivileges || !organisationPrivileges || isFetching) return <Loader />;

    return <UserEdit 
                initialValues={values} 
                organisations={organisations} 
                accountPrivileges={accountPrivileges} 
                organisationPrivileges={organisationPrivileges} 
                onSubmit={saveUser(activeAccount.accountId)}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer)
