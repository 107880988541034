import React, { useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import ConnectionList from "../components/connections/ConnectionList";

import Loader from "../components/common/Loader";

import {
  loadConnectionsList,
  disconnectConnection,
} from "../actions/connections";
import { isFetching } from "../selectors/Fetching";
import { useModal } from "../components/modal/Modal";

const mapStateToProps = (state) => {
  return {
    isFetching:
      isFetching(state.fetching, loadConnectionsList) ||
      isFetching(state.fetching, disconnectConnection),
    connections: state.connections.connections,
  };
};

const mapDispatchToPros = (dispatch) => {
  return {
    initalStateConnections: () => dispatch(loadConnectionsList()),
    onSelect: (connection) =>
      dispatch(push("/admin/connections/details/" + connection.accountKey)),
    onDisconnect: (connection) => dispatch(disconnectConnection(connection)),
  };
};

const ConnectionListContainer = ({
  initalStateConnections,
  isFetching,
  connections,
  onSelect,
  onDisconnect,
}) => {
  const { showModal } = useModal();

  const schema = [
    { accessor: "accountName", header: "Account" },
    { accessor: "accountOwner", header: "Owner" },
    { accessor: "connectedSince", header: "Connected since", type: "date" },
    //{ type: 'button', css: 'btn btn-danger btn-fill btn-wd float-right', action: onDisconnectDialog, actionText: "Disconnect" },
    {
      type: "custom",
      render: (connection) => (
        <button
          className="btn btn-danger float-right text-nowrap"
          onClick={(ev) => {
            ev.stopPropagation(); 
            showModal("Confirm", {
              title: "Delete connection?",
              message: (
                <span>
                  Are you sure you want to disconnect from{" "}
                  <strong>{connection.accountName}</strong>? All shared folders and
                  jobs will be deleted.
                </span>
              ),
              confirmBtnText: "Disconnect",
              confirmBtnDanger: true,
              init: { next: onDisconnect, args: [connection] },
            });
          }}
        >
          <i className="icon icon-unlink"></i> Disconnect
        </button>
      ),
    },
  ];

  useEffect(() => {
    initalStateConnections();
  }, [initalStateConnections]);

  return isFetching ? (
    <Loader />
  ) : (
    <React.Fragment>
      <ConnectionList
        connections={connections}
        schema={schema}
        onConnectionSelect={onSelect}
      />
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToPros
)(ConnectionListContainer);
