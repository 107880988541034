import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isFetching } from '../../../selectors/Fetching'
import Share from '../../../components/explore-and-share/Share'
import Loader from '../../../components/common/Loader'
import { getShareFolder, shareFolder } from '../../../actions/explore';

const mapStateToProps = (state, ownProps) => ({
    share: state.explore.share,
    isFetching: isFetching(state.fetching, getShareFolder, shareFolder)
})

const mapDispatchToProps = dispatch => ({
    initialState: (id, type) => dispatch(getShareFolder(id, type)),
    handleSubmit: (data) => dispatch(shareFolder(data.id, data.name, data.sharedWith))
})

const ShareContainer = ({ share, match, initialState, isFetching, handleSubmit, hideBreadcrumbs}) => {

    useEffect(() => {
        initialState(match.params.id, match.params.type)
    }, [match.params.id, match.params.type, initialState])

    if (!share || isFetching) {
        return <Loader />
    }

    return <Share share={share} handleSubmit={handleSubmit} hideBreadcrumbs={hideBreadcrumbs} />
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareContainer)