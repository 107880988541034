import { Link } from "react-router-dom";
import PageContent, {
  ContentHeader,
  ContentBody,
} from "../common/PageContent";
import Card, { Body, Header } from "../common/Card";
import { dateFormat } from '../../common/dateFormatter';

const EndpointDetails = ({ endpoint: e }) => {
  return (
    <div className="detail-table">
      <div className="row">
        <div className="col-sm-2">Status</div>
        <div className="col-sm-10">
          {e?.status === "online" ? (
            <span className={"badge badge-success"}>
              <i className="icon icon-ok-circled"></i> {e?.status}
            </span>            
          ) : (
            <span className={"badge badge-danger"}>
              <i className="icon icon-attention-circled"></i> {e?.status}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">Name</div>
        <div className="col-sm-10">{e?.name}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">Path</div>
        <div className="col-sm-10">
          <Link to={e?.shareId !== null ? `/explore/${e?.pathId}/${e?.pathType}/${e?.shareId}` : `/explore/${e?.pathId}/${e?.pathType}`}>{e?.path}</Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">Connector</div>
        <div className="col-sm-10">
          <Link to={`/admin/connectors/details/${e?.connectorId}`}>
            {e?.connectorName}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">Account</div>
        <div className="col-sm-10">{e?.account}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">Shared</div>
        <div className="col-sm-10">{e?.isShared ? "yes" : "no"}</div>
      </div>
      {e?.isShared && (
        <div className="row">
          <div className="col-sm-2">Shared as</div>
          <div className="col-sm-10">{e?.sharedAs}</div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-2">Root</div>
        <div className="col-sm-10">{e?.root}</div>
      </div>
      <div className="row">
        <div className="col-sm-2">Last activity</div>
        <div className="col-sm-10">{dateFormat(e?.lastActivity)}</div> 
      </div>
    </div>
  );
};

const EndpointJobs = ({ jobs }) => {
  return (
    <div className="detail-table">
      {
      (jobs ? jobs.length < 1 ? <div className="row"><div className="col-sm-12">n/a</div></div> : "" : "")
      }
      {
      jobs?.map((j) => (
        <div key={j.jobId} className="row">
          <div className="col-sm-10">
            {" "}
            {j.jobName}
          </div>
          <div className="col-sm-2 d-flex justify-content-end">
            <Link className="mr-3" to={`/admin/jobs/edit/${j.jobId}`}><i className="icon icon-edit"></i> Edit</Link>{" "}
            <Link to={`/jobs/runs?jobConfigId=${j.jobId}`}><i className="icon icon-exchange"></i> Runs history</Link>
          </div>
        </div>
      )) 
      }
    </div>
  );
};

const EndpointComponent = ({ endpoint }) => {
  return (

  <PageContent className="border-0">
    <ContentHeader title={`Network / Endpoints / ${endpoint?.name}`}/>
    <ContentBody>
    <Card>
      <Header title="Details" />
      <Body>
        <EndpointDetails endpoint={endpoint} />
      </Body>
    </Card>
    <Card>
      <Header title="Job configurations using this endpoint " />
        {endpoint?.status === "offline" ? 
        <div className="mb-3 alert highlight">
          <h6 className="alert-heading text-danger">
            <i className="icon icon-attention-circled"></i> Job configurations listed will not function due to endpoint status.
          </h6>
          Please check reason of status and correct local problems.
        </div> : ""
        }

      <Body>
        <div>
          <h5>As source</h5>
          <EndpointJobs jobs={endpoint?.source} />
        </div>
        <div className="mt-3">
          <h5>As target</h5>
          <EndpointJobs jobs={endpoint?.target} />
        </div>
      </Body>
    </Card>
    </ContentBody>
   </PageContent>
  );
};

export default EndpointComponent;
