import { get$ , delete$, put$} from './http';

export const loadGeneralSettings$ = () => {
    return get$('/generalsettings');
}

export const updateGeneralSettings$ = (model) => {
    return put$('/generalsettings/update', model);
}

export const updateTimezone$ = (timeZone) => {
    return put$('/generalsettings/timezone', {timeZone})
}

export const leaveOrganisation$ = (orgId) => {
    return delete$(`/generalsettings/organisations/${orgId}/leave`);
}

export const leaveAccount$ = (accountId) => {
    return delete$(`/generalsettings/accounts/${accountId}/leave`);
}

export const deleteUser$ = () => {
    return delete$('/generalsettings/users/self');
}