import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderField } from '../../common/ReduxFormHelper'
import stepOneValidation from './stepOneValidation'
import Card, { Body, Header } from '../../common/Card';
import { dateFormat } from '../../../common/dateFormatter';

const CreateJobWizzardStepOne = ({ job }) => {
    return (
        <Card>
            <Header title="General information">
                {job && job.autoEnable ?
                    <div className="alert alert-danger" role="alert">
                        <span><i className="icon icon-attention-circled"></i>This job has been auto-disabled until <strong>{dateFormat(job.autoEnable)}</strong> due to to many unsuccesful runs. Please check involved connectors and root folders.</span>
                    </div>
                    :""
                }
                <div className="alert highlight" role="alert">
                    <span>Give the job a name and a description, eg. <i>"Move xml files from A to B and then copy files från C to D"</i>.</span>
                </div>
            </Header>
            <Body>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="name"
                            type="text"
                            component={renderField}
                            label="Name"
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="description"
                            component={renderField}
                            label="Description"
                        />
                    </div>
                </div>
            </Body>
        </Card>
    )
}

export default reduxForm({
    form: 'create-job-wizzard',
    validate: stepOneValidation,
    destroyOnUnmount: false,         // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
})(CreateJobWizzardStepOne)