import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import DropDownButton, {
  DropDownButtonClose,
} from "../../common/button/DropDownButton";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";

const statusesDef = [
  { filterId: 1, description: "Running" },
  { filterId: 2, description: "Finished ok" },
  { filterId: 3, description: "Error acknowledged" },
  { filterId: 4, description: "Error" }
];

const RunsFilter = ({ onFilter, initialState = {} }) => {
  const [statuses, setStatus] = useState(
    statusesDef.map((sd) => ({
      ...sd,
      selected: (initialState.statuses || []).includes(sd.filterId),
    }))
  );
  const [withContent, setWithContent] = useState(
    initialState.withContent || false
  );
  const [fromDate, setFromDate] = useState(initialState.fromDate || "");
  const [toDate, setToDate] = useState(initialState.toDate || "");
  const [jobName, setJobName] = useState(initialState.jobName || "");
  const [fileName, setFileName] = useState(initialState.fileName || "");
  const [jobConfigId, setJobConfigId] = useState(
    initialState.jobConfigId || ""
  );
  const [isChanged, setIsChanged] = useState(false);

  const debounceFileNameChanged = useMemo(() => new Subject(), []);
  const debounceJobNameChanged = useMemo(() => new Subject(), []);


  const jobNameRef = useRef();
  const fileNameRef = useRef();

  useEffect(() => {
    debounceFileNameChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text) => {
        setFileName(text);
        setIsChanged(true);
      });

    return () => {
      debounceFileNameChanged.unsubscribe();
    };
  }, [debounceFileNameChanged]);

  useEffect(() => {
    debounceJobNameChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((text) => {
        setJobName(text);
        setIsChanged(true);
      });
    return () => {
      debounceJobNameChanged.unsubscribe();
    };
  }, [debounceJobNameChanged]);

  const getFilter = useCallback(
    () => ({
      ...(statuses.filter((s) => s.selected).length > 0 && {
        statuses: statuses.filter((s) => s.selected).map((s) => s.filterId),
      }),
      ...(withContent && { withContent }),
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
      ...(jobName && { jobName }),
      ...(fileName && { fileName }),
      ...(jobConfigId && { jobConfigId }),
    }),
    [fileName, fromDate, jobName, statuses, toDate, withContent, jobConfigId]
  );

  const selectStatus = (s) => {
    const st = statuses.map((st) =>
      st.filterId !== s.filterId ? st : { ...st, selected: !s.selected }
    );
    setStatus(st);
  };

  useEffect(() => {
    if (isChanged) {
      setIsChanged(false);
      onFilter(getFilter());
    }
  }, [
    statuses,
    withContent,
    fromDate,
    toDate,
    jobName,
    fileName,
    isChanged,
    onFilter,
    getFilter,
  ]);

  const hasFilter = useMemo(() => {
    return (
      withContent ||
      fromDate ||
      toDate ||
      (jobName && jobName.length > 2) ||
      (fileName && fileName.length > 2) ||
      jobConfigId ||
      statuses.some((s) => s.selected)
    );
  }, [statuses, withContent, fromDate, toDate, jobName, fileName, jobConfigId]);

  const clearFilters = useCallback(() => {
    setStatus(statuses.map((s) => ({ ...s, selected: false })));
    setWithContent(false);
    setFromDate("");
    setToDate("");
    setJobName("");
    setFileName("");
    setJobConfigId("");
    setIsChanged(true);

    //Workaround to clear when using debounce
    jobNameRef.current.value = "";
    fileNameRef.current.value = "";
  }, [statuses]);

  const statusItems = statuses.map((s, i) => (
    <button className="dropdown-item" type="button" key={i}>
      <label className="check mb-0">
        <input
          readOnly={true}
          type="checkbox"
          checked={s.selected}
          onChange={() => {
            selectStatus(s);
            setIsChanged(true);
          }}
        />
        {s.description}
        <span className="checkmark"></span>
      </label>
    </button>
  ));

  return (
    <DropDownButton
      title="Filter"
      className={hasFilter ? "has-filter" : ""}
      icon="icon-sliders"
    >
      <h6 className="dropdown-header d-flex">
        <span className="flex-fill">Status</span>
        <div>
          <span
            className="pointer"
            data-testid="clear"
            title="Clear all filters"
            onClick={(e) => clearFilters()}
          >
            Clear
          </span>
          <span className="pr-1 pl-1">|</span>
          <DropDownButtonClose />
        </div>
      </h6>
      {statusItems}

      <div className="dropdown-divider"></div>
      <h6 className="dropdown-header">Only with content</h6>
      <div className="dropdown-item">
        <label className="check mb-0">
          <input
            type="checkbox"
            checked={withContent}
            id="withContent"
            onChange={() => {
              setWithContent(!withContent);
              setIsChanged(true);
            }}
          />
          With content
          <span className="checkmark"></span>
        </label>
      </div>

      <div className="dropdown-divider"></div>
      <h6 className="dropdown-header">Date from</h6>
      <div className="dropdown-item">
        <input
          type="date"
          value={fromDate}
          aria-label="fromDate"
          onChange={(e) => {
            setFromDate(e.target.value);
            setIsChanged(true);
          }}
        />
      </div>
      <h6 className="dropdown-header">Date to</h6>
      <div className="dropdown-item">
        <input
          type="date"
          aria-label="toDate"
          value={toDate}
          onChange={(e) => {
            setToDate(e.target.value);
            setIsChanged(true);
          }}
        />
      </div>
      <h6 className="dropdown-header">Job name</h6>
      <div className="dropdown-item">
        <input
          type="text"
          aria-label="jobName"
          defaultValue={jobName}
          ref={jobNameRef}
          onChange={(e) => {
            debounceJobNameChanged.next(e.target.value);
          }}
        />
      </div>
      <h6 className="dropdown-header">File name</h6>
      <div className="dropdown-item">
        <input
          type="text"
          aria-label="fileName"
          defaultValue={fileName}
          ref={fileNameRef}
          onChange={(e) => {
            debounceFileNameChanged.next(e.target.value);
          }}
        />
      </div>
      {jobConfigId && (
        <div>
          <h6 className="dropdown-header">Job config id</h6>
          <div className="dropdown-item">
            <input
              type="text"
              aria-label="jobConfigId"
              disabled={true}
              defaultValue={jobConfigId}
            />
          </div>
        </div>
      )}
    </DropDownButton>
  );
};

RunsFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default RunsFilter;
