import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { showNotificationError, showNotificationSuccess } from "../../actions/notification";
import {
  loadSchedule$,
  saveSchedule$,
  deleteSchedule$,
} from "../../api/schedules";
import { useModal } from "../../components/modal/Modal";
import ScheduleComponent from "../../components/schedules/Schedule";

const ScheduleContainer = () => {
  const [schedule, setSchedule] = useState();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    loadSchedule$(match.params.scheduleId, true).subscribe((schedule) => {
      setSchedule(schedule);
    }, error => {
      dispatch(showNotificationError(error.response.message))
      history.goBack();
    });
  }, [dispatch, history, match.params.scheduleId]);

  const onSave = useCallback(
    (schedule) => {
      showModal("Confirm", {
        title: "Save schedule",
        message: `Updating this schedule will effect ${schedule.jobs.length} job/s`,
        confirmBtnText: "Save schedule",
        init: {
          next: () => {
            saveSchedule$(schedule).subscribe(
              (result) => {
                dispatch(showNotificationSuccess("Schedule has been saved"));
              },
              (error) => {
                dispatch(showNotificationError("Failed to save schedule"));
              }
            );
          },
        },
      });
    },
    [dispatch, showModal]
  );

  const onDelete = useCallback(
    (schedule) => {
      console.log("schedule", schedule);
      showModal("Confirm", {
        title: "Delete schedule",
        message: `Are you sure you want to delete this schedule?`,
        confirmBtnText: "Delete schedule",
        init: {
          next: () => {
            deleteSchedule$(schedule.id).subscribe(
              (result) => {
                dispatch(showNotificationSuccess("Schedule has been deleted"));
                history.goBack();
              },
              (error) => {
                dispatch(showNotificationError("Failed to delete schedule"));
              }
            );
          },
        },
      });
    },
    [dispatch, history, showModal]
  );

  return (
    <ScheduleComponent
      schedule={schedule}
      onSave={onSave}
      onDelete={onDelete}
    ></ScheduleComponent>
  );
};

export default ScheduleContainer;
