const SchedulesSchema = [
    {
      accessor: "name",
      header: "Name",
      type: "custom",
      render: (data) => (
        <div className="content">
          <div><i className="table-icon icon icon-calendar"></i></div>
          <div className="text">
            <span>{data.name}</span>
          </div>
        </div>
      ),
    },
    { accessor: "description", header: "Description" },
    { accessor: "isDisabled", header: "Disabled" },
    { accessor: "lastActivity", header: "Last activity", type: 'date' },
    { accessor: "nextRun", header: "Next run", type: 'date'  },
    { accessor: "totalJobs", header: "Jobs" },
  ];
  
  export default SchedulesSchema;
  