import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { dateFormat } from "../../../common/dateFormatter";
import Card, { Body, Header } from "../../common/Card";
import PeriodSummary from "../common/PeriodSummary";
import OnDemandContainer from "../../../containers/subscription/OnDemand";

const cvuConverter = (cvu) => (cvu === -1 ? "unlimited" : cvu);

const ProgressBar = ({ value, maxValue }) => {
  const width = useMemo(
    () => Math.round(((value ?? 0) / (maxValue ?? 0)) * 100),
    [maxValue, value]
  );

  if (width < 0) {
    return <></>
  }
  
  return (
    <>
      <div className="pb-2">Account usage {width}%</div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: width + "%" }}
          aria-valuenow={width}
          aria-valuemin="0"
          aria-valuemax={maxValue}
        >
          {width}%
        </div>
      </div>
    </>
  );
};

const subscriptionName = (subscription) =>
  `${subscription?.description}  - ${cvuConverter(
    subscription?.includedCVU
  )} CVU/month`;

const YourPlanInfo = ({ subscription, onChange }) => {
  return (
    <Card>
      <Header title={subscriptionName(subscription)}></Header>
      <Body>
        <div className="row">
          <div
            className="col-sm-3"
            dangerouslySetInnerHTML={{ __html: subscription?.includedHtml }}
          ></div>
          <div className="col-sm-9">
            <ProgressBar
              value={subscription?.cvuUsed}
              maxValue={subscription?.includedCVU}
            />
            <div className="pt-2 mb-5">
              {`${subscription?.cvuUsed} / ${cvuConverter(
                subscription?.includedCVU
              )} CVU used this period. Will reset on ${dateFormat(
                subscription?.currentPeriodEnd
              )}`}
            </div>
            {
              subscription?.groupId === 10 && subscription?.cvuUsed > subscription?.includedCVU &&
              <div className="alert highlight d-flex align-items-center">
                <div className="flex-grow-1">You've reached your monthly limit</div>
                <NavLink
                  to={`/admin/subscription/your-plan/change-plan/` + subscription?.id}
                >
                  Upgrade
                </NavLink>
              </div>
            }
            {
              subscription?.groupId === 20 && subscription?.cvuUsed > subscription?.includedCVU && !subscription?.enableOnDemandCVU &&
              <div className="alert alert-danger d-flex align-items-center">
                <div className="flex-grow-1">You've reached your monthly limit

                  <OnDemandContainer
                    isEnabled={subscription?.enableOnDemandCVU}
                    includedCVU={subscription?.includedCVU}
                    costOnDemandCVU={subscription?.costOnDemandCVU}
                    onChange={enableOnDemandCVU => onChange({ ...subscription, enableOnDemandCVU })}
                  />
                </div>
                <NavLink
                  className="link-light"
                  to={`/admin/subscription/your-plan/change-plan/` + subscription?.id}
                >
                  <span>Upgrade </span>
                </NavLink>
                <span>&nbsp;or enable on-Demand cvu</span>
              </div>
            }
            {
              subscription?.groupId === 20 && subscription?.cvuUsed > subscription?.includedCVU && subscription?.enableOnDemandCVU &&
              <div className="alert highlight d-flex align-items-center">
                <div className="flex-grow-1">
                  <span>You've reached your monthly limit </span>
                  <label className="form-check-label" htmlFor="enableOnDemandCVU"> CVU'S over {subscription?.includedCVU} / month will be billed
                    at {(subscription?.costOnDemandCVU * 100).toFixed(2)} EUR per hundred.
                  </label>
                </div>
                <NavLink
                  to={`/admin/subscription/your-plan/change-plan/` + subscription?.id}
                >
                  Upgrade subscription
                </NavLink>
              </div>
            }
          </div>
        </div>
        {subscription?.id !== subscription?.upcomming?.id && (
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <div className="alert highlight" role="alert">
                <strong>Upcomming change!</strong>
                <p>
                  Plan is changing from{" "}
                  <strong>{subscriptionName(subscription)}</strong> to{" "}
                  <strong>{subscriptionName(subscription?.upcomming)}</strong> (
                  {dateFormat(subscription?.currentPeriodEnd)})
                </p>
                <div className="text-right">
                  <NavLink to={`/admin/subscription/your-plan/compare-change-plan/${subscription.id}`}>cancel change</NavLink>
                </div>
              </div>
            </div>
          </div>
        )}

        <h5 className="mt-3">
          Details period:{" "}
          {dateFormat(subscription?.currentPeriodStart, "YYYY-MM-DD")} to{" "}
          {dateFormat(subscription?.currentPeriodEnd, "YYYY-MM-DD")}
        </h5>
        <PeriodSummary summary={subscription?.currentPeriodSummary} />

        {subscription?.allowAdditionalCVU && subscription?.id < 40 && (

          <div>
            <h5 className="alert-heading">What if I reach my plan limits?</h5>
            <OnDemandContainer
              isEnabled={subscription?.enableOnDemandCVU}
              includedCVU={subscription?.includedCVU}
              costOnDemandCVU={subscription?.costOnDemandCVU}
              onChange={enableOnDemandCVU => onChange({ ...subscription, enableOnDemandCVU })}
            />
          </div>
        )}

        {subscription?.id < 40 && (
          <NavLink
            className="btn btn-primary btn-lg"
            to={`/admin/subscription/your-plan/change-plan/` + subscription?.id}
          >
            Change subscription
          </NavLink>
        )}
      </Body>
    </Card>
  );
};

export default YourPlanInfo;
