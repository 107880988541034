import React, { useCallback, useEffect, useState } from "react";
import UsageInfo from "../../components/subscriptions/usage/UsageInfo";
import {
  getPeriodSummary$,
  listPeriodSummaries$,
  listPeriods$,
} from "../../api/subscriptions";
import LoaderComponent from "../../components/common/Loader";

const Usage = () => {
  const [periods, setPeriods] = useState();
  const [period, setPeriod] = useState();
  const [summary, setSummary] = useState();
  const [summaries, setSummaries] = useState();

  useEffect(() => {
    listPeriods$().subscribe((periods) => {
      setPeriods(periods);
      setPeriod(periods[0]);
    });
  }, []);

  useEffect(() => {
    if (!period) {
      return;
    }
    getPeriodSummary$(period.startPeriod, period.endPeriod).subscribe(
      (summary) => {
        setSummary(summary);
      }
    );

    listPeriodSummaries$(period.startPeriod, period.endPeriod).subscribe(
      (summaries) => {
        setSummaries(summaries);
      }
    );
  }, [period]);

  const onPeriodChange = useCallback((period) => {
    setPeriod(period);
  }, []);

  if(!periods) {
    return <LoaderComponent />
  }

  return (
    <UsageInfo
      periods={periods}
      period={period}
      onChange={onPeriodChange}
      summary={summary}
      summaries={summaries}
    />
  );
};

export default Usage;
