import * as api from "../api";

import { switchMap, catchError, map, mergeMap, mapTo } from "rxjs/operators";
import { ofType } from "redux-observable";

import { push } from "connected-react-router";

import {
  LOAD_INVITES_LIST,
  LOAD_INVITATIONTOKEN_LIST,
  CREATE_INVITATION_TOKEN,
  DELETE_INVITATION_TOKEN,
  LOAD_INVITATION_TOKEN_ACCOUNT,
  CONNECT_WITH_INVITATION_TOKEN,
  REVOKE_INVITE,
  ACCEPT_INVITE,
  DECLINE_INVITE,
  GET_INVITE,
  loadAccountFromTokenSuccess,
  loadInvitationTokens,
  loadInvitationTokenListSuccess,
  loadInvitesListSuccess,
  revokedInviteSuccessFully,
  acceptedInviteSuccessFully,
  declinedInviteSuccessFully,
  getInviteSuccess,
  ACCEPT_INVITE_SUCCESS,
  CREATE_ACCOUNT_RELATION_INVITE,
  CREATE_USER_INVITE,
  REVOKE_USER_INVITE,
  createUserInviteSuccess,
  CREATE_USER_INVITE_SUCCESS,
  DECLINE_INVITE_SUCCESS,
  RESEND_INVITE,
  loadInvitesList,
  createAccountRelationInviteSuccess,
  CREATE_ACCOUNT_RELATION_INVITE_SUCCESS,
} from "../actions/invite";
import { handleError } from "./common";
import { showNotificationSuccess } from "../actions/notification";
import { of } from "rxjs";
import {
  listPendingAccountUsers,
  revokedUserInviteSuccess,
} from "../actions/user";
import { refreshUserInfo } from "../actions/auth";
import { msalInstance } from "../api";
import { loginRequest } from "../config/azureConfig";

export const listInvitesEpic = (action$) => {
  return action$.pipe(
    ofType(LOAD_INVITES_LIST),
    switchMap((action) =>
      api.listAllInvites$().pipe(
        map((response) => {
          return loadInvitesListSuccess(
            response.sentInvites,
            response.recivedInvites
          );
        }),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const listInvitationTokensEpic = (action$) => {
  return action$.pipe(
    ofType(LOAD_INVITATIONTOKEN_LIST),
    switchMap((action) =>
      api.listAllInvitationTokens$().pipe(
        map((response) => loadInvitationTokenListSuccess(response.tokens)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const createInvitationTokenEpic = (action$) => {
  return action$.pipe(
    ofType(CREATE_INVITATION_TOKEN),
    switchMap((action) =>
      api.generateInvitationToken$().pipe(
        map((response) => loadInvitationTokens()),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const deleteInitationTokenEpic = (action$) => {
  return action$.pipe(
    ofType(DELETE_INVITATION_TOKEN),
    switchMap((action) =>
      api.deleteInvitationToken$(action.tokenId).pipe(
        map((response) => loadInvitationTokens()),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const loadAccountFromInvitationToken$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_INVITATION_TOKEN_ACCOUNT),
    switchMap((action) =>
      api.viewInvitationTokenAccount$(action.token).pipe(
        map((response) => loadAccountFromTokenSuccess(response.account)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const connectAccountWithInvitationToken$ = (action$) => {
  return action$.pipe(
    ofType(CONNECT_WITH_INVITATION_TOKEN),
    switchMap((action) =>
      api.connectThroughInvitationToken$(action.token).pipe(
        map((action) => push(`/admin/connections`)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const createAccountRelationInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(CREATE_ACCOUNT_RELATION_INVITE),
    switchMap((action) => {
      return api
        .createAccountRelationInvite$(action.email, action.message)
        .pipe(
          map((action) => createAccountRelationInviteSuccess()),
          catchError(handleError(action$, action.type))
        );
    })
  );
};

export const createAccountRelationInviteSuccessEpic$ = (action$) => {
  return action$.pipe(
    ofType(CREATE_ACCOUNT_RELATION_INVITE_SUCCESS),
    map((action) =>
      showNotificationSuccess("Your connection request has been sent!")
    )
  );
};

export const createUserInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(CREATE_USER_INVITE),
    switchMap((action) => {
      return api.createUserInvite$(action.email, action.inviteInfo).pipe(
        map((action) => createUserInviteSuccess(action.accountId)),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const createUserInviteSuccessEpic$ = (action$) => {
  return action$.pipe(
    ofType(CREATE_USER_INVITE_SUCCESS),
    mergeMap((action) =>
      of(
        listPendingAccountUsers(action.accountId),
        showNotificationSuccess("User has been invited")
      )
    )
  );
};

export const revokeInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(REVOKE_INVITE),
    switchMap((action) => {
      return api.revokeInvite$(action.inviteId).pipe(
        map((response) => revokedInviteSuccessFully()),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const revokeUserInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(REVOKE_USER_INVITE),
    switchMap((action) => {
      return api.revokeInvite$(action.inviteId).pipe(
        map((response) => revokedUserInviteSuccess()),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const acceptInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(ACCEPT_INVITE),
    switchMap((action) => {
      return api
        .acceptInvite$(action.verificationToken, action.acceptInfo)
        .pipe(
          map((response) => acceptedInviteSuccessFully(action.acceptInfo)),
          catchError(handleError(action$, action.type))
        );
    })
  );
};

const handleInviteSuccess = (acceptInfo) => {
  switch (acceptInfo.inviteType) {
    case 1:
    case 2:
    case 4:
      return of(
        showNotificationSuccess("Invitation has been accepted"),
        push("/dashboard")
      );
    case 3:
      return of(
        showNotificationSuccess("Invitation has been accepted"),
        push("/dashboard"),
        refreshUserInfo()
      );
    case 5:
      return of(push("/signup/success", acceptInfo));
    case 6:
      return of(push("/sso", acceptInfo));
    default:
      return msalInstance.loginRedirect(loginRequest);
  }
};

export const acceptInviteSuccessEpic$ = (action$) => {
  return action$.pipe(
    ofType(ACCEPT_INVITE_SUCCESS),
    mergeMap((action) => handleInviteSuccess(action.acceptInfo))
  );
};

export const declineInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(DECLINE_INVITE),
    switchMap((action) => {
      return api.declineInvite$(action.token).pipe(
        map((response) => declinedInviteSuccessFully()),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const resendInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(RESEND_INVITE),
    switchMap((action) => {
      return api.resendInvite$(action.token).pipe(
        mapTo((response) =>
          of(
            loadInvitesList(),
            showNotificationSuccess("Invitation has been resent")
          )
        ),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const declineInviteSuccessEpic$ = (action$) => {
  return action$.pipe(
    ofType(DECLINE_INVITE_SUCCESS),
    mergeMap((action) => {
      return of(
        showNotificationSuccess("Invitation has been declined"),
        push("/dashboard")
      );
    })
  );
};

export const getInviteEpic$ = (action$) => {
  return action$.pipe(
    ofType(GET_INVITE),
    switchMap((action) => {
      return api.getInvite$(action.verificationToken).pipe(
        map((response) => getInviteSuccess(response)),
        catchError(handleError(action$, action.type))
      );
    })
  );
};
