import React, { useEffect, useState } from "react";
import { get$ } from "../../api/subscriptions";
import LoaderComponent from "../../components/common/Loader";
import CancelSubscription from "../../components/subscriptions/your-plan/CancelSubscription";
import YourPlanInfo from "../../components/subscriptions/your-plan/YourPlanInfo";

const YourPlan = () => {
  const [loading, setLoading] = useState();
  const [subscription, setSubscription] = useState();
  useEffect(() => {
    setLoading(true);
    get$().subscribe(
      (result) => {
        setSubscription(result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <YourPlanInfo loading={loading} subscription={subscription} onChange={setSubscription} />
      {
        subscription?.id < 40 &&
        <CancelSubscription />
      }
    </div>
  );
};

export default YourPlan;
