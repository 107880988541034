import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import "./Login.css";
import { required, email } from "../common/ReduxFormFieldValidation";
import LoginCard, { Header, Body } from "../common/LoginCard";
import CbLogo from "../../images/logo_white.png";
import SsoLogin from "../sso/SsoLogin";

const renderField = ({
  input,
  placeholder,
  type,
  name,
  autoComplete,
  dataTestId,
  meta: { touched, error, warning },
  tooltip,
}) => (
  <div className="form-group">
    <input
      className="form-control form-control-lg border-input"
      {...input}
      placeholder={placeholder}
      type={type}
      autoComplete={autoComplete}
      data-testid={dataTestId}
    />
    {touched &&
      ((error && (
        <em htmlFor={name} className="invalid">
          {tooltip}
        </em>
      )) ||
        (warning && <span>{warning}</span>))}
  </div>
);

const LoginComponent = ({ handleSubmit, errors, submitting }) => {
  const showError =
    errors && errors.length
      ? errors.map((e, i) => (
          <div
            key={i}
            className="alert alert-danger alert-dismissible fade show"
          >
            <span>{e}</span>
            <button
              type="button"
              className="close p-2"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        ))
      : "";

  return (
    <div>
      <div className="logo_login_placeholder">
        <img
          src={CbLogo}
          height="30px"
          alt="Cloudbuz Manager"
          className="logo_login"
        />
      </div>
      <LoginCard>
        <Header title="Please login" />
        <Body>
          <form data-testid="form" onSubmit={handleSubmit} className="content">
            <fieldset>
              <Field
                name="email"
                component={renderField}
                type="email"
                placeholder="Email"
                icon="fa-user"
                validate={[required, email]}
                tooltip="Please enter your work email address"
                autoComplete="username"
                dataTestId="Email"
              />

              <Field
                name="password"
                id="password"
                component={renderField}
                type="password"
                placeholder="Password"
                validate={required}
                icon="fa-lock"
                tooltip="Please enter your password"
                autoComplete="current-password"
              />

              {showError}

              <button
                type="submit"
                className="btn btn-primary btn-lg btn-block"
                disabled={submitting}
              >
                Login
              </button>
              <div className="text-center mb-3">or</div>
              <SsoLogin />
            </fieldset>
            <footer>
              <div className="text-center">
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <Link to="forgotpassword">Forgot password?</Link>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p>
                      Don't have an account? <Link to="signup">Sign up</Link>
                    </p>
                  </div>
                </div>
              </div>
            </footer>
          </form>
        </Body>
      </LoginCard>
      <p className="greetings">
        Made with <i className="icon icon-heart love"></i> by{" "}
        <a className="text-white" href="https://www.zavanto.se">
          Zavanto
        </a>
      </p>
    </div>
  );
};

export default reduxForm({
  form: "login", // a unique identifier for this form
})(LoginComponent);
