
import { LOCALSTORAGE_KEY_PERSISTED_STATE } from '../actions/localStorage'

const nameClaim = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
const emailClaim = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
const accountIdClaim = "http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor"
const localStorageKey = 'cloudbuz-auth'

const validModel = (model) => {
    return model && model.accessToken && model.validTo;
}

export const save = (model) => {
    if (!validModel(model)) {
        return null;
    }
    localStorage.setItem(localStorageKey, JSON.stringify(model));
};

export const get = () => {
    try {
        return JSON.parse(localStorage.getItem(localStorageKey)) || {};
    } catch (e) {
        return null;
    }
}

export const isAuthenticated = (model) => {
    if (!validModel(model)) {
        return false;
    }
    var date = new Date(model.validTo);
    return model.accessToken && date > new Date()
}

export const getUser = (model) => {
    if (!validModel(model)) {
        return null;
    }

    var base64Url = model.accessToken.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    let claims = JSON.parse(decodeURIComponent(escape(window.atob(base64))));

    return {
        name: claims[nameClaim],
        role: claims[roleClaim],
        email: claims[emailClaim],
        accountId: claims[accountIdClaim],
        gravatar: model.gravatarHash
    }
}

export const logout = () => {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem(localStorageKeyUserInfo);
    localStorage.removeItem(LOCALSTORAGE_KEY_PERSISTED_STATE);
    return Promise.resolve();
}


//Maybe should be moved to separate file, maybe should be merged with userObject?
const localStorageKeyUserInfo = 'cloudbuz-auth-userinfo'

export const saveUserInfo = (userInfo) => {
    localStorage.setItem(localStorageKeyUserInfo, JSON.stringify(userInfo));
    return getUserInfo();
};

export const getUserInfo = () => {
    try {
        return JSON.parse(localStorage.getItem(localStorageKeyUserInfo)) || {};
    } catch (e) {
        return {};
    }
}
