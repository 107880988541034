import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import "../shared/colors.css";
import "../shared/layout.css";
import "../shared/bootstrap.override.css";

import DashboardPage from "../pages/Dashboard";
import LoginPage from "../pages/Login";
import ForgotPasswordPage from "../pages/ForgotPassword";
import CreateForgotPasswordPage from "../pages/CreateForgotPassword";

import SharePage from "../pages/Share";
import ExplorePage from "../pages/Explore";

import AccountPage from "../pages/Account";
import ExtendNetworkPage from "../pages/ExtendNetwork";
import ExtendNetworkConnectByTokenPage from "../pages/ExtendNetworkConnectByToken";
import ExtendNetworkCreateInvitePage from "../pages/ExtendNetworkCreateInvite";

import HeaderContainer from "../containers/Header";
import LeftSideContainer from "../containers/LeftSide";
import NotificationContainer from "../containers/Notification";

import AccountCreateContainer from "../containers/AccountCreate";

import RequestNewInstallmentPage from "../pages/RequestNewInstallment";

import ConnectionsPage from "../pages/Connections";
import ConnectionsDetailsPage from "../pages/ConnectionsDetails";
import ConnectorsPage from "../pages/Connectors";
import ConnectorDetailsPage from "../pages/ConnectorDetails";

import AccountSettingsPage from "../pages/AccountSettings";

import InviteAccept from "../containers/InviteAccept";

import JobsPage from "../pages/Jobs";
import JobsCreatePage from "../pages/JobsCreate";

import SignUpPage from "../pages/SignUp";

import RunsPage from "../pages/user/jobs/RunsPage";
import RunDetailPage from "../pages/user/jobs/RunDetailPage";
import EventDetailPage from "../pages/user/jobs/EventDetailPage";
import FileDetailPage from "../pages/user/jobs/FileDetailPage";
import UserJobsPage from "../pages/user/jobs/JobsPage";
import ExtendNetworkTokensPage from "../pages/ExtenNetworkTokens";
import MySettingsPage from "../pages/MySettings";
import SignUpSuccessPage from "../pages/SignUpSuccess";
import SignUpCreatedPage from "../pages/SignUpCreated";

import { ViewPortProvider } from "../contexts/ViewPortContext";
import {
  ModalProvider,
  ModalContainer,
  useModal,
} from "../components/modal/Modal";
import SearchContainer from "../containers/right-modal/Search";

import { NotificationsContainer } from "../containers/right-modal/Notifications";
import { ConfirmDialog } from "../components/common/dialogs/ConfirmDialog";
import SubscriptionPage from "../pages/SubscriptionPage";
import DownloadPage from "../pages/Download";
import AuthorizationRoute from "../containers/AuthorizationRoute";
import { MsalProvider } from "@azure/msal-react";
import SsoPage from "../pages/Sso";
import { msalInstance } from "../api/sso";

import TermsPage from "../pages/Terms";
import PrivacyPage from "../pages/Privacy";
import EndpointsPage from "../pages/Endpoints";
import EndpointPage from "../pages/Endpoint";
import SchedulesPage from "../pages/Schedules";
import SchedulePage from "../pages/Schedule";
import ReportsPage from "../pages/Reports";
import NewCreditCardContainer from "../containers/subscription/credit-card/NewCreditCard";

const Contexts = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <ModalProvider>
        <ViewPortProvider>{children}</ViewPortProvider>
      </ModalProvider>
    </MsalProvider>
  );
};

const RedirectToLogin = () => {
  return <Redirect to="/login" />;
};

const App = () => {
  const [modals] = useState({
    Search: { modal: SearchContainer },
    Notifications: { modal: NotificationsContainer },
    Confirm: { modal: ConfirmDialog },
    NewCreditCard: { modal: NewCreditCardContainer, type: "payments" },
  });

  const { addModal } = useModal();

  useEffect(() => {
    addModal(modals);
  }, [addModal, modals]);

  return (
    <div className="d-flex flex-fill">
      <LeftSideContainer />
      <div className="d-flex flex-column flex-fill">
        <HeaderContainer />
        <div
          className="flex-fill w-100 position-relative"
          id="main-scene"
        >
          <Switch>
            <Route exact path="/" component={DashboardPage} />
            <AuthorizationRoute
              exact
              path="/dashboard"
              component={DashboardPage}
            />

            <Route path="/login/:email" component={LoginPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/sso" component={SsoPage} />
            <Route path="/download/connector" component={DownloadPage} />
            <Route
              path="/forgotpassword/:token"
              component={ForgotPasswordPage}
            />
            <Route
              path="/forgotpassword"
              component={CreateForgotPasswordPage}
            />

            <Route path="/signup/created" component={SignUpCreatedPage} />
            <Route path="/signup/success" component={SignUpSuccessPage} />
            <Route path="/signup" component={SignUpPage} />

            <Route path="/terms" component={TermsPage} />
            <Route path="/privacy" component={PrivacyPage} />

            <AuthorizationRoute
              path="/admin/account"
              component={AccountPage}
              type="Account page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/accounts/new"
              component={AccountCreateContainer}
            />
            <AuthorizationRoute
              path="/admin/extendnetwork/invites/createinvite"
              component={ExtendNetworkCreateInvitePage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/extendnetwork/tokens/connectbytoken"
              component={ExtendNetworkConnectByTokenPage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/extendnetwork/tokens"
              component={ExtendNetworkTokensPage}
              type="Network extend menu"
              privilege="read"
              exact
            />
            <AuthorizationRoute
              path="/admin/extendnetwork/invites"
              component={ExtendNetworkPage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/connections/details/:accountKey"
              component={ConnectionsDetailsPage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/connections"
              component={ConnectionsPage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/settings/general"
              component={AccountSettingsPage}
              type="Account page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/settings/users"
              component={AccountSettingsPage}
              type="Account page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/settings/organisations"
              component={AccountSettingsPage}
              type="Account page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/settings/organisations/new"
              component={AccountSettingsPage}
              type="Account page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/settings/organisations/edit/:organisationId"
              component={AccountSettingsPage}
              type="Account page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/settings/subscription"
              component={AccountSettingsPage}
              type="Account page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/connectors/details/:clientId"
              component={ConnectorDetailsPage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/connectors/requestnewinstallment"
              component={RequestNewInstallmentPage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/connectors"
              component={ConnectorsPage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/admin/endpoints"
              component={EndpointsPage}
              type="Network extend menu"
              privilege="read"
              exact={true}
            />
            <AuthorizationRoute
              path="/admin/endpoints/:endpointId"
              component={EndpointPage}
              type="Network extend menu"
              privilege="read"
              exact={true}
            />
            <AuthorizationRoute
              path="/admin/endpoints/:endpointId/:shareId"
              component={EndpointPage}
              type="Network extend menu"
              privilege="read"
              exact={true}
            />
            <AuthorizationRoute
              path="/admin/jobs/edit/:jobId"
              component={JobsCreatePage}
              type="Jobs settings page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/jobs/new"
              component={JobsCreatePage}
              type="Jobs settings page"
              privilege="read_write"
            />
            <AuthorizationRoute
              path="/admin/jobs"
              type="Jobs settings page"
              privilege="read"
              component={JobsPage}
            />

            <AuthorizationRoute
              path="/admin/schedules"
              type="Jobs settings page"
              privilege="read"
              component={SchedulesPage}
              exact={true}
            />

            <AuthorizationRoute
              path="/admin/schedules/:scheduleId"
              type="Jobs settings page"
              privilege="read"
              component={SchedulePage}
              exact={true}
            />

            <AuthorizationRoute
              path="/settings/my-settings/general"
              component={MySettingsPage}
            />
            <AuthorizationRoute
              path="/settings/my-settings/authentication-options"
              component={MySettingsPage}
            />
            <AuthorizationRoute
              path="/settings/my-settings/notification-settings"
              component={MySettingsPage}
            />

            <Route
              path="/invite/accept/:verificationToken"
              component={InviteAccept}
            />

            <AuthorizationRoute
              path="/jobs/runs/:runId/events/:eventId/files/:fileId/:fileSubId"
              component={FileDetailPage}
              type="Jobs page"
              privilege="read"
            />
            <AuthorizationRoute
              path="/jobs/runs/:runId/events/:eventId"
              component={EventDetailPage}
              type="Jobs page"
              privilege="read"
            />
            <AuthorizationRoute
              path="/jobs/runs/:runId"
              component={RunDetailPage}
              type="Jobs page"
              privilege="read"
            />
            <AuthorizationRoute
              path="/jobs/runs"
              type="Jobs page"
              privilege="read"
              component={RunsPage}
            />
            <AuthorizationRoute
              path="/jobs/all"
              type="Jobs page"
              privilege="read"
              component={UserJobsPage}
            />

            <Route path="/admin/subscription" component={SubscriptionPage} />

            <AuthorizationRoute
              path="/history"
              type="Jobs page"
              privilege="read"
              component={ReportsPage}
            />

            <AuthorizationRoute
              path="/explore/:id/:type/share"
              component={SharePage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/explore/:id/:type/:shareId"
              component={ExplorePage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/explore/:id/:type"
              component={ExplorePage}
              type="Network extend menu"
              privilege="read"
            />
            <AuthorizationRoute
              path="/explore"
              component={ExplorePage}
              type="Network extend menu"
              privilege="read"
            />
            <Route path="*" exact={true} component={RedirectToLogin} />
          </Switch>
        </div>
      </div>
      <NotificationContainer className="no-print" />
      <ModalContainer />
    </div>
  );
};

const Root = ({ store, history }) => {
  return (
    <Contexts>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </Contexts>
  );
};

Root.propTypes = {
  store: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.object.isRequired,
  ]).isRequired,
};

export default Root;
