import React from 'react'
import { reduxForm, Field } from 'redux-form'
import {
    JobEventTypes,
    FileExsistsCriterias,
    ScheduleTimeTypes,
    ScheduleDateTypes,
    JobNotificationTypes,
    JobPermissions,
    FileSizeCriterias,
} from '../../../selectors/Job'
import { PrintButton } from '../../common/PrintButton';
import Card, { Body, Header } from '../../common/Card';
import { renderCheckbox } from '../../common/ReduxFormHelper';
import { dateFormat } from '../../../common/dateFormatter';
import { createBinaryString } from '../../../common/decimalToBinary';


const TriggerTypes = [
    { id: 1, name: 'Schedule' },
    { id: 2, name: 'Trigger' },
    { id: 3, name: 'Schedule' }
]

const getFileSizeCriteriaName = (id) => {
    if (!id) {
        return
    }
    const criteria = FileSizeCriterias.find(f => f.id === parseInt(id, 10));

    if(!criteria) {
        return "";
    }

    return criteria.name;
}

const GeneralInfo = ({ name, description }) => (
    <React.Fragment>
        <h6 className="wiz-form-header">General</h6>
        <div className="detail-table">
            <div className="row">
                <div className="col-sm-4">Job name</div>
                <div className="col-sm-8">{name}</div>
            </div>
            <div className="row">
                <div className="col-sm-4">Job description</div>
                <div className="col-sm-8">{description ? description : "n/a"}</div>
            </div>
        </div>
    </React.Fragment>
)

const WeekDays = ({binaryString}) => {
    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return(
        <React.Fragment>
            {weekdays.map((value, index) => {
                return (
                    parseInt(binaryString.charAt(binaryString.length - index-1),10) === 1 
                    ? 
                    <span className="badge badge-primary mr-1" key={index}>{value}</span> 
                    : ""
                )
            })}
        </React.Fragment>
    )
}

const Events = ({ events }) => (
    <React.Fragment>
        <h6 className="wiz-form-header mb-0">Events</h6>

            <div className="row">
                {events && events.map((event, index) => <Event event={event} index={index} key={index} length={events.length} />)}
            </div>

    </React.Fragment>
)

/*
        {index !== 0 && 
        <div className="row">
            <div className="col-md-12 bg-white"><strong></strong></div>
        </div>}

*/

const Event = ({ event, index, length }) => (
    <React.Fragment> 
        <div className={length > 1 ? "col-md-6" : "col-md-12"}>
        <div className="detail-table border mt-3">
        <div className="row">
            <div className="col-md-12 bg-light"><span>EVENT {index + 1}</span></div>
        </div>
        <div className="row">
            <div className="col-md-12 bg-white"><strong>General info</strong></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Description </div>
            <div className="col-sm-8">{event.description}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Type </div>
            <div className="col-sm-8">{JobEventTypes.filter(s => s.id.toString() === event.eventType.toString())[0].name}</div>
        </div>
        <div className="row">
            <div className="col-md-12 bg-white"><strong>Source endpoint</strong></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Source path</div>
            <div className="col-sm-8">{event.source.path}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Source connector</div>
            <div className="col-sm-8">{event.source.clientName}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Source organisation</div>
            <div className="col-sm-8"> {event.source.organisationName}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Source account</div>
            <div className="col-sm-8">{event.source.accountName}</div>
        </div>
        <div className="row">
            <div className="col-md-12 bg-white"><strong>Target endpoint</strong></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Target path</div>
            <div className="col-sm-8"> {event.destination && parseInt(event.eventType, 10) !== 3 ? event.destination.path : "n/a"}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Target connector</div>
            <div className="col-sm-8"> {event.destination && parseInt(event.eventType, 10) !== 3 ? event.destination.clientName : "n/a"}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Target organisation</div>
            <div className="col-sm-8"> {event.destination && parseInt(event.eventType, 10) !== 3 ? event.destination.organisationName : "n/a"}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Target account</div>
            <div className="col-sm-8"> {event.destination && parseInt(event.eventType, 10) !== 3 ? event.destination.accountName : "n/a"}</div>
        </div>

        {/*event.destination && parseInt(event.eventType, 10) !== 3 ?
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-4">Target path</div>
                    <div className="col-sm-8"> {event.destination.path}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Target connector</div>
                    <div className="col-sm-8"> {event.destination.clientName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Target organisation</div>
                    <div className="col-sm-8"> {event.destination.organisationName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Target account</div>
                    <div className="col-sm-8"> {event.destination.accountName}</div>
                </div>
            </React.Fragment>
            :""
        */}
        <div className="row">
            <div className="col-md-12 bg-white"><strong>Selection criterias</strong></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Select files from previous event </div>
            <div className="col-sm-8"><span className={event.source.filesFromPreviousEvent ? "badge badge-success" : "badge badge-danger"}>{event.source.filesFromPreviousEvent ? event.source.filesFromPreviousEvent.toString() : "false"}</span></div>
        </div>

        <div className="row">
            <div className="col-sm-4">Filename pattern include </div>
            <div className="col-sm-8">{event.source.filesFromPreviousEvent ? "n/a" : event.source.fileNamePattern}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Filename pattern exclude </div>
            <div className="col-sm-8">{event.source.filesFromPreviousEvent ? "n/a" : event.source.fileNamePatternExclude ? event.source.fileNamePatternExclude : <span className="badge badge-secondary">not set</span>}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Minimum file age (in minutes) </div>
            <div className="col-sm-8">{event.source.filesFromPreviousEvent ? "n/a" : event.source.minMinutesModified}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">File size (bytes)</div>
            <div className="col-sm-8">
                {event.source.filesFromPreviousEvent ? "n/a" : event.source.fileSizeCriteria ?  getFileSizeCriteriaName(event.source.fileSizeCriteria) + " " + event.source.fileSizeCriteriaValue + " bytes"  : <span className="badge badge-secondary">not set</span> }
            </div>
        </div>        
        
        {/*event.source.filesFromPreviousEvent.toString() === "false" ?
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-4">Filename pattern include </div>
                    <div className="col-sm-8">{event.source.fileNamePattern}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Filename pattern exclude </div>
                    <div className="col-sm-8">{event.source.fileNamePatternExclude ? event.source.fileNamePatternExclude : "n/a"}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Minimum file age (in minutes) </div>
                    <div className="col-sm-8">{event.source.minMinutesModified}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">File size (bytes)</div>
                    <div className="col-sm-8">
                        {event.source.fileSizeCriteria ?  getFileSizeCriteriaName(event.source.fileSizeCriteria) + " " + event.source.fileSizeCriteriaValue + " bytes"  : "n/a" }
                    </div>
                </div>
            </React.Fragment>
        : ""
    */}
        <div className="row">
            <div className="col-md-12 bg-white"><strong>Source options</strong></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Force source sync before execution</div>
            <div className="col-sm-8"><span className={event.source.forceSync ? "badge badge-success" : "badge badge-danger"}>{event.source.forceSync ? event.source.forceSync.toString() : "false"}</span></div>
        </div>        
        <div className="row">
            <div className="col-sm-4">Include subdirectories</div>
            <div className="col-sm-8"><span className={event.source.includeSubFolders ? "badge badge-success" : "badge badge-danger"}>{event.source.includeSubFolders.toString()}</span></div>
        </div>

        <div className="row">
            <div className="col-md-12 bg-white"><strong>Target options</strong></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Force target sync before execution</div>
        <div className="col-sm-8">{parseInt(event.eventType, 10) === 3 ? "n/a" : <span className={event.destination.forceSync ? "badge badge-success" : "badge badge-danger"}>{event.destination.forceSync ? event.destination.forceSync.toString() : "false"}</span>}</div>
        </div>                     
        <div className="row">
            <div className="col-sm-4">Target is archive</div>
            <div className="col-sm-8"> {parseInt(event.eventType, 10) === 3 ? "n/a" : <span className={event.destination.destinationisArchive ? "badge badge-success" : "badge badge-danger"}>{event.destination.destinationisArchive ? event.destination.destinationisArchive.toString() : "false"}</span>}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Target filename</div>
            <div className="col-sm-8"> {parseInt(event.eventType, 10) === 3 ? "n/a" : event.destination.targetFileName ? event.destination.targetFileName : <span className="badge badge-secondary">not set</span>}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">If fileexists on target</div>
            <div className="col-sm-8"> {parseInt(event.eventType, 10) === 3 ? "n/a" : FileExsistsCriterias.filter(s => s.id.toString() === event.destination.fileExsistsCriteria.toString())[0].name}</div>
        </div>                


        {/*event.destination && parseInt(event.eventType, 10) !== 3 ?
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 bg-white"><strong>Target options</strong></div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Force target sync before execution</div>
                    <div className="col-sm-8">{event.destination.forceSync ? event.destination.forceSync.toString() : "false"}</div>
                </div>                     
                <div className="row">
                    <div className="col-sm-4">Target is archive</div>
                    <div className="col-sm-8"> {event.destination.destinationisArchive ? event.destination.destinationisArchive.toString() : 'false'}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">Target filename</div>
                    <div className="col-sm-8"> {event.destination.targetFileName ? event.destination.targetFileName : "n/a"}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">If fileexists on target</div>
                    <div className="col-sm-8"> {FileExsistsCriterias.filter(s => s.id.toString() === event.destination.fileExsistsCriteria.toString())[0].name}</div>
                </div>        
            </React.Fragment> :""
        */}
        
        <div className="row">
            <div className="col-md-12 bg-white"><strong>Process options</strong></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Throw error if source is empty</div>
            <div className="col-sm-8"><span className={event.source.throwErrorIfEmpty ? "badge badge-success" : "badge badge-danger"}>{event.source.throwErrorIfEmpty.toString()}</span></div>
        </div>
        <div className="row">
            <div className="col-sm-4">Do not encrypt file data</div>
            <div className="col-sm-8">{parseInt(event.eventType, 10) === 3 ? "n/a" : <span className={event.options.unEncrypt ? "badge badge-success" : "badge badge-danger"}>{event.options.unEncrypt.toString()}</span>}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Max bundling</div>
            <div className="col-sm-8">{event.options.maxBundling ? event.options.maxBundling.toString() : ""}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Max threads</div>
            <div className="col-sm-8">{event.options.maxThreads ? event.options.maxThreads.toString() : ""}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">On event success</div>
            <div className="col-sm-8">{parseInt(event.onSuccess, 10) === 10 ? "GoToNextEventInChain" : parseInt(event.onSuccess, 10) === 20 ? "StopAndReportError" : "StopAndReportSuccess"}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">On event error</div>
            <div className="col-sm-8">{parseInt(event.onFailure, 10) === 10 ? "GoToNextEventInChain" : parseInt(event.onFailure, 10) === 20 ? "StopAndReportError" : "StopAndReportSuccess"}</div>
        </div>
        <div className="row">
            <div className="col-sm-4">Accept max no. of errors to report success</div>
            <div className="col-sm-8">{event.maxNumberOfError.toString()}</div>
        </div>
        <div className="row">
            <div className="col-md-12 bg-white"><strong>Expected traffic</strong></div>
        </div>
        {event.options.sendAlarmIfTotalAmountDataIsLessThan.isActive || 
        event.options.sendAlarmIfTotalAmountDataIsGreaterThan.isActive || 
        event.options.sendAlarmIfTotalNumberFilesIsLessThan.isActive || 
        event.options.sendAlarmIfTotalNumberFilesIsGreaterThan.isActive ? 

        <React.Fragment>
            <div className="row">
                <div className="col-sm-4">Only check weekdays</div>
                <div className="col-sm-8"><span className={event.options.limitCheckOnlyOnWeekdays ? "badge badge-success" : "badge badge-danger"}>{event.options.limitCheckOnlyOnWeekdays.toString()}</span></div>
            </div>
            {event.options.sendAlarmIfTotalAmountDataIsLessThan.isActive ? 
                <div className="row">
                    <div className="col-sm-12">
                        Send alarm if the total amount of data is less than&nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsLessThan.data}&nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsLessThan.size} the past &nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsLessThan.pastTime} &nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsLessThan.time}
                    </div>
                </div>        
            : ""
            }
            {event.options.sendAlarmIfTotalAmountDataIsGreaterThan.isActive ? 
                <div className="row">
                    <div className="col-sm-12">
                        Send alarm if the total amount of data is greater than&nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsGreaterThan.data}&nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsGreaterThan.size} the past &nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsGreaterThan.pastTime} &nbsp;
                        {event.options.sendAlarmIfTotalAmountDataIsGreaterThan.time}
                    </div>
                </div>        
            : ""
            }
            {event.options.sendAlarmIfTotalNumberFilesIsLessThan.isActive ? 
                <div className="row">
                    <div className="col-sm-12">
                        Send alarm if the total number of files is less than&nbsp;
                        {event.options.sendAlarmIfTotalNumberFilesIsLessThan.data}&nbsp;files the past &nbsp;
                        {event.options.sendAlarmIfTotalNumberFilesIsLessThan.pastTime} &nbsp;
                        {event.options.sendAlarmIfTotalNumberFilesIsLessThan.time}
                    </div>
                </div>        
            : ""
            }        
            {event.options.sendAlarmIfTotalNumberFilesIsGreaterThan.isActive ? 
                <div className="row">
                    <div className="col-sm-12">
                        Send alarm if the total number of files is more than&nbsp;
                        {event.options.sendAlarmIfTotalNumberFilesIsGreaterThan.data}&nbsp;files the past &nbsp;
                        {event.options.sendAlarmIfTotalNumberFilesIsGreaterThan.pastTime} &nbsp;
                        {event.options.sendAlarmIfTotalNumberFilesIsGreaterThan.time}
                    </div>
                </div>        
            : ""
            }                       
        </React.Fragment>
        : 
        <div className="row">
            <div className="col-sm-12">No alarm limits set</div>
        </div>
        }
    </div>
    </div>
    </React.Fragment>

)

const Frequency = ({ triggerType, trigger, schedule }) => (
    <React.Fragment>
        <h6 className="wiz-form-header">Frequency</h6>
        <div className="detail-table">
            <div className="row">
                <div className="col-sm-4">
                    Type
                </div>
                <div className="col-sm-8">
                    {TriggerTypes.filter(s => s.id.toString() === triggerType.toString())[0].name}
                </div>
            </div>
            {triggerType === '2' ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-sm-4">
                            Trigger delay
                        </div>
                        <div className="col-sm-8">
                            {trigger.triggerDelay}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Delete trigger after run
                        </div>
                        <div className="col-sm-8">
                            {trigger.delete ? "Yes" : "No"}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Filename pattern
                        </div>
                        <div className="col-sm-8">
                            {trigger.fileNamePattern}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Source path
                        </div>
                        <div className="col-sm-8">
                            {trigger.source}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Source connector
                        </div>
                        <div className="col-sm-8">
                            {trigger.connector}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Source account
                        </div>
                        <div className="col-sm-8">
                            {trigger.account}
                        </div>
                    </div>
                </React.Fragment>
            : 
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12 bg-white"><strong>General</strong></div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-4">
                            Name
                        </div>
                        <div className="col-sm-8">
                            {schedule.name}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">Description</div>
                        <div className="col-sm-8"> {schedule.description}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">UTC</div>
                        <div className="col-sm-8">{schedule.timeZoneId}</div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 bg-white"><strong>Duration & frequency</strong></div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">Type</div>
                        <div className="col-sm-8">{ScheduleDateTypes.filter(s => s.id.toString() === schedule.dateType.toString())[0].name}</div>
                    </div>
                    {parseInt(schedule.dateType,10) !== 1 ? 
                        <div className="row">
                            <div className="col-sm-4">{parseInt(schedule.dateType,10) === 102 ? "Weekdays" : "Value of X"}</div>
                            <div className="col-sm-8">{parseInt(schedule.dateType,10) === 102 ? <WeekDays binaryString={createBinaryString(schedule.dateValue)}></WeekDays> : schedule.dateValue}</div>
                        </div>
                        : ""
                    }
                    <div className="row">
                        <div className="col-sm-4">Start</div>
                        <div className="col-sm-8">{dateFormat(schedule.dateStart, "YYYY-MM-DD")}</div>
                    </div>
                    {parseInt(schedule.dateType,10) !== 1 ?
                        <div className="row">
                            <div className="col-sm-4">Stop</div>
                            <div className="col-sm-8">{schedule.dateStop ? dateFormat(schedule.dateStop) : "n/a" }</div>
                        </div>
                        :""
                    }
                    <div className="row">
                        <div className="col-md-12 bg-white"><strong>Daily frequency</strong></div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">Type</div>
                        <div className="col-sm-8">{ScheduleTimeTypes.filter(s => s.id.toString() === schedule.timeType.toString())[0].name}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">Value of X</div> 
                        {
                        //fixa denna
                        }
                        <div className="col-sm-8">{schedule.timeValue}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">Start</div>
                        <div className="col-sm-8">{schedule.timeStart}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">Stop</div>
                        <div className="col-sm-8">{schedule.timeStop ? schedule.timeStop : "n/a"}</div>
                    </div>
                </React.Fragment>
                }
        </div>
    </React.Fragment>
)

const ShareAndNotifications = ({ notificationUsers, notificationAccounts, notificationEmails }) => (
    <React.Fragment>
        <h6 className="wiz-form-header">Job share & notifications</h6>
        <div className="detail-table">
            <div className="row">
                <div className="col-12 bg-white pl-0 pb-0"><h6>Share with users on this account</h6></div>
            </div>

            <div className="row">
                <div className="col-sm-3 d-none d-md-block"><strong>User</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>Job Permission</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>Notification Type</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>If job runs empty</strong></div>
            </div>

            { notificationUsers && notificationUsers.length > 0 
                ? 
                <React.Fragment>
                    {notificationUsers && notificationUsers.map(user => <div className="row" key={user.userId}>
                        <div className="col-5 d-md-none">
                            <strong>User</strong>
                        </div>
                        <div className="col-7 col-md-3">{user.name}</div>
                        <div className="col-5 d-md-none">
                            <strong>Job permission</strong>
                        </div>
                        <div className="col-7 col-md-3">{JobPermissions.filter(j => j.id.toString() === user.jobShareType.toString())[0].name}</div>
                        <div className="col-5 d-md-none">
                            <strong>Notification Type</strong>
                        </div>
                        <div className="col-7 col-md-3">{JobNotificationTypes.filter(j => j.id.toString() === user.notificationType.toString())[0].name}</div>
                        <div className="col-5 d-md-none">
                            <strong>If job runs empty</strong>
                        </div>
                        <div className="col-7 col-md-3">{user.withContentOnly ? "Skip notification" : "Send notification anyway"}</div>
                    </div>) }
                </React.Fragment>
                : 
                <div className="row">
                    <div className="col-12">
                        <span className="badge badge-secondary">not set</span>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12 bg-white pl-0 pb-0 pt-4"><h6>Share with admins on connected accounts</h6></div>
            </div>

            <div className="row">
                <div className="col-sm-3 d-none d-md-block"><strong>Account</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>Job Permission</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>Notification Type</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>If job runs empty</strong></div>
            </div>

            {notificationAccounts && notificationAccounts.length > 0 
                ?
                <React.Fragment>
                    {notificationAccounts.map(account => <div className="row" key={account.accountId}>
                        <div className="col-5 d-md-none">
                            <strong>Account</strong>
                        </div>
                        <div className="col-7 col-md-3">{account.name}</div>
                        <div className="col-5 d-md-none">
                            <strong>Job permission</strong>
                        </div>
                        <div className="col-7 col-md-3">{JobPermissions.filter(j => j.id.toString() === account.jobShareType.toString())[0].name}</div>
                        <div className="col-5 d-md-none">
                            <strong>Notification Type</strong>
                        </div>
                        <div className="col-7 col-md-3">{JobNotificationTypes.filter(j => j.id.toString() === account.notificationType.toString())[0].name}</div>
                        <div className="col-5 d-md-none">
                            <strong>If job runs empty</strong>
                        </div>
                        <div className="col-7 col-md-3">{account.withContentOnly ? "Skip notification" : "Send notification anyway"}</div>
                    </div>)}                        
                </React.Fragment>
                : 
                <div className="row">
                    <div className="col-12">
                        <span className="badge badge-secondary">not set</span>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12 bg-white pl-0 pb-0 pt-4"><h6>Send notifications to none users (e-mail)</h6></div>
            </div>

            <div className="row">
                <div className="col-sm-6 d-none d-md-block"><strong>Email</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>Notification Type</strong></div>
                <div className="col-sm-3 d-none d-md-block"><strong>If job runs empty</strong></div>
            </div>

            {notificationEmails && notificationEmails.length > 0
                ?
                <React.Fragment>
                    {notificationEmails.map(email => <div className="row" key={email.email}>
                        <div className="col-5 d-md-none">
                            <strong>Email</strong>
                        </div>
                        <div className="col-7 col-sm-6">{email.email}</div>
                        <div className="col-5 d-md-none">
                            <strong>Notification Type</strong>
                        </div>
                        <div className="col-7 col-md-3">{JobNotificationTypes.filter(j => j.id.toString() === email.notificationType.toString())[0].name}</div>
                        <div className="col-5 d-md-none">
                            <strong>If job runs empty</strong>
                        </div>
                        <div className="col-7 col-md-3">{email.withContentOnly ? "Skip notification" : "Send notification anyway"}</div>
                    </div>)}
                </React.Fragment>
                : 
                <div className="row">
                    <div className="col-12">
                        <span className="badge badge-secondary">not set</span>
                    </div>
                </div>
            }

        </div>
    </React.Fragment>
)

const CreateJobWizzardStepFive = ({ job, handleSubmit, submitting }) => {
    return (
        <Card>
            <Header title={'Configuration compilation - ' + job.name}>
                <div className="alert highlight no-print" role="alert">
                    <span>
                        Before you save please take your time and check the summary of your job configuration. If you do not check "Activate job when finish" you need to enable the job from the job-configuration list to activate.
                    </span>
                </div>

                <form onSubmit={handleSubmit} className="no-print">

                        <Field
                            component={renderCheckbox}
                            name="isActive"
                            label="Activate job when finish"/>


                    <button type="submit" className="btn btn-primary btn-lg" disabled={submitting} >
                            Save and finish
                    </button>
                    <PrintButton title="Print summary" targetId="print-summary"/>

                </form>
            </Header>
            <Body>
                <GeneralInfo
                    name={job.name}
                    description={job.description} />

                <Events events={job.events} />

                <Frequency triggerType={job.triggerType}
                    schedule={job.schedule}
                    trigger={job.trigger} />

                <ShareAndNotifications
                    notificationUsers={job.notificationUsers}
                    notificationAccounts={job.notificationAccounts}
                    notificationEmails={job.notificationEmails} />

                <div className="row">
                    <div className="col-12 pt-5">
                        <form onSubmit={handleSubmit} className="no-print">
                            <Field
                                component={renderCheckbox}
                                name="isActive"
                                label="Activate job when finish"
                            ></Field>
                            <button type="submit" className="btn btn-primary btn-lg" disabled={submitting} >
                                Save and finish
                            </button>
                            <PrintButton title="Print summary" targetId="print-summary"/>
                        </form>
                    </div>
                </div>

{/* 
                <div className="row mt-5">
                    <div className="col-md-12 d-flex">
                        <form onSubmit={handleSubmit} className="no-print">
                            <Field
                                component={renderCheckbox}
                                name="isActive"
                                label="Activate job when finish"
                            ></Field>
                            <button type="submit" className="btn btn-primary btn-lg m-1" disabled={submitting} >
                                Save and finish
                            </button>
                            <PrintButton title="Print summary" targetId="print-summary"/>
                        </form>
                    </div>
                </div>
*/}

            </Body>
        </Card>
    )
}

export default reduxForm({
    form: 'create-job-wizzard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
})(CreateJobWizzardStepFive)

