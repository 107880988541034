import React, { useState, useRef } from 'react';
import Card, { Body } from '../common/Card';
import PageContent, { ContentHeader, ContentBody } from '../common/PageContent';
import { NavLink } from 'react-router-dom';
import './Share.css';
import Table from '../common/table/Table';

const permissions = [
    { id: 1, name: "Read" },
    { id: 2, name: "Write" },
    { id: 3, name: "Full" }
]

const Breadcrumbs = ({ breadcrumbs }) => {
    if (!breadcrumbs || !breadcrumbs.length) {
        return "";
    }

    const bcs = breadcrumbs.map((bc, i) => (
        <li key={bc.id}>
            {
                !bc.id && !bc.type ?
                    <NavLink to={`/explore`} exact activeClassName="active"><span className="icon icon-explorer"></span> {bc.name}</NavLink> :
                    <NavLink to={`/explore/${bc.id}/${bc.type}`}>{bc.name}</NavLink>
            }
            {
                i !== (breadcrumbs.length - 1) &&
                <span className="pl-1 pr-1 primary-color">/</span>
            }
        </li>
    ))

    return <ol className="breadcrumb border">
            {bcs}
            <li className="active"> <span className="icon icon-share"></span></li>
        </ol>
}


const SharedWith = ({ accounts, onDelete }) => {
    if (!accounts || !accounts.length) {
        return (

            <p className="highlight">
                Folder not shared. Select account and then select a permission level and click add.
                If you cant find an account it is probably because you dont have any connected accounts. You can easily connect to any account just send them an invitation from "Network / Connection requests".
            </p>
        )
    }

    const accountsSchema = [
        { accessor: "accountName", header: "Account" },
        { accessor: "permissionName", header: "Permission" },
        {
            type: "custom",
            render: (data, schema, rowIndex) => {
                return <button className="btn btn-danger float-right" onClick={() => onDelete(rowIndex)}>
                    <span className="icon icon-trash"></span>
                </button>
            }
        }
    ]

    return <Table data={accounts} schema={accountsSchema} />
}

const Share = ({ handleSubmit, share, hideBreadcrumbs }) => {

    const [state, setState] = useState({
        id: share.id,
        name: share.name,
        sharedWith: share.sharedWith || []
    });

    const onNameChange = (ev) => {
        if (ev.target.value.length === 0) {
            return;
        }

        setState({
            ...state,
            name: ev.target.value
        });
    }

    const accountEl = useRef()
    const permissionEl = useRef()

    const pushAccount = () => {

        const permissionIndex = parseInt(permissionEl.current.value, 10);
        const accountIndex = parseInt(accountEl.current.value, 10);

        if (permissionIndex < 0 || accountIndex < 0) {
            return;
        }

        const account = share.accounts[accountIndex];
        const permission = permissions[permissionIndex]

        if (state.sharedWith.some(sw => sw.accountId === account.id)) {
            return;
        }

        setState({
            ...state,
            sharedWith: [
                ...state.sharedWith,
                {
                    accountId: account.id,
                    permission: permission.id,
                    accountName: account.name,
                    permissionName: permission.name,
                    isNew: true
                }
            ]
        });
    }

    const deleteAccount = (index) => {
        setState({
            ...state,
            sharedWith: state.sharedWith.map((item, i) => {
                if(i !== index) {
                    return item;
                }

                return {
                    ...item,
                    isDeleted: true
                }
            })
        });
    }

    const submit = (share.sharedWith || []).length > 0 || state.sharedWith.length > 0 ? (
        <div className="row">
            <div className="col-md-12 form-group padding">
                <button type="button" className="btn btn-success btn-lg float-right" onClick={() => { handleSubmit(state) }}>
                    Save share config
                </button>
            </div>
        </div>
    ) : ""

    return (
        <PageContent>
            <ContentHeader title="Network / Explore & share">
                {!hideBreadcrumbs && <Breadcrumbs breadcrumbs={share.breadcrumbs} />}
            </ContentHeader>
            <ContentBody>
                <Card className="p-0 m-0 border-0">
                    <Body className="p-0">
                        <h6>{`Share folder "${share.name}"`}</h6>
                        <div className="col-md-12">
                            <fieldset>
                                <div className="row">
                                    <div className="col-md-12 padding">
                                        <SharedWith accounts={state.sharedWith.filter(sw => !sw.isDeleted)} onDelete={deleteAccount} />
                                    </div>
                                </div>
                                {submit}
                                <div className="row">
                                    <div className="form-group col-md-4 padding">
                                        <label>
                                            Share folder as
                                        </label>
                                        <input className="form-control form-control-lg border-input" value={state.name} onChange={onNameChange} />
                                    </div>
                                    <div className="form-group col-md-4 padding">
                                        <label>
                                            Share with account
                                            </label>
                                        <select className="form-control form-control-lg border-input" ref={accountEl}>
                                            <option value={-1}>--- Select account ---</option>
                                            {(share.accounts || []).map((a, i) =>
                                                <option key={a.id} value={i}>{a.name}</option>
                                            )}
                                        </select>
                                    </div>                      
                                    <div className="form-group col-md-4 padding">
                                        <label>
                                            Permission
                                            </label>
                                        <select className="form-control form-control-lg border-input" ref={permissionEl}>
                                            <option value={-1}>--- Select permission ---</option>
                                            {
                                                permissions.map((p, i) => <option key={p.id} value={i}>{p.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12 padding">
                                        <button className="btn btn-default btn-lg btn-primary float-right" onClick={pushAccount}>
                                            <i className="icon icon-plus"></i> Add
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </Body>
                </Card>
            </ContentBody>
        </PageContent>
    )
}

export default Share