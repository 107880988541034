import { Link, NavLink } from "react-router-dom";
import { ThumbsUp } from "./ThumbsUp";
import React, { useMemo } from "react";

const ConnectorsCard = ({ statusConnectors }) => {
  const filter = useMemo(() => {
    if (statusConnectors.connectorError > 0) {
      return "?statuses[]=NotResponding";
    }

    if (statusConnectors.connectorWarning > 0) {
      return "?statuses[]=NotResponding";
    }

    if (statusConnectors.connectorOk > 0) {
      return "?statuses[]=OK";
    }

    return "";
  }, [statusConnectors]);

  return (
    <div
      className={`card text-white ${
        statusConnectors.connectorError > 0
          ? "bg-danger"
          : statusConnectors.connectorWarning > 0
          ? "bg-warning"
          : "bg-success"
      } mb-3`}
    >
      <div className="card-header">Connectors</div>
      <div className="card-body">
        <div className="row">
          <div className="col-2 align-self-center">
            <h1 className="dashStatusIcon">
              <i
                className={
                  statusConnectors.connectorError > 0
                    ? "icon icon-attention-circled"
                    : statusConnectors.connectorWarning > 0
                    ? "icon icon-info-circled"
                    : "icon icon-ok"
                }
              ></i>
            </h1>
          </div>
          <div className="col-10 text-right">
            <h1 className="card-title">
              {statusConnectors.connectorError > 0
                ? statusConnectors.connectorError
                : statusConnectors.connectorWarning > 0
                ? statusConnectors.connectorWarning
                : statusConnectors.connectorOk}
            </h1>
            <NavLink
              className="text-white"
              activeClassName="active"
              to={"/admin/connectors" + filter}
            >
              <p className="card-text">
                {statusConnectors.connectorError > 0 ? (
                  "Critical - Connectors not responding"
                ) : statusConnectors.connectorWarning > 0 ? (
                  "Warning - Connectors not responding"
                ) : statusConnectors.connectorOk > 0 ? (
                  <ThumbsUp text="Connectors online" />
                ) : (
                  "Seems you don´t have any connectors installed?"
                )}
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const EndpointsCard = ({ statusRootFolders }) => {
  const filter = useMemo(() => {
    if (statusRootFolders.rootError > 0) {
      return "?status=offline";
    }

    if (statusRootFolders.offline > 0) {
      return "?status=offline";
    }

    if (statusRootFolders.rootOk > 0) {
      return "";
    }

    return "";
  }, [statusRootFolders]);

  return (
    <div
      className={`card text-white ${
        statusRootFolders.rootError > 0
          ? "bg-danger"
          : statusRootFolders.rootWarning > 0
          ? "bg-warning"
          : "bg-success"
      } mb-3`}
    >
      <div className="card-header">Endpoints</div>
      <div className="card-body">
        <div className="row">
          <div className="col-2 align-self-center">
            <h1 className="dashStatusIcon">
              <i
                className={
                  statusRootFolders.rootError > 0
                    ? "icon icon-attention-circled"
                    : statusRootFolders.rootWarning > 0
                    ? "icon icon-info-circled"
                    : "icon icon-ok"
                }
              ></i>
            </h1>
          </div>
          <div className="col-10 text-right">
            <h1 className="card-title">
              {statusRootFolders.rootError > 0
                ? statusRootFolders.rootError
                : statusRootFolders.rootWarning > 0
                ? statusRootFolders.rootWarning
                : statusRootFolders.rootOk}
            </h1>
            <NavLink
              className="text-white"
              activeClassName="active"
              to={"/admin/endpoints" + filter}
            >
              <p className="card-text">
                {statusRootFolders.rootError > 0 ? (
                  "Endpoints not accessible"
                ) : statusRootFolders.rootWarning > 0 ? (
                  "Warning"
                ) : statusRootFolders.rootOk > 0 ? (
                  <ThumbsUp text="Accessible endpoints" />
                ) : (
                  "Seems you don´t have any active jobs"
                )}
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const RunsCard = ({ statusJobRuns }) => {
  return (
    <div
      className={`card text-white ${
        statusJobRuns.jobRunsUnAck > 0 ? "bg-danger" : "bg-success"
      } mb-3`}
    >
      <div className="card-header">Runs</div>
      <div className="card-body">
        <div className="row">
          <div className="col-2 align-self-center">
            <h1 className="dashStatusIcon">
              <i
                className={
                  statusJobRuns.jobRunsUnAck > 0
                    ? "icon icon-attention-circled"
                    : "icon icon-ok"
                }
              ></i>
            </h1>
          </div>
          <div className="col-10 text-right">
            <h1 className="card-title">
              {statusJobRuns.jobRunsUnAck > 0
                ? statusJobRuns.jobRunsUnAck
                : "0"}
            </h1>
            <a
              className="text-white"
              href="/jobs/runs?statuses[]=4"
            >
              <p className="card-text">
                {statusJobRuns.jobRunsUnAck > 0 ? (
                  "Unresolved job run errors"
                ) : (
                  <ThumbsUp text="Unresolved job run errors" />
                )}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const JobsConfigurationCard = ({ statusJobsAutoDisabled }) => {
  return (
    <div
      className={`card text-white ${
        statusJobsAutoDisabled.jobsTemporarilyDisabled > 0 ||
        statusJobsAutoDisabled.jobsPermanentlyDisabled > 0
          ? "bg-danger"
          : "bg-success"
      } mb-3`}
    >
      <div className="card-header">Job configurations</div>
      <div className="card-body">
        <div className="row">
          <div className="col-2 align-self-center">
            <h1 className="dashStatusIcon">
              <i
                className={
                  statusJobsAutoDisabled.jobsTemporarilyDisabled > 0 ||
                  statusJobsAutoDisabled.jobsPermanentlyDisabled > 0
                    ? "icon icon-attention-circled"
                    : "icon icon-ok"
                }
              ></i>
            </h1>
          </div>
          <div className="col-10 text-right">
            <h1 className="card-title">
              {statusJobsAutoDisabled.jobsTemporarilyDisabled +
                statusJobsAutoDisabled.jobsPermanentlyDisabled}
            </h1>
            <NavLink
              className="text-white"
              activeClassName="active"
              to="/admin/jobs?status=3"
            >
              <p className="card-text">
                {statusJobsAutoDisabled.jobsTemporarilyDisabled +
                  statusJobsAutoDisabled.jobsPermanentlyDisabled >
                0 ? (
                  "Jobs auto-disabled"
                ) : (
                  <ThumbsUp text="Jobs auto-disabled" />
                )}{" "}
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GeneralStatus = ({
  statusConnectors,
  statusJobRuns,
  statusJobsAutoDisabled,
  statusRootFolders,
}) => {
  return (
    <React.Fragment>
      <div className="card-deck">
        <ConnectorsCard statusConnectors={statusConnectors} />

        <EndpointsCard statusRootFolders={statusRootFolders} />

        <RunsCard statusJobRuns={statusJobRuns} />

        <JobsConfigurationCard
          statusJobsAutoDisabled={statusJobsAutoDisabled}
        />
      </div>
    </React.Fragment>
  );
};
