import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import RunDetails from "../../../components/user/jobs/RunDetails";
import {
  showEventDetails,
  loadJobRun,
  executeActionOnRun,
} from "../../../actions/jobs";
import { volumeFormatter } from "../../../common/volumeFormatter";
import { isFetching } from "../../../selectors/Fetching";
import Progress from "../../../components/common/progress/Progress";
import RunStatusInfo from "../../../components/user/jobs/RunStatusInfo";
import Loader from "../../../components/common/Loader";

const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state.fetching, loadJobRun),
  details: state.jobs.run,
  autoRefresh: state.jobs.autoRefresh || true,
});

const mapDispatchToProps = (dispatch) => ({
  onEventSelect: (runId) => (ev) =>
    dispatch(showEventDetails(runId, ev.sysEventId)),
  initialState: (runId) => dispatch(loadJobRun(runId)),
  executeActionOnRun: (target = "details") => (jobRunId, action) =>
    dispatch(executeActionOnRun(jobRunId, action, target)),
});

const getProgressColor = (status) =>
  status === "1"
    ? "success"
    : status === "0" || status === "10" || status === "100" || status === "101"
    ? "primary"
    : "danger";
const getStatusType = (status) =>
  status === "1"
    ? "success"
    : status === "0" || status === "10" || status === "100" || status === "101"
    ? "inProgress"
    : "error";

/*
0	Queued
1	Finished OK
10	TransferedToCommandQueue
100	TransferedToCommandQueueWaitingForSyncCompletion
101	TransferedToCommandQueueWaitingForCommandCompletion
9000	Error Queued
9001	Error Finished OK
9010	Error TransferedToCommandQueue
9100	Error TransferedToCommandQueueWaitingForSyncCompletion
9999	GeneralError
10000	RemovedQueued
10010	RemovedTransferedToCommandQueue
10100	RemovedTransferedToCommandQueueWaitingForSyncCompletion
19999	RemovedGeneralError
*/

let RunDetailsContainer = ({
  initialState,
  match,
  isFetching,
  details,
  onEventSelect,
  startAutoRefresh,
  executeActionOnRun,
  cancelAutoRefresh,
}) => {
  useEffect(() => {
    initialState(match.params.runId);
    const interval = setInterval(() => {
      initialState(match.params.runId);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [cancelAutoRefresh, initialState, match.params.runId, startAutoRefresh]);

  const onRefresh = useCallback(() => {
    initialState(match.params.runId);
  }, [initialState, match.params.runId]);

  if (
    !details ||
    (isFetching && details.sysJobId !== parseInt(match.params.runId, 10))
  ) {
    return <Loader title="Loading run" />;
  }

  let eventsSchema = [
    { accessor: "sysEventId", header: "Run Id" },
    { accessor: "type", header: "Type" },
    { accessor: "description", header: "Description" },
    {
      type: "custom",
      header: "Files",
      render: (data) => (
        <span>
          {data.totalHandeledFiles} / {data.totalFiles}
        </span>
      ),
    },
    {
      accessor: "totalBytes",
      header: "Total volume",
      type: "custom",
      render: (data) => volumeFormatter(data.totalBytes),
    },
    {
      type: "custom",
      header: "Progress",
      render: (data) => (
        <Progress
          value={data.progress}
          className={"bg-" + getProgressColor(data.statusId)}
          height="16px"
          fontSize="14px"
        />
      ),
    },
    {
      type: "custom",
      header: "Status",
      render: (data) => <RunStatusInfo type={getStatusType(data.statusId)} />,
    },
    { accessor: "start", header: "Start", type: "date" },
    { accessor: "stop", header: "Stop", type: "date" },
  ];

  return (
    <RunDetails
      title={`Jobs / Runs`}
      details={details}
      events={details?.events}
      eventsSchema={eventsSchema}
      onEventSelect={onEventSelect(details?.sysJobId)}
      onRefresh={onRefresh}
      executeActionOnRun={executeActionOnRun()}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RunDetailsContainer);
