import { useState } from 'react';

export const usePasswordVerification = (next) => {
  const [model, appendModel] = useState({
    next
  });

  const setModel = (model) => appendModel({ next, model, show: true });
  const hideVerification = () => appendModel({ next, model, show: false });

  return [model, setModel, hideVerification];
}
