
import {
    TOGGLE_HEADER_CONTEX_MENU,
    TOGGLE_RIGHT_MENU,
    TOGGLE_LEFT_MENU
} from '../actions/menu'

export const menu = (state = { userMenuOpen: false, notificationsMenuIsOpen: false, rightMenuOpen: false, leftMenuOpen: false }, action) => {
    switch (action.type) {
        case TOGGLE_HEADER_CONTEX_MENU:
            return {
                ...state,
                userMenuOpen: !state.userMenuOpen
            }
        case TOGGLE_RIGHT_MENU:
            return {
                ...state,
                rightMenuOpen: action.context != null,
                context: action.context
            }
        case TOGGLE_LEFT_MENU:
            return {
                ...state,
                leftMenuOpen: !state.leftMenuOpen 
            }
        default: return state 
    }
};