import React from 'react'
import './Share.css'
import ShareContainer from '../containers/user/explore/Share'

const SharePage = ({ match }) => {
    return <div id="content">
        <ShareContainer match={match} />
    </div>
}

export default SharePage