import React from 'react'
import { NavLink } from 'react-router-dom'
import './JobBreadCrumbs.css'

const JobBreadCrumbsComponent = ({ active,
    match,
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid }) => {

    const steps = [
        { index: 0, to: '/general', isActiveRoute: false, name: 'General' },
        { index: 1, to: '/events', isActiveRoute: false, name: 'Events' },
        { index: 2, to: '/frequency', isActiveRoute: false, name: 'Frequency' },
        { index: 3, to: '/notifications', isActiveRoute: false, name: 'Share & notifications' },
        { index: 4, to: '/summary', isActiveRoute: false, name: 'Summary' }
    ]

    const isNextStepValid = (step) => {

        switch (step) {
            case 1:
                return stepOneIsValid;
            case 2:
                return stepOneIsValid && stepTwoIsValid
            case 3:
            case 4:
            case 5:
                return stepOneIsValid && stepTwoIsValid && stepThreeIsValid
            default:
                return true
        }
    }

    const renderNextLink = (step) => {
        return isNextStepValid(step.index) ? renderLink(step) : <span>{step.index + 1}. {step.name}</span>
    }

    const renderLink = (step) => {
        return <NavLink to={`${match.url}` + step.to} >{step.index + 1}. {step.name}</NavLink>
    }

    const nextStepActive = () => {
        var activeStep = steps.filter(s => s.to.toLowerCase() === active);

        if (activeStep.length === 0) return false

        return isNextStepValid(activeStep[0].index + 1)
    }

    const renderNextStepButton = () => {
        if (steps.filter(s => s.to.toLowerCase() === active).length === 0)
            return;

        var activeStep = steps.filter(s => s.to.toLowerCase() === active)[0];

        if (activeStep.index === 4) {
            return "";
        }

        var nextStep = steps.filter(s => s.index === activeStep.index + 1)[0];

        return nextStepActive() ?
            <NavLink  className="btn btn-light" to={`${match.url}` + nextStep.to} >Next <span className="icon icon-right-dir"></span></NavLink> :
            <button className="btn btn-light">Next <span className="icon icon-right-dir"></span></button>
    }

    const renderPreviousStepButton = () => {

        if (steps.filter(s => s.to.toLowerCase() === active).length === 0)
            return;

        var activeStep = steps.filter(s => s.to.toLowerCase() === active)[0];

        if (activeStep.index === 0) {
            return null;
        }

        var previousStep = steps.filter(s => s.index === activeStep.index - 1)[0];

        return <NavLink to={`${match.url}` + previousStep.to} className="btn btn-light"><span className="icon icon-left-dir"></span> Previous</NavLink>
    }

    return (
<React.Fragment>
        <div className="row wiz-stepMenu m-0 no-print">
            {
                steps.map(step => {
                    return <div className={step.to===active ? "col-md-auto d-flex align-items-center wiz-cell-active mb-1" : "col-md-auto d-flex align-items-center bg-light mb-1"} key={step.index}>
                        {renderNextLink(step)}
                        {isNextStepValid(step.index +1) ? <React.Fragment><span> </span> <i className="icon icon-ok text-success"></i></React.Fragment> : ""}
                        {
                                
                            
                            /*
                            (step.index + 1) !== steps.length &&
                            <span className="icon icon-angle-right"></span>

                             <div className="col-auto mr-auto bg-light">
                                1. General
                            </div>
                            <div className="col-auto mr-auto bg-light">
                                2. Events
                            </div>
                            <div className="col-auto mr-auto bg-light">
                                3. Frequency
                            </div>
                            <div className="col-auto mr-auto bg-light">
                                4. Share & notifications
                            </div>
                            <div className="col-auto bg-light">
                                5. Summary
                            </div>
                            */
                        }
                    </div>
                })
            }
            <div className="col-md-auto d-flex align-items-center wiz-cell-btns bg-light mb-1">
                <div className="container p-2">
                    <div className="row">
                        {renderPreviousStepButton() ?
                            <React.Fragment>
                                <div className="col-6 wiz-cell-btns">
                                    {renderPreviousStepButton()}
                                </div>
                                <div className="col-6 wiz-cell-btns text-right">
                                    {renderNextStepButton()}
                                </div>
                            </React.Fragment>
                            :
                            <div className="col-6 d-flex align-items-left wiz-cell-btns">
                                {renderNextStepButton()}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>


{
    /*
        <div className="d-flex flex-row">
        <div className="flex-fill">
            <ul className="job-breadcrumb no-print ">
                {
                    steps.map(step => {
                        return <li key={step.index}>
                            {renderNextLink(step)}
                            {
                                (step.index + 1) !== steps.length &&
                                <span className="icon icon-angle-right"></span>
                            }
                        </li>
                    })
                }
            </ul>
        </div>
        */
}


</React.Fragment>
        
    )
}

export default JobBreadCrumbsComponent