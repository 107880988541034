import React, {  useMemo } from "react";
import { valueFormatter } from "../../../common/valueFormatter";
import "./Slider.css";

const Slider = ({ nodes = [], onSelect }) => {

  const selectedIndex = useMemo(() => {
    return nodes.findIndex(n => n.selected);
  }, [nodes])

  return (
    <div className="slider d-flex text-center" data-testid="slider">
      {nodes?.map((node, i) => (
        <div
          key={node.id}
          className="node flex-fill"
          data-testid="node"
          onClick={e => onSelect && onSelect(node)}
        >
          <div className="w-100 d-flex">
            <div data-testid="line" className={`line flex-fill ${i <= selectedIndex ? "blue" : ""}`}></div>
            <div data-testid="line" className={`line flex-fill ${i < selectedIndex ? "blue" : ""}`}></div>
          </div>
          {node.selected && <div className="ball" data-testid="ball"></div>}
          {!node.selected && <div className="mark" data-testid="mark"></div>}
          <div className="text pt-2">{valueFormatter(node.value)}</div>
        </div>
      ))}
    </div>
  );
};

export default Slider;
