import { get$, put$, delete$ } from "./http";
import qs from "query-string";

export const loadSchedule$ = (id, includeJobs = false) => {
  if (includeJobs) {
    return get$(`/schedules/${id}?includeJobs=true`);
  }
  return get$("/schedules/" + id);
};

export const listSchedules$ = (page) => {
  return get$("/schedules?" + qs.stringify({ page }));
};

export const saveSchedule$ = (schedule) => {
  return put$(`/schedules/${schedule.id}`, schedule);
}

export const deleteSchedule$ = (id) => {
  return delete$(`/schedules/${id}`);
}