import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import CreateJobWizzardStepThree from '../../components/jobs/wizzard/CreateJobWizzardStepThree'
import stepTwoValidation from '../../components/jobs/wizzard/stepTwoValidation'
import { loadCreateJobs, loadSchdule } from '../../actions/jobs';
import { Redirect } from 'react-router-dom'

import { isFetching } from '../../selectors/Fetching';

import Loader from '../../components/common/Loader'

const JobWizzardStepThreeContainer = ({ 
    loadSchedules,
    isPreviousStepValid,
    schedules,
    changeSchedule,
    resetSchedule,
    weedDaysChangeEvent,
    resetScheduleDate,
    //resetScheduleDay,
    isFetching,
    updateJobEvent }) => {

        useEffect(() => {
            loadSchedules();
        }, [loadSchedules])

    if (isFetching)
        return <Loader />

    return (
        !isPreviousStepValid ? <Redirect to="./events" /> :
            <CreateJobWizzardStepThree schedules={schedules}
                changeSchedule={changeSchedule}
                resetSchedule={resetSchedule}
                weedDaysChangeEvent={weedDaysChangeEvent}
                resetScheduleDate={resetScheduleDate}
                //resetScheduleDay={resetScheduleDay}
                updateJobEvent={updateJobEvent} />)
}

const mapStateToProps = (state) => {
    const formValues = getFormValues("create-job-wizzard")(state) || {};
    return {
        isPreviousStepValid: Object.keys(stepTwoValidation(formValues)).length === 0,
        schedules: state.jobs.schedules,
        isFetching: isFetching(state.fetching, loadSchdule, loadCreateJobs)
    }
}

/*
resetScheduleDate: () => dispatch(change('create-job-wizzard', 'schedule.date', {
    date: {
        type: "1"
    }
})),
resetSchedule: () => dispatch(change('create-job-wizzard', 'schedule', {
            time: {
                type: "1"
            },
            date: {
                type: "1"
            }
        })),

*/


const mapDispatchToProps = (dispatch) => {
    return {
        updateJobEvent: (key, value) => dispatch(change('create-job-wizzard', key, value)),
        loadSchedules: () => dispatch(loadCreateJobs()),
        changeSchedule: (id) => {
            if (id > 0)
                dispatch(loadSchdule(id))
        },
        /*
        resetScheduleDate: () => dispatch(change('create-job-wizzard', 'schedule', {
            dateType: "101"
        })),
        */
        resetScheduleDate: () => {
            dispatch(change('create-job-wizzard', 'schedule.dateValue', ""));
            dispatch(change('create-job-wizzard', 'schedule.day.1', false));
            dispatch(change('create-job-wizzard', 'schedule.day.2', false));
            dispatch(change('create-job-wizzard', 'schedule.day.4', false));
            dispatch(change('create-job-wizzard', 'schedule.day.8', false));
            dispatch(change('create-job-wizzard', 'schedule.day.16', false));
            dispatch(change('create-job-wizzard', 'schedule.day.32', false));
            dispatch(change('create-job-wizzard', 'schedule.day.64', false));
        },
        
        resetSchedule: () => dispatch(change('create-job-wizzard', 'schedule', {
            timeType: "100", dateType: "101"
        })),
        /*resetScheduleDay: () => {
            dispatch(change('create-job-wizzard', 'schedule.day.1', false));
            dispatch(change('create-job-wizzard', 'schedule.day.2', false));
            dispatch(change('create-job-wizzard', 'schedule.day.4', false));
            dispatch(change('create-job-wizzard', 'schedule.day.8', false));
            dispatch(change('create-job-wizzard', 'schedule.day.16', false));
            dispatch(change('create-job-wizzard', 'schedule.day.32', false));
            dispatch(change('create-job-wizzard', 'schedule.day.64', false));
        },
        */
        weedDaysChangeEvent: (value, isChecked, oldValue) => {

            if (!oldValue)
                oldValue = 0; 

            var newValue = isChecked ? oldValue += value : oldValue -= value
            dispatch(change('create-job-wizzard', 'schedule.dateValue', newValue));
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(JobWizzardStepThreeContainer)