import { get$, delete$ } from './http';

export const listAllConnections$ = () => {
    return get$('/connections');
}

export const loadConnectionDetails$ = (accountRelationId) => {
    return get$('/connections/details/' + accountRelationId);
}

export const disconnectConnection = (connectionId) => {
    return delete$(`/connections/${connectionId}`)
}