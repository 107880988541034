import { of } from 'rxjs'
import { httpError } from '../actions/error';
import { refreshToken } from './auth'
import { logout } from '../actions/auth';
import { push } from 'connected-react-router';

export const handleError = (action$, type) => (error, source) => {
    return error.status === 401 ? 
            refreshToken(action$, error, source) : 
            error.response == null || error.status === 400  ?
                of(logout(window.location.pathname)) :
                of(push('/dashboard'), httpError(error.response.errors), { type: `${type.replace('REQ_', '')}_FAILED`})

}


