import { get$, post$ } from './http';

const baseUrl = (path) => {
    return `/jobs/view${path}`;
}

const convertSortBy = (sortBy) => {
    return {
        sortBy: (sortBy[0] || {}).accessor,
        sortOrder: (sortBy[0] || {}).sortOrder
    }
}

export const loadJobRuns$ = (filter, currentRunsPage = 1) => post$(baseUrl(`/runs?page=${currentRunsPage}`), filter || {})

export const loadJobRun$ = (runId) => get$(baseUrl(`/runs/${runId}`))

export const loadJobRunEvent$ = (runId, eventId, filterFilesId, currentFilesPage) => get$(baseUrl(`/runs/${runId}/events/${eventId}?filterFilesId=${filterFilesId}&currentFilesPage=${currentFilesPage}`))

export const loadJobRunFile$ = (runId, eventId, fileId, fileSubId) => get$(baseUrl(`/runs/${runId}/events/${eventId}/files/${fileId}/${fileSubId}`))

export const executeActionOnRun$ = (runId, action) => post$(baseUrl(`/runs/${runId}/${action}`))

export const loadJobView$ = (text, page = 1, sortBy = null) => 
    get$(baseUrl('/all'), { text, page, ...convertSortBy(sortBy || []) })

export const executeActionOnJob$ = (jobId, action) => post$(baseUrl(`/${jobId}/${action}`))
