import React from 'react'
import Card, { Header, Body } from '../common/Card';

const NotificationDistributionsComponent = ({
    wantsEmail,
    wantsSms,
    wantsWeb,
    checkboxChangeEvent,
    onSave
}) => {
    return (
        <Card className="card-settings">
            <Header title="Notification channels">
            <div className="alert highlight" role="alert"><span>All types of notifications, warnings and errors will be sent whitin theese channels.</span></div>
            </Header>
            <Body>
                <div className="custom-control custom-checkbox mb-2">
                    <input  
                        id="notWantsEmail"
                        type="checkbox" 
                        className="custom-control-input" 
                        onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsEmail')
                        }} defaultChecked={wantsEmail}
                        />
                    <label className="custom-control-label" htmlFor="notWantsEmail">E-mail notifications</label>
                </div>
                <div className="custom-control custom-checkbox mb-4">
                    <input  
                        id="notWantsWeb"
                        type="checkbox" 
                        className="custom-control-input" 
                        onChange={(e) => {
                            checkboxChangeEvent(e.target.checked, 'wantsWeb')
                        }} defaultChecked={wantsWeb}
                        />
                    <label className="custom-control-label" htmlFor="notWantsWeb">Web notifications</label>
                </div>

                <button onClick={() => onSave()} className="btn btn-dark btn-lg">Save</button>
            </Body>
        </Card>)
}

export default NotificationDistributionsComponent;