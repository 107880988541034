import * as api from "../api";

import { switchMap, catchError, map, mapTo, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { of } from "rxjs";

import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  LOAD_ACCOUNT_LIST,
  EDIT_ACCOUNT,
  GET_ACCOUNT,
  createAccountSuccess,
  loadAccountsListSuccess,
  getAccountSuccess,
  SAVE_ACCOUNT,
  SAVE_ACCOUNT_SUCCESS,
  saveAccountSuccess,
  REMOVE_ORGANISATION,
  REMOVE_ORGANISATION_SUCCESS,
  removeOrganisationSuccess,
  REMOVE_USER_FROM_ACCOUNT,
  REMOVE_USER_FROM_ACCOUNT_SUCCESS,
  removeUserFromAccountSuccess,
} from "../actions/account";

import {
  LIST_ACCOUNT_USERS,
  listAccountUsersSuccess,
  SAVE_USER,
  CREATE_USER,
  saveUserSuccess,
  SAVE_USER_SUCCESS,
  GET_USER,
  EDIT_USER,
  getUserSuccess,
  LIST_PENDING_USERS,
  listPendingUsersSuccess,
  listAccountUsers,
  getUser,
} from "../actions/user";

import {
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_SUCCESS,
  createOrganisationSuccess,
  listOrganisations,
} from "../actions/organisation";

import { showNotificationSuccess } from "../actions/notification";
import { handleError } from "./common";

export const createAccountEpic = (action$) => {
  return action$.pipe(
    ofType(CREATE_ACCOUNT),
    switchMap((action) =>
      api.createAccount$(action.model).pipe(
        map((ar) =>
          createAccountSuccess(ar.response.accountId, action.referrer)
        ),
        catchError(handleError(action$, action.type, action.type))
      )
    )
  );
};

export const createAccountSuccessEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_ACCOUNT_SUCCESS),
    mergeMap((action) =>
      action.referrer
        ? of(
            showNotificationSuccess("Create account was successful"),
            push(action.referrer)
          )
        : of(showNotificationSuccess("Create account was successful"))
    )
  );

export const listAccountsEpic = (action$) => {
  return action$.pipe(
    ofType(LOAD_ACCOUNT_LIST),
    switchMap((action) =>
      api.listAllAccounts$().pipe(
        map((response) => loadAccountsListSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const editAccountEpic = (action$, state) => {
  return action$.pipe(
    ofType(EDIT_ACCOUNT),
    map((action) => push((action.path || "") + `/account/${action.accountId}`))
  );
};

export const getAccountEpic = (action$, state) => {
  return action$.pipe(
    ofType(GET_ACCOUNT),
    switchMap((action) =>
      api.getAccount$(action.accountId).pipe(
        map((response) => getAccountSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const saveAccountEpic = (action$, state) => {
  return action$.pipe(
    ofType(SAVE_ACCOUNT),
    switchMap((action) =>
      api.saveAccount$(action.accountId, action.model).pipe(
        map((response) => saveAccountSuccess(action.accountId)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const listAccountUsersEpic = (action$, state) => {
  return action$.pipe(
    ofType(LIST_ACCOUNT_USERS),
    switchMap((action) =>
      api.listAccountUsers$(action.accountId).pipe(
        map((response) => listAccountUsersSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const listPendingAccountUsersEpic = (action$, state) => {
  return action$.pipe(
    ofType(LIST_PENDING_USERS),
    switchMap((action) =>
      api.listPendingAccountUsers$().pipe(
        map((response) => listPendingUsersSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const createOrganisationEpic = (action$, state) => {
  return action$.pipe(
    ofType(CREATE_ORGANISATION),
    switchMap((action) =>
      api.createOrganisation$(action.accountId, action.model).pipe(
        map((or) => createOrganisationSuccess(or.response.organizationId)),
        catchError(handleError(action$, action.type))
      )
    )
  );
};

export const createOrganisationSuccessEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_ORGANISATION_SUCCESS),
    map((action) =>
      push("/admin/settings/organisations/edit/" + action.organisationId)
    )
  );

export const createOrganisationSuccessNotificationEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_ORGANISATION_SUCCESS),
    mapTo(showNotificationSuccess("Create organisation was successful"))
  );

export const saveAccountSuccessNotificationEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_ACCOUNT_SUCCESS),
    mapTo(showNotificationSuccess("Save account was successful"))
  );

export const createUserEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_USER),
    switchMap((action) =>
      api.createUser(action.accountId, action.user).pipe(
        map((ur) => saveUserSuccess(action.accountId, ur.response.userId)),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const createUserSuccessNotificationEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_USER_SUCCESS),
    mapTo(showNotificationSuccess("User has been saved"))
  );

export const createUserSuccessEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_USER_SUCCESS),
    map((action) => push(`/admin/settings/users/${action.userId}/edit`))
  );

export const editUserEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_USER),
    map((action) => push(`/admin/settings/users/${action.userId}/edit`))
  );

export const getUserEpic = (action$) =>
  action$.pipe(
    ofType(GET_USER),
    switchMap((action) =>
      api.getUser(action.accountId, action.userId).pipe(
        map((ur) => getUserSuccess(ur)),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const saveUserEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_USER),
    switchMap((action) =>
      api.saveUser(action.user.accountId, action.user.id, action.user).pipe(
        mergeMap((ur) =>
          of(
            saveUserSuccess(action.user.accountId, action.user.id),
            getUser(action.user.accountId, action.user.id)
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const removeOrganisationEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_ORGANISATION),
    switchMap((action) =>
      api
        .removeOrganisation(
          action.organisation.accountId,
          action.organisation.organisationId
        )
        .pipe(
          map((ur) => removeOrganisationSuccess(action.organisation)),
          catchError(handleError(action$, action.type))
        )
    )
  );

export const removeOrganisationSuccessEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_ORGANISATION_SUCCESS),
    mergeMap((action) =>
      of(
        showNotificationSuccess(`${action.organisation.name} has been removed`),
        listOrganisations(action.organisation.accountId)
      )
    )
  );

export const removeUserFromAccountEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_USER_FROM_ACCOUNT),
    switchMap((action) =>
      api.removeUserFromAccount(action.user.accountId, action.user.userId).pipe(
        map((ur) => removeUserFromAccountSuccess(action.user)),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const removeUserFromAccountSuccessEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_USER_FROM_ACCOUNT_SUCCESS),
    mergeMap((action) =>
      of(
        showNotificationSuccess(`${action.user.fullName} has been removed`),
        listAccountUsers(action.user.accountId)
      )
    )
  );
