import React from 'react'

const RunStatusInfo = ({ type }) => {

    switch (type) {
        case "inProgress": 
            return (
                <div>
                    <i className="icon icon-circle-notch animate-spin text-primary"></i> <span>In Progress</span>
                </div>
            )
        case "error":
            return (
                <div>
                    <i className="icon icon-attention-circled text-danger"></i> <span>Error</span>
                </div>
            )
        case "success":
            return (
                <div>
                    <i className="icon icon-ok-circled text-success"></i> <span>Successful</span>
                </div>
            )
        case "cancelledOk":
            return (
                <div>
                    <i className="icon icon-ok-circled text-warning"></i> <span>Cancelled Ok</span>
                </div>
            )
        case "errorAcknowledged":
            return (
                <div>
                    <i className="icon icon-ok-circled text-warning"></i> <span>Error Acknowledged</span>
                </div>
            )
        default:
            return (
                <div></div>
            )
    }
}

export default RunStatusInfo;
