import React from "react";

import { useModal } from "../modal/Modal";

const SearchIcon = () => {
  const { showModal } = useModal();

  return (
    <span
      onClick={() =>
        showModal("Search", { position: "right", title: "Search", icon: "icon-search" })
      }
    >
      <i className="icon icon-search"></i> Search
    </span>
  );
};

const Search = () => {
  return <SearchIcon />;
};

export default Search;
