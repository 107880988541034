import React from 'react'

const ExploreButtonBreadcrumbs = ({ breadcrumbs, onClick }) => {
    if (!breadcrumbs || !breadcrumbs.length) {
        return "";
    }
    let bcs = breadcrumbs.map((bc,i) => (
        <li key={bc.id}>
            {
               <span className="pointer btn-link" onClick={() => onClick(bc)}>
               { i===0 &&
                <i className="icon icon-explorer"></i>
                }
                <span></span> {bc.name}</span>
            }
            {
                i !== (breadcrumbs.length - 1) &&
                 <span className="pl-1 pr-1 primary-color">/</span>
            }
        </li>
    ))

    return <ol className="breadcrumb">
        {bcs}
    </ol>
}

export default ExploreButtonBreadcrumbs;