import React from 'react';
import DateFormat from '../DateFormat';

const types = {
    "date": (data, schema) =>
        <DateFormat date={data[schema.accessor]} format={schema.format} />,
    "button": (data, schema, rowIndex) =>
        <input type="button"
            disabled={schema.disabled ? schema.disabled(data) : ''}
            className={schema.css}
            onClick={(e) => { e.stopPropagation(); schema.action(data, rowIndex) }}
            value={data[schema.accessor] ? data[schema.accessor] : schema.actionText} />,
    "custom": (data, schema, rowIndex, onRowClick, onChange) =>
        schema.render ? schema.render(data, schema, rowIndex, onRowClick, onChange) : "",
    "boolean": (data, schema, rowIndex) =>
        data[schema.accessor] === undefined ? "false" : data[schema.accessor].toString(),
    "checkbox": (data, schema, rowIndex, _, onChange) =>
        <div className="custom-control custom-checkbox" onClick={(e) => e.stopPropagation()}>
            <input  
                id={rowIndex}
                type="checkbox" 
                className="custom-control-input"
                disabled={schema.disabled ? schema.disabled(data) : ''}
                checked={!!data[schema.accessor]}
                onClick={(e) => e.stopPropagation()}
                onChange={() => { onChange && onChange({ ...data, [schema.accessor]: !data[schema.accessor] }, data) }}/>
            <label className="custom-control-label" htmlFor={rowIndex}></label>
        </div>
}

const render = (data, schema, rowIndex, onRowClick, onChange) =>
    types[schema.type] ? types[schema.type](data, schema, rowIndex, onRowClick, onChange) : data[schema.accessor]

const Row = ({ schema, data, onRowClick, rowIndex, onChange }) => {
    let columns = schema?.map((s, i) => <td key={i}>{render(data, s, rowIndex, onRowClick, onChange)}</td>)

    return <tr className={data.isSelected ? "selected" : ""} onClick={() => { onRowClick && onRowClick(data, 'select'); }}>{columns}</tr>
}

export default Row