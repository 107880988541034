import { get$, post$ } from './http';

const convertSortBy = (sortBy) => {
    return {
        sortBy: (sortBy[0] || {}).accessor,
        sortOrder: (sortBy[0] || {}).sortOrder
    }
}

export const listAllJobs$ = (page, text, status, sortBy) => {
    return get$(`/jobs/list/${page || 1}`, { status, text, ...convertSortBy(sortBy || []) });
}

export const loadCreateJobInitalData$ = () => {
    return get$('/jobs/new')
}


export const getJob$ = (id) => {
    return get$('/jobs/' + id)
}

export const deleteJob$ = (id) => {
    return post$('/jobs/delete/' + id)
}

export const toggleActiveJob$ = (id) => {
    return post$('/jobs/toggle/' + id)
}

export const loadJobNotificationUsers$ = () => get$('/jobs/notificationusers')

export const searchPath$ = (path) => get$('/jobs/search?search=' + path)

export const saveJob$ = (form) => post$('/jobs/save', form)

export const copyJob$ = (id) => get$('/jobs/copy/' + id)