import React from 'react'
import Table from '../common/table/Table'
import Card, { Header, Body } from '../common/Card'

const UserListPending = ({ title, users, schema }) => {
  return (
    <Card className="card-settings">
      <Header title={title}>
        <div className="highlight">
          <span>Invitations sent but not yet accepted. When the user accepts your invitation you will see the user in the user list above.</span>
        </div>
      </Header>
      <Body>
        <Table data={users} schema={schema} />
      </Body>
    </Card>
  )
}

export default UserListPending;