import React, { useEffect, useCallback } from 'react'
import Explore, { ExploreCommands, ExploreBreadcrumbs, ExploreSearch, ExploreTable } from '../../../components/explore-and-share/Explore'
import { volumeFormatter } from '../../../common/volumeFormatter'
import { dateFormat } from '../../../common/dateFormatter'
import { NavLink } from 'react-router-dom'
import Loader from '../../../components/common/Loader'

const renderType = (data, activeAccountId) => {
    switch (data.type) {
        case "Folder":
            return (
                <div className="content">
                    <div><span className={data.isShared ? "table-icon icon icon-folder_shared" : "table-icon icon icon-folder"}></span></div>
                    <div className="text">
                        <span> {data.name}</span>
                        <small>{data.name === data.path ? "" : data.path}</small>
                    </div>
                </div>
            )
        case "FTP root":
            return (
                <div className="content">
                    <div><span className={data.isShared ? "table-icon icon icon-folder_shared" : "table-icon icon icon-folder"}></span></div>
                    <div className="text">
                        <span> {data.name}</span>
                        <small>{data.name === data.path ? "" : data.path}</small>
                    </div>
                </div>
            )
        case "File":
            return (
                <div className="content">
                    <span className="table-icon icon icon-file_1"></span>
                    <div className="text">
                        <span> {data.name}</span>
                        <small>{data.name === data.path ? "" : data.path}</small>
                    </div>
                </div>
            )
        case "File Server":
            return (
                <div className="content">
                    <div className="icon-warning"><span className="table-icon icon-fs_connector"></span></div>
                    <div>
                        <span> {data.name}</span>
                        <small>{data.name === data.path ? "" : data.path}</small>
                    </div>
                </div>
            )
        case "FTP Client":
            return (
                <div className="content">
                    <div className="icon-warning"><span className="table-icon icon-ftp_connector"></span></div>
                    <div className="text">
                        <span> {data.name}</span>
                        <small>{data.name === data.path ? "" : data.path}</small>
                    </div>
                </div>
            )
        case "Organization":
            return (
                <div className="content">
                    <div className="icon-warning"><span className="table-icon icon-organization"></span></div>
                    <div className="text">
                        <span> {data.name}</span>
                        <small>{data.name === data.path ? "" : data.path}</small>
                    </div>
                </div>
            )
        case "Account":
            return (
                <div className="content">
                    <div className="icon-warning"><span className="table-icon icon-home"></span></div>
                    <div className="text">
                        <span> {data.name}</span>
                        <small>{data.id === activeAccountId ? <span><i className="icon icon-circle text-selected"></i> Active account</span> : <span><i className="icon icon-link text-secondary"></i> Connected account</span>}</small>
                    </div>
                </div>
            )
        default:
            return (
                <div className="content">
                    <div className="icon-success"><i className="table-icon ti-panel"></i></div>
                    <div className="text">
                        <span> {data.name}</span>
                        <small>{data.name === data.path ? "" : data.path}</small>
                    </div>
                </div>
            )
    }
}

var refreshSync;

const ExploreDefault = ({
    view,
    onRowClick,
    currentPage,
    onPageChange,
    searchText,
    onSortChange,
    sortBy,
    onChange,
    command,
    onAction,
    executeCommand,
    isSearching,
    onSearchSub$,
    onSearchChange,
    initialState,
    isFetching,
    reset,
    activeAccount,
    download,
    match
}) => {

    const init = useCallback(() => {
        initialState(
            match.params.id,
            match.params.shareId,
            match.params.type, currentPage);
    }, [initialState, match, currentPage])

    useEffect(() => {

        const subscription = onSearchSub$.subscribe(onSearchChange);

        init();

        return () => {
            subscription.unsubscribe();
            reset();
        }

    }, [init, match.params.id, onSearchChange, onSearchSub$, reset])

    useEffect(() => {

        if (!view) {
            return;
        }

        const current = view.breadcrumbs[view.breadcrumbs.length - 1]

        if (current && current.status === 0) {

            refreshSync && clearInterval(refreshSync);

            refreshSync = setInterval(() => {
                if (isFetching) {
                    return;
                }
                init();
            }, 10000);
        } else {
            refreshSync && clearInterval(refreshSync);
        }

        return () => {
            refreshSync && clearInterval(refreshSync);
        };
    }, [view, isFetching, init])

    if (!view) {
        return <Loader title={isSearching ? "Still searching" : "Still loading"} />
    }
    const showCheckbox = !view.breadcrumbs ? false : view.breadcrumbs.length > 0 ? view.breadcrumbs[view.breadcrumbs.length - 1].type === "Folder" || view.breadcrumbs[view.breadcrumbs.length - 1].type === "FTP root" ? true : false : false

    const schema = [
        showCheckbox ?
            {
                header: "#",
                type: "checkbox",
                accessor: "isSelected",
                disabled: (data) => data.isLockedByJob
            } :
            {
                collapseIfEmpty: true
            },
        {
            header: "Name",
            type: "custom",
            accessor: "name",
            render: (data) => renderType(data, activeAccount.accountId)
        },
        {
            header: "Type",
            accessor: "type"
        },
        {
            header: "Account",
            accessor: "accountName",
            collapseIfEmpty: true
        },
        {
            header: "Size",
            type: "custom",
            accessor: "size",
            render: (data) => data.size > 0 ? volumeFormatter(data.size) : "--",
            collapseIfEmpty: true
        },
        {
            header: "Modified",
            type: "custom",
            accessor: "date",
            render: (data) => data.date ? dateFormat(data.date) : "--",
            collapseIfEmpty: true
        },
        {
            header: "Last activity",
            type: "custom",
            accessor: "lastActivity",
            render: (data) => data.lastActivity ? dateFormat(data.lastActivity) : "--",
            collapseIfEmpty: true
        },
        {
            type: "custom",
            render: (data) =>
                <div className="float-right">
                    {
                        (data.type === "Folder" || data.type === "FTP root") && data.shareId <= 0 && !data.isLockedByJob ?
                            <NavLink to={`/explore/${data.id}/${data.type}/share`} onClick={e => e.stopPropagation()}>
                                <div className="on-hover">
                                    <button className="btn btn-outline-primary"><span className="icon icon-export"></span> Share</button>
                                </div>
                            </NavLink>
                            : ""
                    }
                    {
                        data.isLockedByJob &&
                        <div className="table-icon icon-warning">
                            <span className="icon icon-lock text-secondary"></span>
                        </div>
                    }
                </div>
        }
    ]


    return (
        <Explore view={view}
            header={
                <div>
                    <ExploreBreadcrumbs breadcrumbs={view.breadcrumbs} />
                    <ExploreSearch
                        onSearchChange={(searchText) => {
                            if (!searchText) {
                                onSearchSub$.next('');
                                init();
                                return;
                            }
                            onSearchSub$.next(searchText);
                        }}
                        onClear={() => {
                            onSearchSub$.next('');
                            init();
                        }}
                        searchText={searchText} />
                    <ExploreCommands
                        selectedItems={view.items.filter(v => v.isSelected)}
                        command={command}
                        breadcrumbs={view.breadcrumbs}
                        onAction={onAction(match.params.id)}
                        onExecuteCommand={executeCommand(match.params.id)} />
                </div>
            }
            title={"Network / Explore & share"}
        >
            <ExploreTable
                view={view}
                schema={schema}
                onRowClick={onRowClick}
                currentPage={currentPage}
                onPageChange={onPageChange(match.params.id, match.params.shareId, match.params.type, sortBy)}
                onSortChange={isSearching ? null : onSortChange(match.params.id, match.params.shareId, match.params.type)}
                sortBy={sortBy}
                onChange={onChange}
                download={download} />
        </Explore >

    )
}

export default ExploreDefault;