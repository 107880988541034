import React, { useEffect } from "react";
import { connect } from "react-redux";

import OrganisationList from "../components/organisations/OrganisationList";
import { listOrganisations, editOrganisation } from "../actions/organisation";
import Loader from "../components/common/Loader";
import { removeOrganisation } from "../actions/account";
import { isFetching } from "../selectors/Fetching";
import { useModal } from "../components/modal/Modal";

const mapStateToProps = (state, ownProps) => ({
  isFetching:
    isFetching(state.fetching, listOrganisations) ||
    isFetching(state.fetching, editOrganisation) ||
    isFetching(state.fetching, removeOrganisation),
  organisations: state.organisation.organisations,
  accountId: state.auth.user.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  onSelect: (organisation) =>
    dispatch(editOrganisation(organisation.organisationId)),
  onRemove: (organisation) => dispatch(removeOrganisation(organisation)),
  initialState: (accountId) => dispatch(listOrganisations(accountId)),
});

const schema = [
  { accessor: "name", header: "Name" },
  { accessor: "orgNo", header: "Organization number" },
  {
    accessor: "created",
    header: "Created",
    type: "date",
    format: "YYYY-MM-DD HH:mm",
  },
];

const OrganisationsListContainer = ({
  initialState,
  accountId,
  isFetching,
  organisations,
  headers,
  onSelect,
  onRemove,
}) => {
  useEffect(() => {
    initialState(accountId);
  }, [accountId, initialState]);

  const { showModal } = useModal();

  if (isFetching || !organisations) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <OrganisationList
        organisations={organisations}
        schema={[
          ...schema,
          {
            type: "button",
            css: "btn btn-danger float-right",
            action: (org) => {
              showModal("Confirm", {
                title: "Delete organisation?",
                message:
                  "This will delete all connectors, jobs and shares connected to this organisation. Are you really sure?",
                confirmBtnText: "Delete",
                confirmBtnDanger: true,
                init: { next: onRemove, args: [org] },
              });
            },
            actionText: "Delete organisation",
          },
        ]}
        onOrganisationSelect={onSelect}
      />
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationsListContainer);
