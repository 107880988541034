import React from 'react'
import './LeftSideUser.css'

const LeftSideUser = ({ user }) => {

    return (
        <div className="login-info text-center">
            <div className="user-info">
                <div className="d-none d-md-flex">
                    <h5>
                        {user.name}
                    </h5>
                </div>
               
                <div className="email">{user.email}</div>

            </div>
            <div className="avatar d-none d-md-flex">
                <img alt="Gravatar" src={`https://s.gravatar.com/avatar/${user.gravatarHash}?d=https%3A%2F%2Fapp.cloudbuz.com%2Favatar_default.png&?s=120`} />
            </div>

            <div className="account">
                <h6 className="text-dark"><small>Account</small></h6>
                <p className="current">{ user.activeAccount.companyName }</p>
                {/*
                    user.accounts && user.accounts.length > 1 &&
                        <a className="switch">Switch account</a>
                    */
                }
            </div>

        </div>
    )

}


export default LeftSideUser;