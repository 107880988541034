import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "../actions/notification";

export const notification = (state = { notificationType: "none" }, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return  {
                ...state,
                notificationType: action.notificationType,
                notification: action.notification
            }
        case HIDE_NOTIFICATION:
            return {
                ...state,
                notificationType: action.notificationType,
                notification: action.notification
            }
        default:
            return state
    }
}