import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import EventDetails from "../../../components/user/jobs/EventDetails";
import {
  showFileDetails,
  loadJobRunEvent,
  fileFilterChanged,
} from "../../../actions/jobs";
import { volumeFormatter } from "../../../common/volumeFormatter";
import { isFetching } from "../../../selectors/Fetching";
import Progress from "../../../components/common/progress/Progress";
import Loader from "../../../components/common/Loader";

const FilterHeader = ({ noOfFiles, icon, description }) => {
  return (
    <div>
      <span className={icon}> </span> {description}{" "}
      <span
        className={
          noOfFiles > 0 ? "badge badge-primary" : "badge badge-secondary"
        }
      >
        {noOfFiles}
      </span>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state.fetching, loadJobRunEvent),
  details: state.jobs.event,
  selectedFilter: state.jobs.fileFilterId,
  currentFilesPage: state.jobs.currentFilesPage,
});

const mapDispatchToProps = (dispatch) => ({
  onFileSelect: (runId, eventId) => (file) =>
    dispatch(
      showFileDetails(runId, eventId, file.sysCommandId, file.sysCommandSubId)
    ),
  onFilterChange: (runId, eventId, currentFilesPage) => (filterId) =>
    dispatch(fileFilterChanged(filterId, runId, eventId, currentFilesPage)),
  initialState: (runId, eventId, selectedFilter, currentFilesPage) =>
    dispatch(loadJobRunEvent(runId, eventId, selectedFilter, currentFilesPage)),
  onPageChange: (runId, eventId, selectedFilter) => (currentFilesPage) =>
    dispatch(loadJobRunEvent(runId, eventId, selectedFilter, currentFilesPage)),
});

const getProgressColor = (status) =>
  status === "1"
    ? "success"
    : status === "10"
    ? "warning"
    : status === "0" || status === "20" || status === "21" || status === "10000"
    ? "primary"
    : "danger";

/*
0	Queued
1	Finished OK
10	Cancelled OK
20	Started.. Children must not execute
21	Started.. Children, execute allowed
100	MixedResult
9000	Error Queued
9001	Error Finished OK
9010	Error Assigned to client, waiting for completion
9020	Error Started.. Children must not execute
9021	Error Started.. Children, execute allowed
9999	GeneralError 
10000	RemovedQueued
19000	Removed Error Queued
19001	Removed Error Finished OK
19010	Removed Error Assigned to client, waiting for completion
19020	Removed Error Started.. Children must not execute
19021	Removed Error Started.. Children, execute allowed
19999	RemovedGeneralError 
*/

let EventDetailsContainer = ({
  initialState,
  match,
  selectedFilter,
  currentFilesPage,
  isFetching,
  details,
  onFileSelect,
  startAutoRefresh,
  cancelAutoRefresh,
  onFilterChange,
  onPageChange,
}) => {
  useEffect(() => {
    initialState(
      match.params.runId,
      match.params.eventId,
      selectedFilter,
      currentFilesPage
    );

    const interval = setInterval(() => {
      initialState(
        match.params.runId,
        match.params.eventId,
        selectedFilter,
        currentFilesPage
      );
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [
    currentFilesPage,
    initialState,
    match.params.eventId,
    match.params.runId,
    selectedFilter,
  ]);

  const onRefresh = useCallback(() => {
    console.log("on refresh");
    initialState(
      match.params.runId,
      match.params.eventId,
      selectedFilter,
      currentFilesPage
    );
  }, [
    currentFilesPage,
    initialState,
    match.params.eventId,
    match.params.runId,
    selectedFilter,
  ]);

  if (
    !details ||
    (isFetching && details.sysEventId !== parseInt(match.params.eventId, 10))
  ) {
    return <Loader title="Loading event" startDelay={0} />;
  }

  let filesSchema = [
    { accessor: "sourceFileName", header: "Source filename" },
    { accessor: "targetFileName", header: "Target filename" },
    {
      accessor: "totalBytes",
      header: "Size",
      type: "custom",
      render: (data) => volumeFormatter(data.totalBytes),
    },
    {
      type: "custom",
      header: "Progress",
      render: (data) => (
        <Progress
          value={data.progress}
          className={"bg-" + getProgressColor(data.statusId)}
          height="16px"
          fontSize="14px"
        />
      ),
    },
    { accessor: "start", header: "Start", type: "date" },
    { accessor: "stop", header: "Stop", type: "date" },
  ];

  let runId = match.params.runId;

  let filters = [];

  if (details.totalInProgressFiles > 0) {
    filters.push({
      description: (
        <FilterHeader
          noOfFiles={details.totalInProgressFiles}
          icon="icon icon-circle-notch animate-spin text-primary"
          description="In progress"
        />
      ),
      id: 1,
    });
  }
  if (details.totalErrorFiles > 0) {
    filters.push({
      description: (
        <FilterHeader
          noOfFiles={details.totalErrorFiles}
          icon="icon icon-warn1 text-danger"
          description="Error"
        />
      ),
      id: 2,
    });
  }
  if (details.totalOkFiles > 0) {
    filters.push({
      description: (
        <FilterHeader
          noOfFiles={details.totalOkFiles}
          icon="icon icon-ok text-success"
          description="Finished OK"
        />
      ),
      id: 3,
    });
  }
  if (details.totalCanceledFiles > 0) {
    filters.push({
      description: (
        <FilterHeader
          noOfFiles={details.totalCanceledFiles}
          icon="icon icon-ok-circled text-warning"
          description="Canceled"
        />
      ),
      id: 4,
    });
  }

  return (
    <EventDetails
      runId={match.params.runId}
      title={`Jobs / Runs`}
      details={details}
      files={details.files}
      filesSchema={filesSchema}
      onFileSelect={onFileSelect(runId, details.sysEventId)}
      onRefresh={onRefresh}
      filters={filters}
      selectedFilter={selectedFilter}
      onFilterChange={onFilterChange(runId, details.sysEventId, 1)}
      onPageChange={onPageChange(runId, details.sysEventId, selectedFilter)}
      currentFilesPage={currentFilesPage || 1}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetailsContainer);
