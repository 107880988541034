const search = (state = {}, action) => {
    switch (action.type) {
        case 'ON_SEARCH':
            return 
        default:
            return state
    }
}


export default search;