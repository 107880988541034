import React from "react";
import TablePaginator from "../common/table/Paginator";
import Table from "../common/table/Table";

const ReportListComponent = ({ view, schema, page, onPageChange }) => {
  return (
    <>
      <Table
        data={view?.reports}
        schema={schema}
        infoIfEmpty="No reports found.."
      />
      <TablePaginator
        currentPage={page ?? 0}
        totalPages={view?.totalPages}
        onPageChange={onPageChange}
        pageNeighbours={3}
      />
    </>
  );
};

export default ReportListComponent;
