import React from 'react'
import { reduxForm, FieldArray } from 'redux-form'
import CreateEmailNotificationForm from './CreateEmailNotificationForm'
import CreateAccountNotificationForm from './CreateAccountNotificationForm'
import CreateUserNotificationForm from './CreateUserNotificationForm'
import { NavLink } from 'react-router-dom'

import {
    getJobPermission,
    getJobNotificationType
} from '../../../selectors/Job'
import Card, { Body, Header } from '../../common/Card';
import { IconButton } from '../../common/Buttons';

const renderRows = (fields) => {
    return fields.map((f, i) => {
        const field = fields.get(i);
        return (
            <div className="row table-item" key={i}>
                <div className="col-md-12">
                    <div className="row table-row-item share-not-table">
                        <div className="col-4 d-md-none">
                            {field.userId ? "Name:" : ""}
                            {field.accountId ? "Account:" : ""}
                            {field.email ? "E-mail:" : ""}
                        </div>
                        <div className="col-8 col-md-3">
                            {field.name || field.email}
                        </div>
                        {   
                            field.jobShareType ?  
                            <React.Fragment>
                                <div className="col-4 d-md-none">
                                    Permission:
                                </div>
                                <div className="col-8 col-md-3">
                                    { 
                                    field.jobShareType &&
                                    getJobPermission(field.jobShareType).name
                                    }
                                </div>
                            </React.Fragment>
                             : <div className="col-md-3 d-none d-md-block"></div>
                        }
                        <div className="col-4 d-md-none">
                            Notification: 
                        </div>
                        <div className="col-8 col-md-3">
                            {field.notificationType ? getJobNotificationType(field.notificationType).name : ""}
                        </div>
                        <div className="col-4 d-md-none">
                            If job runs empty: 
                        </div>
                        <div className="col-8 col-md-2">
                            {field.withContentOnly ? "Skip notification" : "Send notification anyway"}
                        </div>
                        <div className="col-12 col-md-1 text-right">
                         {/* <button type="button" className="btn btn-outline-danger" onClick={() => fields.remove(i)}>Delete</button>*/}   
                            <IconButton className="" icon="icon-trash" type="danger" tooltip="Delete" tooltipplacement="top" onClick={() => fields.remove(i)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    });
}

const renderUsers = ({ fields, meta: { touched, error }, notificationUsers }) => {

    const addUser = (values) => {
        const user = notificationUsers.filter(x => x.userId.toString() === values.account)[0]
            fields.push({ 
            withContentOnly: values.withContentOnly, 
            userId: values.account, name: user.fullName, 
            notificationType: values.type, 
            jobShareType: values.jobPermission })
    }

    const avalibleUsers = fields.getAll() ? notificationUsers.filter(item => {
        return fields.getAll().map(m => m.userId.toString()).indexOf(item.userId.toString()) < 0
    }) : notificationUsers;


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <h6 className="wiz-form-header">Share with users (none admins) on this account</h6>
                </div>
            </div>
            {notificationUsers.length > 0 ? 
                <div className="content pt-0">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3 p-3 d-none d-md-block">
                                        <strong>User</strong>
                                    </div>
                                    <div className="col-md-3 p-3 d-none d-md-block">
                                        <strong>Job permission</strong>
                                    </div>
                                    <div className="col-md-3 p-3 d-none d-md-block">
                                        <strong>Notification</strong>
                                    </div>
                                    <div className="col-md-2 p-3 d-none d-md-block">
                                        <strong>If job runs empty</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderRows(fields)}
                        <CreateUserNotificationForm onSubmit={addUser} users={avalibleUsers.map((a, index) => { return { id: a.userId, name: a.fullName } })} />
                    </div>
                </div>
                :
                <div className="alert highlight" role="alert">
                    <span>
                        There are only account administrators on this Cloudbuz account. All account administrators has full privilledges on this job. You can invite other users to this account from the account settings page. &nbsp;
                        <NavLink activeClassName="active" to="/admin/settings/users" >
                            Invite a new user
                        </NavLink>
                    </span>
                </div>
            }
        </React.Fragment>
    )
}

const renderAccounts = ({ fields, meta: { touched, error }, notificationAccounts }) => {
    const addAccount = (values) => {
        
        const account = notificationAccounts.filter(x => x.accountKey.toString() === values.account.toString())[0]
        fields.push({ 
            withContentOnly: values.withContentOnly,
             accountId: values.account, 
             name: account.accountName, 
             notificationType: values.type, 
             jobShareType: values.jobPermission 
        })
    }

    const avalibleAccounts = fields.getAll() ? notificationAccounts.filter(item => {
        return fields.getAll().map(m => m.accountId.toString()).indexOf(item.id.toString()) < 0
    }) : notificationAccounts;


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <h6 className="wiz-form-header">Share with admins on connected accounts</h6>
                </div>
            </div>
            {notificationAccounts.length > 0 ? 
                <div className="content pt-0">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3 p-3 d-none d-md-block">
                                        <strong>Account</strong>
                                    </div>
                                    <div className="col-md-3 p-3 d-none d-md-block">
                                        <strong>Job permission</strong>
                                    </div>
                                    <div className="col-md-3 p-3 d-none d-md-block">
                                        <strong>Notification</strong>
                                    </div>
                                    <div className="col-md-2 p-3 d-none d-md-block">
                                        <strong>If job runs empty</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderRows(fields)}
                        <CreateAccountNotificationForm onSubmit={addAccount} accounts={avalibleAccounts.map((a, index) => { return { id: a.accountKey, name: a.accountName } })} />
                    </div>
                </div>
                : 
                <div className="alert highlight" role="alert">
                    <span>
                        There are no connected Cloudbuz accounts.  &nbsp;
                        <NavLink activeClassName="active" to="/admin/extendnetwork/invites" >
                            Connect with other accounts.
                        </NavLink>
                    </span>
                </div>
            }
        </React.Fragment>
    )
}

const renderEmails = ({ add, fields, meta: { touched, error } }) => {

    const addEmailNotification = (values) => {
        const emailNotifications = fields.getAll();

        if (emailNotifications) {
            const existingEmailNotification = emailNotifications.filter(item => item.email === values.email);
            if (existingEmailNotification.length > 0)
                return;
        }

        fields.push({ 
            withContentOnly: values.withContentOnly, 
            email: values.email, 
            notificationType: values.type 
        })
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <h6 className="wiz-form-header">Send notifications to none users (e-mail)</h6>
                </div>
            </div>
            <div className="content pt-0">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6 p-3 d-none d-md-block">
                                    <strong>Email</strong>
                                </div>
                                <div className="col-md-3 p-3 d-none d-md-block">
                                    <strong>Notification</strong>
                                </div>
                                <div className="col-md-3 p-3 d-none d-md-block">
                                    <strong>If job runs empty</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    {renderRows(fields)}
                    <CreateEmailNotificationForm onSubmit={addEmailNotification} />
                </div>
            </div>
        </React.Fragment>
    )
}


const CreateJobWizzardStepFour = props => {

    const { addEmailNotification, notificationUsers, notificationAccounts } = props;

    return (
        <Card>
            <Header title="Share & notifications">
                <div className="alert highlight" role="alert">
                    <span>
                        Share this job with users, account admins on connected accounts or just send e-mail notifications to none users.
                    </span>
                </div>
            </Header>
            <Body>
                <FieldArray name="notificationUsers" component={renderUsers} notificationUsers={notificationUsers} />
                <FieldArray name="notificationAccounts" component={renderAccounts} notificationAccounts={notificationAccounts} />
                <FieldArray name="notificationEmails" component={renderEmails} add={addEmailNotification} />
            </Body>
        </Card>)
}

export default reduxForm({
    form: 'create-job-wizzard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(CreateJobWizzardStepFour)