import React from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "../common/ReduxFormFieldValidation";
import LoginCard, { Header, Body } from "../common/LoginCard";
import CbLogo from "../../images/logo_white.png";

const renderField = ({
    input,
    placeholder,
    type,
    name,
    autoComplete,
    dataTestId,
    meta: { touched, error, warning },
    tooltip,
}) => (
    <div className="form-group">
        <input
            className="form-control form-control-lg border-input"
            {...input}
            placeholder={placeholder}
            type={type}
            autoComplete={autoComplete}
            data-testid={dataTestId}
        />
        {touched &&
            ((error && (
                <em htmlFor={name} className="invalid">
                    {tooltip}
                </em>
            )) ||
                (warning && <span>{warning}</span>))}
    </div>
);

const TwoFactorComponent = ({ cancel, handleSubmit, qrCodeUrl, errors, submitting }) => {
    const showError =
        errors && errors.length
            ? errors.map((e, i) => (
                <div
                    key={i}
                    className="alert alert-danger alert-dismissible fade show"
                >
                    <span>{e}</span>
                    <button
                        type="button"
                        className="close p-2"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            ))
            : "";

    return (
        <div>
            <div className="logo_login_placeholder">
                <img
                    src={CbLogo}
                    height="30px"
                    alt="Cloudbuz Manager"
                    className="logo_login"
                />
            </div>
            <LoginCard>
                <Header title="Two-factor validation" />
                <Body>
                    <form data-testid="form" onSubmit={handleSubmit} className="content">
                        {qrCodeUrl && <img alt="Authenticator QR Code" src={qrCodeUrl} />}
                        <fieldset>
                            <Field
                                name="twofactorCode"
                                id="twofactorCode"
                                component={renderField}
                                type="text"
                                placeholder="Two-factor validation code"
                                validate={required}
                                icon="fa-lock"
                                tooltip="Please enter your authenticator code"
                            />

                            {showError}

                            <button
                                type="submit"
                                className="btn btn-primary btn-lg btn-block"
                                disabled={submitting}
                            >
                                Complete login
                            </button>
                            <div className="text-center mb-3">or</div>
                            <button
                                type="button"
                                className="btn btn-primary btn-lg btn-block"
                                disabled={submitting}
                                onClick={cancel}
                            >
                                Cancel
                            </button>
                        </fieldset>
                        <footer>
                            <div className="text-center">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>

                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </form>
                </Body>
            </LoginCard>
            <p className="greetings">
                Made with <i className="icon icon-heart love"></i> by{" "}
                <a className="text-white" href="https://www.zavanto.se">
                    Zavanto
                </a>
            </p>
        </div>
    );
};

export default reduxForm({
    form: "two-factor", // a unique identifier for this form
})(TwoFactorComponent);
