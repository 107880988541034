import React from 'react'
import PropTypes from 'prop-types'
import Card, { Body} from '../../common/Card'
import PageContent, { ContentHeader, ContentBody } from '../../common/PageContent';
import './RunDetails.css'

import Table from '../../common/table/Table';
import DateFormat from '../../common/DateFormat';
import { volumeFormatter } from '../../../common/volumeFormatter';
import Progress from '../../common/progress/Progress';
import RunStatusInfo from './RunStatusInfo';
import { NavLink } from 'react-router-dom'
import Refresh from './Refresh';


const getProgressColor = (status) => status==="1" ? "success" : status==="9999" || status==="99999" ? "danger" : status==="400" ? "warning" : "primary";
const getStatusType = (status) => status==="1" ? "success" : status==="9999" || status==="99999" ? "error" : status==="400" ? "errorAcknowledged" : "inProgress";


const Details = ({ details }) => {
  return (

        <div className="row m-0 runSummary">
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Job name
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{details.name}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Job run id
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{details.sysJobId}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Total volume
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{volumeFormatter(details.totalBytes)}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Processed events
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{details.totalHandeledEvents} of {details.totalEvents}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Processed files
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{details.totalHandeledFiles} of {details.totalFiles}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Run status
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><RunStatusInfo type={getStatusType(details.statusId)}/></li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Run started
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><DateFormat date={details.start} /></li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                Run stopped
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><DateFormat date={details.stop} /></li>
              </ul>
            </div>
          </div>
        </div>

  )
}

const Events = ({ events, schema, onSelect }) => {
  return <Table data={events} schema={schema} onRowClick={onSelect} />
}

const RunDetails = ({ title, details, events, eventsSchema, onEventSelect, onRefresh, executeActionOnRun }) => {

  const hasErrors = details.statusId === "9999" || details.statusId === "99999" ? 
    <div className="alert highlight">
        <h6 className="alert-heading text-danger">
            <i className="icon icon-attention-circled"></i> An error occurred
        </h6>
      <strong>Message:</strong> {details.errorMessage} 
      <p className="mt-3"><small><i className="icon icon-info-circled text-primary"></i> For more detailed log information try downloading local log files from involved connectors. Check out involved connector details page <NavLink to={`/admin/connectors/`} exact>@admin/connectors</NavLink> where download is available.</small></p>
      <hr/> 
      <button className="btn btn-light" onClick={() => executeActionOnRun(details.sysJobId, 'acknowledge')}><i className="icon icon-ok"></i><span> </span >Acknowledge</button></div> 
      : details.statusId === "400" ? <div className="alert alert-empty">
      <h6 className="alert-heading"><span className="badge badge-success"><i className="icon icon-ok"></i> Error Acknowledged</span></h6>
      <strong>Message:</strong> {details.errorMessage} </div>
      : ""

  return (
    <PageContent className="run-details border-0">
      <ContentHeader title={title}>
        <ol className="breadcrumb border">
            <li className="breadcrumb-item d-flex align-items-center">
                <NavLink to={`/jobs/runs/`} exact>
                    <span>Runs</span>
                </NavLink>
            </li>
            <li className="breadcrumb-item active d-flex align-items-center" aria-current="page">
              <span>Job run {details.sysJobId}</span>
            </li>
        </ol>
        {hasErrors}
      </ContentHeader>
      <ContentBody>
        <Card className="p-0 m-0 border-0">
          <Body className="p-0">
            <div className="row m-0">
              <div className="col-6 p-0">
                <h6>Job run progress</h6>
              </div>
              <div className="col-6 p-0">
                <div className="float-right"><Refresh onRefresh={onRefresh} /></div>
              </div>
            </div>
            
            <Progress value={details.progress} className={"bg-" + getProgressColor(details.statusId)} />

            <h6 className="mt-3">Job run summary</h6>
            <Details details={details} />
            
            <h6 className="mt-3">Job run events</h6>
            <Events events={events} schema={eventsSchema} onSelect={onEventSelect} />
          
          </Body>
        </Card>
      </ContentBody>
    </PageContent>
  )
}

RunDetails.propTypes = {
  details: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  eventsSchema: PropTypes.array.isRequired,
  onEventSelect: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  executeActionOnRun: PropTypes.func
}

export default RunDetails

