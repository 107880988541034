import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import ConnectorsList from "../components/connectors/ConnectorsList";
import PendingRequestsList from "../components/connectors/PendingRequestsList";

import Loader from "../components/common/Loader";
import {
  loadConnectorsList,
  deleteConnectorInstallmentRequest,
} from "../actions/connectors";
import { isFetching } from "../selectors/Fetching";
import downloadFile from "../common/downloadFile";
import {
  showNotificationError,
  showNotificationSuccess,
} from "../actions/notification";

const mapStateToProps = (state) => {
  return {
    isFetching: isFetching(state.fetching, loadConnectorsList),
    connectors: state.connectors.connectors,
    pendingRequests: state.connectors.pendingRequests,
    connectorTypes: state.connectors.connectorTypes,
  };
};

const mapDispatchToPros = (dispatch) => {
  return {
    initalStateConnectors: () => dispatch(loadConnectorsList()),
    onConnectorSelect: (connector) => {
      return connector.rights > 0
        ? dispatch(push("/admin/connectors/details/" + connector.clientKey))
        : dispatch(
            showNotificationError("You are unauthorized to perform this action")
          );
    },
    revokeRequest: (data) =>
      dispatch(deleteConnectorInstallmentRequest(data.token)),
    download: () => {
      downloadFile(
        process.env.REACT_APP_DOWNLOAD_CONNECTOR_URL,
        "FileServerConnector_Setup.exe"
      )
        .then((msg) => {
          dispatch(showNotificationSuccess(msg));
        })
        .catch((msg) => {
          dispatch(showNotificationError(msg));
        });
    },
  };
};

const pendingRequestSchema = [
  { accessor: "to", header: "Request to" },
  { accessor: "sent", header: "Sent", type: "date" },
  { accessor: "token", header: "Token" },
  { accessor: "organization", header: "Organisation" },
];

const ConnectorsListContainer = ({
  initalStateConnectors,
  isFetching,
  connectors,
  pendingRequests,
  onConnectorSelect,
  revokeRequest,
  download,
}) => {
  const connectorsSchema = useMemo(
    () => [
      {
        accessor: "name",
        header: "Server",
        type: "custom",
        render: (data) => (
          <span>
            <i className="icon icon-fs_connector"></i> {data.name}
          </span>
        ),
      },
      { accessor: "description", header: "Description" },
      { accessor: "type", header: "Type" },
      { accessor: "organization", header: "Organisation" },
      {
        type: "custom",
        header: "Status",
        render: (data) =>
          data.status === "OK" ? (
            <i
              className="icon icon-ok-circled text-success"
              title={data.status}
            ></i>
          ) : (
            <span className={"badge badge-danger"}>
              <i className="icon icon-attention-circled"></i> {data.status}
            </span>
          ),
      },
    ],
    []
  );

  useEffect(() => {
    initalStateConnectors();
  }, [initalStateConnectors]);

  const revoke = {
    type: "custom",
    render: (data, schema, rowIndex, onRowClick, onChange) => (
      <button
        className="btn btn-danger float-right text-nowrap"
        onClick={() => {
          revokeRequest(data);
        }}
      >
        <i className="icon icon-trash"></i> Revoke
      </button>
    ),
  };

  return isFetching ? (
    <Loader />
  ) : (
    <div>
      <ConnectorsList
        connectors={connectors}
        schema={connectorsSchema}
        onConnectorSelect={onConnectorSelect}
        download={download}
      />
      <PendingRequestsList
        pendingRequests={pendingRequests}
        schema={[...pendingRequestSchema, revoke]}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToPros
)(ConnectorsListContainer);
