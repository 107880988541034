import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import Card, { Body } from "../../common/Card";

const NewCreditCard = ({ className = "", info, onChange }) => {
  return (
    <Card className={className + " "}>
      <Body>
        <div className="form-group">
          <input
            value={info.name}
            onChange={(e) => onChange({ ...info, name: e.target.value })}
            className="form-control mt-3"
            type="text"
            placeholder="Name"
            aria-label="default input example"
            required={true}
          ></input>
          <input
            value={info.email}
            onChange={(e) => onChange({ ...info, email: e.target.value })}
            className="form-control mt-3"
            type="email"
            placeholder="Email"
            aria-label="default input example"
            required={true}
          ></input>
          <input
            value={info.phone}
            onChange={(e) => onChange({ ...info, phone: e.target.value })}
            className="form-control mt-3"
            type="phone"
            placeholder="Phone"
            aria-label="default input example"
            required={true}
          ></input>
        </div>
        <div className="form-group">
          <div
            id="card-element"
            className="form-control"
            style={{ height: "2.4em", paddingTop: ".7em" }}
          >
            <CardElement></CardElement>
          </div>
        </div>
      </Body>
    </Card>
  );
};

export default NewCreditCard;
