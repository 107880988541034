import {
    SET_FORM_DATA_NOTIFICATION_SETTINGS,
    LOAD_NOTIFICATION_SETTINGS_SUCCESS,
    SET_JOB_NOTIFICATION_SETTINGS
} from '../actions/notificationsettings'

export const notificationsettings = (state = {
    wantsEmail: false,
    wantsSms: false,
    wantsWeb: false,
    wantsJob: false,
    wantsSystem: false,
    wantsPromotional: false,
    selectedJobNotification: 0,
    jobNotifications: []
}, action) => {
    switch (action.type) {
        case SET_FORM_DATA_NOTIFICATION_SETTINGS:
            return {
                ...state,
                settings: { ...state.settings, ...{ [action.key]: action.value}}
            }
        case SET_JOB_NOTIFICATION_SETTINGS:
            return {
                ...state,
                settings: { ...state.settings, ...{ [action.key]: action.value}}
            }
        case LOAD_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.settings,
                jobSettings: action.jobSettings
            }
        default:
            return state
    }
}