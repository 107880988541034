import * as api from "../api";

import { switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import {
  LOAD_JOBS_LIST,
  loadJobsListSuccess,
  LOAD_CREATE_JOBS,
  loadCreateJobsSuccess,
  SHOW_RUN_DETAILS,
  LOAD_SCHEDULE,
  SHOW_EVENT_DETAILS,
  SHOW_FILE_DETAILS,
  LOAD_JOB_NOTIFICATION_USERS,
  loadJobNotificationUsersSuccess,
  LOAD_JOB_RUNS,
  loadJobRunsSuccess,
  loadJobRunSuccess,
  LOAD_JOB_RUN,
  LOAD_JOB_RUN_EVENT,
  LOAD_JOB_RUN_FILE,
  loadJobRunEventSuccess,
  loadJobRunFileSuccess,
  loadJobRunEvent,
  FILE_FILTER_CHANGED,
  loadJobRuns,
  RUNS_CURRENTPAGE_CHANGE,
  EXECUTE_ACTION_ON_RUN,
  loadJobRun,
  LOAD_JOB_VIEW,
  loadJobViewSuccess,
  JOBS_CURRENTPAGE_CHANGE,
  loadJobView,
  EXECUTE_ACTION_ON_JOB,
  SAVE_JOB,
  savedJobSuccess,
  SAVED_JOB_SUCCESS,
  LOAD_JOB,
  loadJobSuccess,
  DELETE_JOB,
  TOGGLE_ACTIVE_JOB,
  deleteJobSuccess,
  toggleActiveJobSuccess,
  TOGGLE_ACTIVE_JOB_SUCCESS,
  DELETE_JOB_SUCCESS,
  loadSchduleSuccess,
  LOAD_SCHEDULE_SUCCESS,
  COPY_JOB,
  COPY_JOB_SUCCESS,
  copyJobSuccess,
  CREATE_NEW_JOB,
} from "../actions/jobs";

import { showNotificationSuccess } from "../actions/notification";

import { handleError } from "./common";
import { push } from "connected-react-router";

import { change, clearFields, initialize } from "redux-form";

export const loadJobEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_JOB),
    switchMap((action) => {
      return api.getJob$(action.id).pipe(
        mergeMap((response) =>
          of(
            initialize("create-job-wizzard", response, false, false, false),
            loadJobSuccess(response)
          )
        ),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const listAllJobsEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_JOBS_LIST),
    switchMap((action) => {
      return api.listAllJobs$(action.page, action.text, action.status, action.sortBy).pipe(
        map((response) => {
          return loadJobsListSuccess(response);
        }),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const refreshAllJobsEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(TOGGLE_ACTIVE_JOB_SUCCESS, DELETE_JOB_SUCCESS, COPY_JOB_SUCCESS),
    switchMap((action) => {
      return api
        .listAllJobs$(
          state$.value.jobs.currentJobsPage,
          state$.value.jobs.text,
          state$.value.jobs.sortBy
        )
        .pipe(
          map((response) => {
            return loadJobsListSuccess(response);
          }),
          catchError(handleError(action$, action.type))
        );
    })
  );
};

export const loadCreateJobInitalDataEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_CREATE_JOBS),
    switchMap((action) => {
      return api.loadCreateJobInitalData$().pipe(
        map((response) => {
          return loadCreateJobsSuccess(response.schedules);
        }),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const loadScheduleEpic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_SCHEDULE),
    switchMap((action) => {
      return api.loadSchedule$(action.scheduleId).pipe(
        map((response) => {
          return loadSchduleSuccess(response.schedule);
        }),
        catchError(handleError(action$, action.type))
      );
    })
  );
};

export const loadScheduleStep2Epic$ = (action$) => {
  return action$.pipe(
    ofType(LOAD_SCHEDULE_SUCCESS),
    map((action) => {
      return change("create-job-wizzard", "schedule", action.schedule);
    })
  );
};

export const loadNotificationUsersEpic$ = (action$) =>
  action$.pipe(
    ofType(LOAD_JOB_NOTIFICATION_USERS),
    switchMap((action) =>
      api.loadJobNotificationUsers$().pipe(
        map((response) =>
          loadJobNotificationUsersSuccess(
            response.availableAccounts,
            response.availableUsers
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const showRunDetailEpic$ = (action$) =>
  action$.pipe(
    ofType(SHOW_RUN_DETAILS),
    map((action) => push(`/jobs/runs/${action.runId}`))
  );

export const showEventDetailEpic$ = (action$) =>
  action$.pipe(
    ofType(SHOW_EVENT_DETAILS),
    map((action) => push(`/jobs/runs/${action.runId}/events/${action.eventId}`))
  );

export const showFileDetailsEpic$ = (action$) =>
  action$.pipe(
    ofType(SHOW_FILE_DETAILS),
    map((action) =>
      push(
        `/jobs/runs/${action.runId}/events/${action.eventId}/files/${action.fileId}/${action.fileSubId}`
      )
    )
  );

export const loadJobRunsEpic$ = (action$) =>
  action$.pipe(
    ofType(LOAD_JOB_RUNS),
    switchMap((action) =>
      api.loadJobRuns$(action.filters, action.currentRunsPage).pipe(
        map((response) => loadJobRunsSuccess(response.response)),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const loadJobRunEpic$ = (action$) =>
  action$.pipe(
    ofType(LOAD_JOB_RUN),
    switchMap((action) =>
      api.loadJobRun$(action.runId).pipe(
        map((response) => loadJobRunSuccess(response)),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const loadJobRunEventEpic$ = (action$) =>
  action$.pipe(
    ofType(LOAD_JOB_RUN_EVENT),
    switchMap((action) =>
      api
        .loadJobRunEvent$(
          action.runId,
          action.eventId,
          action.filterFilesId,
          action.currentFilesPage
        )
        .pipe(
          map((response) => loadJobRunEventSuccess(response)),
          catchError(handleError(action$, action.type))
        )
    )
  );

export const saveJobEpic$ = (action$) =>
  action$.pipe(
    ofType(SAVE_JOB),
    switchMap((action) => {
      return api.saveJob$(action.request).pipe(
        map((r) => savedJobSuccess()),
        catchError(handleError(action$, action.type))
      );
    })
  );

export const savedJobSuccessEpic$ = (action$) =>
  action$.pipe(
    ofType(SAVED_JOB_SUCCESS),
    mergeMap((action) =>
      of(clearFields("create-job-wizzard", false), push("/admin/jobs"))
    )
  );

export const navigateNewJobEpic$ = (action$) =>
  action$.pipe(
    ofType(CREATE_NEW_JOB),
    mergeMap((action) =>
      of(
        initialize("create-job-wizzard", {}, false, false, false),
        push("/admin/jobs/new")
      )
    )
  );

export const loadJobRunFileEpic$ = (action$) =>
  action$.pipe(
    ofType(LOAD_JOB_RUN_FILE),
    switchMap((action) =>
      api
        .loadJobRunFile$(
          action.runId,
          action.eventId,
          action.fileId,
          action.fileSubId
        )
        .pipe(
          map((response) => loadJobRunFileSuccess(response)),
          catchError(handleError(action$, action.type))
        )
    )
  );

export const onFilesFilterChangeEpic$ = (action$) =>
  action$.pipe(
    ofType(FILE_FILTER_CHANGED),
    map((action) =>
      loadJobRunEvent(
        action.runId,
        action.eventId,
        action.fileFilterId,
        action.currentFilesPage
      )
    )
  );

export const onRunsCurrentPageChangeEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(RUNS_CURRENTPAGE_CHANGE),
    map((action) =>
      loadJobRuns(state$.value.jobs.selectedRunsFilter, action.currentRunsPage)
    )
  );

export const onExcuteActionOnRunEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(EXECUTE_ACTION_ON_RUN),
    switchMap((action) =>
      api.executeActionOnRun$(action.jobRunId, action.action).pipe(
        map((response) =>
          action.target === "details"
            ? loadJobRun(action.jobRunId)
            : loadJobRuns(
                state$.value.jobs.selectedRunsFilter,
                state$.value.jobs.currentRunsPage
              )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const loadJobsView$ = (action$) =>
  action$.pipe(
    ofType(LOAD_JOB_VIEW),
    switchMap((action) =>
      api
        .loadJobView$(action.text, action.currentJobsAllPage, action.sortAllBy)
        .pipe(
          map((response) => loadJobViewSuccess(response)),
          catchError(handleError(action$, action.type))
        )
    )
  );

export const onJobsCurrentPageChangeEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(JOBS_CURRENTPAGE_CHANGE),
    map((action) => loadJobView(action.currentJobsAllPage))
  );

/*
export const onExcuteActionOnJobEpic$ = (action$, state$) => action$.pipe(
    ofType(EXECUTE_ACTION_ON_JOB),
    switchMap(action =>
        api.executeActionOnJob$(action.jobId, action.action).pipe(
            map(response => loadJobView(action.currentJobsPage, action.text)),
            catchError(handleError(action$, action.type))
        ))
)

*/

export const onExcuteActionOnJobEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(EXECUTE_ACTION_ON_JOB),
    switchMap((action) =>
      api.executeActionOnJob$(action.jobId, action.action).pipe(
        mergeMap((response) =>
          of(
            loadJobView(action.currentJobsPage, action.text),
            showNotificationSuccess(
              action.action === "run"
                ? `Job (${action.jobId}) queued for run`
                : `Action ${action.action} on job (${action.jobId}) succeeded`
            )
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const deleteJobEpic$ = (action$) =>
  action$.pipe(
    ofType(DELETE_JOB),
    switchMap((action) =>
      api.deleteJob$(action.jobId).pipe(
        map((action) => deleteJobSuccess()),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const toggleActiveJobEpic$ = (action$) =>
  action$.pipe(
    ofType(TOGGLE_ACTIVE_JOB),
    switchMap((action) =>
      api.toggleActiveJob$(action.jobId).pipe(
        map((action) => toggleActiveJobSuccess()),
        catchError(handleError(action$, action.type))
      )
    )
  );

export const copyJobEpic$ = (action$) =>
  action$.pipe(
    ofType(COPY_JOB),
    switchMap((action) =>
      api.copyJob$(action.id).pipe(
        mergeMap((action) =>
          of(
            copyJobSuccess(),
            showNotificationSuccess("Job copied successful!")
          )
        ),
        catchError(handleError(action$, action.type))
      )
    )
  );
